/* eslint-disable prettier/prettier */
import { Button, Grid, InputAdornment, TextField, IconButton, Typography, Stack, Tabs, Tab } from '@mui/material';
import { Add, Remove, BorderColor, Delete } from '@mui/icons-material';
import { useFormik } from 'formik';
import useClubs from 'hooks/useClubs';
import FormSection from 'views/admin/Events/create-event/components/FormSection';
import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import actionSnackbar from 'ui-component/actionSnackbar';
import SaveFooter from 'views/admin/AdminHub/components/SaveFooter';
import FormComponents from 'ui-component/FormComponents';
import CardModal from 'ui-component/CardModal';
import CustomTable from 'ui-component/table/CustomTable';
import CurrencyFormat from 'react-currency-format';
import { useNavigate, useSearchParams } from 'react-router-dom';
import capitalizeWords from 'utils/capitalizeWords';

const MembershipList = ({ clubId, toggleMembershipCreationModal }) => {
    const { clubMemberships, membershipTab, handleMembershipTabChange, setValue, getClubMemberships, loading } = useClubs();
    const [queryParams] = useSearchParams();
    const page = queryParams.get('page');
    const [data, setData] = useState([])
    const [view, setView] = useState('individual');
    useEffect(() => {
        if (page) {
            setView(page);
        }
    }, [page]);
    const headers = [
        { id: 'name', label: 'Name' },
        { id: 'short_code', label: 'Short Code' },
        { id: 'members', label: 'Members' },
        { id: 'initiation_fee', label: 'Initiation Fee' },
        { id: 'monthly_price', label: 'Monthly Fee' },
        { id: 'annual_price', label: 'Annual Fee' }
    ];
    const navigate = useNavigate();

    useEffect(() => {
        if (clubId) {
            getClubMemberships(clubId, view)
        }

    }, [clubId, view])

    useEffect(() => {
        setData(clubMemberships.map((item) => {
            const monthItem = item.pricing.find((price) => price.term === 'monthly')
            const monthlyPrice = item.pricing.find((price) => price.term === 'monthly')?.price / 100
            const annualPrice = item.pricing.find((price) => price.term === 'annual')?.price / 100
            return [
                {
                    id: 'id',
                    data: item.id,
                    isVisible: false
                },
                {
                    id: 'name',
                    data: item.name,
                    isVisible: true
                },
                {
                    id: 'short_code',
                    data: item.short_code,
                    isVisible: true
                },
                {
                    id: 'members',
                    data: item.members_count,
                    isVisible: true
                },
                {
                    id: 'initiation_fee',
                    data: <CurrencyFormat
                        decimalScale={2}
                        fixedDecimalScale
                        value={monthItem?.initiation_fee ? monthItem?.initiation_fee / 100 : 0}
                        displayType="text"
                        thousandSeparator
                        prefix="$"
                    />,
                    isVisible: true
                },
                {
                    id: 'monthly_price',
                    data: (
                        <CurrencyFormat
                            decimalScale={2}
                            fixedDecimalScale
                            value={monthlyPrice || 0}
                            displayType="text"
                            thousandSeparator
                            prefix="$"
                        />
                    ),
                    isVisible: true
                },
                {
                    id: 'annual_price',
                    data: (
                        <CurrencyFormat
                            decimalScale={2}
                            fixedDecimalScale
                            value={annualPrice || 0}
                            displayType="text"
                            thousandSeparator
                            prefix="$"
                        />
                    ),
                    isVisible: true
                }
            ];

        }))
    }, [clubMemberships])

    console.log('loading', loading.memberships)

    return (
        <Grid container spacing={gridSpacing} sx={{ paddingBottom: 10 }}>
            <Grid item xs={12}>
                <Tabs
                    value={view}
                    onChange={(e, newValue) =>
                        navigate(`/club/${clubId}/Memberships?page=${newValue}`)
                    }
                    sx={{ display: 'flex', alignSelf: 'flex-end' }}
                    variant="scrollable"
                >
                    <Tab label="Individual" value="individual" />
                    <Tab label="Family" value="family" />

                </Tabs>

            </Grid>
            <Grid item xs={12} lg={12} marginTop={{ xs: 2, md: 0 }}>
                <Grid container spacing={gridSpacing} mt={2} pl={1}>
                    <CustomTable
                        title={`${capitalizeWords(view)} Memberships`}
                        buttonText="Add Membership"
                        handleButtonClick={() => toggleMembershipCreationModal()}
                        headers={headers}
                        data={data}
                        showActions
                        actions={[{ onClick: (row) => navigate(`/club/${clubId}/Memberships/${row}`), label: 'Edit', chipColor: 'warning' }]}
                        selectable
                        handleClick={(row) => navigate(`/club/${clubId}/Memberships/${row}`)}
                        showSorting
                        loading={loading.memberships}
                    />
                </Grid>
            </Grid>

        </Grid>
    );
};

export default MembershipList;
