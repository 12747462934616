/* eslint-disable no-plusplus */
import React, { useState, createContext, useReducer } from 'react';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import { API_URL as apiUrl } from 'config';
import moment from 'moment';
import useEvents from 'hooks/useEvents';
import useGroups from 'hooks/useGroups';

export const ShopContext = createContext();
const updateNestedStateKey = (keyPath, value) => ({
    type: 'UPDATE_NESTED_STATE_KEY',
    payload: { keyPath, value }
});
function updateNestedStateKeyReducer(state, keyPath, value) {
    const keys = keyPath.split('.');
    const nestedState = { ...state };

    let currentLevel = nestedState;
    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        currentLevel[key] = { ...currentLevel[key] };
        currentLevel = currentLevel[key];
    }

    currentLevel[keys[keys.length - 1]] = value;

    return nestedState;
}
const initialState = {
    filter: {
        search: '',
        sort: 'upcoming',
        gender: [],
        categories: ['all'],
        colors: [],
        price: '',
        rating: 0,
        location: { city: '', latitude: null, longitude: null },
        radius: 100,
        earliestDate: moment()
    },
    isLocationLoaded: false
};

function shopReducer(state, action) {
    switch (action.type) {
        case 'SET_STATE':
            return { ...state, [action.payload.key]: action.payload.value };
        case 'RESET_STATE':
            return initialState; // Resetting to initial state
        case 'UPDATE_NESTED_STATE_KEY':
            return updateNestedStateKeyReducer(state, action.payload.keyPath, action.payload.value);
        default:
            return state;
    }
}

export function ShopProvider({ children }) {
    const [state, dispatch] = useReducer(shopReducer, initialState);

    const setValue = (key, value) => {
        dispatch({ type: 'SET_STATE', payload: { key, value } });
    };

    const resetState = () => {
        dispatch({ type: 'RESET_STATE' });
    };
    const updateValue = (keyPath, value) => {
        dispatch(updateNestedStateKey(keyPath, value));
    };

    return (
        <ShopContext.Provider
            value={{
                ...state,
                setValue,
                updateValue,
                resetState
            }}
        >
            {children}
        </ShopContext.Provider>
    );
}
