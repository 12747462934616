import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import InternalLayout from 'layout/InternalLayout';

// eslint-disable-next-line no-unused-vars

// dashboard routing

// widget routing
const InternalToolDashboard = Loadable(lazy(() => import('views/internal-tools/Dashboard')));
const EmailCampaigns = Loadable(lazy(() => import('views/internal-tools/Email')));
const CampaignEmails = Loadable(lazy(() => import('views/internal-tools/Email/CampaignEmails')));
const EmailPage = Loadable(lazy(() => import('views/internal-tools/Email/EmailPage')));
const EditEmail = Loadable(lazy(() => import('views/club-management/pages/Email')));

// sample page routing

// ==============================|| MAIN ROUTING ||============================== //

const InternalRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <InternalLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/internal-tools/home',
            element: <InternalToolDashboard />
        },
        {
            path: '/internal-tools/campaigns',
            element: <EmailCampaigns />
        },
        {
            path: '/internal-tools/campaigns/:id',
            element: <CampaignEmails />,
            breadcrumb: true
        },
        {
            path: '/internal-tools/campaigns/:id/email/:emailId',
            element: <EmailPage />,
            breadcrumb: true
        }
    ]
};

export default InternalRoutes;
