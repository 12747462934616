import { Button, CardActions, CardContent, Grid, IconButton, Modal, Typography } from '@mui/material';
import MainCard from './cards/MainCard';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import PerfectScrollbar from 'react-perfect-scrollbar';

const CardModal = ({
    open,
    toggleModal,
    title,
    content,
    actionText,
    actionColor = 'primary',
    submitAction,
    showCancel,
    formik,
    hasContent = false,
    loading = false,
    cancelAction = null,
    buttonDisabled = false,
    width = '65%'
}) => (
    <Modal open={open} onClose={toggleModal}>
        <MainCard
            sx={{
                position: 'absolute',
                width: { xs: '95%', lg: width },
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxHeight: '80vh', // Set max height for the modal
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden' // Ensure the overflow is handled within the card
            }}
            title={
                <Grid>
                    <Typography variant="h3">{title}</Typography>
                </Grid>
            }
            content={hasContent}
            secondary={
                <IconButton onClick={toggleModal} size="large">
                    <Close fontSize="small" />
                </IconButton>
            }
        >
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                <PerfectScrollbar style={{ flex: 1, overflowY: 'auto' }}>
                    <CardContent>{content}</CardContent>
                </PerfectScrollbar>
                <CardActions
                    sx={{
                        justifyContent: 'flex-end', // Align actions to the right
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)' // Optional: add a top border for separation
                    }}
                >
                    {formik ? (
                        <>
                            {showCancel && (
                                <Button type="reset" variant="outlined" onClick={cancelAction || toggleModal}>
                                    Cancel
                                </Button>
                            )}
                            {actionText && (
                                <Button type="submit" variant="contained" color={actionColor} onClick={submitAction}>
                                    {actionText}
                                </Button>
                            )}
                        </>
                    ) : (
                        <>
                            {showCancel && (
                                <Button type="reset" variant="outlined" onClick={cancelAction || toggleModal}>
                                    Cancel
                                </Button>
                            )}
                            {actionText && (
                                <LoadingButton
                                    loading={loading}
                                    type="submit"
                                    variant="contained"
                                    color={actionColor}
                                    onClick={submitAction}
                                    disabled={buttonDisabled}
                                >
                                    {actionText}
                                </LoadingButton>
                            )}
                        </>
                    )}
                </CardActions>
            </div>
        </MainCard>
    </Modal>
);

export default CardModal;
