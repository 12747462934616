import { Grid } from '@mui/material';
import useAuth from 'hooks/useAuth';
import useClubs from 'hooks/useClubs';
import useGroups from 'hooks/useGroups';
import { useEffect, useState } from 'react';
import ResourceCalendar from 'views/groups/calendar';

const SchedulerFrame = () => {
    const { getGroupInfo } = useGroups();
    const { domainClub } = useClubs();
    const [groupId, setGroupId] = useState(null);

    useEffect(() => {
        if (domainClub && domainClub.group_id) {
            setGroupId(domainClub.group_id);
            getGroupInfo(domainClub.group_id);
        }
    }, [domainClub]);

    return (
        <Grid p={2}>
            <ResourceCalendar groupId={groupId} editable />
        </Grid>
    );
};

export default SchedulerFrame;
