/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Container, Grid, Tab, Tabs, Typography, CircularProgress } from '@mui/material';

// project imports
import FeedTab from './FeedTab';
import PlayersTab from './PlayersTab';
import Chip from 'ui-component/extended/Chip';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets
import { IconFriends, IconInbox, IconCalendar, IconTrophy } from '@tabler/icons';
import PersonAddTwoToneIcon from '@mui/icons-material/PersonAddTwoTone';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import mixpanel from 'mixpanel-browser';

import useGroups from 'hooks/useGroups';
import GroupCalendar from './calendar/GroupCalendar';
import Leagues from './leagues';
import Leaderboard from 'views/leagues/LeagueHome/Leaderboard/Leaderboard';
import LeagueStats from './leagues/LeagueStats';
import LeagueMatches from './leagues/LeagueMatches';
import useAuth from 'hooks/useAuth';
import useClubs from 'hooks/useClubs';
import ResourceCalendar from './calendar';
import MembershipPricing from './membership/MembershipPricing';

function TabPanel({ children, groupTab, index, ...other }) {
    return (
        <div role="tabpanel" hidden={groupTab !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {groupTab === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    groupTab: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const GroupHome = () => {
    const { subdomain } = useAuth();
    const theme = useTheme();
    const { groupId: paramGroupId, tab } = useParams();
    const { getGroupInfo, groupInfo, joinGroup, getClubGroup } = useGroups();
    const { domainClub, getClubCourts, clubCourts } = useClubs();
    const [groupId, setGroupId] = useState(paramGroupId || null);
    const [groupTab, setGroupTab] = useState(null);
    const [sortedTabOptions, setSortedTabOptions] = useState([]);
    const [tabOptions, setTabOptions] = useState([]);
    const location = useLocation();
    const { pathname } = location;
    const isGroupRoute = pathname.includes('web/group');
    const baseRoute = isGroupRoute ? `/web/group/${groupId}` : `/c`;
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (paramGroupId) {
            setGroupId(paramGroupId);
            getGroupInfo(paramGroupId);
        } else if (subdomain) {
            getClubGroup(subdomain);
        }
    }, [paramGroupId, subdomain]);

    useEffect(() => {
        if (!paramGroupId && domainClub && domainClub.group_id) {
            setGroupId(domainClub.group_id);
            getGroupInfo(domainClub.group_id);
        }
    }, [domainClub]);

    useEffect(() => {
        if (groupId) {
            mixpanel.track('Group View', {
                group_id: groupId,
                tab
            });
        }
    }, [tab]);

    useEffect(() => {
        if (groupInfo) {
            if (groupInfo?.group?.club?.visibility === 'members-only' && !groupInfo?.group?.club?.is_member) {
                setGroupTab(8);
            } else if (!tab) {
                if (groupInfo?.user_group) {
                    setGroupTab(1);
                } else if (groupInfo?.group?.league) {
                    setGroupTab(5);
                } else {
                    setGroupTab(3);
                }
            } else {
                const tabMapping = {
                    feed: 1,
                    members: 2,
                    calendar: 7,
                    schedule: 7,
                    events: 3,
                    leaderboard: 4,
                    'league-stats': 5,
                    matches: 6,
                    memberships: 8
                };
                setGroupTab(tabMapping[tab] || '');
            }
            setLoading(false);
        }
    }, [groupInfo, tab]);

    const handleChange = (event, newTabId) => {
        setGroupTab(newTabId);
    };

    useEffect(() => {
        const options = [];
        const club = groupInfo?.group?.club;

        if (club) {
            if (club?.visibility === 'members-only' && !club?.is_member) {
                options.push({
                    id: 8,
                    to: `${baseRoute}/memberships`,
                    icon: <IconFriends stroke={1.5} size="17px" />,
                    label: 'Memberships'
                });
            } else {
                if (groupInfo?.user_group) {
                    options.push({
                        id: 1,
                        to: `${baseRoute}/feed`,
                        icon: <IconInbox stroke={1.5} size="17px" />,
                        label: 'Feed'
                    });
                }
                options.push(
                    {
                        id: 2,
                        to: `${baseRoute}/members`,
                        icon: <IconFriends stroke={1.5} size="17px" />,
                        label: (
                            <>
                                Members <Chip label={groupInfo?.group.num_members} size="small" chipcolor="secondary" sx={{ ml: 1.5 }} />
                            </>
                        )
                    },
                    ...(clubCourts.length > 0
                        ? [
                            {
                                id: 7,
                                to: `${baseRoute}/schedule`,
                                icon: <IconCalendar stroke={1.5} size="17px" />,
                                label: 'Court Reservations'
                            }
                        ]
                        : []),
                    {
                        id: 3,
                        to: `${baseRoute}/events`,
                        icon: <IconTrophy stroke={1.5} size="17px" />,
                        label: 'Events'
                    }
                );
            }
        } else if (groupInfo?.user_group) {
            options.push({
                id: 1,
                to: `${baseRoute}/feed`,
                icon: <IconInbox stroke={1.5} size="17px" />,
                label: 'Feed'
            });
        }

        if (groupInfo?.group?.league?.event_type === 'league') {
            options.push(
                {
                    id: 2,
                    to: `${baseRoute}/members`,
                    icon: <IconFriends stroke={1.5} size="17px" />,
                    label: (
                        <>
                            Players <Chip label={groupInfo?.group.num_members} size="small" chipcolor="secondary" sx={{ ml: 1.5 }} />
                        </>
                    )
                },
                {
                    id: 4,
                    to: `${baseRoute}/leaderboard`,
                    icon: <IconCalendar stroke={1.5} size="17px" />,
                    label: 'Leaderboard'
                },
                {
                    id: 5,
                    to: `${baseRoute}/league-stats`,
                    icon: <IconCalendar stroke={1.5} size="17px" />,
                    label: 'League Stats'
                },
                {
                    id: 6,
                    to: `${baseRoute}/matches`,
                    icon: <IconCalendar stroke={1.5} size="17px" />,
                    label: 'Matches'
                },
                {
                    id: 7,
                    to: `${baseRoute}/calendar`,
                    icon: <IconCalendar stroke={1.5} size="17px" />,
                    label: 'Schedule'
                }
            );
        } else if (groupInfo?.group?.league?.event_type === 'tournament') {
            options.push(
                {
                    id: 2,
                    to: `${baseRoute}/members`,
                    icon: <IconFriends stroke={1.5} size="17px" />,
                    label: (
                        <>
                            Players <Chip label={groupInfo?.group.num_members} size="small" chipcolor="secondary" sx={{ ml: 1.5 }} />
                        </>
                    )
                },
                {
                    id: 5,
                    to: `${baseRoute}/league-stats`,
                    icon: <IconCalendar stroke={1.5} size="17px" />,
                    label: 'Tournament Stats'
                },
                {
                    id: 6,
                    to: `${baseRoute}/matches`,
                    icon: <IconCalendar stroke={1.5} size="17px" />,
                    label: 'Matches'
                },
                {
                    id: 7,
                    to: `${baseRoute}/calendar`,
                    icon: <IconCalendar stroke={1.5} size="17px" />,
                    label: 'Schedule'
                }
            );
        }

        setTabOptions(options);
    }, [groupInfo, clubCourts]);

    useEffect(() => {
        const sortedTabs = tabOptions.sort((a, b) => a.id - b.id);
        setSortedTabOptions(sortedTabs);
    }, [tabOptions]);

    useEffect(() => {
        if (!isGroupRoute && domainClub) {
            getClubCourts(domainClub?.id);
        }
    }, [isGroupRoute, domainClub]);

    const getTabPanel = (groupTab) => {
        switch (groupTab) {
            case 1:
                return <FeedTab groupId={groupId} setGroupTab={setGroupTab} />;
            case 2:
                return <PlayersTab />;
            case 7:
                return isGroupRoute ? <GroupCalendar groupId={groupId} /> : <ResourceCalendar groupId={groupId} editable />;
            case 3:
                return <Leagues groupId={groupId} clubId={groupInfo?.group?.club?.id} />;
            case 4:
                return <Leaderboard leagueId={groupInfo?.group?.league?.id} />;
            case 5:
                return <LeagueStats leagueId={groupInfo?.group?.league?.id} type={groupInfo?.group?.league?.event_type} />;
            case 6:
                return (
                    <LeagueMatches
                        leagueId={groupInfo?.group?.league?.id}
                        type={groupInfo?.group?.league?.event_type}
                        isAdmin={groupInfo?.user_group?.role === 'admin'}
                    />
                );
            case 8:
                return <MembershipPricing clubId={groupInfo?.group?.club?.id} />;
            default:
                return <FeedTab groupId={groupId} />;
        }
    };

    return (
        <Grid container spacing={gridSpacing} data-test-id="views/groups/index">
            <Grid item xs={12}>
                <MainCard
                    contentSX={{
                        p: 1.5,
                        paddingBottom: '0px !important',
                        [theme.breakpoints.down('lg')]: {
                            textAlign: 'center'
                        }
                    }}
                >
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h2" ml={2}>
                                        {groupInfo?.group.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            justifyContent: 'flex-end',
                                            [theme.breakpoints.down('lg')]: {
                                                justifyContent: 'center'
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="space-between" alignItems="center" ml={2}>
                                <Grid item>
                                    <Typography variant="caption"> {groupInfo?.facility?.name}</Typography>
                                </Grid>
                                <Grid container justifyContent="flex-start">
                                    {(!groupInfo?.group?.club || groupInfo.group?.club?.visibility === 'public' || groupInfo?.group?.club?.is_member) ? (
                                        <Tabs
                                            value={groupTab}
                                            variant="scrollable"
                                            onChange={handleChange}
                                            sx={{
                                                marginTop: 2.5,
                                                '& .MuiTabs-flexContainer': {
                                                    border: 'none'
                                                },
                                                '& a': {
                                                    minHeight: 'auto',
                                                    minWidth: 10,
                                                    py: 1.5,
                                                    px: 1,
                                                    mr: 2.25,
                                                    color: 'grey.700',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                },
                                                '& a.Mui-selected': {
                                                    color: 'primary.main'
                                                },
                                                '& a > svg': {
                                                    marginBottom: '4px !important',
                                                    mr: 1.25
                                                }
                                            }}
                                        >
                                            {sortedTabOptions.map((option, index) => (
                                                <Tab
                                                    key={index}
                                                    component={Link}
                                                    to={option.to}
                                                    icon={option.icon}
                                                    label={option.label}
                                                    value={option.id}
                                                    {...a11yProps(index)}
                                                />
                                            ))}
                                        </Tabs>
                                    ) : (
                                        <Tabs value={8} variant="scrollable" sx={{ marginTop: 2.5 }}>
                                            <Tab
                                                component={Link}
                                                to={`${baseRoute}/memberships`}
                                                icon={<IconFriends stroke={1.5} size="17px" />}
                                                label="Memberships"
                                                value={8}
                                                {...a11yProps(0)}
                                            />
                                        </Tabs>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MainCard>
            </Grid>
            <Grid item xs={12}>
                {isLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                        <CircularProgress />
                    </Box>
                ) : (
                    getTabPanel(groupTab)
                )}
            </Grid>
        </Grid>
    );
};

export default GroupHome;
