/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable eqeqeq */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
import { useEffect, useRef, useState } from 'react';
import { Button, Dialog, useMediaQuery, Grid, Typography, Chip, IconButton } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';

import { useDispatch } from 'store';
import { addEvent, updateEvent, removeEvent } from 'store/slices/calendar';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import CalendarStyled from './CalendarStyled';
import Toolbar from './Toolbar';
import AddAlarmTwoToneIcon from '@mui/icons-material/AddAlarmTwoTone';
import { useParams } from 'react-router-dom';
import useEvents from 'hooks/useEvents';
import useAuth from 'hooks/useAuth';
import useClubs from 'hooks/useClubs';
import { Add, AddCircle } from '@mui/icons-material';
import CourtResoModal from './CourtResoModal';
import moment, { locale } from 'moment';
import EventDrawer from './EventDrawer';

// Custom CSS for hover effect
const styles = {
    calendarHover: `
       .slot-cell {
            flex: 1;
            display: flex;
            text-align: center;
            opacity: 0;
            align-items: center;
            justify-content: center;
            height: 60px;

            &:hover {
                background: green;
                cursor: pointer;
                opacity: 1;
                color: white;
                font-weight: 600;
            }
        }
       .fc .fc-timegrid-slot {
            height: 65px;  /* Ensure the height matches your custom slot content */
            border-bottom: 0;
       }   
    `
};

const ResourceCalendar = ({ groupId, editable = false, isAdmin }) => {
    const dispatch = useDispatch();
    const calendarRef = useRef(null);
    const { domainClub, getClubCourts, clubCourts } = useClubs();
    const { user } = useAuth();
    const { getEvents, events } = useEvents();
    const initialDate = new Date();
    initialDate.setHours(0, 0, 0, 0);
    const [date, setDate] = useState(initialDate);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [mappedEvents, setMappedEvents] = useState([]);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const matchSm = useMediaQuery((theme) => theme.breakpoints.down('md'));
    useEffect(() => {
        if (domainClub) {
            getClubCourts(domainClub?.id);
        }
    }, [domainClub]);

    useEffect(() => {
        getEvents(user?.id, groupId, null, false, false, date, date, moment.tz.guess());
    }, [groupId, date]);
    const [view, setView] = useState('resourceTimeGridDay');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRange, setSelectedRange] = useState(null);
    const [selectedResource, setSelectedResource] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [eventId, setEventId] = useState(null);
    const [hoveredResourceId, setHoveredResourceId] = useState(null);
    const [selectedArg, setSelectedArg] = useState();

    useEffect(() => {
        if (!isModalOpen) {
            setEventId(null);
        }
    }, [isModalOpen]);
    const handleDateToday = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.today();
            setDate(calendarApi.getDate());
        }
    };

    const handleViewChange = (newView) => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.changeView(newView);
            setView(newView);
        }
    };

    useEffect(() => {
        handleViewChange(matchSm ? 'resourceTimeGridDay' : 'resourceTimeGridDay');
    }, [matchSm]);

    const handleDatePrev = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.prev();
            setDate(calendarApi.getDate());
        }
    };

    const handleDateNext = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.next();
            setDate(calendarApi.getDate());
        }
    };

    const handleRangeSelect = (arg) => {
        const now = new Date();
        if (arg.start < now) {
            console.log('not opened');
            return; // Do not proceed with opening the modal
        }

        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.unselect();
        }
        setSelectedRange({ start: arg.start, end: arg.end });
        setSelectedResource({
            id: arg.resource._resource.id,
            name: arg.resource._resource.title,
            minDuration: arg.resource._resource.extendedProps.minDuration,
            maxDuration: arg.resource._resource.extendedProps.maxDuration,
            hourlyRate: arg.resource._resource.extendedProps.hourlyRate,
            courtFee: arg.resource._resource.extendedProps.courtFee,
            businessHours: arg.resource._resource.extendedProps.customBusinessHours,
            maxLeadTime: arg.resource._resource.extendedProps.maxLeadTime
        });
        setIsModalOpen(true);
    };

    const handleEventSelect = (arg) => {
        setEventId(arg.event.id);
        setSelectedArg(arg);
        // const localStart = moment.utc(arg.event._def.extendedProps.start_date_time).tz(moment.tz.guess()).toDate();
        // const localEnd = moment.utc(arg.event._def.extendedProps.end_date_time).tz(moment.tz.guess()).toDate();

        // setSelectedRange({ start: localStart, end: localEnd });
        // const resource = clubCourts.find((item) => item.id == arg.event._def.resourceIds[0]);
        // setSelectedResource({
        //     id: resource.id,
        //     name: resource.name,
        //     minDuration: resource.min_duration,
        //     maxDuration: resource.max_duration,
        //     hourlyRate: resource.hourly_rate,
        //     courtFee: resource.court_fee,
        //     businessHours: resource.hours
        // });
        // setIsModalOpen(true);

        toggleDrawer();
    };

    const handleEdit = () => {
        const localStart = moment.utc(selectedArg.event._def.extendedProps.start_date_time).tz(moment.tz.guess()).toDate();
        const localEnd = moment.utc(selectedArg.event._def.extendedProps.end_date_time).tz(moment.tz.guess()).toDate();

        setSelectedRange({ start: localStart, end: localEnd });
        const resource = clubCourts.find((item) => item.id == selectedArg.event._def.resourceIds[0]);
        setSelectedResource({
            id: resource.id,
            name: resource.name,
            minDuration: resource.min_duration,
            maxDuration: resource.max_duration,
            hourlyRate: resource.hourly_rate,
            courtFee: resource.court_fee,
            businessHours: resource.hours
        });
        toggleDrawer();
        setIsModalOpen(true);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleEventUpdate = async ({ event }) => {
        try {
            dispatch(updateEvent({ eventId: event.id, update: { allDay: event.allDay, start: event.start, end: event.end } }));
        } catch (err) {
            console.error(err);
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedEvent(null);
        setSelectedRange(null);
    };

    const handleEventCreate = async (data) => {
        dispatch(addEvent(data));
        handleModalClose();
    };

    const handleUpdateEvent = async (eventId, update) => {
        dispatch(updateEvent({ eventId, update }));
        handleModalClose();
    };

    const handleEventDelete = async (id) => {
        try {
            dispatch(removeEvent(id));
            handleModalClose();
        } catch (err) {
            console.error(err);
        }
    };

    const renderEventContent = (eventInfo) => {
        const isFull = eventInfo.event.extendedProps.event_invitations_count >= eventInfo.event.extendedProps.participant_limit;
        return (
            <Grid>
                <Grid container alignItems="center" justifyContent="space-between" mb={0.5}>
                    <Typography variant="h5" color="white">
                        {eventInfo.timeText}
                    </Typography>
                    {eventInfo.event.extendedProps.participant_limit && (
                        <Grid ml={0.5}>
                            <Chip
                                size="small"
                                label={
                                    isFull
                                        ? 'FULL'
                                        : `${eventInfo.event.extendedProps.event_invitations_count} / ${eventInfo.event.extendedProps.participant_limit}`
                                }
                                color="secondary"
                            />
                        </Grid>
                    )}
                </Grid>
                <Typography style={{ whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '95%' }}>
                    {eventInfo.event.title}
                </Typography>
            </Grid>
        );
    };

    const dayOfWeekMap = {
        Sunday: 0,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6
    };

    // Transform clubCourts into resources for FullCalendar
    const resources = clubCourts.map((court) => ({
        id: court.id,
        title: court.name,
        minDuration: court.min_duration,
        maxDuration: court.max_duration,
        hourlyRate: court.hourly_rate,
        courtFee: court.club?.court_fee,
        maxLeadTime: court.max_lead_time,
        businessHours: court.hours.map((hour) => ({
            daysOfWeek: [dayOfWeekMap[hour.day_of_week]],
            startTime: hour.open ? hour.opening_time : '00:00:00',
            endTime: hour.open ? hour.closing_time : '00:00:00'
        })),
        customBusinessHours: court.hours.map((hour) => ({
            daysOfWeek: [dayOfWeekMap[hour.day_of_week]],
            startTime: hour.open ? hour.opening_time : '00:00:00',
            endTime: hour.open ? hour.closing_time : '00:00:00'
        }))
    }));

    useEffect(() => {
        const data = events.map((event) => ({
            ...event,
            resourceIds: event.resources.map((resource) => resource.club_court_id)
        }));
        setMappedEvents(data);
    }, [events]);
    // Map events to include resourceId

    const isWithinBusinessHours = (start, end, resourceBusinessHours) => {
        const dayOfWeek = start.getDay();

        const hours = resourceBusinessHours.find((hour) => hour.daysOfWeek.includes(dayOfWeek));
        if (!hours) return false;

        const [startHour, startMinute, startSecond] = hours.startTime.split(':').map(Number);
        const [endHour, endMinute, endSecond] = hours.endTime.split(':').map(Number);

        // Create new Date objects for the start and end business hours
        const startTime = new Date(start);
        startTime.setHours(startHour, startMinute, startSecond, 0);
        const endTime = new Date(start);
        endTime.setHours(endHour, endMinute, endSecond, 0);

        // Compare using getTime()
        const startTimestamp = start.getTime();
        const endTimestamp = end.getTime();

        return startTimestamp >= startTime.getTime() && endTimestamp <= endTime.getTime();
    };

    const selectAllow = (selectInfo) => {
        // eslint-disable-next-line eqeqeq
        const resource = resources.find((res) => res.id == selectInfo.resource._resource.id);
        if (!resource) return false;

        // Check if the selected time is in the past
        const now = new Date();
        if (selectInfo.start < now) {
            console.log('passed');
            return false;
        }

        return isWithinBusinessHours(selectInfo.start, selectInfo.end, resource.businessHours);
    };

    const handleSlotClick = (resource, date) => {
        // Extract the time part from the date field
        const time = moment(date).format('HH:mm:ss');

        // Get the actual date from calendarRef.current.props.initialDate
        const initialDate = calendarRef.current.props.initialDate;
        const actualDate = moment(initialDate).format('YYYY-MM-DD');

        // Combine the actual date with the extracted time
        const startDateTime = moment(`${actualDate}T${time}`).toDate();
        const endDateTime = moment(startDateTime).add(30, 'minutes').toDate();
        const now = new Date();
        if (startDateTime < now) {
            console.log('not opened');
            return; // Do not proceed with opening the modal
        }

        // Set the selected range
        setSelectedRange({ start: startDateTime, end: endDateTime });

        // Set the selected resource
        setSelectedResource({
            id: resource.id,
            name: resource.title,
            minDuration: resource.minDuration,
            maxDuration: resource.maxDuration,
            hourlyRate: resource.hourlyRate,
            courtFee: resource.courtFee,
            businessHours: resource.businessHours,
            maxLeadTime: resource.maxLeadTime
        });

        // Open the modal
        setIsModalOpen(true);
    };

    const slotLaneContent = (args) => {
        const now = new Date();
        const initialDate = calendarRef?.current?.props?.initialDate;
        const actualDate = moment(initialDate).format('YYYY-MM-DD');

        // Extract the time from args.date
        const time = moment(args.date).format('HH:mm:ss');

        // Combine the actual date with the extracted time
        const startDateTime = moment(`${actualDate}T${time}`).toDate();

        // Check if the startDateTime is in the past
        const isPast = startDateTime < now;

        return (
            <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                {resources.map((resource) => (
                    <div
                        key={resource.id}
                        className="slot-cell"
                        onMouseEnter={() => setHoveredResourceId(resource.id)}
                        onMouseLeave={() => setHoveredResourceId(null)}
                        onClick={hoveredResourceId === resource.id && editable ? () => handleSlotClick(resource, args.date) : null}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            cursor: hoveredResourceId === resource.id && editable && !isPast ? 'pointer' : 'default',
                            backgroundColor: isPast ? 'red' : 'green' // Change color based on whether it's in the past or future
                        }}
                    >
                        <AddCircle fontSize="xs" style={{ marginRight: 4 }} />
                        {args.date?.toLocaleTimeString(undefined, { timeStyle: 'short' })}
                    </div>
                ))}
            </div>
        );
    };

    return (
        // <MainCard title="Court Reservations" sx={{ padding: 0 }}>
        <>
            <CalendarStyled>
                <Toolbar
                    date={date}
                    view={view}
                    onClickNext={handleDateNext}
                    onClickPrev={handleDatePrev}
                    onClickToday={handleDateToday}
                    onChangeView={handleViewChange}
                    views={[]}
                />
                <style>{styles.calendarHover}</style>
                <FullCalendar
                    weekends
                    eventBackgroundColor="#0e264c"
                    selectAllow={selectAllow}
                    selectable={editable}
                    slotDuration="00:30:00"
                    ref={calendarRef}
                    rerenderDelay={10}
                    initialDate={date}
                    initialView={view}
                    dayMaxEventRows={3}
                    select={handleRangeSelect}
                    eventDisplay="block"
                    dayMinWidth={150}
                    headerToolbar={false}
                    allDayMaintainDuration
                    eventResizableFromStart
                    eventContent={renderEventContent}
                    eventClick={isAdmin ? handleEventSelect : null}
                    eventResize={handleEventUpdate}
                    height={matchSm ? 'auto' : 720}
                    slotMinTime="04:00:00"
                    slotLabelFormat={{ hour: 'numeric', minute: '2-digit', meridiem: 'short' }}
                    slotLabelInterval="00:30:00"
                    plugins={[
                        listPlugin,
                        dayGridPlugin,
                        timelinePlugin,
                        timeGridPlugin,
                        interactionPlugin,
                        resourceTimelinePlugin,
                        resourceTimeGridPlugin,
                        scrollGridPlugin
                    ]}
                    resources={resources}
                    resourceAreaHeaderContent="Courts"
                    events={mappedEvents}
                    slotLaneContent={slotLaneContent}
                />
            </CalendarStyled>

            {/* Dialog renders its body even if not open */}
            {isModalOpen && (
                <CourtResoModal
                    open={isModalOpen}
                    toggleModal={toggleModal}
                    selectedRange={selectedRange}
                    selectedResource={selectedResource}
                    groupId={groupId}
                    mappedEvents={mappedEvents}
                    isAdmin={isAdmin}
                    eventId={eventId}
                    setEventId={setEventId}
                />
            )}
            {drawerOpen && <EventDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} eventId={eventId} handleEdit={handleEdit} />}
        </>
        // </MainCard>
    );
};

export default ResourceCalendar;
