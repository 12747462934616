import { useContext } from 'react';

// auth provider
import { GroupContext } from '../contexts/GroupsContext';
// import AuthContext from 'contexts/Auth0Context';
// import AuthContext from 'contexts/JWTContext';
// import AuthContext from 'contexts/AWSCognitoContext';

// ==============================|| AUTH HOOKS ||============================== //

const useGroups = () => {
    const context = useContext(GroupContext);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};

export default useGroups;
