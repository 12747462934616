/* eslint-disable react/button-has-type */
import { Close, Folder } from '@mui/icons-material';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import getReadableDateTime from 'utils/get_readable_datetime';

const DocumentUpload = ({ selectedFile, setSelectedFile, fileType = null, text, descriptor }) => {
    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            setSelectedFile(file);

            // Generate a preview for image files
        }
    };
    const convertSize = (size) => {
        const mb = Math.floor(size / 1048576);
        if (mb < 1) {
            const kb = Math.floor(size / 1024);
            return `${kb}KB`;
        }
        return `${mb}MB`;
    };

    return (
        <Grid item mt={2} data-test-id="views/admin/AdminHub/EventInfo/components/DocumentUpload">
            <Grid item mb={2}>
                <Typography variant="h4"> {descriptor}: </Typography>
            </Grid>
            {!selectedFile ? (
                <Grid item xs={12}>
                    <Button variant="contained" component="label">
                        <Grid textAlign="center">
                            <Typography color="white">{text}</Typography>
                        </Grid>
                        <input hidden type="file" accept={fileType} onChange={handleFileChange} />
                    </Button>
                </Grid>
            ) : (
                <Grid item mt={2}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={1}>
                            <Folder color="primary" />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="h5">{selectedFile?.name || selectedFile?.document_name}</Typography>

                            <Grid container>
                                <Typography variant="caption">
                                    {selectedFile?.lastModifiedDate
                                        ? getReadableDateTime(selectedFile?.lastModifiedDate)
                                        : getReadableDateTime(selectedFile?.created_at)}
                                </Typography>

                                {selectedFile.size && (
                                    <Typography variant="caption" ml={4}>
                                        {convertSize(selectedFile.size)}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item ml={2}>
                            <IconButton color="error" onClick={() => setSelectedFile(null)}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default DocumentUpload;
