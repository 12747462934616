import { Close, EmojiEvents, EventRepeat } from '@mui/icons-material';
import { Button, Grid, IconButton, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';

const CreateEventModal = ({ open, handleToggleModal }) => {
    const test = true;
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4
    };
    const buttonStyle = {
        padding: 6,
        width: '100%',
        border: '2px solid lightgrey',
        boxShadow: 1
    };
    const navigate = useNavigate();
    const handleNavigate = (type) => {
        if (type === 'league') {
            navigate('/clubs/events/create/league');
        } else {
            navigate('/clubs/events/create/tournament');
        }
        handleToggleModal();
    };

    return (
        <Modal
            open={open}
            onClose={handleToggleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            data-test-id="views/admin/Events/create-event/CreateEventModal"
        >
            <Box sx={style} textAlign="center">
                <Grid width="100%" justifyContent="flex-end" textAlign="right">
                    <IconButton onClick={handleToggleModal}>
                        <Close />
                    </IconButton>
                </Grid>
                <Typography id="modal-modal-title" variant="h4" gutterBottom>
                    What type of event do you want to create?
                </Typography>
                <Grid container justifyContent="space-between" alignItems="center" mt={4} spacing={2}>
                    <Grid item xs={12} md={6}>
                        <MainCard onClick={() => handleNavigate('league')} sx={{ cursor: 'pointer' }}>
                            {/* <Button variant="outlined" sx={buttonStyle} onClick={() => handleNavigate('league')}> */}
                            <Grid display="flex" justifyContent="center" alignItems="center" textAlign="center" flexDirection="column">
                                <Grid
                                    sx={{
                                        borderRadius: 25,
                                        width: 50,
                                        height: 50,
                                        backgroundColor: '#F7F6F9',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex'
                                    }}
                                >
                                    <EventRepeat color="secondary" />
                                </Grid>
                                <Typography mt={2} variant="h4">
                                    League
                                </Typography>
                            </Grid>
                            {/* </Button> */}
                        </MainCard>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MainCard onClick={() => handleNavigate('tournament')} sx={{ cursor: 'pointer' }}>
                            <Grid display="flex" justifyContent="center" alignItems="center" textAlign="center" flexDirection="column">
                                <Grid
                                    sx={{
                                        borderRadius: 25,
                                        width: 50,
                                        height: 50,
                                        backgroundColor: '#F7F6F9',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex'
                                    }}
                                >
                                    <EmojiEvents color="secondary" />
                                </Grid>
                                <Typography mt={2} variant="h4">
                                    Tournament
                                </Typography>
                            </Grid>
                        </MainCard>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default CreateEventModal;
