// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, CardMedia, Grid, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';

// assets
import imageEmpty from 'assets/images/e-commerce/empty.svg';
import imageDarkEmpty from 'assets/images/e-commerce/empty-dark.svg';

// ==============================|| NO/EMPTY Product ||============================== //

const ProductEmpty = ({ showMedia = true, content = 'No events right now', helperText, image, imageWidth }) => {
    const theme = useTheme();
    return (
        <Grid container spacing={gridSpacing} data-test-id="views/application/e-commerce/Products/ProductEmpty">
            <Grid item xs={12}>
                <Box sx={{ maxWidth: 720, m: '0 auto', textAlign: 'center' }}>
                    <Grid container justifyContent="center" spacing={gridSpacing}>
                        {showMedia && (
                            <Grid item xs={12}>
                                <CardMedia
                                    component="img"
                                    sx={{ width: imageWidth, height: 'auto', display: 'block', margin: '0 auto' }}
                                    image={image || (theme.palette.mode === 'dark' ? imageDarkEmpty : imageEmpty)}
                                    title="Empty Image"
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Typography variant="h1" color="inherit" component="div">
                                        {content}
                                    </Typography>
                                    {helperText && (
                                        <Typography mt={2} variant="body" color="inherit" component="div">
                                            {helperText}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ProductEmpty;
