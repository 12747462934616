import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import Logo from 'ui-component/Logo';
import useAuth from 'hooks/useAuth';
import useClubs from 'hooks/useClubs';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const { subdomain } = useAuth();
    const { domainClub } = useClubs();
    return (
        <Link component={RouterLink} to={DASHBOARD_PATH}>
            {!subdomain ? <Logo /> : <img alt="club" style={{ maxHeight: 75 }} src={domainClub?.image?.image_url} />}
        </Link>
    );
};

export default LogoSection;
