/* eslint-disable no-unused-expressions */
import React, { useState, createContext, useReducer } from 'react';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import moment from 'moment';
import { API_URL as apiUrl } from 'config';
import useTournaments from 'hooks/useTournaments';
import { useTheme } from '@mui/styles';

export const EventsContext = createContext();

const initialState = {
    event: {},
    eventInvitations: []
};

function eventsReducer(state, action) {
    switch (action.type) {
        case 'SET_STATE':
            return { ...state, [action.payload.key]: action.payload.value };
        case 'RESET_STATE':
            return initialState; // Resetting to initial state
        default:
            return state;
    }
}

export function EventsProvider({ children }) {
    const [state, dispatch] = useReducer(eventsReducer, initialState);
    const [errors, setErrors] = useState({});

    const setValue = (key, value) => {
        dispatch({ type: 'SET_STATE', payload: { key, value } });
    };

    const resetState = () => {
        setErrors({});
        dispatch({ type: 'RESET_STATE' });
    };
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const { user, token } = useAuth();
    const [createEventVisible, setCreateEventVisible] = useState(false);
    const [editSeries, setEditSeries] = useState(false);
    const [edit, setEdit] = useState(false);
    const [eventInfo, setEventInfo] = useState();
    const theme = useTheme();

    const convertDatetoString = (date) => {
        const isoString = date.toISOString();
        const dateString = isoString.slice(0, 10);
        const timeString = isoString.slice(11, 19);
        const formattedDateTime = `${dateString} ${timeString}`;

        return formattedDateTime;
    };

    // const convertUTCToLocalTime = (dateString) => {
    //   const utcDate = new Date(dateString);
    //   const utcTime = utcDate.getTime();

    //   // Convert UTC time to local time
    //   const localTime = new Date(utcTime).toLocaleTimeString();

    //   // Set the local time in state
    //   return localTime;
    // };

    function convertUTCToLocalTime(date) {
        const newDate = new Date(date);
        newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
        return newDate;
    }
    // const convertUTCToLocalTime = (dateString) => {
    //   let date = new Date(dateString);
    //   const milliseconds = Date.UTC(
    //     date.getUTCFullYear(),
    //     date.getUTCMonth(),
    //     date.getUTCDate(),
    //     date.getUTCHours(),
    //     date.getUTCMinutes(),
    //     date.getUTCSeconds()
    //   );
    //   const localTime = new Date(milliseconds);
    //   localTime.getDate(); // local date
    //   localTime.getHours(); // local hour
    //   return localTime;
    // };

    const getEvents = async (
        user_id,
        group_id,
        page,
        refresh = false,
        loadMore = false,
        start_date = null,
        end_date = null,
        timezone = null,
        date = null,
        show_previous = true
    ) => {
        refresh && !loadMore ? setIsRefreshing(true) : !loadMore && setIsLoading(true);

        const response = await axios.get(`/api/events`, {
            params: { user_id, group_id, page, show_previous, start_date, end_date, timezone, date },
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const getBackgroundColor = (event) => {
            if (event.status === 'merged') {
                return theme.palette.primary.light;
            }
            if (event.all_day) {
                return theme.palette.secondary.main;
            }
            return theme.palette.primary.main;
        };

        // Map over the events and change start_date_time to start
        const modifiedEvents = response.data.data.data.map((event) => {
            // Parse the times and convert them to local time strings
            // moment.utc(eventInfo.start_date_time).local()
            const startLocal = moment.utc(event.start_date_time).local().format();
            const endLocal = event.end_date_time ? moment.utc(event.end_date_time).local().format() : null;

            return {
                ...event,
                start: startLocal,
                end: endLocal,
                backgroundColor: getBackgroundColor(event),
                allDay: event.all_day,
                group_id
            };
        });

        if (refresh) {
            setIsRefreshing(false);
            setEvents((prevState) => [...prevState, ...modifiedEvents]);
        } else {
            setIsLoading(false);
            setEvents(modifiedEvents);
        }

        refresh ? setIsRefreshing(false) : setIsLoading(false);

        return response.data.data;
    };

    const getModifiedEvents = async (user_id, group_id, page, refresh = false, loadMore = false) => {
        refresh && !loadMore ? setIsRefreshing(true) : !loadMore && setIsLoading(true);

        const response = await axios.get(`/api/events`, {
            params: { user_id, group_id, page, show_previous: true },
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });

        // Map over the events and change start_date_time to start
        const modifiedEvents = response.data.data.data.map((event) => {
            // Parse the times and convert them to local time strings
            // moment.utc(eventInfo.start_date_time).local()
            const startLocal = moment.utc(event.start_date_time).local().format();
            const endLocal = event.end_date_time ? moment.utc(event.end_date_time).local().format() : null;

            return {
                ...event,
                start: startLocal,
                end: endLocal,
                group_id
            };
        });

        if (refresh) {
            setIsRefreshing(false);
            setEvents((prevState) => [...prevState, ...modifiedEvents]);
        } else {
            setIsLoading(false);
            setEvents(modifiedEvents);
        }

        refresh ? setIsRefreshing(false) : setIsLoading(false);

        return modifiedEvents;
    };

    const getEvent = async (id) => {
        const response = await axios
            .get(`/api/event/info/${id}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setValue('event', res.data.data);
                return res.data.data;
            });

        return response;
    };

    const getEventParticipants = async (event_id, event_instance_id, page) => {
        setIsLoading(true);
        const response = await axios
            .get(`/api/event/invitations`, {
                params: { event_id, event_instance_id, page },
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setValue('eventInvitations', res.data.data);
                return res.data.data;
            });
        // const sortedEvents = response.data.data.sort((a, b) => {
        //   const startTimeA = new Date(a.start_date_time).getTime();
        //   const startTimeB = new Date(b.start_date_time).getTime();
        //   return startTimeA - startTimeB;
        // });

        setIsLoading(false);
        return response;
    };
    const checkInUser = async (id, checked_in) => {
        const params = { checked_in };
        await axios
            .post(`/api/event/check-in/${id}`, params, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(() => {
                const newState = state.eventInvitations.map((item) => {
                    if (item.invitation_id === id) {
                        return { ...item, checked_in };
                    }
                    return item;
                });
                setValue('eventInvitations', newState);
            });
    };
    const createEvent = async (params) => {
        setIsUploading(true);
        const user_id = user.id;
        console.log('params', params);

        await axios
            .post(`/api/event`, params, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsUploading(false);
                console.log('RES', res.data.data);
                setEvents((prev) => [...prev, ...res.data.data]); // Spread res.data.data to include all events
            })
            .catch(() => {
                console.log('ERROR');
                setIsUploading(false);
            });
    };
    const { tournamentDetails, setValue: setTournamentValue } = useTournaments();
    const createEventInstance = async (params, id = null) => {
        const startDate = moment(params.startDate);

        // Next, extract just the time from params.start and params.end
        const startTime = moment(params.start);
        const endTime = moment(params.end);

        // Now, combine startDate with the time from startTime and endTime
        // Note: We keep the time in the original timezone and convert the final datetime to UTC
        const startDateTime = startDate
            .clone()
            .set({
                hour: startTime.hour(),
                minute: startTime.minute(),
                second: startTime.second()
            })
            .tz(params.timezone)
            .utc();

        const endDateTime = startDate
            .clone()
            .set({
                hour: endTime.hour(),
                minute: endTime.minute(),
                second: endTime.second()
            })
            .tz(params.timezone)
            .utc();

        // Finally, format the combined datetimes for your backend
        const start_date_time = `${startDateTime.format('YYYY-MM-DDTHH:mm:ss')}`; // Append 'Z' to indicate UTC
        const end_date_time = `${endDateTime.format('YYYY-MM-DDTHH:mm:ss')}`;

        // Incorporate these into your params
        const updatedParams = {
            ...params,
            start_date_time,
            end_date_time
        };

        const route = `/api/event-instance`;

        await axios
            .post(route, updatedParams)
            .then((res) => {
                // Parse the times and convert them to local time strings
                const event = res.data.data; // moment.utc(eventInfo.start_date_time).local()
                const startLocal = moment.utc(event.start_date_time).local().format();
                const endLocal = event.end_date_time ? moment.utc(event.end_date_time).local().format() : null;
                const newEventInstance = {
                    ...event,
                    start: startLocal,
                    end: endLocal
                };

                setEvents((prev) => [...prev, newEventInstance]);
                const updatedTournamentDetails = {
                    ...tournamentDetails,
                    grouped_events: res.data.grouped_events
                };
                setTournamentValue('tournamentDetails', updatedTournamentDetails);
                // setEvents([]);
            })
            .catch((e) => {
                console.log('ERROR', e);
            });
    };

    const updateEventInstance = async (params, id) => {
        console.log('params', params);
        const startDate = moment(params.startDate);
        const endDate = moment(params.endDate);

        // Next, extract just the time from params.start and params.end
        const startTime = moment(params.start);
        const endTime = moment(params.end);

        // Now, combine startDate with the time from startTime and endTime
        // Note: We keep the time in the original timezone and convert the final datetime to UTC
        const startDateTime = startDate
            .clone()
            .set({
                hour: startTime.hour(),
                minute: startTime.minute(),
                second: startTime.second()
            })
            .tz(params.timezone)
            .utc();

        const endDateTime = endDate
            .clone()
            .set({
                hour: endTime.hour(),
                minute: endTime.minute(),
                second: endTime.second()
            })
            .tz(params.timezone)
            .utc();

        // Finally, format the combined datetimes for your backend
        const start_date_time = `${startDateTime.format('YYYY-MM-DDTHH:mm:ss')}`; // Append 'Z' to indicate UTC
        const end_date_time = `${endDateTime.format('YYYY-MM-DDTHH:mm:ss')}`;

        // Incorporate these into your params
        const updatedParams = {
            ...params,
            start_date_time,
            end_date_time
        };
        console.log('updatedParams', updatedParams);
        const route = `/api/event-instance/${id}`;

        await axios
            .put(route, updatedParams)
            .then((res) => {
                // Parse the times and convert them to local time strings
                const event = res.data.data; // moment.utc(eventInfo.start_date_time).local()
                const startLocal = moment.utc(event.start_date_time).local().format();
                const endLocal = event.end_date_time ? moment.utc(event.end_date_time).local().format() : null;
                const newEventInstance = {
                    ...event,
                    start: startLocal,
                    end: endLocal
                };

                // Update the event in the events array
                setEvents((prevEvents) =>
                    prevEvents.map((event) => {
                        if (event.id === newEventInstance.id) {
                            // This is the event that needs to be updated, so return the updated instance
                            return newEventInstance;
                        }
                        // This is not the event we're updating, so return it unchanged
                        return event;
                    })
                );
                // setEvents([]);
            })
            .catch((e) => {
                console.log('ERROR', e);
            });
    };

    const editEvent = async (id, params) => {
        setIsUploading(true);
        const user_id = user.id;
        console.log('edit params', params);
        console.log('id', id);

        await axios
            .put(`/api/event/${id}`, params, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsUploading(false);
                let updatedEvents = events.slice();

                const updateEvent = (returnedEvent) => {
                    updatedEvents = updatedEvents.map((event) => {
                        if (event.id === returnedEvent.id) {
                            return { ...event, ...returnedEvent };
                        }
                        return event;
                    });
                };

                if (Array.isArray(res.data.data)) {
                    res.data.data.forEach(updateEvent);
                } else {
                    updateEvent(res.data.data);
                }

                setEvents(updatedEvents);
            })
            .catch((err) => {
                console.log('err', err.response);
                setIsUploading(false);
            });
    };

    const sendEventInvite = async (params) => {
        const newEventInvitation = {
            sender_user_id: params.sender_user_id,
            recipient_user_id: params.recipient_user_id,
            event_id: params.event_id,
            status: 'pending'
        };
        await axios.post(`/api/event/invitation`, newEventInvitation, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });
    };

    const updateEvent = async (params) => {
        setIsLoading(true);
        const user_id = user.id;
        const { startDate, startTime, endDate, endTime } = params.startEndDateTime;
        const startDateTimeString = `${startDate} ${startTime}`;
        // const startDateObject = moment(startDateTimeString, 'YYYY-MM-DD HH:mm').toDate();
        const startDateObject = moment(startDateTimeString, 'ddd MMM DD YYYY hh:mm A').toDate();

        // const startDateObject = new Date(startDateTimeString);
        const finalStartDate = convertDatetoString(startDateObject);

        const finalStartDateLocale = convertUTCToLocalTime(finalStartDate);

        const endDateTimeString = `${endDate} ${endTime}`;
        const endDateObject = moment(endDateTimeString, 'ddd MMM DD YYYY hh:mm A').toDate();

        // const endDateObject = new Date(endDateTimeString);

        const finalEndDate = convertDatetoString(endDateObject);
        const finalEndDateLocale = convertUTCToLocalTime(finalEndDate);
        const title = params.title ? params.title : '';
        const newEvent = {
            id: params.id,
            title,
            user_id,
            start_date_time: finalStartDate,
            end_date_time: finalEndDate,
            facility_id: params.facility_id,
            event_location: params.event_location,
            event_invitations: params.event_invitations
        };
        await axios
            .put(`/api/event/${params.id}`, newEvent, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                params.event_invitations.forEach((user) => {
                    sendEventInvite({
                        sender_user_id: user_id,
                        recipient_user_id: user.recipient_user.id,
                        event_id: res.data.data.id
                    });
                });
            })
            .then(() => {
                newEvent.start_date_time = startDateObject;
                newEvent.end_date_time = finalEndDateLocale;
                newEvent.user = { name: user.name, id: user.id };
                newEvent.event_invitations = newEvent.event_invitations.map((item) => {
                    if (!item.status) {
                        return { ...item, status: 'pending' };
                    }
                    return item;
                });

                const updatedEvents = events.filter((event) => event.id !== params.id);

                const updatedEventsWithNewEvent = [...updatedEvents, newEvent];
                const sortedEvents = updatedEventsWithNewEvent.sort((a, b) => {
                    const startTimeA = new Date(a.start_date_time).getTime();
                    const startTimeB = new Date(b.start_date_time).getTime();
                    return startTimeA - startTimeB;
                });

                setEvents(sortedEvents);
            })
            .then(() => setIsLoading(false))
            .catch((er) => {
                setIsLoading(false);
            });
    };

    const deleteEvent = async (id) => {
        setIsUploading(true);
        await axios
            .delete(`/api/event/${id}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(() => {
                setEvents((prevState) => prevState.filter((event) => event.event.id !== id));
                setIsUploading(false);
            })
            .catch(() => {
                setIsUploading(false);
            });
    };
    const deleteInstance = async (id) => {
        setIsLoading(true);
        await axios
            .delete(`/api/event/instance/${id}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(() => {
                setEvents((prevState) => prevState.filter((event) => Number(event.id) !== Number(id)));
                setIsLoading(false);
            });
    };
    const deleteEventInvite = async (id) => {
        await axios.delete(`/api/event/invitation/${id}`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });
    };
    const deleteEventInviteLive = async (recipient_user_id, event_id) => {
        const params = { recipient_user_id, event_id };
        await axios.put(`/api/event/invitation/delete`, params, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });
    };

    const updateEventInvitationStatus = async (id, status, all_instances) => {
        setIsLoading(true);
        const data = { id, status };
        const response = await axios
            .put(`/api/event/invitation/${id}?status=${status}&all_instances=${all_instances}`, null, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                console.log('res', res);
                setIsLoading(false);

                return res.data;
            })
            .catch((error) => {
                setIsLoading(false);
                // console.log(error.message);

                // console.log(error);
                return error.response.data;
            });

        return response;
    };
    const reserveCourt = async (values, subtotal, court_id, is_admin, paymentResult, event_instance_id = null) => {
        const timezone = moment.tz.guess();
        const startDate = moment(values.date);

        // Next, extract just the time from params.start and params.end
        const startTime = moment(values.start);
        const endTime = moment(values.end);

        // Now, combine startDate with the time from startTime and endTime
        // Note: We keep the time in the original timezone and convert the final datetime to UTC
        const startDateTime = startDate
            .clone()
            .set({
                hour: startTime.hour(),
                minute: startTime.minute(),
                second: startTime.second()
            })
            // .tz(values.timezone)
            .utc();

        const endDateTime = startDate
            .clone()
            .set({
                hour: endTime.hour(),
                minute: endTime.minute(),
                second: endTime.second()
            })
            // .tz(values.timezone)
            .utc();

        // Finally, format the combined datetimes for your backend
        const start_date_time = `${startDateTime.format('YYYY-MM-DDTHH:mm:ss')}`; // Append 'Z' to indicate UTC
        const end_date_time = `${endDateTime.format('YYYY-MM-DDTHH:mm:ss')}`;

        const updatedParams = {
            ...values,
            start_date_time,
            end_date_time,
            subtotal,
            court_id,
            timezone,
            is_admin,
            event_instance_id,
            paymentResult
        };
        console.log('updatedParams', updatedParams);
        await axios
            .post(`/api/club/court/reserve`, updatedParams)
            .then((res) => {
                const event = res.data.data; // moment.utc(eventInfo.start_date_time).local()
                const startLocal = moment.utc(event.start_date_time).local().format();
                const endLocal = event.end_date_time ? moment.utc(event.end_date_time).local().format() : null;
                const newEventInstance = {
                    ...event,
                    start: startLocal,
                    end: endLocal
                };

                setEvents((prev) => [...prev, newEventInstance]);
            })
            .catch((err) => {
                throw err;
            });
    };

    return (
        <EventsContext.Provider
            value={{
                ...state,
                createEvent,
                events,
                setEvents,
                isLoading,
                getEvents,
                getModifiedEvents,
                convertUTCToLocalTime,
                updateEventInvitationStatus,
                deleteEvent,
                updateEvent,
                deleteEventInvite,
                deleteEventInviteLive,
                getEventParticipants,
                deleteInstance,
                createEventVisible,
                setCreateEventVisible,
                edit,
                setEdit,
                eventInfo,
                setEventInfo,
                editEvent,
                isRefreshing,
                setIsRefreshing,
                editSeries,
                setEditSeries,
                setIsUploading,
                isUploading,
                getEvent,
                createEventInstance,
                updateEventInstance,
                reserveCourt,
                checkInUser
            }}
        >
            {children}
        </EventsContext.Provider>
    );
}
