import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import MedalRound from 'views/admin/AdminHub/TournamentManager/MedalRound';
import AppBracket from 'views/mobileApp/bracket';
import AppPools from 'views/mobileApp/pools';
import AppMatches from 'views/mobileApp/matches';
import SchedulerFrame from 'views/mobileApp/scheduler_iframe';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));
const PagesContactUS = Loadable(lazy(() => import('views/pages/contact-us')));
const EditEmail = Loadable(lazy(() => import('views/club-management/pages/Email')));

// ==============================|| AUTH ROUTING ||============================== //

const AppRoutes = {
    path: '/',
    // element: <MinimalLayout />,
    children: [
        {
            path: '/mobile/app/bracket/:tournamentId',
            element: <AppBracket />
        },
        {
            path: '/mobile/app/pools/:tournamentId',
            element: <AppPools />
        },
        {
            path: '/mobile/app/matches/:tournamentId',
            element: <AppMatches />
        },
        {
            path: '/scheduler/iframe',
            element: <SchedulerFrame />
        },
        {
            path: '/email/editor',
            element: <EditEmail />
        }
    ]
};

export default AppRoutes;
