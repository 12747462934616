// material-ui
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { useEffect } from 'react';

// project imports

import FooterPage from '../landing/Footer';
import AppBar from 'ui-component/extended/AppBar';
import HeaderPage from '../landing/Header';
import HostLanding from 'views/landing/Host';

const APP_STORE_URL = 'https://apps.apple.com/us/app/stacked-pickleball-community/id6446376840';
const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.jordanthiel.stacked';

const HeaderWrapper = styled('div')(({ theme }) => ({
    paddingTop: 30,
    overflowX: 'hidden',
    overflowY: 'clip',
    [theme.breakpoints.down('md')]: {
        paddingTop: 42
    }
}));

const SecondWrapper = styled('div')(({ theme }) => ({
    paddingTop: 160,
    [theme.breakpoints.down('md')]: {
        paddingTop: 60
    }
}));

// =============================|| LANDING MAIN ||============================= //

const Download = () => {
    useEffect(() => {
        const userAgent = navigator.userAgent;

        // If the user is on an Android device, redirect to the Google Play Store
        if (/android/i.test(userAgent)) {
            window.location.href = PLAY_STORE_URL;
        }

        // If the user is on an iOS device, redirect to the App Store
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            window.location.href = APP_STORE_URL;
        }
    }, []);
    return (
        <>
            {/* <HeaderWrapper id="home">
                <AppBar />
            </HeaderWrapper> */}

            <Grid sx={{ minHeight: '100vh' }} data-test-id="views/pages/download/index">
                <HostLanding />
            </Grid>
            <FooterPage />
        </>
    );
};

export default Download;
