/* eslint-disable prettier/prettier */
import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { Avatar, Chip, Divider, Grid, Stack, Typography, capitalize, Link, IconButton, Menu, MenuItem } from '@mui/material';
import { gridSpacing } from 'store/constant';
import getReadableDateTime from 'utils/get_readable_datetime';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Check, Edit, KeyboardArrowDown, LocationOn, OpenInNew, Today } from '@mui/icons-material';
import useTournaments from 'hooks/useTournaments';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import actionSnackbar from 'ui-component/actionSnackbar';
import capitalizeWords from 'utils/capitalizeWords';

export default function EventMenu({
    title,
    startDate,
    endDate,
    handleMainTabChange,
    activeMainTab,
    eventId,
    type,
    eventDetails,
    setMenuOpen,
    location,
    hasPool,
    allowedScreens
}) {
    const { tournamentDetails, editLeague } = useTournaments();
    const [viewAnchorEl, setViewAnchorEl] = React.useState(null);


    const getIcon = (check, step) => {
        if (check) {
            return (
                <Grid
                    sx={{ width: 28, height: 28, borderRadius: 25, backgroundColor: 'green' }}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                >
                    <Check sx={{ color: 'white' }} fontSize="small" />
                </Grid>
            );
        }
        return (
            <Grid
                sx={{ width: 30, height: 30, borderRadius: 25, backgroundColor: 'lightgrey' }}
                justifyContent="center"
                alignItems="center"
                display="flex"
            >
                <Typography>{step}</Typography>{' '}
            </Grid>
        );
    };
    const navigate = useNavigate();


    const eventDetailsMenu = [
        // {
        //     title: 'Basic Info',
        //     to: `/clubs/events/${type}/${eventId}/basic-info`,
        //     icon: getIcon(true)
        // },
        {
            title: 'Details',
            to: `/clubs/events/${type}/${eventId}/Details`,
            icon: getIcon(tournamentDetails?.description, 1)
        },
        {
            title: type === 'league' ? 'Schedule' : 'Divisions',
            to: `/clubs/events/${type}/${eventId}/Events`,
            icon: getIcon(tournamentDetails?.grouped_events?.length > 0, 2)
        },
        {
            title: 'Tickets',
            to: `/clubs/events/${type}/${eventId}/Tickets`,
            icon: getIcon(tournamentDetails?.event_tickets?.length > 0, 3)
        },
        ...(!tournamentDetails.is_flex
            ? [
                {
                    title: 'Courts',
                    to: `/clubs/events/${type}/${eventId}/Courts`,
                    icon: getIcon(tournamentDetails?.courts?.length > 0, 4)
                }
            ]
            : [])
        // {
        //     title: 'Publish',
        //     to: `/clubs/events/${type}/${eventId}/Publish`,
        //     icon: getIcon(tournamentDetails?.status === 'published', 4)

        // },
    ];
    const menuItems = [
        {
            title: 'Dashboard',
            to: `/clubs/events/${type}/${eventId}/Dashboard`
        },
        {
            title: 'Orders',
            to: `/clubs/events/${type}/${eventId}/Orders`
        },
        ...(type === 'tournament' ? [
            {
                title: 'Simulator',
                to: `/clubs/events/${type}/${eventId}/Simulator`
            },
        ] : []),
        // {
        //     title: 'Order Options',
        //     to: `/clubs/events/${type}/${eventId}/order-options`
        // },
        // {
        //     title: 'Marketing',
        //     to: `/clubs/events/${type}/${eventId}/Marketing`
        // },
        {
            title: 'Manage Attendees',
            to: `/clubs/events/${type}/${eventId}/attendees`,
            children: [
                {
                    title: 'Attendee List',
                    to: `/clubs/events/${type}/${eventId}/attendee-list`
                },
                ...(type === 'league'
                    ? [
                        {
                            title: 'Waitlist',
                            to: `/clubs/events/${type}/${eventId}/Waitlist`
                        }
                    ]
                    : []),

                {
                    title: 'Feed',
                    to: `/clubs/events/${type}/${eventId}/Feed`
                }
            ]
        },
        {
            title: 'Marketing',
            to: `/clubs/events/${type}/${eventId}/invite-players`,
            children: [
                {
                    title: 'Invite Players',
                    to: `/clubs/events/${type}/${eventId}/invite-players`
                }
            ]
        },
        {
            title: 'Event Admin',
            to: '/event/dashboard',
            children: [
                {
                    title: 'Overview',
                    to: `/clubs/events/${type}/${eventId}/Overview`
                },
                {
                    title: 'Roster',
                    to: `/clubs/events/${type}/${eventId}/Roster`
                },
                {
                    title: 'Check-in',
                    to: `/clubs/events/${type}/${eventId}/Check-in`
                },
                ...(!tournamentDetails?.is_flex ?
                    [
                        {
                            title: 'Court Queue',
                            to: `/clubs/events/${type}/${eventId}/court-queue`
                        }] : []),
                ...(hasPool
                    ? [
                        {
                            title: 'Pools',
                            to: `/clubs/events/${type}/${eventId}/Pools`
                        }
                    ]
                    : []),
                ...(type === 'league'
                    ? [
                        {
                            title: 'Groups',
                            to: `/clubs/events/${type}/${eventId}/Groups`
                        }
                    ]
                    : []),
                ...(type === 'tournament'
                    ? [
                        {
                            title: 'Bracket',
                            to: `/clubs/events/${type}/${eventId}/Bracket`
                        }
                    ]
                    : []),
                {
                    title: 'Matches',
                    to: `/clubs/events/${type}/${eventId}/Matches`
                },
                ...(type === 'league'
                    ? [
                        {
                            title: 'Results',
                            to: `/clubs/events/${type}/${eventId}/Results`
                        }
                    ]
                    : [])
            ]
        },
        {
            title: 'Settings',
            to: `/clubs/events/${type}/${eventId}/Settings`,
            children: [
                {
                    title: `Admins`,
                    to: `/clubs/events/${type}/${eventId}/Admins`
                },
                {
                    title: `Registration`,
                    to: `/clubs/events/${type}/${eventId}/Registration`
                }
            ]
        }

    ];

    const [openStates, setOpenStates] = React.useState(
        menuItems.reduce((states, item) => {
            states[item.title] = false;
            return states;
        }, {})
    );

    // Function to handle clicking an item
    const handleClick = (itemTitle) => {
        // Toggle the open state of the clicked item
        setOpenStates((prevStates) => ({
            ...prevStates,
            [itemTitle]: !prevStates[itemTitle]
        }));
    };
    const getIconColor = (status) => {
        switch (status) {
            case 'draft':
                return 'warning.main';
            case 'published':
                return 'success.main';
            case 'completed':
                return 'primary.main'
            case 'cancelled':
                return 'error.main';
            default:
                return 'warning.main';
        }
    };



    const checkDisabledStatus = (title) => {
        if (title === 'Event Admin') {
            if (tournamentDetails?.grouped_events.length > 0) {
                return false;
            }
            return true;
        }
        return false;
    };
    const handleViewClick = (event) => {
        setViewAnchorEl(event.currentTarget);
    };
    const handleViewClose = () => {
        setViewAnchorEl(null);
    };
    const stickyBottom = ['Details', 'Basic Info', 'Registration']
    return (
        <Stack pt={4} pb={stickyBottom.includes(activeMainTab) ? 12 : 4} data-test-id="views/admin/AdminHub/EventMenu">
            <Grid pb={2} pr={2}>
                <Grid pl={2}>
                    <MainCard content={false} sx={{ padding: 2, position: 'relative' }} elevation={4} >
                        <Grid>
                            <Typography variant="h2">
                                {title}
                            </Typography>
                            <Stack direction="row" mt={1}>
                                <Today sx={{ marginRight: 2 }} fontSize='small' />
                                <Typography gutterBottom variant="body2">
                                    {getReadableDateTime(startDate, 'date', false)}
                                </Typography>
                            </Stack>
                            {location &&
                                <Stack direction="row">
                                    <LocationOn sx={{ marginRight: 2 }} fontSize='small' />
                                    <Typography gutterBottom variant="body2">
                                        {location}
                                    </Typography>
                                </Stack>
                            }

                        </Grid>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">

                            <Chip
                                variant="outlined"
                                clickable
                                onClick={handleViewClick}
                                label={
                                    <Stack direction="row" alignItems="center">
                                        <Typography variant="h6" sx={{ fontSize: 14 }}>
                                            {tournamentDetails?.status && capitalize(tournamentDetails?.status)}
                                        </Typography>
                                        <KeyboardArrowDown fontSize="small" />

                                    </Stack>
                                }
                                sx={{ paddingLeft: 1, paddingRight: 1, paddingY: 2.2, border: '1px solid grey', boxShadow: 2 }}

                                icon={<Grid sx={{ width: 8, height: 8, backgroundColor: getIconColor(eventDetails?.status), borderRadius: 25 }} />}
                            />
                            <Menu
                                anchorEl={viewAnchorEl}
                                open={Boolean(viewAnchorEl)}
                                onClose={handleViewClose}
                            >
                                <MenuItem onClick={() => {
                                    editLeague(tournamentDetails?.id, {
                                        status: tournamentDetails?.status === 'published' ? 'draft' : 'published', editStatus: true
                                    }).then(() => {
                                        handleViewClose();
                                        if (tournamentDetails?.status === 'draft') {
                                            dispatch(
                                                openSnackbar({
                                                    open: true,
                                                    message: 'Event Published',
                                                    variant: 'alert',
                                                    alert: {
                                                        color: 'success'
                                                    },
                                                    close: true
                                                })
                                            );
                                        }
                                    });
                                }}>{tournamentDetails?.status === 'published' ? 'Unpublish event' : 'Publish event'}</MenuItem>
                                {tournamentDetails?.status !== 'completed' &&
                                    <MenuItem onClick={() => {
                                        editLeague(tournamentDetails?.id, {
                                            status: 'completed', editStatus: true
                                        }).then(() => {
                                            handleViewClose();

                                            actionSnackbar(true, `${capitalizeWords(type)} marked as complete`)
                                        });
                                    }}>Complete {type}</MenuItem>
                                }


                            </Menu>
                            {/* {open && (
                                <Grid
                                    position="absolute"
                                        sx={{ backgroundColor: 'white', width: 200 }}
                                    // onMouseLeave={() => setOpen(false)}
                                        boxShadow={4}
                                        onBlur={() => setOpen(false)}
                                >
                                        <Collapse in={open} unmountOnExit>
                                            <List component="div">

                                                <ListItemButton
                                                    sx={{ zIndex: 9999 }}
                                                    onClick={() => {
                                                        editLeague(tournamentDetails?.id, {
                                                            status: tournamentDetails?.status === 'published' ? 'draft' : 'published', editStatus: true
                                                        }).then(() => {
                                                            setOpen(!open);
                                                        });
                                                    }}
                                                >
                                                    <ListItemText
                                                        primary={tournamentDetails?.status === 'published' ? 'Unpublish event' : 'Publish event'}
                                                    />
                                                </ListItemButton>
                                            </List>
                                        </Collapse>
                                    </Grid>
                            )} */}
                            <Link
                                onClick={() => navigate(`/e/${type}/${eventDetails?.slug}`)}
                                variant="h6"
                                color="secondary"
                                sx={{ cursor: 'pointer', textDecoration: 'none' }} // This line changes the cursor on hover
                            >
                                Preview <OpenInNew fontSize='16' />
                            </Link>
                        </Stack>
                        <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={() => {
                            setMenuOpen(false);
                            navigate(`/clubs/events/${type}/${eventId}/basic-info`)
                        }}>
                            <Edit fontSize='small' />
                        </IconButton>
                    </MainCard>
                </Grid>

            </Grid >
            <Grid mt={2}>
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', paddingLeft: 0 }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {eventDetailsMenu.map((item) => (
                        <>
                            {
                                (allowedScreens().length === 0 || allowedScreens().includes(item.title)) &&
                                <React.Fragment key={item.title}>
                                    <ListItemButton
                                        selected={activeMainTab === item.title}
                                        // LinkComponent={Link}
                                        // to={item.to}
                                        onClick={() => {
                                            setMenuOpen(false);
                                            navigate(item.to);
                                        }}
                                    // onClick={() => handleMainTabChange(item.title)}
                                    >
                                        {item.icon && <ListItemIcon> {item.icon} </ListItemIcon>}

                                        <ListItemText style={{ marginLeft: 4 }}  ><Typography variant="h5"> {item.title} </Typography> </ListItemText>
                                    </ListItemButton>
                                </React.Fragment>
                            }
                        </>
                    ))}
                    <Divider sx={{ marginY: 1 }} />
                    {menuItems.map((item) => {
                        const isDisabled = checkDisabledStatus(item.title);
                        if (allowedScreens().length > 0 && !allowedScreens().includes(item.title)) {
                            return null;
                        }
                        return (
                            <React.Fragment key={item.title}>
                                {item.children ? (
                                    <>
                                        <ListItemButton onClick={() => handleClick(item.title)} disabled={isDisabled}>
                                            <Grid flex={1}>
                                                <ListItemText primary={item.title} />
                                                {isDisabled && (
                                                    <Typography variant="caption" color="error">

                                                        Division required
                                                    </Typography>
                                                )}
                                            </Grid>

                                            {openStates[item.title] ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                        <Collapse in={openStates[item.title]} timeout="auto" unmountOnExit>
                                            {item.children.map((child, index) => {
                                                if (child.title === 'Bracket' && type !== 'tournament') {
                                                    return null;
                                                }
                                                const selectedTab = activeMainTab
                                                return (
                                                    <List key={index} component="div" disablePadding>
                                                        <ListItemButton
                                                            sx={{ pl: 4 }}
                                                            selected={selectedTab === child.title}
                                                            // onClick={() => handleMainTabChange(child.title, item.title)}
                                                            onClick={() => {
                                                                setMenuOpen(false);
                                                                navigate(child.to);
                                                            }}
                                                        >
                                                            <ListItemText primary={child.title} />
                                                        </ListItemButton>
                                                    </List>
                                                );
                                            })}
                                        </Collapse>
                                    </>
                                ) : (
                                    <ListItemButton
                                        selected={activeMainTab === item.title}
                                        // LinkComponent={Link}
                                        // to={item.to}
                                        onClick={() => {
                                            setMenuOpen(false);
                                            navigate(item.to);
                                        }}
                                    // onClick={() => handleMainTabChange(item.title)}
                                    >
                                        {item.icon && <ListItemIcon> {item.icon} </ListItemIcon>}

                                        <ListItemText primary={item.title} />
                                    </ListItemButton>
                                )}
                            </React.Fragment>
                        );
                    })}
                </List>
            </Grid>
        </Stack >
    );
}

EventMenu.propTypes = {
    title: PropTypes.string,
    startDate: PropTypes.string,
    handleMainTabChange: PropTypes.func,
    activeMainTab: PropTypes.string
};
