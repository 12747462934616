import { useContext } from 'react';

// auth provider
import { MarketingContext } from 'contexts/MarketingContext';

// ==============================|| AUTH HOOKS ||============================== //

const useMarketing = () => {
    const context = useContext(MarketingContext);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};

export default useMarketing;
