// project imports
import HelpLayout from 'layout/HelpLayout';
import HelpHome from 'views/help-center';
import HelpArticle from 'views/help-center/article';
import HelpTopics from 'views/help-center/topics';

const HelpRoutes = {
    path: '/help',
    element: <HelpLayout />,
    breadcrumbs: true,
    children: [
        {
            path: '',
            element: <HelpHome />
        },
        {
            path: 'topics/:slug/:id',
            element: <HelpTopics />,
            breadcrumbs: true
        },
        {
            path: 'article/:slug',
            element: <HelpArticle />,
            breadcrumbs: true
        }
    ]
};

export default HelpRoutes;
