import { Avatar, Grid, Stack, Typography } from '@mui/material';
import { borderRadius } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import getReadableDateTime from 'utils/get_readable_datetime';

const HorizontalItemCard = ({ image, clickAction, date, content, imageSize = null }) => {
    const size = imageSize || 150;

    return (
        <MainCard elevation={6} pointer onClick={clickAction}>
            <Grid container spacing={2}>
                <Grid item display={{ xs: 'none', sm: 'block' }}>
                    <Typography variant="h4">{getReadableDateTime(date, 'dateShort')}</Typography>
                </Grid>
                {image && (
                    <Grid item>
                        <img src={image} width={size} height="auto" style={{ borderRadius: 2 }} alt="club" />
                        {/* <Avatar variant="square" src={image} sx={{ width: size, height: 'auto', borderRadius: 2 }} /> */}
                    </Grid>
                )}
                <Grid item>{content}</Grid>
            </Grid>
        </MainCard>
    );
};

export default HorizontalItemCard;
