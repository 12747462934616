import React, { useMemo, useEffect, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Grid, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Customization from '../Customization';

import navigation from 'menu-items';
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { WindowContext } from 'contexts/WindowContext';

// assets
import { IconChevronRight } from '@tabler/icons';
import useAuth from 'hooks/useAuth';
import ProfileSection from 'layout/MainLayout/TopRightMenuButton/ProfileSection';
import UnAuthAppBar from 'layout/MainLayout/TopRightMenuButton/AppBar/AppBar';
import Header from './AppBar';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, layout, isLoggedIn }) => ({
    ...theme.typography.helpContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    // transition: theme.transitions.create('margin', {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.shorter
    // }),
    padding: '16px',
    marginTop: '8px'
}));

// ==============================|| MAIN LAYOUT ||============================== //

const HelpLayout = () => {
    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const [localDrawerState, setLocalDrawerState] = useState();

    const { drawerOpen } = useSelector((state) => state.menu);
    const { container, drawerType, layout } = useConfig();
    const { isLoggedIn } = useAuth();

    const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;
    useEffect(() => {
        const savedDrawer = localStorage.getItem('drawer');
        const savedDrawerState = savedDrawer ? savedDrawer === 'true' : undefined; // Keep undefined or set a default state
        if (savedDrawerState !== undefined) {
            setLocalDrawerState(savedDrawerState);
        }
    }, []);

    return (
        <>
            <Toolbar sx={{ p: condition ? '10px' : '10px', borderBottom: '1px solid lightgrey' }} data-test-id="layout/HelpLayout/index">
                <Header />
            </Toolbar>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <Main theme={theme} open={drawerOpen} layout={layout} isLoggedIn={isLoggedIn} backgroundColor="white">
                    <Container maxWidth="xl" {...(!container && { sx: { px: { xs: 0 } } })}>
                        {/* breadcrumb */}
                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />
                    </Container>
                </Main>

                {/* <Customization /> */}
            </Box>
        </>
    );
};

export default HelpLayout;
