import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

const actionSnackbar = (success, message) => {
    dispatch(
        openSnackbar({
            open: true,
            message,
            variant: 'alert',
            alert: {
                color: success ? 'success' : 'error'
            },
            close: true
        })
    );
};

export default actionSnackbar;
