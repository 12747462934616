import { Avatar, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import getReadableDateTime from 'utils/get_readable_datetime';

const PlayerTicketCard = ({ item }) => {
    const { name, item_images } = item;
    const mainImage = item_images.filter((image) => image.is_main === 1);
    const coverImage = mainImage.length > 0 ? mainImage[0].image_url : null;
    const imageSize = 150;
    const navigate = useNavigate();

    return (
        <MainCard
            elevation={6}
            pointer
            onClick={() => {
                navigate(`/web/group/${item.group_id}`);
            }}
        >
            {console.log('hi', item)}
            <Grid container spacing={2}>
                <Grid item display={{ xs: 'none', sm: 'block' }}>
                    <Typography variant="h4">{getReadableDateTime(item.start_date, 'dateShort')}</Typography>
                </Grid>
                <Grid item>
                    <Avatar variant="square" src={coverImage} sx={{ width: imageSize, height: 'auto', borderRadius: 2 }} />
                </Grid>
                <Grid item>
                    <Typography variant="h3"> {item.name}</Typography>
                    <Typography variant="caption"> {getReadableDateTime(item.start_date, 'date')}</Typography> <br />
                    {item.order && (
                        <Typography variant="caption">
                            {' '}
                            Order {item.order?.id} of (${item.order?.total_amount / 100}) purchase on{' '}
                            {getReadableDateTime(item.order?.created_at, 'dateshort')}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default PlayerTicketCard;
