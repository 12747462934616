/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import {
    Container,
    TextField,
    Box,
    Typography,
    IconButton,
    FormControlLabel,
    Checkbox,
    Radio,
    Button,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Grid,
    Paper,
    Tabs,
    Tab
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import MultipleChoiceIcon from '@mui/icons-material/RadioButtonChecked';
import MainCard from 'ui-component/cards/MainCard';
import { borderColor, borderLeftColor } from '@mui/system';
import SaveFooter from '../components/SaveFooter';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FormComponents from 'ui-component/FormComponents';
import useForms from 'hooks/useForms';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import FormResponses from './FormResponses';


const FormEditor = () => {
    const { storeForm, getForm, formData, getFormResults, formResults, formResponseCount } = useForms();

    const { id } = useParams();
    const [queryParams] = useSearchParams();
    const product_id = queryParams.get('product_id');
    const [questions, setQuestions] = useState([]);
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
    const [view, setView] = useState('questions'); // New state for managing view

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),

    });

    console.log('formResults', formResults)

    const formik = useFormik({
        initialValues: {
            title: '',
            description: ''
        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            const params = { ...values, questions, id, product_id }
            storeForm(params).then(() => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Form Saved',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
            }).catch(() => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Error Saving Form',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            })
        }
    });

    useEffect(() => {
        getForm(id)
    }, [id])




    useEffect(() => {
        formik.resetForm({
            values: {
                title: formData?.title || '',
                description: formData?.description || ''
            }
        })
        if (formData?.fields?.length > 0) {
            setQuestions(formData.fields)
        }
    }, [formData])

    const addQuestion = () => {
        setQuestions([...questions, { type: 'text', label: '', options: [], is_required: false }]);
        setActiveQuestionIndex(questions.length);
    };

    const handleQuestionChange = (index, field, value) => {
        const newQuestions = [...questions];
        newQuestions[index][field] = value;
        setQuestions(newQuestions);
    };

    const addOption = (index) => {
        const newQuestions = [...questions];
        newQuestions[index].options.push({ option_text: '' });
        setQuestions(newQuestions);
    };

    const handleOptionChange = (qIndex, oIndex, value) => {
        const newQuestions = [...questions];
        newQuestions[qIndex].options[oIndex] = { option_text: value };
        setQuestions(newQuestions);
    };

    const deleteQuestion = (index) => {
        const newQuestions = questions.filter((_, i) => i !== index);
        setQuestions(newQuestions);
        setActiveQuestionIndex(null);
    };
    const navigate = useNavigate();
    const handleCancel = () => {
        navigate(-1);
    }


    return (
        <Container sx={{ paddingBottom: 10 }} maxWidth="lg">
            <Tabs value={view} onChange={(e, newValue) => setView(newValue)}>
                <Tab label="Questions" value="questions" />
                <Tab label="Responses" value="responses" />
            </Tabs>
            {view === 'questions' && (
                <>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2} mt={4} mb={4} bgcolor="white" p={2} borderRadius={2} borderTop={8} borderColor="primary.main">

                            <FormComponents
                                input={{
                                    type: 'text',
                                    id: 'title',
                                    label: 'Form Title',
                                    placeholder: '',
                                    value: formik.values.title,
                                    onChange: formik.handleChange,
                                    touched: formik.touched.title,
                                    onBlur: formik.handleBlur,
                                    error: formik.errors.title
                                }}
                                index={1}
                                formik={formik}
                            />
                            <FormComponents
                                input={{
                                    type: 'text',
                                    id: 'description',
                                    label: 'Description',
                                    placeholder: '',
                                    value: formik.values.description,
                                    onChange: formik.handleChange,
                                    touched: formik.touched.description,
                                    onBlur: formik.handleBlur,
                                    error: formik.errors.description,
                                    multiline: true
                                }}
                                index={1}
                                formik={formik}
                            />

                        </Grid>
                        <Grid container direction="column" spacing={2}>
                            {questions.map((question, index) => {
                                const isActive = activeQuestionIndex === index;
                                return (
                                    <Grid item>
                                        <MainCard onClick={() => setActiveQuestionIndex(index)} pointer={!isActive} elevation={isActive ? 12 : 0} key={index} mb={4} p={2} sx={{ border: 1, borderLeft: isActive && 6, borderLeftColor: isActive && "secondary.main", borderColor: isActive ? "secondary.main" : "grey.300" }} >
                                            {isActive ? (
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={8}>
                                                        <TextField
                                                            fullWidth
                                                            label={`Question ${index + 1}`}
                                                            variant="outlined"
                                                            value={question.label}
                                                            onChange={(e) => handleQuestionChange(index, 'label', e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel>Type</InputLabel>
                                                            <Select
                                                                value={question.type}
                                                                onChange={(e) => handleQuestionChange(index, 'type', e.target.value)}
                                                                label="Type"
                                                            >
                                                                <MenuItem value="text">Text</MenuItem>
                                                                <MenuItem value="multiple-choice">Multiple Choice</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    {question.type === 'multiple-choice' && (
                                                        <Grid item xs={12}>
                                                            {question.options.map((option, oIndex) => (
                                                                <Box key={oIndex} display="flex" alignItems="center" sx={{ mb: 1 }}>
                                                                    <Radio disabled />
                                                                    <TextField
                                                                        fullWidth
                                                                        value={option.option_text}
                                                                        onChange={(e) => handleOptionChange(index, oIndex, e.target.value)}
                                                                        variant="outlined"
                                                                    />
                                                                </Box>
                                                            ))}
                                                            <Button startIcon={<AddIcon />} onClick={() => addOption(index)} sx={{ mb: 2 }}>
                                                                Add Option
                                                            </Button>
                                                        </Grid>
                                                    )}
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={question.is_required}
                                                                    onChange={(e) => handleQuestionChange(index, 'is_required', e.target.checked)}
                                                                />
                                                            }
                                                            label="Required"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                                                        <IconButton onClick={() => deleteQuestion(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>

                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <Paper sx={{ p: 2, cursor: 'pointer' }}>
                                                    <Typography variant="h5">{`Question ${index + 1}: ${question.label || 'Untitled Question'
                                                        }`}</Typography>
                                                    {question.type === 'multiple-choice' ? (
                                                        <FormComponents
                                                            input={{
                                                                type: 'multiple-choice',
                                                                value: `Question ${index + 1}: Answer`,
                                                                disabled: true,
                                                                options: question.options

                                                            }}
                                                            index={1}

                                                        />

                                                    ) : (
                                                        <Box mt={1}>
                                                            <FormComponents
                                                                input={{
                                                                    type: 'text',
                                                                    value: `Question ${index + 1}: Answer`,
                                                                    disabled: true
                                                                }}
                                                                index={1}
                                                                formik={formik}
                                                            />
                                                            {/* <TextField fullWidth disabled variant="outlined" placeholder={question.label} /> */}
                                                        </Box>
                                                    )}
                                                    <Box display="flex" justifyContent="space-between" mt={2}>
                                                        <IconButton onClick={() => setActiveQuestionIndex(index)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Paper>
                                            )}
                                        </MainCard>
                                    </Grid>
                                )
                            })}
                            <Grid item>
                                <Button startIcon={<AddIcon />} onClick={addQuestion} variant="contained">
                                    Add Question
                                </Button>
                            </Grid>
                        </Grid>
                        <SaveFooter handleCancel={handleCancel} />

                    </form>
                </>
            )
            }
            {
                view === 'responses' && (
                    <FormResponses id={id} />
                )
            }
        </Container >
    );
};

export default FormEditor;
