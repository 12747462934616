// material-ui
import { Avatar, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';

// project imports
import useAuth from 'hooks/useAuth';
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

// assets
import Avatar1 from 'assets/images/users/avatar-1.png';
import { Formik, useFormik } from 'formik';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { useNavigate } from 'react-router-dom';
import CustomDateTime from 'views/forms/components/DateTime/CustomDateTime';
import * as Yup from 'yup';

// ==============================|| PROFILE 3 - PROFILE ||============================== //

const AccountDeletion = () => {
    const { user, requestAccountDeletion } = useAuth();
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Email is required')
    });

    const formik = useFormik({
        validationSchema,
        initialValues: {
            email: ''
        },
        onSubmit: (values, { resetForm }) => {
            requestAccountDeletion(values.email).then(() => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Success: Account Deletion Request. We will delete your account and all associated data within 48 hours',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
            }); // Reset the form values
            resetForm();
        }
    });

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item sm={6} md={8}>
                <Formik initialValues={formik.initialValues}>
                    {({ isSubmitting, setFieldValue }) => (
                        <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                            <SubCard title="Request to delete account for: Stacked App on both iOS & Android. Once request, your account and all associated data will be permenantly deleted within 48 hours">
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="email"
                                            fullWidth
                                            label="Email address"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <AnimateButton>
                                                <Button
                                                    type="submit" // This will trigger form submission
                                                    variant="contained"
                                                >
                                                    Save
                                                </Button>
                                            </AnimateButton>
                                        </Stack>
                                        <Typography mt={2} color="error">
                                            {formik.touched.email && formik.errors.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </form>
                    )}
                </Formik>
            </Grid>
        </Grid>
    );
};

export default AccountDeletion;
