import { Grid, Typography } from '@mui/material';
import useClubs from 'hooks/useClubs';
import { useEffect } from 'react';
import Members from 'views/club-management/pages/Members';

const MembershipMembers = ({ clubId }) => {
    const test = true;
    const { membershipDetails, getClubMembers, clubMembers } = useClubs();
    const membershipId = membershipDetails?.id;

    return (
        <Grid mt={1}>
            <Members clubId={clubId} membershipId={membershipId} membershipName={membershipDetails?.name} />
        </Grid>
    );
};

export default MembershipMembers;
