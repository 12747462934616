import dashboard from '../dashboard';
import widget from '../widget';
import events from '../events';
import forms from '../forms';
import elements from '../elements';
import pages from '../pages';
import utilities from '../utilities';
import support from '../support';
import other from '../other';
import internalDashboard from './dashboard';

// ==============================|| MENU ITEMS ||============================== //

const internalItems = {
    // items: [dashboard, widget, application, forms, elements, pages, utilities, support, other]
    items: [internalDashboard]
};

export default internalItems;
