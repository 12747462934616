import React, { useState, createContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import useAuth from '../hooks/useAuth';

export const InternalToolsContext = createContext();
const initialState = {
    dashboard: {},
    loading: { internalTools: false }
};

function internalToolsReducer(state, action) {
    switch (action.type) {
        case 'SET_STATE':
            return { ...state, [action.payload.key]: action.payload.value };

        default:
            return state;
    }
}
export function InternalToolsProvider({ children }) {
    const [state, dispatch] = useReducer(internalToolsReducer, initialState);

    const setValue = (key, value) => {
        dispatch({ type: 'SET_STATE', payload: { key, value } });
    };

    const getDashboard = async () => {
        const response = await axios.get(`/api/internal-tools/dashboard`).then((res) => {
            setValue('dashboard', res.data.data);
        });
        return response;
    };
    return <InternalToolsContext.Provider value={{ ...state, getDashboard }}>{children}</InternalToolsContext.Provider>;
}
