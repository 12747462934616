import moment from 'moment';

function calculateAge(dateOfBirth, asOf = null) {
    // Check if moment is loaded
    if (typeof moment === 'undefined') {
        throw new Error('Moment.js is not loaded.');
    }

    // Parse the date of birth
    const dob = moment(dateOfBirth, 'YYYY-MM-DD');
    if (!dob.isValid()) {
        return false;
    }

    // Get the current date
    const today = asOf ? moment(asOf, 'YYYY-MM-DD') : moment();

    // Calculate the age
    const age = today.diff(dob, 'years');

    return age;
}

export default calculateAge;
