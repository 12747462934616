import { Grid } from '@mui/material';
import useClubs from 'hooks/useClubs';
import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import CustomTable from 'ui-component/table/CustomTable';
import capitalizeWords from 'utils/capitalizeWords';
import MemberDrawer from './components/MemberDrawer';
import { Add, AddCircle } from '@mui/icons-material';

const Members = ({ clubId, membershipId = null, membershipName = null }) => {
    const headers = [
        { id: 'id', label: 'ID' },
        { id: 'name', label: 'Name' },
        { id: 'membership', label: 'Membership' },
        { id: 'price', label: 'Price' },
        { id: 'term', label: 'Term' }
    ];
    const { getClubMembers, clubMembers } = useClubs();
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [pageLength, setPageLength] = useState(10);
    const [selectedMember, setSelectedMember] = useState();
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        getClubMembers(clubId, page, pageLength, membershipId).then((res) => {
            setPage(res.current_page);
            setLastPage(res.last_page);
        });
    }, [clubId, page, pageLength]);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };
    const handleRowClick = (memberId) => {
        setSelectedMember(memberId);
        toggleDrawer();
    };

    useEffect(() => {
        const data = clubMembers?.map((item) => [
            {
                id: 'id',
                data: item.id,
                isVisible: true
            },
            {
                id: 'name',
                data: item.user?.name,
                isVisible: true
            },
            {
                id: 'membership',
                data: item.membership?.name,
                isVisible: true
            },
            {
                id: 'price',
                data: (
                    <CurrencyFormat
                        decimalScale={2}
                        fixedDecimalScale
                        value={item.pricing?.price / 100}
                        displayType="text"
                        thousandSeparator
                        prefix="$"
                    />
                ),
                isVisible: true
            },
            {
                id: 'term',
                data: capitalizeWords(item.pricing?.term),
                isVisible: true
            }
        ]);
        setTableData(data);
    }, [clubMembers]);
    return (
        <Grid container>
            <CustomTable
                headers={headers}
                title={membershipId ? `${membershipName} Members` : 'Club Members'}
                buttonText="Add Member"
                handleButtonClick={() => console.log('clicked')}
                buttonIcon={<AddCircle />}
                data={tableData}
                selectable
                handleClick={handleRowClick}
                page={page}
                setPage={setPage}
                pageLength={pageLength}
                setPageLength={setPageLength}
                showFooter
                lastPage={lastPage}
                showSorting
            />
            {drawerOpen && <MemberDrawer toggleDrawer={toggleDrawer} drawerOpen={drawerOpen} memberId={selectedMember} />}
        </Grid>
    );
};

export default Members;
