/* eslint-disable prettier/prettier */
import { Button, Grid, InputAdornment, TextField, IconButton, Typography, Stack } from '@mui/material';
import { Add, Remove, BorderColor, Delete } from '@mui/icons-material';
import { useFormik } from 'formik';
import useClubs from 'hooks/useClubs';
import FormSection from 'views/admin/Events/create-event/components/FormSection';
import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import actionSnackbar from 'ui-component/actionSnackbar';
import MembershipList from '../../../../../components/MembershipList';
import SaveFooter from 'views/admin/AdminHub/components/SaveFooter';
import FormComponents from 'ui-component/FormComponents';
import CardModal from 'ui-component/CardModal';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/styles';

const ReservationRules = () => {

    const { membershipDetails, getClubMembership, updateClubMembership, deleteMembership } = useClubs();
    const theme = useTheme()

    const [features, setFeatures] = useState(membershipDetails?.features || ['']); // Initialize with a default feature
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const toggleDeleteModal = () => {
        setDeleteModalOpen(!deleteModalOpen)
    }
    const SectionHeader = ({ title }) => (
        <Stack sx={{ backgroundColor: theme.palette.primary.light, padding: 1, borderRadius: 2 }}>
            <Typography variant="h4">
                {title}
            </Typography>
        </Stack>
    )

    const initialValues = {
        online_booking: Boolean(membershipDetails?.online_booking),
        registration_approval: Boolean(membershipDetails?.registration_approval),
        book_resources: Boolean(membershipDetails?.book_resources),
        pick_members: Boolean(membershipDetails?.pick_members),
        advance_court_booking_hours: membershipDetails?.advance_court_booking_hours || '',
        advance_court_booking_days: membershipDetails?.advance_court_booking_days || '',
        advanced_cancellation: membershipDetails?.advanced_cancellation || '',
        booking_buffer_minutes: membershipDetails?.booking_buffer_minutes || '',
        num_of_guests: membershipDetails?.num_of_guests || '',
        num_of_guests_term: membershipDetails?.num_of_guests_term || '',
        exclude_last_reservation: Boolean(membershipDetails?.exclude_last_reservation),
        cancellation_window_message: membershipDetails?.cancellation_window_message || '',
        lock_reservations_after_penalty: membershipDetails?.lock_reservations_after_penalty || '',
        individual_courts_per_day: membershipDetails?.individual_courts_per_day || '',
        individual_courts_per_week: membershipDetails?.individual_courts_per_week || '',
        individual_courts_per_month: membershipDetails?.individual_courts_per_month || '',
        family_courts_per_day: membershipDetails?.family_courts_per_day || '',
        family_courts_per_week: membershipDetails?.family_courts_per_week || '',
        family_courts_per_month: membershipDetails?.family_courts_per_month || '',
    };

    const formik = useFormik({
        initialValues,
        // validationSchema,
        onSubmit: (values, { resetForm }) => {
            const updatedValues = { ...values, id: membershipDetails?.id };

            updateClubMembership(updatedValues)
                .then(() => {
                    actionSnackbar(true, 'Membership updated');
                })
                .catch(() => {
                    actionSnackbar(false, 'Failed to update membership');
                });
        }
    });
    console.log('formik', formik.values)

    useEffect(() => {
        formik.resetForm({
            values: initialValues
        });
    }, [membershipDetails]);



    const handleCancel = () => {
        formik.resetForm();
        actionSnackbar(true, 'Form Reset')
    };


    const settings = [
        {
            type: 'switch',
            id: 'online_booking',
            label: 'Allow members to book courts online',
            value: formik.values.online_booking,
            onChange: formik.handleChange,
            touched: formik.touched.online_booking,
            onBlur: formik.handleBlur,
            error: formik.errors.online_booking,
            switchFirst: true,
        },
        {
            type: 'switch',
            id: 'registration_approval',
            label: 'Reservations must be approved',
            value: formik.values.registration_approval,
            onChange: formik.handleChange,
            touched: formik.touched.registration_approval,
            onBlur: formik.handleBlur,
            error: formik.errors.registration_approval,
            switchFirst: true,
        },
        {
            type: 'switch',
            id: 'book_resources',
            label: 'Allow members to book resources',
            value: formik.values.book_resources,
            onChange: formik.handleChange,
            touched: formik.touched.book_resources,
            onBlur: formik.handleBlur,
            error: formik.errors.book_resources,
            switchFirst: true,
        },
        {
            type: 'switch',
            id: 'pick_members',
            label: 'Allow members to pick other members to play with',
            value: formik.values.pick_members,
            onChange: formik.handleChange,
            touched: formik.touched.pick_members,
            onBlur: formik.handleBlur,
            error: formik.errors.pick_members,
            switchFirst: true,
        },
    ];
    const settings2 = [
        {
            type: 'text',
            inputType: 'number',
            id: 'advanced_cancellation',
            label: 'Cancellation Window',
            tooltip: 'Hours before a reservation to cancel without penalty',
            value: formik.values.advanced_cancellation,
            onChange: formik.handleChange,
            endAdornment: <Typography variant="h5"> hours</Typography>,
            smWidth: 6,
            inputWidth: 1.5
        },
        {
            type: 'text',
            inputType: 'number',
            id: 'booking_buffer_minutes',
            label: 'Booking buffer minutes',
            tooltip: 'Advance notice to prevent last minute bookings',
            value: formik.values.booking_buffer_minutes,
            onChange: formik.handleChange,
            endAdornment: <Typography variant="h5"> mins</Typography>,
            smWidth: 6,
            inputWidth: 1.5
        },
        {
            type: 'text',
            inputType: 'number',
            id: 'lock_reservations_after_penalty',
            label: 'Lock Reservations after X Cancellations',
            tooltip: 'If a user has too many penalty cancellations, you can block them from booking more',
            value: formik.values.lock_reservations_after_penalty,
            onChange: formik.handleChange,
            smWidth: 6,
            inputWidth: 1.5
        },
        {
            type: 'text',
            multiline: true,
            placeholder: 'Sorry, we do not allow cancellations within 2 hours \n',
            id: 'cancellation_window_message',
            label: 'Message to show member if outside cancellation window',
            value: formik.values.cancellation_window_message,
            onChange: formik.handleChange,
            inputWidth: 8

        },


    ]
    const individualRestrictions = [
        {
            type: 'text',
            inputType: 'number',
            id: 'individual_courts_per_day',
            label: 'Court reservations per day',
            tooltip: 'The max number of court reservations a player can make for a day',
            value: formik.values.individual_courts_per_day,
            onChange: formik.handleChange,
            smWidth: 6,
            inputWidth: 1.5
        },
        {
            type: 'text',
            inputType: 'number',
            id: 'individual_courts_per_week',
            label: 'Court reservations per week',
            tooltip: 'The max number of court reservations a player can make during a 1 week period',
            value: formik.values.individual_courts_per_week,
            onChange: formik.handleChange,
            smWidth: 6,
            inputWidth: 1.5
        },
        {
            type: 'text',
            inputType: 'number',
            id: 'individual_courts_per_month',
            label: 'Court reservations per month',
            tooltip: 'The max number of court reservations a player can make during a 1 month period',
            value: formik.values.individual_courts_per_month,
            onChange: formik.handleChange,
            smWidth: 6,
            inputWidth: 1.5
        },




    ]
    const familyRestrictions = [
        {
            type: 'text',
            inputType: 'number',
            id: 'family_courts_per_day',
            label: 'Court reservations per day',
            tooltip: 'The max number of court reservations a family can make for a day',
            value: formik.values.family_courts_per_day,
            onChange: formik.handleChange,
            smWidth: 6,
            inputWidth: 1.5
        },
        {
            type: 'text',
            inputType: 'number',
            id: 'family_courts_per_week',
            label: 'Court reservations per week',
            tooltip: 'The max number of court reservations a family can make during a 1 week period',
            value: formik.values.family_courts_per_week,
            onChange: formik.handleChange,
            smWidth: 6,
            inputWidth: 1.5
        },
        {
            type: 'text',
            inputType: 'number',
            id: 'family_courts_per_month',
            label: 'Court reservations per month',
            tooltip: 'The max number of court reservations a family can make during a 1 month period',
            value: formik.values.family_courts_per_month,
            onChange: formik.handleChange,
            smWidth: 6,
            inputWidth: 1.5
        },
    ]


    return (
        <Grid container spacing={gridSpacing} sx={{ paddingBottom: 10 }}>

            {membershipDetails && (
                <Grid item xs={12} lg={12} marginTop={{ xs: 2, md: 0 }}>
                    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>

                        <Grid container direction="column" spacing={gridSpacing} mt={2} pl={1}>
                            <Grid item xs={12}>
                                <SectionHeader title="General" />
                            </Grid>
                            {/* <FormSection {...sectionProps} formik={formik} /> */}
                            {settings.map((input) => (
                                <FormComponents input={input} />
                            ))}
                            <Grid item xs={12}>
                                {/* <Grid item xs={4}> */}
                                <Typography variant="h5" gutterBottom>
                                    Allowed number of guest(s)
                                </Typography>
                                <Grid container alignItems="center" justifyContent="flex-start" >


                                    <FormComponents input={{ type: 'text', inputType: 'number', id: 'num_of_guests', value: formik.values.num_of_guests, smWidth: 1 }} formik={formik} />



                                    <Grid item pl={1} pr={1}>
                                        <Typography variant="h5"> every</Typography>
                                    </Grid>

                                    <FormComponents input={{
                                        type: 'select', id: 'num_of_guests_term', value: formik.values.num_of_guests_term, options: [
                                            { label: 'Day', value: 'day' },
                                            { label: 'Week', value: 'week' },
                                            { label: 'Month', value: 'month' },
                                            { label: 'Year', value: 'year' },
                                        ],
                                        smWidth: 2
                                    }}
                                        formik={formik} />



                                </Grid>
                                {/* </Grid> */}
                            </Grid>
                            <Grid item xs={12}>
                                {/* <Grid item xs={4}> */}
                                <Typography variant="h5" gutterBottom>
                                    Time in advance a court can be booked
                                </Typography>
                                <Grid container alignItems="center" justifyContent="flex-start" >


                                    <FormComponents input={{ type: 'text', inputType: 'number', id: 'advance_court_booking_days', value: formik.values.advance_court_booking_days, smWidth: 1 }} formik={formik} />



                                    <Grid item pl={1} pr={1}>
                                        <Typography variant="h5"> days</Typography>
                                    </Grid>

                                    <FormComponents input={{ type: 'text', inputType: 'number', id: 'advance_court_booking_hours', value: formik.values.advance_court_booking_hours, smWidth: 1 }} formik={formik} />
                                    <Grid item pl={1} pr={1}>
                                        <Typography variant="h5"> hours</Typography>
                                    </Grid>




                                </Grid>
                                {/* </Grid> */}
                            </Grid>
                            {settings2.map((input) => (
                                <FormComponents input={input} />
                            ))}
                            <Grid item xs={12}>
                                <SectionHeader title="Individual Restrictions" />
                            </Grid>
                            {individualRestrictions.map((input) => (
                                <FormComponents input={input} />
                            ))}
                            <Grid item xs={12}>
                                <SectionHeader title="Family Restrictions" />
                            </Grid>
                            {familyRestrictions.map((input) => (
                                <FormComponents input={input} />
                            ))}
                            <SaveFooter handleCancel={handleCancel} />

                        </Grid>
                    </form>
                </Grid>
            )}
        </Grid>
    );
};

export default ReservationRules;
