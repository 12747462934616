import { Container, Grid, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import useLeagues from 'hooks/useLeagues';
import useAuth from 'hooks/useAuth';
import useUsers from 'hooks/useUsers';
import PlayerTicketCard from './playerTicketCard';
import { gridSpacing } from 'store/constant';

const PlayerTickets = () => {
    const groupTicketsByMonth = (tickets) => {
        const groups = tickets.reduce((acc, ticket) => {
            const month = moment(ticket.start_date).format('MMMM YYYY'); // E.g., 'January 2021'
            if (!acc[month]) {
                acc[month] = { data: [], itemCount: 0 };
            }
            acc[month].data.push(ticket);
            acc[month].itemCount += 1;
            return acc;
        }, {});

        let cumulativeCount = 0;
        return Object.keys(groups).map((month) => {
            const section = {
                title: month,
                data: groups[month].data,
                indexOffset: cumulativeCount
            };
            cumulativeCount += groups[month].itemCount;
            return section;
        });
    };

    const { getPlayerTickets, playerTickets } = useUsers();
    const [groupedTickets, setGroupedTickets] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const { user } = useAuth();
    const key = { 1: 'upcoming', 2: 'previous' };

    useEffect(() => {
        getPlayerTickets(key[selectedTab]);
    }, [user?.id, selectedTab]);

    useEffect(() => {
        if (playerTickets) {
            setGroupedTickets(groupTicketsByMonth(playerTickets));
        }
    }, [playerTickets]);
    return (
        <Container>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Typography variant="h2"> Your Tickets</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
                        <Tab label="Upcoming" value={1} />
                        <Tab label="Previous" value={2} />
                    </Tabs>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        {groupedTickets?.map((month) => (
                            <Grid item xs={12} key={month.title}>
                                <Typography mb={2} variant="h4">
                                    {month.title}
                                </Typography>
                                <Grid container spacing={gridSpacing}>
                                    {month.data?.map((item) => (
                                        <Grid item xs={12}>
                                            <PlayerTicketCard item={item} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default PlayerTickets;
