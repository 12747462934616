/* eslint-disable array-callback-return */
import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    Grid,
    TableHead,
    Avatar,
    Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import getReadableDateTime from 'utils/get_readable_datetime';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import TeamsTable from 'views/components/teamsTable.component';

function capitalizeWords(str) {
    return str
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

const getTeamStatusIcon = (status) => {
    switch (status) {
        case 'Confirmed':
            return <CheckCircleIcon color="success" />;
        default:
            return <HistoryToggleOffIcon color="warning" />;
    }
};

const Divisions = ({ eventList }) => (
    <Box sx={{ backgroundColor: 'white', marginTop: 2 }}>
        {eventList?.map((event, index) => {
            const categoryName = capitalizeWords(event.group);
            const events = event.events;

            return (
                <Accordion key={index} sx={{ marginBottom: 2 }} defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        aria-controls={`event-panel${index}a-content`}
                        id={`event-panel${index}a-header`}
                        sx={{ backgroundColor: 'primary.main', borderRadius: 1, paddingHorizontal: 1, paddingVertical: 0.5 }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                color: 'primary.contrastText',
                                borderRadius: 1,
                                flexGrow: 1
                            }}
                        >
                            {categoryName}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer>
                            <Table size="small" aria-label={categoryName}>
                                <TableBody>
                                    {events.map((event, eventIndex) => {
                                        const waitlistedCount = event?.teams?.filter((team) => team.is_waitlisted).length;
                                        return (
                                            <React.Fragment key={event.id}>
                                                <Accordion sx={{ marginTop: 1 }}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={`team-panel${eventIndex}a-content`}
                                                        id={`team-panel${eventIndex}a-header`}
                                                    >
                                                        <Grid>
                                                            <Typography variant="caption">
                                                                {getReadableDateTime(event.start_date_time)}
                                                            </Typography>
                                                            <Typography variant="h5">{event.title}</Typography>
                                                            <Grid container justifyContent="flex-start">
                                                                <Grid item ml={2}>
                                                                    <Typography variant="caption">Max Teams: </Typography>
                                                                    <Typography variant="caption">{event?.max_teams}</Typography>
                                                                </Grid>
                                                                <Grid item ml={2}>
                                                                    <Typography variant="caption">Registered Teams: </Typography>
                                                                    <Typography variant="caption">
                                                                        {event?.teams?.filter((team) => !team.is_waitlisted).length ?? 0}
                                                                    </Typography>
                                                                </Grid>
                                                                {waitlistedCount ? (
                                                                    <Grid item ml={2}>
                                                                        <Typography variant="caption">Waitlisted Teams: </Typography>
                                                                        <Typography variant="caption">{waitlistedCount}</Typography>
                                                                    </Grid>
                                                                ) : null}
                                                            </Grid>
                                                            <Grid mt={1}>
                                                                <Button variant="outlined" size="small">
                                                                    Show Teams
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails key={event.id}>
                                                        <TeamsTable
                                                            eventId={event?.id}
                                                            selectedEvent={event}
                                                            eventName={event?.title}
                                                            showAdmin={false}
                                                            teamList={event?.teams}
                                                            key={event.id}
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                            </React.Fragment>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            );
        })}
    </Box>
);

export default Divisions;
