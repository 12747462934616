import React, { useCallback, useEffect, useState, createContext } from 'react';

export const WindowContext = createContext({
    isMobile: true
});

export function WindowContextProvider({ children }) {
    const getIsMobile = useCallback(() => window.innerWidth < 720, []);

    const [isMobile, setIsMobile] = useState(getIsMobile());

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(getIsMobile());
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [getIsMobile]);

    return <WindowContext.Provider value={{ isMobile }}>{children}</WindowContext.Provider>;
}
