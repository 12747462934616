import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import Download from 'views/pages/download';
import Blog from 'views/pages/saas-pages/Blog';
import MainLayout from 'layout/MainLayout';

// login option 1 routing

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const HostLanding = Loadable(lazy(() => import('views/landing/Host')));
const LoginHook = Loadable(lazy(() => import('views/login-hook')));

const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthForgotPassword3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));
const AuthCheckMail3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/CheckMail3')));
const AuthResetPassword3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword3')));
const AuthCodeVerification3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/CodeVerification3')));

// maintenance routing
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));
const MaintenanceComingSoon1 = Loadable(lazy(() => import('views/pages/maintenance/ComingSoon/ComingSoon1')));
const MaintenanceComingSoon2 = Loadable(lazy(() => import('views/pages/maintenance/ComingSoon/ComingSoon2')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('views/pages/maintenance/UnderConstruction')));

// landing & contact-us routing
const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));
const PagesContactUS = Loadable(lazy(() => import('views/pages/contact-us')));
const PagesFaqs = Loadable(lazy(() => import('views/pages/saas-pages/Faqs')));
const PagesPrivacyPolicy = Loadable(lazy(() => import('views/pages/saas-pages/PrivacyPolicy')));
const TeamPage = Loadable(lazy(() => import('views/pages/saas-pages/Team')));
const BlogHome = Loadable(lazy(() => import('views/pages/saas-pages/BlogHome')));
const CourtPage = Loadable(lazy(() => import('views/pages/courts/CourtPage')));
const AppECommProducts = Loadable(lazy(() => import('views/application/e-commerce/Products')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/pages/login/login3',
            element: <AuthLogin3 />
        },
        {
            path: '/pages/register/register3',
            element: <AuthRegister3 />
        },
        {
            path: '/forgot-password',
            element: <AuthForgotPassword3 />
        },
        {
            path: '/pages/check-mail/check-mail3',
            element: <AuthCheckMail3 />
        },
        {
            path: '/reset-password',
            element: <AuthResetPassword3 />
        },
        {
            path: '/verification-code',
            element: <AuthCodeVerification3 />
        },

        {
            path: '/pages/error',
            element: <MaintenanceError />
        },
        {
            path: '/pages/coming-soon1',
            element: <MaintenanceComingSoon1 />
        },
        {
            path: '/pages/coming-soon2',
            element: <MaintenanceComingSoon2 />
        },
        {
            path: '/pages/under-construction',
            element: <MaintenanceUnderConstruction />
        },

        {
            path: '/landing',
            element: <PagesLanding />
        },
        {
            path: '/contact-us',
            element: <PagesContactUS />
        },
        {
            path: '/faq',
            element: <PagesFaqs />
        },
        {
            path: '/privacy-policy',
            element: <PagesPrivacyPolicy />
        },
        {
            path: '/team',
            element: <TeamPage />
        },
        {
            path: '/blog',
            element: <BlogHome />
        },
        {
            path: '/blog/:slug',
            element: <Blog />
        },
        // {
        //     path: '/tournaments',
        //     element: <AppECommProducts />
        // },

        {
            path: '/download',
            element: <Download />
        },
        { path: '/court/:id', element: <CourtPage /> },
        { path: '/host', element: <HostLanding /> },
        { path: '/login_hook', element: <LoginHook /> }
    ]
};

export default AuthenticationRoutes;
