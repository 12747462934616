// material-ui
import { useTheme } from '@mui/material/styles';
import { CardContent, Grid, IconButton, InputAdornment, Modal, Typography, useMediaQuery } from '@mui/material';
import { gridSpacing } from 'store/constant';

// assets
import EventList from 'views/home/Home/EventList';
import useLeagues from 'hooks/useLeagues';
import OrdersTable from './components/OrdersTable';
import { clearError, getAdminCompletedOrder, refundPayment } from 'store/slices/user';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { dispatch, useSelector } from 'store';
import SimpleModal from 'views/forms/plugins/Modal/SimpleModal';
import MainCard from 'ui-component/cards/MainCard';
import { Close } from '@mui/icons-material';
import CardModal from 'ui-component/CardModal';
import FormComponents from 'ui-component/FormComponents';
import { useFormik } from 'formik';
import * as yup from 'yup';

// project imports

// ==============================|| ANALYTICS DASHBOARD ||============================== //

const Orders = ({ showTitle = true, eventId = null }) => {
    const { user } = useAuth();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('created_at');
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState();

    const { completedOrders, totalOrders, error } = useSelector((state) => state.user);
    useEffect(() => {
        dispatch(getAdminCompletedOrder(page + 1, rowsPerPage, order, orderBy, eventId));
    }, [user?.id, page, rowsPerPage, order, orderBy]);

    const headCells = [
        {
            id: 'id',
            numeric: true,
            label: 'ID',
            align: 'center'
        },
        {
            id: 'created_at',
            numeric: true,
            label: 'Date',
            align: 'center'
        },
        {
            id: 'player',
            numeric: false,
            label: 'Player',
            align: 'left'
        },
        {
            id: 'event',
            numeric: false,
            label: 'Event',
            align: 'left'
        },
        {
            id: 'amount',
            numeric: true,
            label: 'Amount',
            align: 'right'
        },
        {
            id: 'profit',
            numeric: true,
            label: 'Revenue',
            align: 'right'
        },
        {
            id: 'discount_code',
            numeric: true,
            label: 'Discount Code',
            align: 'center'
        },

        {
            id: 'status',
            numeric: true,
            label: 'Status',
            align: 'center'
        }
    ];
    const toggleModal = (row) => {
        setOpen(!open);
        setSelectedRow(row);
        dispatch(clearError());
    };
    const validationSchema = yup.object().shape({
        amount: yup
            .number()
            .required('Amount is required')
            .max(selectedRow?.total_amount / 100, `Amount cannot be more than ${selectedRow?.total_amount / 100}`)
    });

    const formik = useFormik({
        initialValues: {
            amount: ''
        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            dispatch(refundPayment(selectedRow?.id, values.amount));
        }
    });

    useEffect(() => {
        formik.resetForm({
            values: {
                amount: selectedRow?.amount_transferred / 100
            }
        });
    }, [selectedRow]);

    return (
        <Grid container data-test-id="views/admin/Orders/index">
            {showTitle && (
                <Grid xs={12} paddingY={2}>
                    <Typography variant="h1" color="#262626" fontSize={48}>
                        Completed Orders
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        {/* <MarketShareAreaChartCard /> */}
                        <OrdersTable
                            orders={completedOrders}
                            headCells={headCells}
                            tableTitle="Orders"
                            page={page}
                            setPage={setPage}
                            totalRows={totalOrders}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            order={order}
                            setOrder={setOrder}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            handleRefundPress={toggleModal}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <CardModal
                        title="Refund Payment"
                        open={open}
                        toggleModal={toggleModal}
                        content={
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography>
                                        Refund can take 5-10 days to appear on a customer&apos;s statement. Stacked&apos;s fee for the
                                        original payment won&apos;t be returned, but there are no additional fees for the refund
                                    </Typography>
                                </Grid>
                                <Grid item direction="column">
                                    <FormComponents
                                        input={{
                                            type: 'text',
                                            id: 'amount',
                                            label: 'Refund Amount',
                                            value: formik.values.amount,
                                            onChange: formik.handleChange,
                                            touched: formik.touched.amount,
                                            onBlur: formik.handleBlur,
                                            error: formik.errors.amount,
                                            startAdornment: <InputAdornment position="start"> $</InputAdornment>
                                        }}
                                        index={1}
                                        formik={formik}
                                    />
                                    <Grid item mt={2}>
                                        <Typography color="error"> {error?.refund}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        actionText="Refund"
                        showCancel
                        submitAction={formik.handleSubmit}
                        formik={formik}
                        errorMessage={error?.refund}
                    />
                </form>
            )}
        </Grid>
    );
};

export default Orders;
