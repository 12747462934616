// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Container, Grid, Typography } from '@mui/material';

// project imports
import Slider from './Slider';
import { gridSpacing } from 'store/constant';

// assets
import imgLayout1 from 'assets/images/landing/demo-dark.png';
import imgLayout2 from 'assets/images/landing/demo-rtl.png';
import imgLayout3 from 'assets/images/landing/demo-multi.png';
import courtImage from 'assets/images/landing/find_courts_image.png';
import homeImage from 'assets/images/landing/join_group_image.png';
import groupImage from 'assets/images/landing/activity_page.png';
import imgLayoutGrid from 'assets/images/landing/img-lay-grid.png';
import imgLayoutDarkGrid from 'assets/images/landing/img-bg-grid-dark.svg';

// styles
const LayoutImageWrapper = styled(Box)(({ theme }) => ({
    // width: '100%',
    position: 'relative',
    margin: '-30px 0px 0px 20px',
    [theme.breakpoints.down('lg')]: {
        margin: '-30px 0px'
    }
}));

const LayoutImage = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '36rem',
    height: '26rem',
    animation: '5s wings ease-in-out infinite'
});

const LayoutContent = styled(Grid)(({ theme }) => ({
    maxWidth: 400,
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.dark : '#FFFFFF',
        border: `6px solid${theme.palette.secondary.main}`,
        width: 25,
        height: 25,
        borderRadius: '50%',
        top: 13,
        left: -20
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#9E9E9E',
        width: 1,
        height: 390,
        top: 13,
        left: -8
    },
    [theme.breakpoints.down('md')]: {
        '&:before': {
            height: 290
        }
    },
    [theme.breakpoints.down('lg')]: {
        '&:after': {
            left: -12
        },
        '&:before': {
            left: 0,
            height: 290
        }
    }
}));

const LayoutRightContent = styled(Grid)(({ theme }) => ({
    maxWidth: 400,
    textAlign: 'right',
    marginLeft: 'auto',
    position: 'relative',
    paddingRight: 24,
    '&:after': {
        content: '""',
        position: 'absolute',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.dark : '#FFFFFF',
        border: `6px solid${theme.palette.secondary.main}`,
        width: 25,
        height: 25,
        borderRadius: '50%',
        top: 13,
        right: -12
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#9E9E9E',
        width: 1,
        height: 300,
        top: 13,
        right: -1
    },
    [theme.breakpoints.down('md')]: {
        '&:before': {
            height: '400%'
        }
    },
    [theme.breakpoints.down('lg')]: {
        '&:after': {
            right: -4
        },
        '&:before': {
            right: 7
        }
    },
    [theme.breakpoints.down('md')]: {
        '&:after': {
            right: 'auto',
            left: -12
        },
        '&:before': {
            right: 'auto',
            left: 0,
            height: 160
        }
    }
}));

// =============================|| LANDING - LAYOUTS PAGE ||============================= //

const LayoutsPage = () => {
    const theme = useTheme();

    return (
        <Container maxWidth="xl">
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
                    <Slider />
                </Grid>
                <Box sx={{ display: { xs: 'none', md: 'block' }, m: '0 auto' }}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={gridSpacing}>
                            <Grid item sm={6}>
                                <LayoutImageWrapper>
                                    <img
                                        src={theme.palette.mode === 'dark' ? imgLayoutDarkGrid : imgLayoutGrid}
                                        alt="Berry"
                                        style={{ width: '100%' }}
                                    />
                                    <LayoutImage src={courtImage} alt="Berry" />
                                </LayoutImageWrapper>
                            </Grid>
                            <Grid item sm={6}>
                                <LayoutContent container spacing={2}>
                                    <Grid item sm={12}>
                                        <Typography variant="h4" component="div">
                                            Find Courts
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Typography variant="body2">
                                            We have one of the largest repository of courts in the world. Find the court you are looking
                                            for...
                                        </Typography>
                                    </Grid>
                                </LayoutContent>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={gridSpacing}>
                            <Grid item sm={6}>
                                <LayoutRightContent container spacing={2}>
                                    <Grid item sm={12}>
                                        <Typography variant="h4" component="div">
                                            Join Groups
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Typography variant="body2">Join existings groups and create your own</Typography>
                                    </Grid>
                                </LayoutRightContent>
                            </Grid>
                            <Grid item sm={6}>
                                <LayoutImageWrapper>
                                    <img
                                        src={theme.palette.mode === 'dark' ? imgLayoutDarkGrid : imgLayoutGrid}
                                        alt="Berry"
                                        style={{ width: '100%' }}
                                    />
                                    <LayoutImage src={homeImage} alt="Berry" style={{ animationDelay: '1.5s' }} />
                                </LayoutImageWrapper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={gridSpacing}>
                            <Grid item sm={6}>
                                <LayoutImageWrapper>
                                    <img
                                        src={theme.palette.mode === 'dark' ? imgLayoutDarkGrid : imgLayoutGrid}
                                        alt="Berry"
                                        style={{ width: '100%' }}
                                    />
                                    <LayoutImage src={groupImage} alt="Berry" style={{ animationDelay: '3s' }} />
                                </LayoutImageWrapper>
                            </Grid>
                            <Grid item sm={6}>
                                <LayoutContent
                                    container
                                    spacing={2}
                                    sx={{
                                        '&:before': {
                                            background: theme.palette.mode === 'dark' ? theme.palette.dark[900] : '#fff !important'
                                        }
                                    }}
                                >
                                    <Grid item sm={12}>
                                        <Typography variant="h4" component="div">
                                            See who&apos;s at the court
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Typography variant="body2">
                                            With our first ever geofence court check-in feature, you can see who&apos;s at the courts and
                                            track your playing time.
                                        </Typography>
                                    </Grid>
                                </LayoutContent>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Container>
    );
};

export default LayoutsPage;
