// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'axios';
import { dispatch } from '../index';
import { v4 as uuidv4 } from 'uuid';

// ----------------------------------------------------------------------
const FIXED_FEE = 1.79;
const PERCENTAGE_FEE = 0.037; // 3.7%
const roundUp = (num) => Math.ceil(num * 100) / 100;

const initialState = {
    error: null,
    checkout: {
        step: 0,
        products: [],
        subtotal: 0,
        total: 0,
        fee: 1.79,
        totalFee: 0,
        discount: 0,
        appliedDiscountsDetails: [],
        credit: 0,
        appliedCreditsDetails: [],
        shipping: 0,
        billing: null,
        payment: {
            type: 'free',
            method: 'cod',
            card: ''
        },
        url: null
    },
    intentToken: null
};

const slice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        addProductSuccess(state, action) {
            const products = action.payload.products;
            let subtotal = 0;

            // Iterate over each product to check cart_items
            subtotal += action.payload.subtotal;

            products.forEach((product) => {
                if (product.cart_items && product.cart_items.length > 0) {
                    // Check if any cart_item has isFull set to false
                    const hasAvailableSlot = product.cart_items.some((cart_item) => {
                        console.log('isFull', cart_item.product.isFull, cart_item.product.awaitingRegistration);
                        return !cart_item.product.isFull || cart_item.product.awaitingRegistration;
                    });

                    // If there's at least one available slot, add product total to subtotal
                    if (!hasAvailableSlot) {
                        product.product.entry_fee = 0;
                        //     subtotal += action.payload.subtotal;
                        // } else {
                    }
                }
            });

            // Update the state with the new subtotal and other calculations
            state.checkout.products = products;
            state.checkout.subtotal = subtotal;
            if (subtotal > 0) {
                const feeTotal = FIXED_FEE * state.checkout.products.length;
                const percentageFee = (state.checkout.subtotal + feeTotal) * PERCENTAGE_FEE;
                state.checkout.totalFee = roundUp(feeTotal + percentageFee);
                state.checkout.total = roundUp(state.checkout.subtotal + feeTotal + percentageFee);
            } else {
                // If subtotal is 0, set totalFee and total to 0 as no fees should be added
                state.checkout.totalFee = 0;
                state.checkout.total = 0;
            }
        },
        addTeamMemberSuccess(state, action) {
            const { product_id, event_id, user, id, cart_id } = action.payload;
            const productIndex = state.checkout.products.findIndex((p) => p.id === product_id);

            if (productIndex !== -1) {
                const eventIndex = state.checkout.products[productIndex].cart_items.findIndex((e) => e.id === event_id);

                if (eventIndex !== -1) {
                    const teamMembers = state.checkout.products[productIndex].cart_items[eventIndex].team_members || [];
                    // const newTeamMember = { user, id: action.payload.id }; // Extract ID from action.payload
                    if (user?.id) {
                        console.log('ID', user?.id);
                        teamMembers.push({ user });
                    } else {
                        console.log('NO ID');
                        teamMembers.push({
                            name: `${user.first_name} ${user.last_name}`,
                            first_name: user?.first_name,
                            last_name: user?.last_name,
                            email: user.email,
                            user: null
                        });
                    }
                    state.checkout.products[productIndex].cart_items[eventIndex].team_members = teamMembers;
                }
            }
        },
        addLeagueTeamMemberSuccess(state, action) {
            const { product_id, event_id, user, id, cart_id } = action.payload;
            const productIndex = state.checkout.products.findIndex((p) => p.id === cart_id);
            const teamMembers = state.checkout.products[productIndex].team_members || [];

            if (user?.id) {
                console.log('ID', user?.id);
                teamMembers.push({ user });
            } else {
                console.log('NO ID');
                teamMembers.push({
                    name: `${user.first_name} ${user.last_name}`,
                    first_name: user?.first_name,
                    last_name: user?.last_name,
                    email: user.email,
                    user: null
                });
            }
            state.checkout.products[productIndex].team_members = teamMembers;
        },

        removeTeamMemberSuccess(state, action) {
            const { product_id, cart_item_id, user } = action.payload;
            const productIndex = state.checkout.products.findIndex((p) => p.id === product_id);
            console.log('event_id', cart_item_id);
            if (productIndex !== -1) {
                const eventIndex = state.checkout.products[productIndex].cart_items.findIndex((e) => e.id === cart_item_id);

                if (eventIndex !== -1) {
                    const teamMembers = state.checkout.products[productIndex].cart_items[eventIndex].team_members || [];

                    const updatedTeamMembers = teamMembers.filter((member) => member.id !== user.id);

                    state.checkout.products[productIndex].cart_items[eventIndex].team_members = updatedTeamMembers;
                }
            }
        },
        removeLeagueTeamMemberSuccess(state, action) {
            const { cart_id, user, id } = action.payload;
            const productIndex = state.checkout.products.findIndex((p) => p.id === cart_id);

            const product = state.checkout.products[productIndex];

            if (productIndex !== -1 && product.team_members) {
                const teamMembers = product.team_members;

                const updatedTeamMembers = teamMembers.filter((member) => member.id !== id);

                state.checkout.products[productIndex].team_members = updatedTeamMembers;
            }
        },

        // REMOVE PRODUCT
        removeProductSuccess(state, action) {
            state.checkout.products = action.payload.products;
            state.checkout.subtotal = action.payload.subtotal;
            const feeTotal = FIXED_FEE * state.checkout.products.length;
            const percentageFee = (state.checkout.subtotal + feeTotal) * PERCENTAGE_FEE;
            state.checkout.totalFee = roundUp(feeTotal + percentageFee);
            state.checkout.total = roundUp(state.checkout.subtotal + feeTotal + percentageFee);
        },

        // UPDATE PRODUCT
        updateProductSuccess(state, action) {
            state.checkout.products = action.payload.products;
            state.checkout.subtotal = state.checkout.subtotal - action.payload.oldSubTotal + action.payload.subtotal;
            state.checkout.total = state.checkout.total - action.payload.oldSubTotal + action.payload.subtotal;
        },

        // SET STEP
        setStepSuccess(state, action) {
            state.checkout.step = action.payload;
        },

        // SET NEXT STEP
        setNextStepSuccess(state) {
            state.checkout.step += 1;
        },
        setCompleteCheckoutSuccess(state) {
            state.checkout.step = 3;
        },

        // SET BACK STEP
        setBackStepSuccess(state) {
            state.checkout.step -= 1;
        },

        // SET BILLING ADDRESS
        setBillingAddressSuccess(state, action) {
            state.checkout.billing = action.payload.billing;
        },
        setCheckoutSuccess(state, action) {
            // state.checkout.url = action.payload.url;
            state.intentToken = action.payload;
        },
        setCheckoutError(state, action) {
            // state.checkout.url = action.payload.url;
            state.intentToken = null;
        },

        // SET DISCOUNT
        setDiscountSuccess(state, action) {
            let difference = 0;
            if (state.checkout.discount > 0) {
                difference = state.checkout.discount;
            }

            state.checkout.discount = action.payload.totalDiscountsApplied;
            state.checkout.total = state.checkout.total + difference - action.payload.totalDiscountsApplied;
            state.checkout.appliedDiscountsDetails = [...state.checkout.appliedDiscountsDetails, ...action.payload.appliedDiscountsDetails];
        },
        setCreditSuccess(state, action) {
            let difference = 0;
            if (state.checkout.credit > 0) {
                difference = state.checkout.credit;
            }

            state.checkout.credit = action.payload.totalCreditsApplied;
            state.checkout.total = state.checkout.total + difference - action.payload.totalCreditsApplied;
            state.checkout.appliedCreditsDetails = [...state.checkout.appliedCreditsDetails, ...action.payload.appliedCreditsDetails];
        },

        // SET SHIPPING CHARGE
        setShippingChargeSuccess(state, action) {
            state.checkout.shipping = action.payload.shipping;
            state.checkout.total += action.payload.newShipping;
            state.checkout.payment = {
                ...state.checkout.payment,
                type: action.payload.type
            };
        },
        setFormResponses(state, action) {
            const updatedProducts = state.checkout.products.map((item) => {
                if (item.product.form && item.product.form.fields) {
                    // Initialize a new property to hold form responses
                    if (!item.product.formResponses) {
                        item.product.formResponses = {};
                    }
                    item.product.form.fields.forEach((field) => {
                        item.product.formResponses[field.id] = action.payload[item.product.id][field.id];
                    });
                }
                return item;
            });
            state.checkout.products = updatedProducts;
        },

        // SET PAYMENT METHOD
        setPaymentMethodSuccess(state, action) {
            state.checkout.payment = {
                ...state.checkout.payment,
                method: action.payload.method
            };
        },

        // SET PAYMENT CARD
        setPaymentCardSuccess(state, action) {
            state.checkout.payment = {
                ...state.checkout.payment,
                card: action.payload.card
            };
        },

        // RESET CART
        resetCardSuccess(state) {
            state.checkout = initialState.checkout;
            state.intentToken = null;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addProduct(product_id, product_type, events, recently_authenticated = false) {
    return async () => {
        try {
            const response = await axios.post('/api/cart/add', { product_id, product_type, events, recently_authenticated });
            dispatch(slice.actions.addProductSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addTeamMember(product_id, event_id, user, cart_id = null) {
    return async (dispatch) => {
        try {
            const response = await axios.post('/api/event/partner', { user, cart_item_id: event_id, cart_id });
            const addedUserId = response.data.data.id; // Adjust according to your API response structure
            if (event_id) {
                dispatch(slice.actions.addTeamMemberSuccess({ product_id, event_id, user, id: addedUserId, cart_id }));
            } else if (cart_id) {
                dispatch(slice.actions.addLeagueTeamMemberSuccess({ product_id, event_id, user, id: addedUserId, cart_id }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function addTeamMemberLocally(product_id, event_id, user) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.addTeamMemberSuccess({ product_id, event_id, user, id: null }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeTeamMember(product_id, cart_item_id, user, cart_id = null, id = null) {
    console.log('cart_id', cart_item_id);
    return async (dispatch, getState) => {
        try {
            // You can perform any validation or checks here if necessary

            // Dispatch the success action with the necessary payload
            if (cart_item_id) {
                dispatch(slice.actions.removeTeamMemberSuccess({ product_id, cart_item_id, user }));
            } else if (cart_id) {
                dispatch(slice.actions.removeLeagueTeamMemberSuccess({ cart_id, cart_item_id, user, id }));
            }
            await axios.post(`/api/event/partner/delete`, { user, cart_item_id, cart_id });
        } catch (error) {
            // Handle any errors
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function removeTeamMemberLocally(product_id, event_id, user, id) {
    return async (dispatch, getState) => {
        try {
            // You can perform any validation or checks here if necessary

            dispatch(slice.actions.removeTeamMemberSuccess({ product_id, event_id, user }));
        } catch (error) {
            // Handle any errors
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function addProductLocally(product, eventType, events) {
    return async (dispatch, getState) => {
        try {
            const uniqueId = uuidv4();

            // Convert event entry fees to numbers and sum them up
            const eventSubtotal = events ? events.reduce((sum, event) => sum + parseFloat(event.entry_fee || 0), 0) : 0;

            // Convert product entry fee to a number
            const productSubtotal = parseFloat(product.entry_fee || 0);

            // Calculate total subtotal
            const totalSubtotal = eventSubtotal + productSubtotal;

            const newProductStructure = {
                id: uniqueId,
                product: { ...product, price: productSubtotal },
                product_type: eventType,
                main_item_id: uniqueId,
                cart_items: events?.map((event) => ({
                    id: event.id,
                    main_item_id: uniqueId,
                    product_id: uniqueId,
                    product: event,
                    price: parseFloat(event.entry_fee || 0)
                })),
                subtotal: totalSubtotal // Update subtotal to include both product and event fees
            };

            const { cart } = getState();
            let existingProducts = cart.checkout.products;

            // Check if the product already exists in the cart and remove it
            existingProducts = existingProducts.filter((item) => item.product.id !== product.id);

            // Add the new product
            const updatedProducts = [...existingProducts, newProductStructure];

            const newProducts = {
                products: updatedProducts,
                subtotal: updatedProducts.reduce((sum, item) => sum + item.subtotal, 0) // Recalculate the overall subtotal
            };

            dispatch(slice.actions.addProductSuccess(newProducts));
        } catch (error) {
            console.log('ERROR', error);

            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeProductLocally(productId, eventType) {
    return async (dispatch, getState) => {
        try {
            const { cart } = getState();
            const existingProducts = cart.checkout.products;

            // Filter out the product with the given productId and eventType
            const updatedProducts = existingProducts.filter(
                (productStructure) => !(productStructure.id === productId && productStructure.product_type === eventType)
            );

            // Recalculate the subtotal by summing the subtotals of remaining products
            const newSubtotal = updatedProducts.reduce((sum, productStructure) => sum + productStructure.subtotal, 0);

            const newProducts = {
                products: updatedProducts,
                subtotal: newSubtotal // Update the overall subtotal
            };

            dispatch(slice.actions.addProductSuccess(newProducts));
        } catch (error) {
            console.log('ERROR', error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeProductItem(id) {
    return async () => {
        try {
            const response = await axios.post(`/api/cart/remove/item/${id}`);
            dispatch(slice.actions.removeProductSuccess(response.data));
        } catch (error) {
            console.log('error');
            dispatch(slice.actions.checkoutSuccess());

            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getCart() {
    return async () => {
        try {
            console.log('getting car');
            const response = await axios.get(`/api/cart`);
            dispatch(slice.actions.resetCardSuccess());
            dispatch(slice.actions.addProductSuccess(response.data));
            dispatch(
                slice.actions.setCreditSuccess({
                    totalCreditsApplied: response.data.totalCreditsApplied,
                    appliedCreditsDetails: response.data.appliedCreditsDetails
                })
            );
            dispatch(
                slice.actions.setDiscountSuccess({
                    totalDiscountsApplied: response.data.totalDiscountsApplied,
                    appliedDiscountsDetails: response.data.appliedDiscountsDetails
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function setDiscount(code, total) {
    return async () => {
        try {
            const response = await axios.post('/api/cart/discount', { code, total });
            dispatch(slice.actions.setDiscountSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function removeProduct(id) {
    return async () => {
        try {
            const response = await axios.post(`/api/cart/remove/${id}`);
            dispatch(slice.actions.addProductSuccess(response.data));
            dispatch(getCart());
        } catch (error) {
            console.log('error', error);
            dispatch(slice.actions.resetCardSuccess());

            dispatch(slice.actions.hasError(error));
        }
    };
}
export function checkout(subtotal) {
    return async (dispatch, getState) => {
        try {
            const response = await axios.post(`/api/payment_intents`, { subtotal });
            dispatch(slice.actions.setCheckoutSuccess(response.data.data));
            // window.location.href = response.data.data.url;

            // dispatch(slice.actions.addProductSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.setCheckoutError(error));
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
}
export function updatePaymentIntent(id, subtotal) {
    return async () => {
        try {
            const response = await axios.post(`/api/payment_intents/${id}`, { subtotal });
            dispatch(slice.actions.setCheckoutSuccess(response.data.data));
            // window.location.href = response.data.data.url;

            // dispatch(slice.actions.addProductSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.setCheckoutError(error));
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
}

export function updateProduct(id, quantity, products) {
    return async () => {
        try {
            const response = await axios.post('/api/cart/update', { id, quantity, products });
            dispatch(slice.actions.updateProductSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setStep(step) {
    return () => {
        dispatch(slice.actions.setStepSuccess(step));
    };
}

export function setNextStep() {
    return () => {
        dispatch(slice.actions.setNextStepSuccess({}));
    };
}
export function completeCheckoutSteps() {
    return () => {
        dispatch(slice.actions.setCompleteCheckoutSuccess({}));
    };
}

export function setBackStep() {
    return () => {
        dispatch(slice.actions.setBackStepSuccess({}));
    };
}

export function setBillingAddress(address) {
    return async () => {
        try {
            // const response = await axios.post('/api/cart/billing-address', { address });
            dispatch(slice.actions.setBillingAddressSuccess(address.address));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setShippingCharge(charge, shipping) {
    return async () => {
        try {
            const response = await axios.post('/api/cart/shipping-charge', { charge, shipping });
            dispatch(slice.actions.setShippingChargeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setPaymentMethod(method) {
    return async () => {
        try {
            const response = await axios.post('/api/cart/payment-method', { method });
            dispatch(slice.actions.setPaymentMethodSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setPaymentCard(card) {
    return async () => {
        try {
            const response = await axios.post('/api/cart/payment-card', { card });
            dispatch(slice.actions.setPaymentCardSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetCart() {
    return async () => {
        try {
            console.log('resseting');
            const response = await axios.post('/api/cart/reset');
            dispatch(slice.actions.resetCardSuccess([]));
        } catch (error) {
            console.log('reset error');
            dispatch(slice.actions.resetCardSuccess([]));

            dispatch(slice.actions.hasError(error));
        }
    };
}
export function resetCartLocally() {
    return async () => {
        try {
            console.log('resseting');
            dispatch(slice.actions.resetCardSuccess([]));
        } catch (error) {
            dispatch(slice.actions.resetCardSuccess([]));

            dispatch(slice.actions.hasError(error));
        }
    };
}
export function completeCheckout(paymentResult, checkout) {
    return async () => {
        try {
            await axios.post('/api/cart/checkout', { paymentResult, checkout });
            // dispatch(slice.actions.resetCardSuccess([]));
        } catch (error) {
            // dispatch(slice.actions.resetCardSuccess([]));
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
}
export function applyDiscount(code) {
    return async () => {
        try {
            const response = await axios.post('/api/discount/apply', { code }).then(() => dispatch(getCart()));
            dispatch(slice.actions.hasError(null));
            return response;

            // dispatch(slice.actions.resetCardSuccess([]));
        } catch (error) {
            // dispatch(slice.actions.resetCardSuccess([]));
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
}
export function removeDiscount(discount_id, product_id) {
    return async () => {
        try {
            const response = await axios.post('/api/discount/remove', { discount_id, product_id }).then(() => dispatch(getCart()));
            dispatch(slice.actions.hasError(null));
            return response;

            // dispatch(slice.actions.resetCardSuccess([]));
        } catch (error) {
            // dispatch(slice.actions.resetCardSuccess([]));
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
}
export function applyFormResponses(formInputs) {
    return async () => {
        try {
            dispatch(slice.actions.setFormResponses(formInputs));

            // dispatch(slice.actions.resetCardSuccess([]));
        } catch (error) {
            // dispatch(slice.actions.resetCardSuccess([]));
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
}
