// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconHome, IconDeviceAnalytics } from '@tabler/icons';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';

const icons = {
    IconHome,
    IconDeviceAnalytics,
    AdminPanelSettingsOutlinedIcon,
    DynamicFeedOutlinedIcon,
    ChatOutlinedIcon,
    HomeOutlinedIcon
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    icon: icons.HomeOutlinedIcon,
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/home',
            icon: icons.IconHome,
            breadcrumbs: false
        }
        // {
        //     id: 'feed',
        //     title: <FormattedMessage id="feed" />,
        //     type: 'item',
        //     url: '/feed',
        //     icon: icons.DynamicFeedOutlinedIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'chat',
        //     title: <FormattedMessage id="chat" />,
        //     type: 'item',
        //     url: '/chat',
        //     icon: icons.ChatOutlinedIcon,
        //     breadcrumbs: false
        // }
        // {
        //     id: 'admin',
        //     title: <FormattedMessage id="admin" />,
        //     type: 'item',
        //     url: '/admin',
        //     icon: icons.AdminPanelSettingsOutlinedIcon,
        //     breadcrumbs: false
        // }
    ]
};

export default dashboard;
