import React from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports
import FollowerCard from 'ui-component/cards/FollowerCard';
import { gridSpacing } from 'store/constant';
import { useDispatch, useSelector } from 'store';
import { getFollowers, filterFollowers } from 'store/slices/user';
import chartData from 'views/widget/Chart/chart-data';
import SeoChartCard from 'ui-component/cards/SeoChartCard';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

// assets
import { useTheme } from '@mui/material/styles';
import DivisionSignups from './DivisionSignups';

// ==============================|| SOCIAL PROFILE - FOLLOWERS ||============================== //

const Overview = ({ eventList }) => {
    const dispatch = useDispatch();
    const [followers, setFollowers] = React.useState([]);
    const userState = useSelector((state) => state.user);
    const theme = useTheme();

    React.useEffect(() => {
        setFollowers(userState.followers);
    }, [userState]);

    React.useEffect(() => {
        dispatch(getFollowers());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [search, setSearch] = React.useState('');
    const handleSearch = async (event) => {
        const newString = event?.target.value;
        setSearch(newString);

        if (newString) {
            dispatch(filterFollowers(newString));
        } else {
            dispatch(getFollowers());
        }
    };

    let followersResult = <></>;
    if (followers) {
        followersResult = followers.map((follower, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <FollowerCard {...follower} />
            </Grid>
        ));
    }

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sm={6} lg={3}>
                <SeoChartCard
                    chartData={chartData.SeoChartCardData1}
                    value="1000"
                    title="Tournament Visits"
                    icon={<ArrowDropDownIcon color="error" />}
                />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
                <SeoChartCard
                    chartData={chartData.SeoChartCardData2}
                    value="29.54%"
                    title="Bounce Rate"
                    icon={<ArrowDropUpIcon color="primary" />}
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={3}>
                <SeoChartCard
                    chartData={chartData.SeoChartCardData3}
                    value="726"
                    title="Registerd Players"
                    icon={<ArrowDropDownIcon color="error" />}
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={3}>
                <SeoChartCard
                    chartData={chartData.SeoChartCardData2}
                    value="$5,726"
                    title="Revenue"
                    icon={<ArrowDropDownIcon color="error" />}
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={3}>
                <DivisionSignups eventList={eventList} />
            </Grid>
        </Grid>
    );
};

export default Overview;
