import { TabPanel } from '@mui/lab';
import { Grid, CardContent, Tab, Tabs, Typography } from '@mui/material';
import useForms from 'hooks/useForms';
import { useState } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import PlayerCell from 'views/components/playerCell.component';

const ResponseSummary = ({ formResults }) => (
    <Grid container spacing={gridSpacing}>
        {formResults?.map((result, reIndex) => (
            <Grid item xs={12}>
                <MainCard title={result.question} content={false} elevation={8}>
                    <CardContent>
                        {result?.responses?.length > 0 &&
                            result?.responses?.map((response) => (
                                <>
                                    {reIndex === 0 ? (
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <PlayerCell user={response} />
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} p={1} bgcolor="grey.100" borderRadius={1} mt={2}>
                                                <Typography> {response.response} </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            ))}
                    </CardContent>
                </MainCard>
            </Grid>
        ))}
    </Grid>
);

export default ResponseSummary;
