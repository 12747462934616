import { Divider, Grid, Link, Typography } from '@mui/material';
import useHelpCenter from 'hooks/useHelpCenter';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';

const HelpTopics = () => {
    const test = true;
    const { slug, id } = useParams();
    const { getArticles, articles, topic, getTopics, topics } = useHelpCenter();
    const navigate = useNavigate();

    useEffect(() => {
        getArticles(id);
    }, [slug]);

    useEffect(() => {
        getTopics(topic?.category);
    }, [topic?.category]);
    const getCategoryVerbiage = (category) => {
        switch (category) {
            case 'hosting':
                return 'Hosting an event topics';
            case 'attending':
                return 'Attending an event topics';
            default:
                return null;
        }
    };

    return (
        <Grid>
            <Grid py={2}>
                <Typography mb={2}>
                    <Link onClick={() => navigate(`/help`)} underline="hover" sx={{ ':hover': { cursor: 'pointer' } }}>
                        {'<'} {'    '}Help Center
                    </Link>
                </Typography>
                <Typography variant="h1">{topic?.name}</Typography>
            </Grid>
            <Divider />
            <Grid container spacing={2} py={2}>
                <Grid item xs={12} md={5}>
                    <Typography variant="h3" mb={2}>
                        {getCategoryVerbiage(topic?.category)}
                    </Typography>
                    {topics.map((item, index) => (
                        <Typography variant="body1" key={item.id} py={1}>
                            <Link
                                onClick={() => navigate(`/help/topics/${item?.slug}/${item?.id}`)}
                                underline="hover"
                                sx={{ ':hover': { cursor: 'pointer' }, fontWeight: topic?.id === item?.id ? 600 : 300 }}
                            >
                                {item?.name}
                            </Link>
                        </Typography>
                    ))}
                </Grid>
                <Grid item xs={12} md={7}>
                    {Object.keys(articles).map((category, index) => (
                        <Grid item xs={12} key={index}>
                            <Typography variant="h2" mb={2}>
                                {category}
                            </Typography>
                            {articles[category].map((article) => (
                                <Typography variant="body1" key={article.id} py={1}>
                                    <Link
                                        onClick={() => navigate(`/help/article/${article.slug}`)}
                                        underline="hover"
                                        sx={{ ':hover': { cursor: 'pointer' } }}
                                    >
                                        {article.title}
                                    </Link>
                                </Typography>
                            ))}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HelpTopics;
