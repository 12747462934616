import {
    AttachMoney,
    CalendarMonthRounded,
    ConfirmationNumber,
    EmojiEvents,
    House,
    LocationOn,
    LocationOnRounded
} from '@mui/icons-material';
import { Container, Typography, Grid, Tooltip, Button, Stack } from '@mui/material';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import useUsers from 'hooks/useUsers';
import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useParams } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import FieldSection from 'ui-component/FieldSection';
import FormComponents from 'ui-component/FormComponents';
import CondensedTeamCard from 'ui-component/cards/CondensedTeamCard';
import getReadableDateTime from 'utils/get_readable_datetime';
import TeamDrawer from 'views/admin/AdminHub/EventInfo/components/TeamDrawer';
import PlayerCell from 'views/components/playerCell.component';
import TeamsTable from 'views/components/teamsTable.component';

const apiKey = 'AIzaSyDmaBGYH1EjkaybTv8IzfM1TxRB3bhXRUA';
const libraries = ['places'];

const PlayerReservation = () => {
    const { id } = useParams();
    const { getPlayerReservation, playerReservation } = useUsers();
    const [teamLength, setTeamLength] = useState();
    const [teamDrawerOpen, setTeamDrawerOpen] = useState(false);
    const [teamId, setTeamId] = useState();
    const [team, setTeam] = useState();
    const [selectedEventId, setSelectedEventId] = useState();

    useEffect(() => {
        getPlayerReservation(id);
    }, [id]);
    const mapContainerStyle = {
        width: '100%',
        height: '400px'
    };

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries
    });
    if (loadError) return 'Error loading maps';
    if (!isLoaded) return 'Loading Maps';

    return (
        <Container>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h2">
                            {' '}
                            {playerReservation?.court?.club?.name} - {playerReservation?.court?.name}
                        </Typography>
                        {/* <Button variant="contained" color="error">
                            Withdraw
                        </Button> */}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} sm={6}>
                            <FieldSection
                                icon={<ConfirmationNumber />}
                                label="Order #"
                                value={<Typography> {playerReservation?.id}</Typography>}
                            />
                            <FieldSection
                                icon={<CalendarMonthRounded />}
                                label="Res. Date"
                                value={<Typography> {getReadableDateTime(playerReservation?.start_time, 'dateShort')}</Typography>}
                            />
                            <FieldSection
                                icon={<CalendarMonthRounded />}
                                label="Time"
                                value={
                                    <Typography>
                                        {getReadableDateTime(playerReservation?.start_time, 'time')} -{' '}
                                        {getReadableDateTime(playerReservation?.end_time, 'time')}
                                    </Typography>
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FieldSection
                                icon={<CalendarMonthRounded />}
                                label="Date Ordered"
                                value={<Typography> {getReadableDateTime(playerReservation?.created_at, 'dateShort')}</Typography>}
                            />
                            <FieldSection
                                icon={<AttachMoney />}
                                label="Amount Paid"
                                value={
                                    <CurrencyFormat
                                        decimalScale={2}
                                        fixedDecimalScale
                                        value={playerReservation?.total_cost / 100}
                                        displayType="text"
                                        thousandSeparator
                                        prefix="$"
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h3">
                                Club Details
                            </Typography>
                            <FieldSection
                                icon={<House />}
                                label="Club"
                                value={<Typography>{playerReservation?.court?.club?.name}</Typography>}
                            />
                            {playerReservation?.court?.club?.address && (
                                <FieldSection
                                    icon={<House />}
                                    label="Address"
                                    value={<Typography>{playerReservation?.court?.club?.address}</Typography>}
                                />
                            )}
                        </Grid>
                        {playerReservation?.court?.club?.latitude && playerReservation?.court?.club?.longitude && (
                            <Grid item xs={12}>
                                <GoogleMap
                                    mapContainerStyle={mapContainerStyle}
                                    zoom={15}
                                    center={{
                                        lat: playerReservation?.court?.club?.latitude,
                                        lng: playerReservation?.court?.club?.longitude
                                    }}
                                    clickable={false}
                                    draggable={false}
                                    editable={false}
                                >
                                    <Marker
                                        position={{
                                            lat: playerReservation?.court?.club?.latitude,
                                            lng: playerReservation?.court?.club?.longitude
                                        }}
                                    />
                                </GoogleMap>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default PlayerReservation;
