import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// material-ui
import { Button, Toolbar, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/styles';

// project imports

// assets

const SupportBar = () => {
    const salesEmail = 'sales@stackedpb.com';
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <Toolbar
            sx={{
                backgroundColor: theme.palette.primary.main,
                marginLeft: -40,
                marginRight: -40,
                paddingLeft: 40,
                paddingRight: 42,
                display: { xs: 'none', md: 'block' },
                paddingY: 2
            }}
            disableGutters
            variant="dense"
            data-test-id="layout/MainLayout/SupportBar"
        >
            <Grid container sx={{ justifyContent: 'flex-end' }} spacing={2}>
                <Grid item>
                    <Button
                        color="inherit"
                        // component={RouterLink}
                        // to={`mailto:${supportEmail}`}
                        onClick={() => navigate('/help')}
                        variant="text"
                        size="small"
                        sx={{ borderRadius: 6, paddingX: 0, paddingY: 0 }}
                    >
                        <Typography variant="h5" color="white">
                            Help Center
                        </Typography>
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        color="inherit"
                        onClick={() => window.open(`mailto:${salesEmail}`)}
                        variant="text"
                        size="small"
                        sx={{ borderRadius: 6, paddingX: 0, paddingY: 0 }}
                    >
                        <Typography variant="h5" color="white">
                            Contact Sales
                        </Typography>
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        color="inherit"
                        component={RouterLink}
                        variant="text"
                        size="small"
                        to="demo/request"
                        sx={{ borderRadius: 6, paddingX: 0, paddingY: 0 }}
                    >
                        <Typography variant="h5" color="white">
                            Request a Demo
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Toolbar>
    );
};

export default SupportBar;
