/* eslint-disable no-plusplus */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
import React, { useState, createContext, useReducer } from 'react';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import { API_URL as apiUrl } from 'config';
import moment from 'moment';
import useEvents from 'hooks/useEvents';
import useGroups from 'hooks/useGroups';
import { set } from 'lodash';

export const ClubsContext = createContext();
function getNextHour(hours) {
    const now = new Date();
    now.setHours(now.getHours() + hours);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    return now;
}
const initialState = {
    courtsAvailable: undefined,
    isLoading: false,
    clubSettings: undefined,
    userClubs: [],
    club: undefined,
    clubEvents: [],
    upcomingCount: undefined,
    pastCount: undefined,
    clubCourts: [],
    clubMemberships: [],
    tab: 0,
    membershipTab: 0,
    selectedCourt: undefined,
    selectedMembership: undefined,
    courtDetails: {},
    membershipDetails: {},
    domainClub: {},
    intentToken: undefined,
    loading: { bookingInsights: false, clientSecret: true, memberships: false },
    clientSecret: undefined,
    bookingInsights: {},
    clubMembers: [],
    member: {},
    clubCourtCosts: [],
    stackedMemberships: [],
    courtCostRule: {}
};
const updateNestedStateKey = (keyPath, value) => ({
    type: 'UPDATE_NESTED_STATE_KEY',
    payload: { keyPath, value }
});
function updateNestedStateKeyReducer(state, keyPath, value) {
    const keys = keyPath.split('.');
    const nestedState = { ...state };

    let currentLevel = nestedState;
    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        currentLevel[key] = { ...currentLevel[key] };
        currentLevel = currentLevel[key];
    }

    currentLevel[keys[keys.length - 1]] = value;

    return nestedState;
}

function clubsReducer(state, action) {
    switch (action.type) {
        case 'SET_STATE':
            return { ...state, [action.payload.key]: action.payload.value };
        case 'RESET_STATE':
            return initialState; // Resetting to initial state
        case 'UPDATE_NESTED_STATE_KEY':
            return updateNestedStateKeyReducer(state, action.payload.keyPath, action.payload.value);
        default:
            return state;
    }
}

export function ClubsProvider({ children }) {
    const [state, dispatch] = useReducer(clubsReducer, initialState);
    const { groupInfo, setGroupInfo } = useGroups();
    const [errors, setErrors] = useState({});

    const setValue = (key, value) => {
        dispatch({ type: 'SET_STATE', payload: { key, value } });
    };
    const updateValue = (keyPath, value) => {
        dispatch(updateNestedStateKey(keyPath, value));
    };

    const resetState = () => {
        setErrors({});
        dispatch({ type: 'RESET_STATE' });
    };

    const getClubSettings = async (club_id) => {
        setValue('isLoading', true);

        await axios
            .get(`/api/club/${club_id}`)
            .then((res) => {
                setValue('isLoading', false);
                setValue('clubSettings', res.data.data);
            })
            .catch((err) => {
                console.log('ERR2', err);
                setValue('isLoading', false);
            });
    };
    const getClub = async (club_id) => {
        setValue('isLoading', true);

        await axios
            .get(`/api/c/${club_id}`)
            .then((res) => {
                setValue('isLoading', false);
                setValue('club', res.data.data);
            })
            .catch((err) => {
                console.log('ERR2', err);
                setValue('isLoading', false);
            });
    };
    const getDomainClub = async (subdomain) => {
        setValue('isLoading', true);
        await axios
            .get(`/api/club/subdomain/${subdomain}`)
            .then((res) => {
                setValue('isLoading', false);
                setValue('domainClub', res.data.data);
            })
            .catch((err) => {
                console.log('ERR2', err);
                setValue('isLoading', false);
            });
    };
    const getClubEvents = async (club_id, timeframe) => {
        setValue('isLoading', true);

        const params = { club_id, timeframe };
        const response = await axios
            .get(`/api/club/events`, { params })
            .then((res) => {
                setValue('isLoading', false);
                console.log(res.data);
                setValue('clubEvents', res.data.data);
                setValue('upcomingCount', res.data.upcomingCount);
                setValue('pastCount', res.data.pastCount);
                return res.data;
            })
            .catch((err) => {
                console.log('ERR2', err);
                setValue('isLoading', false);
            });
        return response;
    };
    const updateClubSettings = async (club_id, clubSettings, image) => {
        setValue('isLoading', true);
        const formData = new FormData();
        const latitude = clubSettings?.location?.geometry?.location?.lat();
        const longitude = clubSettings?.location?.geometry?.location?.lng();
        let updatedParams = { ...clubSettings };
        if (clubSettings.location?.formatted_address) {
            updatedParams = {
                ...clubSettings,
                address: clubSettings.location ? clubSettings.location?.formatted_address : null,
                city: clubSettings.location ? clubSettings.location?.address_components[1].long_name : null,
                country: clubSettings.location ? clubSettings.location?.address_components[4].long_name : null,
                postal_code: clubSettings.location ? clubSettings.location?.address_components[5].long_name : null,
                state: clubSettings.location ? clubSettings.location?.address_components[3].long_name : null,
                venue: clubSettings.location ? clubSettings.location?.name : null,
                latitude,
                longitude
            };
        }
        if (image) {
            // Assuming `image` is a File object or similar
            formData.append('image', image, image.name || `${Date.now()}.jpg`);
        }

        // Append each field of clubSettings to formData
        for (const key in updatedParams) {
            if (updatedParams.hasOwnProperty(key)) {
                const value = updatedParams[key];
                // Only append if the value is not null and not undefined
                if (value !== null && value !== undefined) {
                    formData.append(key, value);
                }
            }
        }

        await axios
            .post(`/api/club/${club_id}`, formData)
            .then((res) => {
                setValue('isLoading', false);
                console.log('res', res.data);
                setValue('clubSettings', res.data.data);
            })
            .catch((err) => {
                console.log('ERR2', err);
                setValue('isLoading', false);
                throw err;
            });
    };
    const createNewClub = async (clubId, clubSettings, image) => {
        setValue('isLoading', true);

        const formData = new FormData();
        const latitude = clubSettings?.location?.geometry?.location?.lat();
        const longitude = clubSettings?.location?.geometry?.location?.lng();
        let updatedParams = { ...clubSettings };
        if (clubSettings.location?.formatted_address) {
            updatedParams = {
                ...clubSettings,
                address: clubSettings.location ? clubSettings.location?.formatted_address : null,
                city: clubSettings.location ? clubSettings.location?.address_components[1].long_name : null,
                country: clubSettings.location ? clubSettings.location?.address_components[4].long_name : null,
                postal_code: clubSettings.location ? clubSettings.location?.address_components[5].long_name : null,
                state: clubSettings.location ? clubSettings.location?.address_components[3].long_name : null,
                venue: clubSettings.location ? clubSettings.location?.name : null,
                latitude,
                longitude
            };
        }

        if (image) {
            // Assuming `image` is a File object or similar
            formData.append('image', image, image.name || `${Date.now()}.jpg`);
        }

        // Append each field of clubSettings to formData
        for (const key in updatedParams) {
            if (updatedParams.hasOwnProperty(key)) {
                const value = updatedParams[key];
                // Only append if the value is not null and not undefined
                if (value !== null && value !== undefined) {
                    formData.append(key, value);
                }
            }
        }

        await axios
            .post(`/api/club`, formData)
            .then((res) => {
                setValue('isLoading', false);
                console.log('res', res.data);
                setValue('clubSettings', res.data.data);
            })
            .catch((err) => {
                console.log('ERR2', err);
                setValue('isLoading', false);
                throw err;
            });
    };
    const getUserClubs = async () => {
        const response = await axios
            .get(`/api/user/clubs`)
            .then((res) => {
                setValue('isLoading', false);
                setValue('userClubs', res.data.data);
                return res.data.data;
            })
            .catch((err) => {
                setValue('isLoading', false);
            });

        return response;
    };
    const getClubCourts = async (club_id) => {
        const response = await axios
            .get(`/api/club/courts/${club_id}`)
            .then((res) => {
                setValue('clubCourts', res.data.data);
                return res.data.data;
            })
            .catch((err) => {
                setValue('isLoading', false);
            });

        return response;
    };
    const getClubMemberships = async (club_id, type) => {
        updateValue('loading.memberships', true);
        const response = await axios
            .get(`/api/club/memberships/${club_id}`, { params: { type } })
            .then((res) => {
                setValue('clubMemberships', res.data.data);
                updateValue('loading.memberships', false);
                return res.data.data;
            })
            .catch((err) => {
                setValue('isLoading', false);
                updateValue('loading.memberships', false);
            });

        return response;
    };
    const getStackedMemberships = async () => {
        const response = await axios
            .get(`/api/stacked/memberships/`)
            .then((res) => {
                console.log('res', res.data.data);
                setValue('stackedMemberships', res.data.data);
                return res.data.data;
            })
            .catch((err) => {
                setValue('isLoading', false);
            });

        return response;
    };
    const updateClubCourtsHours = (updatedHours) => {
        const newState = state.clubCourts.map((court) => {
            const courtHours = updatedHours.filter((hour) => hour.court_id === court.id);
            if (courtHours.length > 0) {
                return {
                    ...court,
                    hours: courtHours
                };
            }
            return court;
        });
        return newState;
    };
    const updateCourtHours = async (club_id, data) => {
        const response = await axios
            .post(`/api/club/courts/hours`, { data, club_id })
            .then((res) => {
                setValue('clubCourts', updateClubCourtsHours(res.data.data));
                return res.data.data;
            })
            .catch((err) => {
                setValue('isLoading', false);
            });

        return response;
    };
    const deleteClub = async (club_id) => {
        setValue('isLoading', true);

        await axios
            .delete(`/api/club/${club_id}`)
            .then(() => {
                const newState = state.userClubs.filter((item) => item.id !== club_id);
                setValue('userClubs', newState);
            })
            .catch((err) => {
                console.log('ERR2', err);
                setValue('isLoading', false);
            });
    };
    const handleMainTabChange = (event, newValue) => {
        setValue('tab', newValue);
        // If we're leaving the Tournament Manager tab, reset the subtab
    };
    const handleMembershipTabChange = (event, newValue) => {
        setValue('membershipTab', newValue);
        // If we're leaving the Tournament Manager tab, reset the subtab
    };

    const updateClubCourt = async (data) => {
        const response = await axios
            .put(`/api/club/court/${data?.id}`, data)
            .then((res) => {
                const index = state.clubCourts.findIndex((item) => item.id === data.id);
                const updatedCourt = res.data.data;

                if (index !== -1) {
                    // Create a new array with the updated court
                    const updatedCourts = [...state.clubCourts.slice(0, index), updatedCourt, ...state.clubCourts.slice(index + 1)];

                    // Update the state with the new array
                    setValue('clubCourts', updatedCourts);
                }

                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
                throw err;
            });

        return response;
    };
    const getClubCourt = async (id) => {
        const response = await axios
            .get(`/api/club/court/${id}`)
            .then((res) => {
                setValue('courtDetails', res.data.data);
                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
            });

        return response;
    };
    const getClubMembership = async (id) => {
        const response = await axios
            .get(`/api/club/membership/${id}`)
            .then((res) => {
                setValue('membershipDetails', res.data.data);
                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
            });

        return response;
    };
    const updateClubMembership = async (data) => {
        const response = await axios
            .put(`/api/club/membership/${data?.id}`, data)
            .then((res) => {
                const index = state.clubMemberships.findIndex((item) => item.id === data.id);
                const updatedMembership = res.data.data;

                if (index !== -1) {
                    // Create a new array with the updated court
                    const updatedMemberships = [
                        ...state.clubMemberships.slice(0, index),
                        updatedMembership,
                        ...state.clubMemberships.slice(index + 1)
                    ];

                    // Update the state with the new array
                    setValue('clubMemberships', updatedMemberships);
                    setValue('membershipDetails', res.data.data);
                }

                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
                throw err;
            });

        return response;
    };
    const addClubCourts = async (club_id, values) => {
        const params = { club_id, ...values };
        const response = await axios
            .post(`/api/club/courts`, params)
            .then((res) => {
                setValue('clubCourts', [...state.clubCourts, ...res.data.data]);
                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
                throw err;
            });

        return response;
    };
    const addClubCourtsCost = async (club_id, values, id = null) => {
        const params = { club_id, id, ...values };
        const response = await axios
            .post(`/api/club/courts/cost`, params)
            .then((res) => {
                if (id) {
                    const index = state.clubCourtCosts.findIndex((item) => item.id === id);
                    const updatedCost = res.data.data;

                    if (index !== -1) {
                        // Create a new array with the updated court
                        const updatedCosts = [
                            ...state.clubCourtCosts.slice(0, index),
                            updatedCost,
                            ...state.clubCourtCosts.slice(index + 1)
                        ];

                        // Update the state with the new array
                        setValue('clubCourtCosts', updatedCosts);
                    }
                } else {
                    setValue('clubCourtCosts', [...state.clubCourtCosts, res.data.data]);
                }

                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
                throw err;
            });

        return response;
    };
    const getClubCourtsCosts = async (club_id) => {
        const params = { club_id };
        const response = await axios
            .get(`/api/club/courts-cost`, { params })
            .then((res) => setValue('clubCourtCosts', res.data.data))

            .catch((err) => {
                console.log('err', err);
                throw err;
            });

        return response;
    };
    const getClubCourtPrice = async (court_id, start_date_time) => {
        const params = { court_id, start_date_time };
        const response = await axios
            .get(`/api/club/court/price`, { params })
            .then((res) => res.data.price)

            .catch((err) => {
                console.log('err', err);
                throw err;
            });

        return response;
    };
    const deleteMembership = async (id) => {
        const response = await axios
            .delete(`/api/club/membership/${id}`)
            .then((res) => {
                const newState = state.clubMemberships.filter((item) => item.id !== id);
                setValue('clubMemberships', newState);
                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
                throw err;
            });

        return response;
    };
    const addClubMemberships = async (club_id, values) => {
        const params = { club_id, ...values };
        const response = await axios
            .post(`/api/club/memberships`, params)
            .then((res) => {
                console.log('res', res.data.data);
                setValue('clubMemberships', [...state.clubMemberships, ...res.data.data]);
                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
                throw err;
            });

        return response;
    };
    const newPaymentIntent = async (subtotal, email) => {
        await axios.post(`/api/payment_intents`, { subtotal, email }).then((res) => {
            setValue('intentToken', res.data.data);
        });
    };
    const updatePaymentIntent = async (id, subtotal) => {
        await axios.post(`/api/payment_intents/${id}`, { subtotal }).then((res) => {
            setValue('intentToken', res.data.data);
        });
    };
    const getBookingInsights = async (club_id) => {
        const params = { club_id };
        await axios.get(`/api/club/bookings/insights`, { params }).then((res) => {
            console.log('res', res.data.data);
            setValue('bookingInsights', res.data.data);
        });
    };
    const getCourtCostRule = async (id) => {
        await axios.get(`/api/club/courts-cost/${id}`).then((res) => {
            setValue('courtCostRule', res.data.data);
        });
    };
    const deleteCourtCostRule = async (id) => {
        await axios.delete(`/api/club/courts-cost/${id}`).then((res) => {
            const newState = state.clubCourtCosts.filter((item) => item.id !== id);
            setValue('clubCourtCosts', newState);
        });
    };
    const selectMembership = async (membership_id, price_id) => {
        const params = { membership_id, price_id };
        const response = await axios.post(`/api/membership/select`, params).then((res) => {
            console.log('res', res.data);
            return res;
        });
        return response;
    };
    const selectStackedMembership = async (membership_id, price_id, club_id) => {
        const params = { membership_id, price_id, club_id };
        const response = await axios.post(`/api/stacked/membership/select`, params).then((res) => {
            console.log('res', res.data);
            return res;
        });
        return response;
    };
    const completeMembership = async (membership_id, price_id, stripe_subscription_id, stripe_invoice_id, paymentResult) => {
        try {
            const params = { membership_id, price_id, stripe_subscription_id, stripe_invoice_id, paymentResult };
            const response = await axios.post(`/api/membership/complete`, params);

            setGroupInfo((prevState) => ({
                ...prevState,
                group: { ...prevState.group, club: { ...prevState.group.club, is_member: true } }
            }));

            return response;
        } catch (err) {
            console.error('Error completing membership:', err);
            // Optionally, you could return a specific value or throw the error
            throw err;
        }
    };
    const completeStackedMembership = async (
        membership_id,
        price_id,
        stripe_subscription_id,
        stripe_invoice_id,
        paymentResult,
        club_id
    ) => {
        try {
            const params = { membership_id, price_id, stripe_subscription_id, stripe_invoice_id, paymentResult, club_id };
            const response = await axios.post(`/api/stacked/membership/complete`, params).then((res) => {
                setValue('club', res.data.club);
                return res;
            });

            return response;
        } catch (err) {
            console.error('Error completing membership:', err);
            // Optionally, you could return a specific value or throw the error
            throw err;
        }
    };
    const getClubMembers = async (club_id, page, page_length, membership_id = null) => {
        const params = { club_id, page_length, page, membership_id };
        const response = await axios.get(`/api/club/members`, { params }).then((res) => {
            setValue('clubMembers', res.data.data.data);
            return res.data.data;
        });
        return response;
    };
    const getClubMember = async (id) => {
        const response = await axios.get(`/api/club/member/${id}`).then((res) => {
            setValue('member', res.data.data);
            return res.data.data;
        });
        return response;
    };
    const fetchClientSecret = async () => {
        updateValue('loading.clientSecret', true);
        const response = await axios.post(`/api/stripe/session`).then((res) => {
            setValue('clientSecret', res.data.client_secret);
            updateValue('loading.clientSecret', false);
            return res.data.client_secret;
        });
        return response;
    };

    return (
        <ClubsContext.Provider
            value={{
                ...state,
                setValue,
                getClubSettings,
                updateClubSettings,
                getUserClubs,
                createNewClub,
                getClub,
                getClubEvents,
                deleteClub,
                getClubCourts,
                handleMainTabChange,
                getClubCourt,
                updateClubCourt,
                addClubCourts,
                updateCourtHours,
                getDomainClub,
                newPaymentIntent,
                updatePaymentIntent,
                getBookingInsights,
                getClubMemberships,
                handleMembershipTabChange,
                getClubMembership,
                updateClubMembership,
                addClubMemberships,
                deleteMembership,
                selectMembership,
                completeMembership,
                getClubMembers,
                getClubMember,
                fetchClientSecret,
                addClubCourtsCost,
                getClubCourtsCosts,
                getClubCourtPrice,
                getStackedMemberships,
                selectStackedMembership,
                completeStackedMembership,
                getCourtCostRule,
                deleteCourtCostRule
            }}
        >
            {children}
        </ClubsContext.Provider>
    );
}
