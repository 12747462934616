import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    AppBar as MuiAppBar,
    Box,
    Button,
    Container,
    Drawer,
    IconButton,
    Link,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Toolbar,
    useScrollTrigger,
    Typography,
    Grid,
    Collapse
} from '@mui/material';

// project imports
import StackedLogo from 'assets/images/landing/stacked_logo_full_nobackplate.png';

// assets
import { IconBook, IconHome2 } from '@tabler/icons';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import PeopleIcon from '@mui/icons-material/People';

import MenuIcon from '@mui/icons-material/Menu';
import { gridSpacing } from 'store/constant';
import SearchSection from '../SearchSection';
import LogoSection from 'layout/MainLayout/LogoSection';
import { Person, PersonAdd, PersonAddAlt, StarBorder } from '@mui/icons-material';
import useAuth from 'hooks/useAuth';
import SupportBar from 'layout/MainLayout/SupportBar';

// elevation scroll
function ElevationScroll({ children, window }) {
    const theme = useTheme();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window
    });
    const darkBorder = theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.grey[200];

    return React.cloneElement(children, {
        elevation: trigger ? 2 : 0,
        style: {
            backgroundColor: theme.palette.background.default,
            borderBottom: trigger ? 'none' : '1px solid',
            borderColor: trigger ? '' : darkBorder,
            color: theme.palette.text.dark
        }
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.node,
    window: PropTypes.object
};

// ==============================|| MINIMAL LAYOUT APP BAR ||============================== //

const UnAuthAppBar = ({ ...others }) => {
    const [showFeatureSection, setShowFeatureSection] = useState(null);
    const [open, setOpen] = React.useState(false);
    const { setPrevRoute } = useAuth();
    const theme = useTheme();

    const navigate = useNavigate();
    const handleClick = () => {
        setOpen(!open);
    };
    const handleEventClick = (route) => {
        console.log('handling');
        setOpen(false);
        navigate(route);
    };

    const [drawerToggle, setDrawerToggle] = React.useState(false);
    /** Method called on multiple components with different event types */
    const drawerToggler = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerToggle(open);
    };
    const prevLocation = useLocation();

    const onAuthPress = (link) => {
        console.log('location', prevLocation.pathname);
        setPrevRoute(prevLocation.pathname);
        navigate(link, { replace: true });
    };

    const navigationItems = [
        {
            title: 'Find Events',
            link: '/comp/league',
            icon: <IconHome2 />,
            action: () => handleClick()
        },
        { title: 'Host Events', link: '/host/overview', icon: <RecentActorsIcon /> },
        { title: 'Log In', link: '/auth', icon: <ContactSupportIcon />, action: () => onAuthPress('auth') },
        { title: 'Sign Up', link: '/auth', icon: <IconBook />, action: () => onAuthPress('auth') }
    ];
    const handleToggleDropdown = (event) => {
        event.stopPropagation(); // This prevents the event from reaching the drawer's onClick handler
        setOpen(!open); // Toggle the existing state for dropdown visibility
    };
    const supportEmail = 'support@stackedpb.com';
    const salesEmail = 'sales@stackedpb.com';
    return (
        <ElevationScroll {...others} data-test-id="layout/MainLayout/Header/Appbar">
            <MuiAppBar>
                <Container maxWidth="2xl">
                    <SupportBar />
                    <Toolbar>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {/* Add flex display and direction here */}
                                    <Box component="span" mr={2} sx={{ display: { xs: 'none', md: 'block' } }}>
                                        <LogoSection />
                                    </Box>
                                    {/* <SearchSection /> */}
                                </Box>
                            </Grid>

                            {/* <Stack direction="row" sx={{ display: { xs: 'none', sm: 'block' } }} spacing={2}> */}
                            <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
                                <Grid container>
                                    {navigationItems.map((item, index) => (
                                        <Grid item>
                                            {!item.action ? (
                                                <Button
                                                    key={index.toString()}
                                                    color="inherit"
                                                    component={RouterLink}
                                                    to={item.link}
                                                    sx={{ paddingX: 2, paddingY: 1, borderRadius: 6 }}
                                                >
                                                    {item.title}
                                                </Button>
                                            ) : (
                                                <Button
                                                    key={index.toString()}
                                                    color="inherit"
                                                    onClick={item.action}
                                                    // onMouseEnter={() => setOpen(true)}
                                                    // // onMouseLeave={() => setOpen(false)}

                                                    // onBlur={() => setOpen(false)}
                                                    sx={{ paddingX: 2, paddingY: 1, borderRadius: 6 }}
                                                >
                                                    {item.title}
                                                </Button>
                                            )}
                                            {item.title === 'Find Events' && (
                                                <Grid
                                                    position="absolute"
                                                    sx={{ backgroundColor: 'white', width: 300 }}
                                                    // onMouseLeave={() => setOpen(false)}
                                                    boxShadow={4}
                                                >
                                                    <Collapse in={open} unmountOnExit>
                                                        <List component="div">
                                                            <ListItemButton onClick={() => handleEventClick('/comp/league')}>
                                                                <ListItemText primary="Leagues" />
                                                            </ListItemButton>
                                                            <ListItemButton onClick={() => handleEventClick('/comp/tournament')}>
                                                                <ListItemText primary="Tournaments" />
                                                            </ListItemButton>
                                                        </List>
                                                    </Collapse>
                                                </Grid>
                                            )}
                                        </Grid>
                                    ))}

                                    {/* <Button component={RouterLink} to="/download" disableElevation variant="contained" color="secondary">
                                    Download Now
                                </Button> */}
                                </Grid>
                            </Grid>
                            {/* </Stack> */}
                        </Grid>
                        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                            <IconButton color="inherit" onClick={drawerToggler(true)} size="large">
                                <MenuIcon />
                            </IconButton>
                            <Drawer anchor="left" open={drawerToggle} onClose={drawerToggler(false)}>
                                {drawerToggle && (
                                    <Box
                                        sx={{
                                            width: { xs: 320, md: 450 },
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            display: 'flex',
                                            flex: 1
                                        }}
                                        role="presentation"
                                        onClick={drawerToggler(false)}
                                        onKeyDown={drawerToggler(false)}
                                    >
                                        <List>
                                            {navigationItems.map((item, index) => {
                                                if (item.title === 'Find Events') {
                                                    // Adjust this part for the dropdown logic
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <ListItemButton onClick={(event) => handleToggleDropdown(event)}>
                                                                <ListItemIcon>{item.icon}</ListItemIcon>
                                                                <ListItemText primary={item.title} />
                                                            </ListItemButton>
                                                            <Collapse in={open} unmountOnExit>
                                                                <List component="div" disablePadding>
                                                                    <ListItemButton
                                                                        sx={{ pl: 8 }}
                                                                        onClick={() => handleEventClick('/comp/league')}
                                                                    >
                                                                        <ListItemText primary="Leagues" />
                                                                    </ListItemButton>
                                                                    <ListItemButton
                                                                        sx={{ pl: 8 }}
                                                                        onClick={() => handleEventClick('/comp/tournament')}
                                                                    >
                                                                        <ListItemText primary="Tournaments" />
                                                                    </ListItemButton>
                                                                </List>
                                                            </Collapse>
                                                        </React.Fragment>
                                                    );
                                                }

                                                return (
                                                    <Link
                                                        key={index}
                                                        style={{ textDecoration: 'none' }}
                                                        component={RouterLink}
                                                        to={item.link}
                                                    >
                                                        <ListItemButton key={index} component="a">
                                                            <ListItemIcon>{item.icon}</ListItemIcon>
                                                            <ListItemText primary={item.title} />
                                                        </ListItemButton>
                                                    </Link>
                                                );
                                            })}
                                        </List>
                                        <List>
                                            <Link style={{}}>
                                                <ListItemButton onClick={() => navigate('/help')}>
                                                    <ListItemText primary="Help Center" />
                                                </ListItemButton>
                                            </Link>
                                            <Link style={{ textDecoration: 'none' }}>
                                                <ListItemButton onClick={() => window.open(`mailto:${salesEmail}`)}>
                                                    <ListItemText primary="Contact Sales" />
                                                </ListItemButton>
                                            </Link>
                                            <Link style={{ textDecoration: 'none' }}>
                                                <ListItemButton component={RouterLink} to="demo/request">
                                                    <ListItemText primary="Request a Demo" />
                                                </ListItemButton>
                                            </Link>
                                        </List>
                                    </Box>
                                )}
                            </Drawer>
                        </Box>
                    </Toolbar>
                </Container>
            </MuiAppBar>
        </ElevationScroll>
    );
};

export default UnAuthAppBar;
