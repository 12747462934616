/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';

// material-ui
import { Grid, Typography, Chip, Button, Stack } from '@mui/material';

// assets
import { makeStyles } from '@mui/styles';

// project imports
import { WindowContext } from 'contexts/WindowContext';
import getReadableDateTime from 'utils/get_readable_datetime';

const PoolsTitleBar = ({ categoryName, pool, poolAction, setSelectedPool }) => {
    const { isMobile } = useContext(WindowContext);
    const useStyles = makeStyles({
        tableCell: {
            borderRight: '1px solid #e0e0e0'
        },
        chipCustom: {
            '& .MuiChip-label': { fontSize: isMobile ? 12 : 13 } // sub-selector
        }
    });
    const styles = useStyles();
    const handlePoolPress = (pool) => {
        poolAction();
        setSelectedPool(pool?.id);
    };

    function renderMatchCompletedTimestamp(pool) {
        if (pool.status !== 'completed') {
            return <></>;
        }
        const displayText = getReadableDateTime(pool.updated_at, 'dayTime');
        return (
            <Typography variant="subtitle2" sx={{ pt: 1, minWidth: '75px' }} noWrap>
                {displayText}
            </Typography>
        );
    }
    const getPoolStatus = (pool) => {
        switch (pool?.status) {
            case 'completed':
                return <Chip label="Completed" color="success" variant="filled" className={styles.chipCustom} />;
            case 'waiting':
                return <Chip label="Not Started" color="error" variant="filled" className={styles.chipCustom} />;
            case 'in_progress':
                if (pool?.pool_teams[0]?.won === 0 && pool?.pool_teams[0]?.lost === 0) {
                    return <Chip label="Not Started" color="error" variant="filled" className={styles.chipCustom} />;
                }
                return <Chip label="In Progress" color="warning" variant="filled" className={styles.chipCustom} />;
            default:
                return <Chip label="Playing" color="success" variant="filled" className={styles.chipCustom} />;
        }
    };

    function renderRounds(pool) {
        return pool.rounds?.map((round) => (
            <Grid item ml={3}>
                <Grid justifyContent="center" alignItems="center">
                    <Typography variant="h6" color="primary.text" textAlign="center">
                        {`Round ${round?.round_number}`}
                    </Typography>
                    <Grid container>
                        {round.matches?.map((match) => (
                            <Grid
                                ml={0.5}
                                sx={{
                                    width: 24,
                                    height: 12,
                                    backgroundColor: match.status === 'completed' ? 'green' : null,
                                    border: '0.5px solid green'
                                }}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        ));
    }

    function renderWithoutRounds(categoryName, pool) {
        return (
            <>
                <Grid item md={2} sm={2} xs={3}>
                    <Typography
                        variant="h4"
                        sx={{
                            color: 'primary.text',
                            borderRadius: 1
                        }}
                    >
                        {categoryName}
                    </Typography>
                </Grid>
                <Grid item md={2} sm={2} xs={3} mr={-5} sx={{ minWidth: '98px' }}>
                    <Stack direction="row" spacing={1}>
                        {getPoolStatus(pool)}
                        {renderMatchCompletedTimestamp(pool)}
                    </Stack>
                </Grid>
                <Grid
                    item
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        alignSelf: 'flex-end',
                        // textAlign: { xs: 'start', md: 'end' },
                        textAlign: 'end'
                        // marginTop: { xs: 2, md: 0 }
                    }}
                    md={8}
                    sm={8}
                    xs={6}
                >
                    <Button
                        variant="contained"
                        size={isMobile ? 'small' : 'medium'}
                        onClick={() => {
                            handlePoolPress(pool);
                        }}
                        sx={{
                            minWidth: '100px'
                        }}
                    >
                        View Pool
                    </Button>
                </Grid>
            </>
        );
    }

    function renderWithRounds(categoryName, pool) {
        return (
            <>
                <Grid item md={1} sm={2} xs={3}>
                    <Typography
                        variant="h4"
                        sx={{
                            color: 'primary.text',
                            borderRadius: 1
                        }}
                    >
                        {categoryName}
                    </Typography>
                </Grid>
                <Grid item md={2} sm={2} xs={2} mr={-5} sx={{ minWidth: '98px' }}>
                    {getPoolStatus(pool)}
                </Grid>
                <Grid container md={6} sm={0} xs={0} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                    {renderRounds(pool)}
                </Grid>
                <Grid
                    item
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        alignSelf: 'flex-end',
                        // textAlign: { xs: 'start', md: 'end' },
                        textAlign: 'end'
                        // marginTop: { xs: 2, md: 0 }
                    }}
                    md={3}
                    sm={5}
                    xs={6}
                >
                    <Button
                        variant="contained"
                        size={isMobile ? 'small' : 'medium'}
                        onClick={() => {
                            handlePoolPress(pool);
                        }}
                    >
                        View Pool
                    </Button>
                </Grid>
                <Grid container sm={12} sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }} mt={2}>
                    {renderRounds(pool)}
                </Grid>
            </>
        );
    }
    return (
        <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            mt={2}
            sx={{
                backgroundColor: 'white',
                borderRadius: 1,
                paddingX: 2,
                paddingY: 2,
                borderBottom: '1px solid lightgrey'
            }}
        >
            {pool.rounds?.length > 0 ? renderWithRounds(categoryName, pool) : renderWithoutRounds(categoryName, pool)}
        </Grid>
    );
};

export default PoolsTitleBar;
