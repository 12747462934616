/* eslint-disable no-bitwise */
import React, { useState, useEffect, useContext } from 'react';

import ReactApexChart from 'react-apexcharts';
import useLeagues from 'hooks/useLeagues';
import { WindowContext } from 'contexts/WindowContext';
import { hashStringForHex } from 'utils/colorUtils';

const LadderGraph = ({ leagueId }) => {
    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({});
    const [chartHeight, setChartHeight] = useState(0);
    const [chartWidth, setChartWidth] = useState('100%');
    const { leagueTrends, getLeagueTrends } = useLeagues();
    const { isMobile } = useContext(WindowContext);

    function filterTrends(leagueTrends, numRounds) {
        const filteredTrends = [];
        for (let i = 0; i < leagueTrends.length; i += 1) {
            if (leagueTrends[i].ranks?.length >= numRounds - 2) {
                filteredTrends.push(leagueTrends[i]);
            }
        }
        return filteredTrends;
    }

    function getCategories(numRounds) {
        const categories = [];
        categories.push('Seed');
        for (let i = 1; i < numRounds; i += 1) {
            categories.push(`Week ${i}`);
        }
        return categories;
    }

    useEffect(() => {
        if (leagueId) {
            getLeagueTrends(leagueId);
        }
    }, [leagueId]);

    useEffect(() => {
        if (!leagueTrends[0]) {
            return;
        }

        leagueTrends.sort((a, b) => a.ranks[0] - b.ranks[0]);

        let numRounds = 0;
        let lowestRank = 0;
        for (let i = 0; i < leagueTrends.length; i += 1) {
            const newTrend = leagueTrends[i];
            numRounds = Math.max(newTrend.ranks.length, numRounds);
            lowestRank = Math.max(lowestRank, newTrend.ranks[0]);
        }
        const categories = getCategories(numRounds);

        const filteredLeagueTrends = filterTrends(leagueTrends, numRounds);

        const newSeries = [];
        const colors = [];
        for (let i = 0; i < filteredLeagueTrends.length; i += 1) {
            const playerTrend = filteredLeagueTrends[i];
            newSeries.push({ name: playerTrend.user.name, data: playerTrend.ranks });
            colors.push(hashStringForHex(playerTrend.user.name));
        }
        setSeries(newSeries);

        const heightForEverything = filteredLeagueTrends.length * 27;
        setChartHeight(heightForEverything);
        if (isMobile && numRounds > 6) {
            setChartWidth(100 * numRounds);
        }

        setOptions({
            chart: {
                height: heightForEverything,
                type: 'line',
                toolbar: {
                    show: false,
                    enabled: false
                },
                background: '#fff'
            },
            colors,
            stroke: {
                width: isMobile ? 4 : 6
            },
            dataLabels: {
                enabled: true, // shows new number at each point
                style: {
                    fontSize: isMobile ? '12px' : '15px'
                }
            },
            title: {
                text: 'Ladder Rank Over Time',
                align: 'left',
                style: {
                    fontSize: '24px',
                    margin: '25px'
                }
            },
            grid: {
                borderColor: '#e7e7e7' // horizonal lines to help eye
            },
            markers: {
                size: 1
            },
            xaxis: {
                categories,
                type: 'numeric'
            },
            yaxis: {
                categories: [], // display nothing
                type: 'numeric',
                min: 1,
                max: lowestRank,
                reversed: true,
                labels: {
                    show: false
                }
            },
            legend: {
                position: 'left',
                horizontalAlign: 'right',
                floating: false,
                height: heightForEverything,
                offsetY: 25,
                offsetX: -15,
                itemMargin: {
                    horizontal: 0,
                    vertical: 2
                },
                onItemClick: {
                    // TODO: find some way to highlight data series on click.
                    toggleDataSeries: false
                },
                onItemHover: {
                    highlightDataSeries: true
                }
            },
            tooltip: {
                enabled: true,
                enabledOnSeries: [0],
                x: {
                    show: false
                }
                // TODO: use custom tooltips to make fixed() work and do other nice things.
                // highlight series on hover also doesn't work :(
            }
        });
    }, [leagueTrends]);

    return <ReactApexChart options={options} series={series} type="line" height={chartHeight} width={chartWidth} />;
};

export default LadderGraph;
