// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Container, Grid, Typography, Card, CardMedia, CardContent, CardActions, Box, Avatar } from '@mui/material';
import SubCard from 'ui-component/cards/SubCard';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import { Link, useParams, Link as RouterLink } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';

// import MainCard from 'ui-component/cards/MainCard';

// project imports
import AppBar from 'ui-component/extended/AppBar';
import Card1 from 'assets/images/cards/card-1.jpg';
import usePosts from 'hooks/usePosts';
import UnAuthAppBar from 'layout/MainLayout/TopRightMenuButton/AppBar/AppBar';

// assets

const HeaderWrapper = styled('div')(({ theme }) => ({
    // backgroundImage: `url(${headerBackground})`,
    // backgroundColor: theme.palette.primary.main,
    justifyContent: 'center',
    // backgroundSize: '100% 600px',
    // backgroundAttachment: 'fixed',
    // backgroundRepeat: 'no-repeat',

    paddingTop: 10,
    flex: 1,
    height: '100vh', // add this line
    [theme.breakpoints.down('md')]: {
        paddingTop: 0
    }
}));

// ============================|| SAAS PAGES - FAQs ||============================ //

const Blog = () => {
    const { slug } = useParams();
    const { getBlog } = usePosts();
    const [blogData, setBlogData] = useState();

    function convertDateTime(dateTime, format = 'dateTime') {
        const inputDate = moment.utc(dateTime);

        switch (format) {
            case 'time':
                return inputDate.local().format('h:mm A');
            case 'date':
                return inputDate.local().format('ddd, MMM D');
            case 'fullDate':
                return inputDate.local().format('MMM D, YYYY');
            case 'appreviatedDate':
                return inputDate.local().format('YYYY-MM-DD');
            default:
                return inputDate.local().format('MMMM Do h:mm A');
        }
    }

    useEffect(() => {
        // const fetchData = async () => {
        //     const response = await fetch(`/api/blog/${slug}`);
        //     const data = await response.json();
        //     console.log('DATA', data);
        //     setBlogData(data.blog);
        // };
        // fetchData();
        getBlog(slug).then((res) => setBlogData(res));
    }, [slug]);

    const theme = useTheme();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100],
        maxWidth: 600
    };

    return (
        <HeaderWrapper>
            <Helmet>
                <meta name="description" content={blogData?.meta_description} />
                <meta
                    name="keywords"
                    content={blogData ? blogData?.meta_keywords : 'pickleball, pickleball app, improve pickleball game'}
                />
                <title>{blogData ? blogData?.title : 'Blog'}</title>
                <meta property="og:title" content={blogData?.title} />
                <meta property="og:description" content={blogData?.meta_description} />
                <meta property="og:url" content={`https://stackedpickleball.com/blog/${blogData?.slug}`} />
            </Helmet>
            {/* <UnAuthAppBar /> */}
            <Container>
                {blogData && (
                    <Grid container justifyContent="flex-start" alignItems="center" xs={12}>
                        <Grid item xs={12} md={12} sx={{ mt: { md: 12.5, xs: 2.5 }, mb: { md: 8.5, xs: 2.5 } }}>
                            <Typography
                                variant="h2"
                                color="primary"
                                component="div"
                                sx={{
                                    fontSize: '2.5rem',
                                    fontWeight: 900,
                                    lineHeight: 1.4,
                                    // marginLeft: 4,
                                    // textAlign: 'center',
                                    [theme.breakpoints.down('md')]: { fontSize: '1.8125rem', marginTop: '80px' }
                                }}
                            >
                                {blogData.title}
                            </Typography>
                        </Grid>
                        <Grid item display="flex" mb={6} alignItems="center">
                            <Grid item>
                                <Avatar alt={blogData.user?.name} src={blogData.user?.user_attributes?.profile_picture_url} />

                                {/* <img
                                    src={blogData.user?.user_attributes?.profile_picture_url}
                                    alt="blog"
                                    width="40rem"
                                    height="40rem"
                                    style={{ borderRadius: '40rem' }}
                                /> */}
                            </Grid>
                            <Grid item>
                                <Link component={RouterLink} to="/team" style={{ textDecoration: 'none' }}>
                                    <Typography variant="h5" color="#0BA723" component="div" ml={2}>
                                        {blogData?.user?.name}
                                    </Typography>
                                </Link>
                            </Grid>

                            <Grid item ml={6} display="flex" alignItems="center">
                                {' '}
                                {/* Add display="flex" here */}
                                <Typography variant="h5" color="lightgrey" component="div" mr={2}>
                                    {' '}
                                    {/* Add mr={2} here */}
                                    Published on:
                                </Typography>
                                <Typography variant="h5" color="primary" component="div">
                                    {convertDateTime(blogData?.published_at, 'fullDate')}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid item xs={12} md={8} justifyContent="center" mb={4}>
                                <img src={blogData.featured_image} alt="blog" width="90%" style={{ borderRadius: '2rem' }} />
                            </Grid>
                            <Grid item xs={12} mb={12}>
                                <Typography color="primary" component="div">
                                    <Grid item xs={12}>
                                        <div dangerouslySetInnerHTML={{ __html: blogData.content }} />
                                    </Grid>
                                    {/* <ReactMarkdown>{blogData.content}</ReactMarkdown> */}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </HeaderWrapper>
    );
};

export default Blog;
