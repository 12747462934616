// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconHome, IconDeviceAnalytics, IconCalendarEvent, IconBuildingBank, IconSettings } from '@tabler/icons';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

const icons = {
    IconHome,
    IconDeviceAnalytics,
    AdminPanelSettingsOutlinedIcon,
    IconCalendarEvent,
    ChatOutlinedIcon,
    IconBuildingBank,
    IconSettings,
    ReceiptLongIcon
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const internalDashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    icon: icons.IconHome,
    type: 'group',
    breadcrumbs: true,
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/internal-tools/home',
            icon: icons.IconHome,
            breadcrumbs: false
        },

        {
            id: 'marketing',
            title: <FormattedMessage id="marketing" />,
            type: 'item',
            url: '/internal-tools/campaigns',
            icon: icons.ReceiptLongIcon,
            breadcrumbs: false
        }
    ]
};

export default internalDashboard;
