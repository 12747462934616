/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

// material-ui
import {
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    Avatar,
    Chip,
    Button,
    Menu,
    MenuItem,
    Tooltip
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import { useTheme } from '@mui/material/styles';

// project imports
import PlayerCell from 'views/components/playerCell.component';

// assets
import useLeagues from 'hooks/useLeagues';
import { useDispatch, dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import emptyImage from 'assets/images/empty.png';
import ProductEmpty from 'views/application/e-commerce/Products/ProductEmpty';
import { renderLoadingSkeleton } from 'utils/utilWorkbench';

const TeamsTable = ({
    eventId,
    leagueId,
    eventName,
    showAdmin = false,
    teamList = null,
    handleGenerateDrawer,
    handleGenerateTeamDrawer,
    selectedEvent,
    type = null,
    showPoolInfo,
    showAddTeam = false,
    showEvent = false,
    showDelete = true
}) => {
    const { getEventTeams, deleteTeam, eventTeams, upgradeTeamFromWaitlist, setValue, getTournamentTeams, matches, eventPools, loading } =
        useLeagues();
    const [teams, setTeams] = useState([]);
    const handleTeamDeletion = (team_id) => {
        deleteTeam(team_id).then(() => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Team Removed',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        });
    };

    function isSingles(teams) {
        if (!teams || !teams[0] || !teams[0].players) {
            return null;
        }
        return teams[0].players.length === 1;
    }

    function isSeedsPopulated(teams) {
        if (!teams || !teams[0] || !teams[0]) {
            return null;
        }
        return teams.find((team) => !!team.seed) !== undefined;
    }

    function consolidateRating(rating) {
        if (rating === null || rating === 'NR') {
            return 0;
        }
        return Number(rating);
    }

    useEffect(() => {
        if (teamList) {
            const sortedTeams = [...teamList].sort((a, b) => a.seed - b.seed);
            setTeams(sortedTeams);
        } else if (selectedEvent) {
            getEventTeams(selectedEvent?.id).then((res) => {
                setTeams(res);
            });
        } else if (eventId) {
            getEventTeams(eventId).then((res) => {
                if (!isSeedsPopulated(res) && isSingles(res)) {
                    const sortedTeams = res.sort((a, b) => {
                        if (a.is_waitlisted || b.is_waitlisted) {
                            if (a.is_waitlisted && b.is_waitlisted) {
                                return 0;
                            }
                            if (a.is_waitlisted) {
                                return 1;
                            }
                            return -1;
                        }
                        const bAttrs = b.players[0].user.user_attributes;
                        const aAttrs = a.players[0].user.user_attributes;
                        const compResult = consolidateRating(bAttrs?.singles_dupr_rating) - consolidateRating(aAttrs?.singles_dupr_rating);
                        if (compResult !== 0) {
                            return compResult;
                        }
                        return consolidateRating(bAttrs?.dupr_rating) - consolidateRating(aAttrs?.dupr_rating);
                    });
                    setTeams(sortedTeams);
                } else {
                    setTeams(res);
                }
            });
        } else if (leagueId) {
            getTournamentTeams(leagueId).then((res) => {
                setTeams(res);
            });
        }
    }, [eventId, teamList]);

    const getTeamStatusIcon = (status) => {
        switch (status) {
            case 1:
                return <CheckCircleIcon color="success" />;
            default:
                return <HistoryToggleOffIcon color="warning" />;
        }
    };
    const [actionMenuAnchorEl, setActionMenuAnchorEl] = React.useState(null);
    const [selectedEventId, setSelectedEventId] = React.useState(null);
    const [selected, setSelected] = useState([]);

    const handleActionMenuClick = (event, eventId) => {
        setActionMenuAnchorEl(event.currentTarget);
        setSelectedEventId(eventId);
    };

    const handleActionClose = () => {
        setActionMenuAnchorEl(null);
        setSelectedEventId(null);
    };
    const handleClose = () => {
        setActionMenuAnchorEl(null);
        setSelectedEventId(null);
    };
    const handleTeamUpgrade = (team_id) => {
        upgradeTeamFromWaitlist(team_id, leagueId).then(() => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Team upgraded from waitlist & payment requested',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        });
    };
    const theme = useTheme();

    const TeamsTableHeader = () => (
        <TableHead>
            <TableRow>
                <TableCell align="center" width="5%">
                    Seed
                </TableCell>
                <TableCell>Players</TableCell>
                {showEvent && <TableCell> Event</TableCell>}
                {!isSingles(teams) && <TableCell align="center">Comb. Rating</TableCell>}
                <TableCell align="center">Confirmed</TableCell>
                <TableCell align="center" />
            </TableRow>
        </TableHead>
    );

    return (
        <Grid data-test-id="views/components/teamsTable.component">
            {showAdmin && (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    mt={2}
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: 1,
                        paddingX: 2,
                        paddingY: 2,
                        borderBottom: '1px solid lightgrey'
                    }}
                >
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: 'primary.text'
                                    }}
                                >
                                    {eventName}
                                </Typography>
                            </Grid>
                            {showPoolInfo && (
                                <Grid item ml={2}>
                                    <Chip label="No Pools" color="error" variant="filled" />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {type !== 'league' && showPoolInfo && (
                        <Grid
                            sx={{
                                flexGrow: 1,
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                                alignSelf: 'flex-end',
                                textAlign: { xs: 'start', md: 'end' },
                                marginTop: { xs: 2, md: 0 }
                            }}
                            xs={12}
                            md={6}
                        >
                            <Button
                                variant="contained"
                                onClick={() => {
                                    handleGenerateDrawer(eventId, eventTeams.length);
                                }}
                            >
                                Generate Pools
                            </Button>
                        </Grid>
                    )}
                    {showAdmin && showAddTeam && (
                        <Grid
                            sx={{
                                flexGrow: 1,
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                                alignSelf: 'flex-end',
                                textAlign: { xs: 'start', md: 'end' },
                                marginTop: { xs: 2, md: 0 }
                            }}
                            xs={12}
                            md={6}
                        >
                            <Tooltip title={(matches?.length > 0 || eventPools?.length > 0) && 'Pool or bracket already generated'}>
                                <span>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            handleGenerateTeamDrawer(eventId, eventTeams.length);
                                        }}
                                        disabled={matches?.length > 0 || eventPools?.length > 0}
                                    >
                                        Add Team
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            )}
            <TableContainer>
                <Table size="small" aria-label={eventName} sx={{ backgroundColor: 'white', borderRadius: 2 }}>
                    <TeamsTableHeader />
                    {loading?.eventTeams ? (
                        renderLoadingSkeleton(10, 4)
                    ) : teams?.length > 0 ? (
                        <TableBody>
                            {teams?.map((team, teamIndex) => (
                                <TableRow key={team?.id}>
                                    {isSeedsPopulated(teams) && (
                                        <TableCell align="center">
                                            {team.is_waitlisted ? (
                                                <Typography color="warning.main" variant="subtitle1">
                                                    WAIT
                                                </Typography>
                                            ) : (
                                                <Typography variant="subtitle1">{team?.seed}</Typography>
                                            )}
                                        </TableCell>
                                    )}
                                    {!isSeedsPopulated(teams) && (
                                        <TableCell align="center">
                                            {team.is_waitlisted ? (
                                                <Typography color="warning.main" variant="subtitle1">
                                                    WAIT
                                                </Typography>
                                            ) : (
                                                <Typography variant="subtitle1">{teamIndex + 1}</Typography>
                                            )}
                                        </TableCell>
                                    )}
                                    <TableCell
                                        sx={{
                                            flexDirection: 'column',
                                            minWidth: '150px',
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        <Grid
                                            container
                                            sx={{
                                                flexDirection: 'column',
                                                whiteSpace: 'nowrap',
                                                minWidth: '150px'
                                            }}
                                        >
                                            {team.players?.map((player) => (
                                                <PlayerCell user={player?.user} key={player.id} isAdmin={showAdmin} />
                                            ))}
                                        </Grid>
                                    </TableCell>
                                    {showEvent && <TableCell>{team.event.title}</TableCell>}
                                    {!isSingles(teams) && (
                                        <TableCell align="center">
                                            {Number(
                                                team.players
                                                    ?.reduce((total, player) => {
                                                        if (player.user.user_attributes?.dupr_rating) {
                                                            return total + Number(player.user.user_attributes.dupr_rating);
                                                        }
                                                        if (player.user.user_attributes?.rating) {
                                                            return total + Number(player.user.user_attributes.rating);
                                                        }
                                                        if (player.user.user_attributes?.doubles_self_rating) {
                                                            return total + Number(player.user.user_attributes.doubles_self_rating);
                                                        }
                                                        return total;
                                                    }, 0)
                                                    .toFixed(2)
                                            )}
                                        </TableCell>
                                    )}
                                    <TableCell align="center">
                                        {team.players?.map((player) => (
                                            <Grid p={1} alignItems="center" key={player?.id}>
                                                {getTeamStatusIcon(player?.registered)}
                                            </Grid>
                                        ))}
                                    </TableCell>
                                    {showAdmin && (
                                        <TableCell align="center">
                                            <Chip
                                                size="small"
                                                label="actions"
                                                // chipcolor={getChipColor(row.status)}
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                                onClick={(e) => handleActionMenuClick(e, team.id)}
                                            />
                                            <Menu
                                                id="menu-popular-card-2"
                                                anchorEl={actionMenuAnchorEl}
                                                open={Boolean(actionMenuAnchorEl) && selectedEventId === team.id}
                                                keepMounted
                                                onClose={handleActionClose}
                                                variant="selectedMenu"
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right'
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right'
                                                }}
                                                sx={{
                                                    '& .MuiMenu-paper': {
                                                        boxShadow: theme.customShadows.z1
                                                    }
                                                }}
                                            >
                                                {team?.is_waitlisted ? (
                                                    <MenuItem
                                                        onClick={() => {
                                                            handleTeamUpgrade(team.id);
                                                            handleClose();
                                                        }}
                                                    >
                                                        <Chip
                                                            size="small"
                                                            label="Upgrade"
                                                            color="success"
                                                            sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                                        />
                                                    </MenuItem>
                                                ) : null}
                                                {matches?.length === 0 && eventPools?.length === 0 && showDelete && (
                                                    <MenuItem
                                                        onClick={() => {
                                                            handleTeamDeletion(team.id);
                                                            handleClose();
                                                        }}
                                                    >
                                                        <Chip
                                                            size="small"
                                                            label="Remove"
                                                            color="error"
                                                            sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                                        />
                                                    </MenuItem>
                                                )}
                                                <MenuItem
                                                    onClick={() => {
                                                        handleGenerateTeamDrawer(eventId, eventTeams.length, team?.id);
                                                        handleClose();
                                                    }}
                                                >
                                                    <Chip
                                                        size="small"
                                                        label="Edit"
                                                        color="warning"
                                                        sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                                    />
                                                </MenuItem>
                                            </Menu>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    ) : (
                        <Grid justifyContent="center" container mt={2}>
                            <ProductEmpty
                                content="No teams signed up"
                                helperText="As players register, you will see them here"
                                image={emptyImage}
                                imageWidth={{ xs: 200, sm: 300 }}
                            />
                        </Grid>
                    )}
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default TeamsTable;
