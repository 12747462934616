import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { Button, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import
import MainCard from './MainCard';
import SkeletonProductPlaceholder from 'ui-component/cards/Skeleton/ProductPlaceholder';
import getReadableDateTime from 'utils/get_readable_datetime';
import PBIcon from 'assets/images/pb_brackets_logo.png';

// ==============================|| PRODUCT CARD ||============================== //

const ProductCard = ({
    id,
    slug,
    color,
    name,
    images,
    description,
    offerPrice,
    salePrice,
    rating,
    startDate,
    endDate,
    location,
    playersRegistered,
    eventType,
    isRegistered,
    groupId,
    isExternal,
    externalEventId,
    externalParticipants
}) => {
    const theme = useTheme();

    const mainImage = images?.filter((item) => item.is_main === 1) || [];
    const prodProfile = mainImage[0]?.image_url;

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    }, []);

    const getNextLink = () =>
        // if (isExternal) {
        //     return `https://pickleballbrackets.com/ptd.aspx?eid=${externalEventId}`;
        // }
        // if (isRegistered) {
        //     return `/web/group/${groupId}`;
        // }
        `/e/${eventType}/${slug}`;
    const nextLink = getNextLink();
    const handleClick = () => {
        // Check if the link is internal or external
        // A simple heuristic: if it starts with "http", consider it external
        // if (isExternal) {
        //     // External link
        //     window.open(nextLink, '_blank');
        // } else {
        // Internal link
        navigate(nextLink);
        // }
    };
    return (
        <>
            {isLoading ? (
                <SkeletonProductPlaceholder />
            ) : (
                <MainCard
                    content={false}
                    border={false}
                    elevation={16}
                    boxShadow
                    shadow={theme.shadows[6]}
                    sx={{
                        '&:hover': {
                            transform: 'scale3d(1.02, 1.02, 1)',
                            transition: 'all .4s ease-in-out'
                        },
                        position: 'relative'
                    }}
                >
                    <CardMedia sx={{ height: 220 }} image={prodProfile} title="Contemplative Reptile" onClick={handleClick} />

                    <CardContent sx={{ p: 2, position: 'relative' }}>
                        {isExternal ? (
                            <Grid position="absolute" top={1} right={1}>
                                <img src={PBIcon} width={25} height={25} alt="pickleball brackets" />
                            </Grid>
                        ) : null}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography onClick={handleClick} variant="subtitle1" sx={{ textDecoration: 'none' }}>
                                    {name}
                                </Typography>
                            </Grid>
                            {startDate && endDate && (
                                <Grid item xs={12}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            overflow: 'hidden'
                                            // height: 45
                                        }}
                                    >
                                        {getReadableDateTime(startDate, 'date', false)}

                                        {startDate !== endDate && `- ${getReadableDateTime(endDate, 'date', false)}`}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item xs={12} sx={{ pt: '8px !important' }}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    {/* <Rating precision={0.5} name="size-small" value={productRating} size="small" readOnly /> */}
                                    {/* <Typography variant="caption">({offerPrice}+)</Typography> */}
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            overflow: 'hidden'
                                            // height: 45
                                        }}
                                    >
                                        {location}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sx={{ pt: '8px !important' }}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    {/* <Rating precision={0.5} name="size-small" value={productRating} size="small" readOnly /> */}
                                    {/* <Typography variant="caption">({offerPrice}+)</Typography> */}
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            overflow: 'hidden'
                                            // height: 45
                                        }}
                                    >
                                        {playersRegistered || externalParticipants} Players
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Typography variant="h4">${offerPrice}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Button variant="contained" sx={{ minWidth: 0 }} onClick={handleClick}>
                                        <Typography> Details</Typography>
                                        {/* <ShoppingCartTwoToneIcon fontSize="small" /> */}
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                </MainCard>
            )}
        </>
    );
};

ProductCard.propTypes = {
    id: PropTypes.number,
    color: PropTypes.string,
    name: PropTypes.string,
    images: PropTypes.array,
    description: PropTypes.string,
    offerPrice: PropTypes.number,
    salePrice: PropTypes.number,
    rating: PropTypes.number
};

export default ProductCard;
