import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Typography, Button, Box, Checkbox, Grid, Divider, TextField, Container, Breadcrumbs } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { CheckCircle, Home, Send } from '@mui/icons-material';
import SaveFooter from 'views/admin/AdminHub/components/SaveFooter';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useMarketing from 'hooks/useMarketing';
import FormComponents from 'ui-component/FormComponents';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import getReadableDateTime from 'utils/get_readable_datetime';
import moment from 'moment';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
import StyledBreadcrumb from 'ui-component/extended/StyledBreadcrumb';
import { useTheme } from '@mui/styles';
import CardModal from 'ui-component/CardModal';
import actionSnackbar from 'ui-component/actionSnackbar';
import { v4 as uuidv4 } from 'uuid';

const EmailPage = () => {
    const { emailId, clubId } = useParams();
    const [emailContent, setEmailContent] = useState(null);
    const [htmlPreview, setHtmlPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [testEmails, setTestEmails] = useState('');
    const [testEmailModalOpen, setTestEmailModalOpen] = useState(false);
    const toggleModal = () => setModalOpen(!modalOpen);
    const toggleTestEmailModal = () => setTestEmailModalOpen(!testEmailModalOpen);
    const emailEditorRef = useRef(null);
    const location = useLocation();
    const isInternal = location.pathname.includes('internal-tools');
    const theme = useTheme();
    const { getEmail, email, updateEmail, sendEmail } = useMarketing();
    const navigate = useNavigate();
    const [fieldVisible, setFieldVisible] = useState({ recipients: false, from: false, subject: false, sendTime: false });

    const formik = useFormik({
        initialValues: {
            send_time: email?.send_time ? moment.utc(email?.send_time) : null,
            subject: email?.subject,
            from: email?.from,
            recipients: email?.recipients,
            timezone: email?.timezone || moment.tz.guess()
        },
        onSubmit: (values) => {
            console.log(values);
        }
    });
    const toggleFieldVisibility = (field) => {
        setFieldVisible({ ...fieldVisible, [field]: !fieldVisible[field] });
    };
    useEffect(() => {
        if (email) {
            let parsedContent;
            try {
                parsedContent = JSON.parse(email?.content);
                // emailEditorRef.current.editor.loadDesign(parsedContent);
                // emailEditorRef.current.editor.exportHtml(({ html }) => {
                //     setHtmlPreview(html);
                // });
            } catch (e) {
                parsedContent = email?.content; // Fallback to original content if parsing fails
            }
            setEmailContent(parsedContent);
        }
    }, [email?.id]);

    const onLoad = () => {
        const unlayer = emailEditorRef.current?.editor;

        unlayer.loadDesign(emailContent);
        unlayer.exportHtml(({ html }) => {
            setHtmlPreview(html);
        });
    };

    // useEffect(() => {
    //     onLoad();
    // }, [emailContent]);
    const exportHtml = () => {
        setLoading(true);
        const unlayer = emailEditorRef.current?.editor;
        const urls = new Set(); // Use a Set to store unique URLs
        unlayer?.exportHtml((data) => {
            const { design, html } = data;
            const modifiedHtml = html.replace(/href="([^"]*)"/g, (match, url) => {
                let trackingId;
                const existingEntry = [...urls].find((entry) => entry.url === url);
                if (existingEntry) {
                    trackingId = existingEntry.trackingId;
                } else {
                    trackingId = uuidv4(); // Create a tracking ID
                    urls.add({ url, trackingId }); // Add the URL to the Set
                }
                return `href="${process.env.REACT_APP_API_URL}/api/track-click?trackingId=${trackingId}&redirectUrl=${encodeURIComponent(
                    url
                )}"`;
            });
            sendEmail(emailId, modifiedHtml, testEmails, Array.from(urls)).then(() => {
                // Convert Set to Array
                if (testEmails) {
                    toggleTestEmailModal();
                    actionSnackbar(true, 'Test email sent successfully');
                } else {
                    toggleModal();
                    actionSnackbar(true, 'Email sent successfully');
                }
                setLoading(false);
            });
        });
    };

    useEffect(() => {
        getEmail(emailId);
    }, [emailId]);
    const SaveSection = ({ section }) => (
        <Grid item xs={12} mt={2}>
            <Button sx={{ mr: 2 }} variant="outlined" size="small" onClick={() => toggleFieldVisibility(section)}>
                Cancel
            </Button>
            <Button
                variant="contained"
                size="small"
                onClick={() => {
                    updateEmail(emailId, formik.values);
                    toggleFieldVisibility(section);
                }}
            >
                Save
            </Button>
        </Grid>
    );

    return (
        <Container maxWidth="xxl">
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 4 }}>
                <StyledBreadcrumb
                    component="a"
                    href="#"
                    label="Campaigns"
                    icon={<Home fontSize="small" />}
                    theme={theme}
                    onClick={() => navigate(isInternal ? `/internal-tools/campaigns` : `/club/${clubId}/email-campaigns`)}
                />
                <StyledBreadcrumb onClick={() => navigate(-1)} label={email?.campaign?.name} theme={theme} />
                <StyledBreadcrumb component="a" href="#" label={email?.name} theme={theme} />
            </Breadcrumbs>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={8}>
                    <MainCard title={email?.name} sx={{ mb: 8 }}>
                        <CardContent>
                            {/* Option Sections */}
                            <Box sx={{ mb: 4 }}>
                                {/* To Section */}
                                <Grid container alignItems="center">
                                    <Grid item xs={1}>
                                        <CheckCircle />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="subtitle1">To</Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Who are you sending this email to?
                                        </Typography>
                                    </Grid>
                                    {!fieldVisible.recipients && (
                                        <Grid item xs={3} textAlign="right">
                                            <Button variant="outlined" size="small" onClick={() => toggleFieldVisibility('recipients')}>
                                                Add recipients
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                                {fieldVisible.recipients && (
                                    <Box sx={{ mt: 2 }}>
                                        <TextField label="Recipients" variant="outlined" fullWidth />
                                        <Button variant="outlined" size="small" onClick={() => toggleFieldVisibility('recipients')}>
                                            Cancel
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                            <Divider />

                            {/* From Section */}
                            <Box sx={{ my: 4 }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={1}>
                                        <CheckCircle />
                                    </Grid>
                                    {!fieldVisible.from ? (
                                        <Grid item xs={8}>
                                            <Typography variant="subtitle1">From</Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Stacked • hello@stackedpb.com
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={8}>
                                            <FormComponents
                                                input={{
                                                    type: 'text',
                                                    id: 'from',
                                                    label: 'From',
                                                    value: formik.values.from
                                                }}
                                                formik={formik}
                                            />
                                            <SaveSection section="from" />
                                        </Grid>
                                    )}
                                    {/* {!fieldVisible.from && (
                                <Grid item xs={3} textAlign="right">
                                    <Button variant="outlined" size="small" onClick={() => toggleFieldVisibility('from')}>
                                        Edit from
                                    </Button>
                                </Grid>
                            )} */}
                                </Grid>
                            </Box>

                            <Divider />

                            {/* Subject Section */}
                            <Box sx={{ my: 4 }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={1}>
                                        <CheckCircle />
                                    </Grid>
                                    {!fieldVisible.subject ? (
                                        <Grid item xs={8}>
                                            <Typography variant="subtitle1">Subject</Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {email?.subject || 'Untitled'}
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={8}>
                                            <FormComponents
                                                input={{
                                                    type: 'text',
                                                    id: 'subject',
                                                    label: 'Subject',
                                                    value: formik.values.subject
                                                }}
                                                formik={formik}
                                            />
                                            <SaveSection section="subject" />
                                        </Grid>
                                    )}
                                    {!fieldVisible.subject && (
                                        <Grid item xs={3} textAlign="right">
                                            <Button variant="outlined" size="small" onClick={() => toggleFieldVisibility('subject')}>
                                                Edit subject
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>

                            <Divider />

                            {/* Send Time Section */}
                            <Box sx={{ my: 4 }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={1}>
                                        <CheckCircle />
                                    </Grid>
                                    {!fieldVisible.sendTime ? (
                                        <Grid item xs={8}>
                                            <Typography variant="subtitle1">Send time</Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {email?.send_time
                                                    ? getReadableDateTime(email.send_time, 'dateTime', true, email.timezone)
                                                    : 'Send now'}
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={8}>
                                            <Grid container spacing={gridSpacing}>
                                                <FormComponents
                                                    input={{
                                                        type: 'datetime',
                                                        id: 'send_time',
                                                        label: 'Send time',
                                                        value: formik.values.send_time
                                                        // width: 8,
                                                        // smWidth: 8
                                                    }}
                                                    formik={formik}
                                                />
                                                <FormComponents
                                                    input={{
                                                        type: 'timezone',
                                                        id: 'timezone',
                                                        label: 'Time Zone',
                                                        value: formik.values.timezone
                                                    }}
                                                    formik={formik}
                                                />
                                            </Grid>
                                            <SaveSection section="sendTime" />
                                        </Grid>
                                    )}

                                    {!fieldVisible.sendTime && (
                                        <Grid item xs={3} textAlign="right">
                                            <Button variant="outlined" size="small" onClick={() => toggleFieldVisibility('sendTime')}>
                                                Edit send time
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                            <Divider />
                            <Box sx={{ mt: 4 }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={1}>
                                        <CheckCircle />
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Typography variant="subtitle1">Content</Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Edit Content
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={3} textAlign="right">
                                        <Button variant="outlined" size="small" onClick={() => navigate(`/email/editor?id=${emailId}`)}>
                                            Edit content
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </MainCard>
                </Grid>
                <Grid item xs={0} md={4}>
                    <Grid container justifyContent="flex-end" pb={1}>
                        <Button startIcon={<Send />} onClick={toggleTestEmailModal}>
                            Send Test Email
                        </Button>
                    </Grid>
                    <iframe
                        srcDoc={htmlPreview} // Render the generated HTML
                        title="Email Template Preview"
                        style={{
                            width: '100%',
                            borderRadius: '8px',
                            height: '85%',
                            border: 'none',
                            // transform: 'scale(0.5)',
                            transformOrigin: '0 0'
                        }}
                    />
                </Grid>
            </Grid>
            <SaveFooter saveText="Send Email" handleSave={toggleModal} handleCancel={() => navigate(-1)} />
            <CardModal
                open={modalOpen}
                toggleModal={toggleModal}
                title="Email Confirmation"
                content={<Typography>Are you sure you want to send this email? This action cannot be undone.</Typography>}
                actionText="Send Email"
                showCancel
                submitAction={exportHtml}
                cancelAction={toggleModal}
                loading={loading}
            />
            <CardModal
                open={testEmailModalOpen}
                toggleModal={toggleTestEmailModal}
                title="Send Test Email"
                content={
                    <FormComponents
                        input={{
                            type: 'text',
                            placeholder: 'Ex: freddie@stackedpb.com, jonathan@stackedpb.com',
                            onChange: (e) => {
                                setTestEmails(e.target.value);
                            },
                            value: testEmails,
                            label: 'Send a test to',
                            tooltip: 'Separate multiple emails with commas',
                            multiline: true
                        }}
                    />
                }
                actionText="Send Test Email"
                showCancel
                submitAction={exportHtml}
                cancelAction={toggleTestEmailModal}
                loading={loading}
            />
            <Grid display="none">{emailContent && <EmailEditor ref={emailEditorRef} onLoad={onLoad} />}</Grid>
        </Container>
    );
};

export default EmailPage;
