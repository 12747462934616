/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
import { AccessTime, Add, CheckCircle, Print, Refresh } from '@mui/icons-material';
import { CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import useLeagues from 'hooks/useLeagues';
import useTournaments from 'hooks/useTournaments';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CondensedTeamCard from 'ui-component/cards/CondensedTeamCard';
import CustomTable from 'ui-component/table/CustomTable';
import MatchCard from 'views/dashboard/Default/MatchCard';
import MatchInputDrawer from './MatchInputDrawer';

const FreeformMatches = () => {
    const { id } = useParams();
    const { getEventMatches, eventMatches, loading, printMatches, getDivision, division, addMatch } = useTournaments();
    const { uploadMatchesToDupr } = useLeagues();
    const [printMatchId, setPrintMatchId] = useState(null); // State to track the match being printed

    const handlePrintClick = async (matchId) => {
        setPrintMatchId(matchId);
        await printMatches({ match_id: matchId });
        setPrintMatchId(null); // Reset the state after printing
    };

    const [data, setData] = useState([]);

    useEffect(() => {
        getEventMatches(id);
        getDivision(id);
    }, [id]);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedMatchId, setSelectedMatchId] = useState(null);

    const handleDrawerOpen = () => {
        setDrawerOpen(!drawerOpen);
    };

    const headers = [
        { id: 'id', label: 'ID' },
        { id: 'dupr', label: 'Dupr' },
        { id: 'team_a_id', label: 'Team A' },
        { id: 'score', label: '' },
        { id: 'vs', label: '' },
        { id: 'score', label: '' },
        { id: 'team_b_id', label: 'Team B' }
    ];

    useEffect(() => {
        setData(
            eventMatches?.map((item) => [
                {
                    id: 'id',
                    data: item.id,
                    isVisible: true
                },
                {
                    id: 'dupr',
                    data: (
                        <>
                            {item.winner_id ? (
                                item.dupr_match_code ? (
                                    <CheckCircle color="success" />
                                ) : (
                                    <IconButton onClick={() => uploadMatchesToDupr(item.id)}>
                                        <AccessTime color="warning" />
                                    </IconButton>
                                )
                            ) : null}
                        </>
                    ),
                    isVisible: true
                },

                {
                    id: 'team_a_id',
                    data: <CondensedTeamCard match={item} party={item?.team_a} />,
                    isVisible: true
                },
                {
                    id: 'score',
                    data: (
                        <>
                            {item?.match_games?.map((game, index) => {
                                const wonGame =
                                    parseInt(game?.game_scores.find((score) => score?.team_id === item?.team_a?.id)?.score) >
                                    parseInt(game?.game_scores.find((score) => score?.team_id === item?.team_b?.id)?.score);
                                const gameScores = game?.game_scores.find((score) => score?.team_id === item?.team_a?.id)?.score;
                                if (gameScores !== undefined) {
                                    return (
                                        <Grid
                                            mt={0.5}
                                            sx={{
                                                backgroundColor: wonGame ? 'green' : 'red',
                                                textAlign: 'center',
                                                paddingX: 1,
                                                paddingY: 0.25
                                            }}
                                        >
                                            <Typography variant="h5" color="white">
                                                {gameScores}
                                            </Typography>
                                        </Grid>
                                    );
                                }
                                return null;
                            })}
                        </>
                    ),
                    isVisible: true
                },
                {
                    id: 'vs',
                    data: 'VS',
                    isVisible: true
                },
                {
                    id: 'score',
                    data: (
                        <>
                            {item?.match_games?.map((game, index) => {
                                const wonGame =
                                    parseInt(game?.game_scores.find((score) => score?.team_id === item?.team_b?.id)?.score) >
                                    parseInt(game?.game_scores.find((score) => score?.team_id === item?.team_a?.id)?.score);
                                const gameScores = game?.game_scores.find((score) => score?.team_id === item?.team_b?.id)?.score;
                                if (gameScores !== undefined) {
                                    return (
                                        <Grid
                                            mt={0.5}
                                            sx={{
                                                backgroundColor: wonGame ? 'green' : 'red',
                                                textAlign: 'center',
                                                paddingX: 1,
                                                paddingY: 0.25
                                            }}
                                        >
                                            <Typography variant="h5" color="white">
                                                {gameScores}
                                            </Typography>
                                        </Grid>
                                    );
                                }
                                return null;
                            })}
                        </>
                    ),
                    isVisible: true
                },
                {
                    id: 'team_b_id',
                    data: <CondensedTeamCard match={item} party={item?.team_b} />,
                    isVisible: true
                },
                {
                    id: 'print',
                    data: (
                        <>
                            <IconButton
                                onClick={() => handlePrintClick(item.id)}
                                disabled={loading?.printMatches && printMatchId === item.id}
                            >
                                {loading?.printMatches && printMatchId === item.id ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <Print color={item?.printed ? 'success' : 'grey'} />
                                )}
                            </IconButton>
                        </>
                    ),
                    isVisible: true
                }
            ])
        );
    }, [eventMatches]);
    return (
        <Grid container>
            <CustomTable
                title="Event Matches"
                secondaryHeader={
                    <Stack direction="row" alignItems="center" gap={1}>
                        <IconButton onClick={() => getEventMatches(id)}>
                            <Refresh />
                        </IconButton>
                        <IconButton onClick={() => addMatch({ division_id: id })}>
                            <Add />
                        </IconButton>
                    </Stack>
                }
                headers={headers}
                data={data}
                selectable
                handleClick={(row) => {
                    setSelectedMatchId(row);
                    handleDrawerOpen();
                }}
                showSorting
                loading={loading.eventMatches}
                showActions
            />
            <MatchInputDrawer
                handleDrawerOpen={handleDrawerOpen}
                openSidebar={drawerOpen}
                selectedMatchId={selectedMatchId}
                eventId={division?.event?.league?.id}
                type={division?.event?.league?.event_type}
                divisionId={id}
                isAdmin
                allowTeamChange
                deleteAction={(item) => console.log('item', item.target)}
                showTeamDelete={false}
            />
        </Grid>
    );
};

export default FreeformMatches;
