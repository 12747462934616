import { Outlet } from 'react-router-dom';

// project imports
import Customization from '../Customization';
import UnAuthAppBar from 'layout/MainLayout/TopRightMenuButton/AppBar/AppBar';
import useAuth from 'hooks/useAuth';
import ProfileSection from 'layout/MainLayout/TopRightMenuButton/ProfileSection';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = ({ showAppBar = true }) => {
    const { user } = useAuth();
    return (
        <>
            {/* {showAppBar && <UnAuthAppBar />} */}
            {user ? (
                <>
                    {/* <NotificationSection /> */}
                    <ProfileSection />
                </>
            ) : (
                <UnAuthAppBar />
                // <UnauthenticatedSection />
            )}
            <Outlet />
            {/* <Customization /> */}
        </>
    );
};

export default MinimalLayout;
