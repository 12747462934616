import { Link as RouterLink, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Button, Container, Grid, Link, Typography } from '@mui/material';

// third party
import { motion } from 'framer-motion';

// project imports
// project imports
import Avatar from 'ui-component/extended/Avatar';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

// assets
import dashboard from 'assets/images/landing/dashboard.png';
import widget1 from 'assets/images/landing/widget-1.png';
import widget2 from 'assets/images/landing/widget-2.png';
import MainImage from 'assets/images/landing/pickleball_stadium_dark.png';
import LaunchVideo from 'assets/images/landing/stacked_launch_ae_1080x1920_v3.mp4';
import useAuth from 'hooks/useAuth';
import { ArrowForward, ArrowRight } from '@mui/icons-material';
// styles
const HeaderImage = styled('img')(({ theme }) => ({
    maxWidth: '100%',
    borderRadius: '20px',
    transform: 'scale(0.6)',
    transformOrigin: theme.direction === 'rtl' ? '100% 50%' : '0 50%',
    [theme.breakpoints.down('lg')]: {
        transform: 'scale(1.2)'
    }
}));

const HeaderAnimationImage = styled('img')({
    // maxWidth: '150%',
    width: '100%',
    height: '100%',
    filter: 'drop-shadow(0px 0px 50px rgb(33 150 243 / 30%))'
});

// ==============================|| LANDING - HEADER PAGE ||============================== //

const HeaderPage = () => {
    const theme = useTheme();
    const { user, createHostAccount, isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const handleStartHosting = () => {
        if (isLoggedIn && !user?.is_host) {
            createHostAccount();
        }
        navigate('/clubs/home');
    };

    return (
        <Container maxWidth="xl" sx={{ paddingY: 12 }}>
            {/* <Grid
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 40,
                    right: 40,
                    bottom: { xs: '50%', md: '25%' },
                    overflow: 'clip',
                    // backgroundColor: 'blue',

                    // width: 200,
                    // height: 200,
                    zIndex: 2,
                    borderRadius: 6
                }}
            >
                <motion.div
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                        type: 'spring',
                        stiffness: 150,
                        damping: 30,
                        delay: 0.2
                    }}
                >
                    <HeaderAnimationImage src={MainImage} alt="Berry" />
                </motion.div>
            </Grid> */}
            <Grid
                container
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                spacing={gridSpacing}
                sx={{ backgroundImage: MainImage }}
            >
                <Grid item xs={12} zIndex={3} textAlign="center" alignItems="center" display="flex" flex={1} flexGrow={1}>
                    <Grid
                        container
                        spacing={gridSpacing}
                        sx={{ [theme.breakpoints.down('lg')]: { pr: 0, textAlign: 'center' }, justifyContent: 'center' }}
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    color="grey[800]"
                                    sx={{
                                        fontSize: { xs: '2.25rem', sm: '3rem', md: '4rem' },
                                        fontWeight: 900,
                                        lineHeight: 1.4
                                    }}
                                >
                                    Pickleball events made
                                    <Box component="span" sx={{ ml: 2, color: theme.palette.secondary.main }}>
                                        easy
                                    </Box>
                                </Typography>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} md={8} container alignItems="center" justifyContent="center" sx={{ textAlign: 'center' }}>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 0.2
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    component="div"
                                    color="grey[800]"
                                    sx={{
                                        fontSize: { xs: '1rem', md: '1.125rem' },
                                        fontWeight: 400,
                                        lineHeight: 1.4,
                                        maxWidth: 440,
                                        textAlign: 'center'
                                    }}
                                >
                                    <Box component="span" sx={{ fontWeight: 900 }}>
                                        Run your event more efficiently{' '}
                                    </Box>
                                    than ever before on a platform designed for automation.
                                </Typography>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} sx={{ my: 3.25 }} justifyContent="center">
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 0.4
                                }}
                            >
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item>
                                        <AnimateButton>
                                            <Button
                                                // to="/dashboard/default"
                                                onClick={handleStartHosting}
                                                size="large"
                                                variant="contained"
                                                color="secondary"
                                                sx={{
                                                    paddingX: 8,
                                                    paddingY: 2,
                                                    borderRadius: 4,
                                                    color: 'white',
                                                    background: 'linear-gradient(90deg, #0DC92B 0%, #061F04 100%)'
                                                }}
                                            >
                                                <Typography variant="h4" color="white" fontSize={28} fontWeight={900} mr={2}>
                                                    Get Started. It&apos;s FREE
                                                </Typography>
                                                <ArrowForward color="white" />
                                            </Button>
                                        </AnimateButton>
                                        <Typography mt={2}>Free Forever. No Credit Card.</Typography>
                                    </Grid>
                                </Grid>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12}>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 0.6
                                }}
                            >
                                <Grid
                                    container
                                    alignItems="center"
                                    spacing={2}
                                    sx={{ [theme.breakpoints.down('lg')]: { display: 'inline-flex', width: 'auto' } }}
                                />
                            </motion.div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default HeaderPage;
