// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, useMediaQuery } from '@mui/material';

// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconMenu2 } from '@tabler/icons';
import useAuth from 'hooks/useAuth';
import LogoSection from 'layout/MainLayout/LogoSection';
import ProfileSection from 'layout/MainLayout/TopRightMenuButton/ProfileSection';
import { useLocation, useNavigate } from 'react-router-dom';
import HelpLogo from 'ui-component/HelpLogo';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { layout } = useConfig();
    const { user, isLoggedIn } = useAuth();
    const prevLocation = useLocation();
    const { setPrevRoute } = useAuth();
    const navigate = useNavigate();

    const onAuthPress = (link) => {
        console.log('location', prevLocation.pathname);
        setPrevRoute(prevLocation.pathname);
        navigate(link, { replace: true });
    };

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ flexGrow: 1 }}>
                    <HelpLogo />
                </Box>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {user ? (
                <>
                    {/* <UnAuthAppBar /> */}
                    {/* <NotificationSection /> */}
                    <ProfileSection />
                </>
            ) : (
                <Button onClick={() => onAuthPress('auth')}> Log In</Button>
            )}
        </>
    );
};

export default Header;
