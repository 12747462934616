// material-ui
import { useTheme } from '@mui/material/styles';
import { Container, Grid, Stack, Typography } from '@mui/material';

// project imports
import FadeInWhenVisible from './Animation';
import SubCard from 'ui-component/cards/SubCard';
import Avatar from 'ui-component/extended/Avatar';
import { gridSpacing } from 'store/constant';

// assets

import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import EventIcon from '@mui/icons-material/Event';
import { AttachMoney, ConfirmationNumber, ConfirmationNumberOutlined, EventOutlined, PeopleAltOutlined } from '@mui/icons-material';
// =============================|| LANDING - FEATURE PAGE ||============================= //

const Tools = () => {
    const theme = useTheme();
    const sectionContent = [
        {
            title: 'Event Ticketing',
            descriptor: 'Everything you need to sell tickets for in-person or online events',
            icon: <ConfirmationNumberOutlined color="grey.900" fontSize="large" />
        },
        {
            title: 'Player Management',
            descriptor: 'Easily add or remove players from events + manage a waitlist',
            icon: <PeopleAltOutlined color="grey.900" fontSize="large" />
        },
        {
            title: 'Event Management',
            descriptor: 'Automated tools generate pools and brackets for you using seeding algorithms for the best possible matchups',
            icon: <EventOutlined color="grey.900" fontSize="large" />
        },
        {
            title: 'Payments',
            descriptor: 'Easily collect and simplify event payments',
            icon: <AttachMoney color="grey.900" fontSize="large" />
        }
    ];
    const CopySection = ({ content }) => {
        const { title, descriptor, icon } = content;
        return (
            <Grid xs={12} md={3} p={4}>
                {icon}
                <Grid style={{ padding: 6 }}>
                    <Typography variant="h4" color="primary.main" style={{ borderBottom: '2px solid #a64f03', display: 'inline-block' }}>
                        {title}
                    </Typography>
                </Grid>
                <Typography variant="caption" color="grey.900">
                    {descriptor}
                </Typography>
            </Grid>
        );
    };

    return (
        <Container maxWidth="xl">
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Grid container spacing={2} sx={{ mb: 2, justifyContent: 'center' }}>
                        <Grid item xs={12} md={8}>
                            <Typography variant="h1" component="div" textAlign="center">
                                All the tools you need to host a tournament or league and grow your community
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ justifyContent: 'space-between', textAlign: 'center', flexDirection: 'row' }}>
                    <Grid container>
                        {sectionContent.map((content) => (
                            <CopySection content={content} />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Tools;
