/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
// import useAuth from '../hooks/useAuth';
import { API_URL as apiUrl } from 'config';

export const FacilityContext = createContext();

export function FacilityProvider({ children }) {
    //   const [user, setUser] = useState({
    //     name: "Jordan Thiel",
    //     email: "jordandt83@gmail.com",
    //   });
    const [facilityInfo, setFacilityInfo] = useState();
    const [facilities, setFacilities] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isAPLoading, setIsAPLoading] = useState(false);
    const [activePlayers, setActivePlayers] = useState([]);
    const [activeFacility, setActiveFacility] = useState();
    const [subscribedChannels, setSubscribedChannels] = useState([]);
    const [isUserCheckedIn, setIsUserCheckedIn] = useState(false);

    // const { token, user, addNewLog } = useAuth();
    const token = null;
    const user = null;
    const getUserFacilities = async () => {
        const user_id = user.id;
        try {
            const response = await axios.get(`/api/facility/user`, {
                params: {
                    user_id
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setFacilities(response.data);
            setIsLoading(false);
            return response.data;
        } catch (error) {
            console.log('Error fetching facilities', error);
            setIsLoading(false);
            throw error;
        }
    };
    const getActivePlayers = async (facility_id) => {
        setIsAPLoading(true);
        try {
            const response = await axios.get(`/api/facility/players`, {
                params: {
                    facility_id
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const playerAtFacility = response.data.active_players.filter((player) => player.user_id === user.id);
            setActivePlayers(response.data.active_players);
            setActiveFacility(playerAtFacility[0]);
            setIsAPLoading(false);
            return response.data;
        } catch (error) {
            console.log('Error fetching active players', error);
            setIsAPLoading(false);
            throw error;
        }
    };
    const [hoursPlayed, setHoursPlayed] = useState();
    const [hoursPlayedIsLoading, setHoursPlayedIsLoading] = useState(false);

    const getHoursPlayed = async (user_id) => {
        setHoursPlayedIsLoading(true);
        try {
            const response = await axios.get(`/api/hours-played`, {
                params: {
                    user_id
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            setHoursPlayed(response.data.total_time);
            setHoursPlayedIsLoading(false);

            return response.data;
        } catch (error) {
            console.log('Error fetching active players', error);
            setHoursPlayedIsLoading(false);
            throw error;
        }
    };
    const [thirtyDayActivity, setThirtyDayActivity] = useState();
    const getThirtyDayActivity = async (user_id, date, timeframe) => {
        try {
            const timezoneOffsetInMinutes = -new Date().getTimezoneOffset();

            // Convert the offset to hours and get the timezone name (e.g., "GMT+03:00")
            const timeZoneOffset = timezoneOffsetInMinutes / 60;
            console.log(user_id, date, timeframe, timeZoneOffset);

            const response = await axios.get(`/api/activity/30`, {
                params: {
                    user_id,
                    date,
                    timeframe,
                    timeZoneOffset
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            setThirtyDayActivity(response.data.activity_dates);

            return response.data;
        } catch (error) {
            console.log('Error fetching active players', error);
            // setHoursPlayedIsLoading(false);
            throw error;
        }
    };
    const getSession = async (id) => {
        try {
            const response = await axios.get(`/api/session/${id}`, {
                params: null,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return response.data.sessions;
        } catch (error) {
            console.log('Error fetching active players', error);
            // setHoursPlayedIsLoading(false);
            throw error;
        }
    };
    const [recentActivity, setRecentActivity] = useState([]);
    const [profileRecentActivity, setProfileRecentActivity] = useState();
    const getRecentActivity = async (user_id, page, num_per_page, profilePage = false, loadMore = false, refresh = false) => {
        if (!loadMore && !refresh) {
            setIsLoading(true);
        }
        try {
            const response = await axios.get(`/api/activity/recent`, {
                params: {
                    user_id,
                    page,
                    num_per_page
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (profilePage) {
                setProfileRecentActivity(response.data.data);
            } else if (loadMore) {
                setRecentActivity((prevState) => prevState.concat(response.data.data));
            } else {
                setRecentActivity(response.data.data);
            }
            setIsLoading(false);
            return response.data;
        } catch (error) {
            console.log('Error fetching active players', error);
            // setHoursPlayedIsLoading(false);
            throw error;
        }
    };
    const deleteSession = async (id) => {
        try {
            const response = await axios.delete(`/api/session/${id}`, {
                params: null,
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            getRecentActivity(user.id, 1, 3, true, false);

            setRecentActivity((prevState) => prevState.filter((session) => session.id !== id));

            return response.data.sessions;
        } catch (error) {
            console.log('Error fetching active players', error);
            // setHoursPlayedIsLoading(false);
            throw error;
        }
    };
    const [isUploading, setIsUploading] = useState(false);
    const editSession = async (id, params) => {
        setIsUploading(true);
        let responseResult = null;

        await axios
            .put(`/api/session/${id}`, params, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                getRecentActivity(user.id, 1, 3, true, false);
                // setRecentActivity((prevState) => {
                //   return prevState.map((session) => {
                //     // If the session ID matches the updated session, replace it with the updated session
                //     if (session.id === res.data.session.id) {
                //       return res.data.session;
                //     }
                //     // Otherwise, keep the original session
                //     return session;
                //   });
                // });

                responseResult = res.data; // setting the result to the response data

                setIsUploading(false);
            })
            .catch((error) => {
                setIsUploading(false);
                responseResult = { error: error.message || 'An error occurred.' };
            });

        return responseResult;
    };
    const createSession = async (params) => {
        setIsUploading(true);
        let responseResult = null;

        await axios
            .post(`/api/session`, params, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                // getRecentActivity(user.id, 1, 3, true, false);

                setRecentActivity((prevState) => [res.data.session, ...prevState]);

                responseResult = res.data; // setting the result to the response data

                setIsUploading(false);
            })
            .catch((error) => {
                setIsUploading(false);
                responseResult = { error: error.message || 'An error occurred.' };
            });

        return responseResult;
    };

    const addActivePlayer = async (facility_id) => {
        setIsLoading(true);
        setIsUserCheckedIn(true);
        try {
            const user_id = user.id;
            const data = { facility_id, user_id };
            const response = await axios.post(`/api/facility/players`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data.data) {
                setActiveFacility(response.data.data);

                setActivePlayers((prevState) => [...prevState, response.data.data]);
            }
            // getActivePlayers(facility_id);
            setIsLoading(false);

            return response.data;
        } catch (error) {
            console.log('Error adding active players', error);

            setIsLoading(false);

            throw error;
        }
    };
    const departActivePlayer = async (id, user_id, facility_id) => {
        setIsLoading(true);
        setIsUserCheckedIn(false);

        try {
            const data = { id, user_id, facility_id, send_notification: true };
            const response = await axios.put(`/api/facility/players`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // getActivePlayers(facility_id);
            // setActivePlayers(
            // addNewLog(user.id, 'facility_departure', 'Successfully departed', null)
            const newActivePlayers = activePlayers.filter((player) => player.user_id !== user.id);
            setActivePlayers(newActivePlayers);

            // );

            setActiveFacility();
            setIsLoading(false);
            return response.data;
        } catch (error) {
            console.log('Error fetching active players', error);
            // addNewLog(
            //   user.id,
            //   'facility_exit_error',
            //   'departActivePlayer error happened',
            //   JSON.stringify({ error: error })
            // );

            setIsLoading(false);
            throw error;
        }
    };

    // useEffect(() => {
    //   getUserFacilities();
    // }, []);

    const searchFacilities = async (region, location = null) => {
        try {
            const response = await axios.get(`/api/facility/search/location`, {
                params: {
                    search_term: location,
                    region
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const srchResults = response.data;
            setSearchResults(srchResults.facilities);
            return srchResults.facilities;
        } catch (error) {
            console.error(error);
        }
    };
    // useEffect(() => {
    //   searchFacilities('Cedar Park, TX').then((res) => {
    //     console.log('SEARCHED CP', res.facilities);
    //   });
    // }, []);
    const searchFacilitiesByName = async (name) => {
        try {
            const response = await axios.get(`/api/facility/search`, {
                params: {
                    name,
                    user_id: user.id
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const addFacility = async (params) => {
        // setIsLoading(true);
        const formData = new FormData();
        if (params.image) {
            const fileUri = params.image.uri;
            // if (Platform.OS === 'android' && !fileUri.startsWith('file://')) {
            //     fileUri = `file:/${fileUri}`;
            // }

            const file = {
                uri: fileUri,
                name: params.image.fileName || Date.now().toString(),
                type: params.image.type
            };

            formData.append('images[]', file);
        }
        // Append other parameters from params
        for (const key in params) {
            if (params.hasOwnProperty(key) && key !== 'image') {
                formData.append(key, params[key]);
            }
        }

        try {
            const response = await axios.post(`/api/facility`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // if (response.data.data) {
            // setActiveFacility(response.data.data);
            // setActivePlayers((prevState) => [...prevState, response.data.data]);
            // }
            // getActivePlayers(facility_id);
            setIsLoading(false);

            // return response.data;
        } catch (error) {
            console.log('Error creating court', error.response);

            setIsLoading(false);

            throw error;
        }
    };

    return (
        <FacilityContext.Provider
            value={{
                isLoading,
                getUserFacilities,
                facilities,
                facilityInfo,
                error,
                searchFacilities,
                searchResults,
                getActivePlayers,
                activePlayers,
                setActivePlayers,
                addActivePlayer,
                departActivePlayer,
                activeFacility,
                setActiveFacility,
                isAPLoading,
                subscribedChannels,
                setSubscribedChannels,
                isUserCheckedIn,
                getHoursPlayed,
                hoursPlayed,
                hoursPlayedIsLoading,
                getThirtyDayActivity,
                thirtyDayActivity,
                getRecentActivity,
                searchFacilitiesByName,
                addFacility,
                getSession,
                deleteSession,
                recentActivity,
                setRecentActivity,
                profileRecentActivity,
                editSession,
                isUploading,
                setIsUploading,
                createSession
            }}
        >
            {children}
        </FacilityContext.Provider>
    );
}
