import { Grid, Link, Stack, Typography } from '@mui/material';
// import { Client } from '@notionhq/client';
import { useEffect, useState } from 'react';
import { NotionRenderer } from 'react-notion';
import 'react-notion/src/styles.css';
// import 'prismjs/themes/prism-tomorrow.css';

import axios from 'axios';
import useHelpCenter from 'hooks/useHelpCenter';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';

const HelpArticle = () => {
    const { slug } = useParams();
    // const [recordMap, setRecordMap] = useState();
    // const [article, setArticle] = useState();
    const { getHelpArticle, article, recordMap, topic } = useHelpCenter();
    const navigate = useNavigate();

    // const notion = new Client({ auth: 'secret_pIZf9V4pbZq7Aavt1btWGBuhQkZ7Oed4V33mdjJEo8X' });

    // const getNotionPage = async () => {
    //     const response = await axios.get('/api/help/article/');

    //     // const data = await fetch('https://notion-api.splitbee.io/v1/page/d088e36aa1b34cd5a8ea07233a63f4c3').then((res) => res.json());
    //     setRecordMap(response.data.blocks);
    //     setArticle(response.data.data);
    // };

    useEffect(() => {
        getHelpArticle(slug);
    }, [slug]);

    return (
        <Grid>
            <Typography mb={2}>
                <Stack direction="row" alignItems="center">
                    <Link onClick={() => navigate(`/help`)} underline="hover" sx={{ ':hover': { cursor: 'pointer' } }}>
                        Help Center
                    </Link>
                    <ChevronLeft color="primary" fontSize="small" />
                    <Link
                        onClick={() => navigate(`/help/topics/${topic?.slug}/${topic?.id}`)}
                        underline="hover"
                        sx={{ ':hover': { cursor: 'pointer' } }}
                        ml={1}
                    >
                        {topic?.name}
                    </Link>
                </Stack>
            </Typography>
            <Typography variant="h1"> {article?.title}</Typography>
            {recordMap && <NotionRenderer blockMap={recordMap} />}
        </Grid>
    );
};
export default HelpArticle;
