/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable no-lonely-if */
import React, { useState, createContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { API_URL as apiUrl } from 'config';
import useGroups from '../hooks/useGroups';
import useAuth from 'hooks/useAuth';

export const PostContext = createContext();

export function PostProvider({ children }) {
    //   const [user, setUser] = useState({
    //     name: "Jordan Thiel",
    //     email: "jordandt83@gmail.com",
    //   });
    const { getUserGroups, noGroups, userGroups, groups } = useGroups();
    const [posts, setPosts] = useState([]);
    const [comments, setComments] = useState();
    const [likes, setLikes] = useState([]);
    const [feed, setFeed] = useState([]);
    const [groupFeed, setGroupFeed] = useState();
    const [userFeed, setUserFeed] = useState();
    const [groupId, setGroupId] = useState();
    const [userId, setUserId] = useState();
    const [error, setError] = useState();
    const [refreshing, setRefreshing] = useState(false);
    const [pusherChannels, setPusherChannels] = useState([]);
    const subscribedChannelsRef = useRef([]);
    const [postLikeUsers, setPostLikeUsers] = useState([]);

    const [postsIsLoading, setPostsIsLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { token, user } = useAuth();

    const addNewPostComment = async (user_id, post_id, comment, tagged_users) => {
        const data = {
            user_id,
            post_id,
            comment,
            tagged_users
        };
        await axios
            .post(`/api/comment`, data, {
                // headers: {
                //     Accept: 'application/json',
                //     'Content-Type': 'application/json',
                //     Authorization: `Bearer ${token}`
                // }
            })
            .catch((er) => console.log('ERROR Comment', er));
    };

    const handleAddComment = (postId, comment, tagged_users) => {
        setFeed((prevState) =>
            prevState?.map((item) => {
                if (item.id === postId) {
                    // Update comments array for the matching post
                    return {
                        ...item,
                        comments: [...item.comments, comment]
                    };
                }
                return item;
            })
        );
        setGroupFeed((prevState) =>
            prevState?.map((item) => {
                if (item.id === postId) {
                    // Update comments array for the matching post
                    return {
                        ...item,
                        comments: [...item.comments, comment]
                    };
                }
                return item;
            })
        );
        setUserFeed((prevState) =>
            prevState?.map((item) => {
                if (item.id === postId) {
                    // Update comments array for the matching post
                    return {
                        ...item,
                        comments: [...item.comments, comment]
                    };
                }
                return item;
            })
        );

        addNewPostComment(user.id, postId, comment.comment, tagged_users);
    };
    const getPost = async (id) => {
        try {
            const response = await axios.get(`/api/post/${id}`, {
                params: null,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });

            return response.data.post;
        } catch (error) {
            console.log('Error fetching active players', error);
            // setHoursPlayedIsLoading(false);
            throw error;
        }
    };
    const handleAddLike = async (post_id) => {
        const user_id = user.id;
        const tempLikeId = Math.random().toString(36).substr(2, 9); // Create a unique temporary ID

        // Create the new like with the temporary ID
        const newLike = {
            id: tempLikeId,
            post_id,
            user_id,
            created_At: new Date().toISOString()
        };

        // Optimistically add the new like to the UI
        // setLikes((prevLikes) => [...prevLikes, newLike]);

        setFeed((prevState) =>
            prevState?.map((item) => {
                if (item.id === post_id) {
                    // Update comments array for the matching post
                    return {
                        ...item,
                        likes: [...item.likes, newLike]
                    };
                }
                return item;
            })
        );
        setGroupFeed((prevState) =>
            prevState?.map((item) => {
                if (item.id === post_id) {
                    // Update comments array for the matching post
                    return {
                        ...item,
                        likes: [...item.likes, newLike]
                    };
                }
                return item;
            })
        );
        setUserFeed((prevState) =>
            prevState?.map((item) => {
                if (item.id === post_id) {
                    // Update comments array for the matching post
                    return {
                        ...item,
                        likes: [...item.likes, newLike]
                    };
                }
                return item;
            })
        );

        try {
            // Send the like data to the server
            await axios.post(
                `/api/post/like`,
                {
                    user_id,
                    post_id
                }
                // {
                //     headers: {
                //         Accept: 'application/json',
                //         'Content-Type': 'application/json',
                //         Authorization: `Bearer ${token}`
                //     }
                // }
            );

            // Replace the temporary like with the actual like from the response
            // setLikes((prevLikes) =>
            //     prevLikes.map((like) =>
            //         // console.log('ID', response);
            //         like.id === tempLikeId ? { ...like, id: response.data.id } : like
            //     )
            // );
        } catch (error) {
            // If there's an error, remove the optimistic like
            console.error('Failed to add like: ', error);
            // setLikes((prevLikes) => prevLikes.filter((like) => like.id !== tempLikeId));
        }
    };

    const handleRemoveLike = async (post_id) => {
        const user_id = user.id;

        setFeed((prevState) =>
            prevState?.map((item) => {
                if (item.id === post_id) {
                    const newLikes = item.likes.filter((like) => like.user_id !== user_id);
                    // Update comments array for the matching post
                    return {
                        ...item,
                        likes: newLikes
                    };
                }
                return item;
            })
        );
        setGroupFeed((prevState) =>
            prevState?.map((item) => {
                if (item.id === post_id) {
                    const newLikes = item.likes.filter((like) => like.user_id !== user_id);
                    // Update comments array for the matching post
                    return {
                        ...item,
                        likes: newLikes
                    };
                }
                return item;
            })
        );
        const params = {
            user_id,
            post_id
        };
        await axios.delete(`/api/post/like`, {
            params
            // headers: {
            //     Accept: 'application/json',
            //     'Content-Type': 'application/json',
            //     Authorization: `Bearer ${token}`
            // }
        });
    };
    const flagPost = async (post_id) => {
        const user_id = user.id;

        const data = {
            user_id,
            post_id
        };
        await axios.post(`/api/posts/report`, data, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        // console.log({ data: feed });
        const newFeed = feed.data.filter((item) => item.id !== post_id);

        setFeed({ data: newFeed });
    };

    const deletePost = async (post_id) => {
        const data = {
            post_id
        };
        await axios.post(`/api/posts/delete`, data).then(() => {
            const newFeed = feed.filter((item) => item.id !== post_id);
            setFeed(newFeed);

            if (groupFeed && groupFeed.length > 0) {
                const newGroupFeed = groupFeed.filter((item) => item.id !== post_id);
                setGroupFeed(newGroupFeed);
            }
        });
        // console.log({ data: feed });
    };

    const getGroupPosts = async (group_id) => {
        setPostsIsLoading(true);
        const response = await axios.get(`/api/group/posts`, {
            params: {
                group_id
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        setPosts(response.data);
        setPostsIsLoading(false);
        return response.data;
    };
    const [postLikeUsersLoading, setPostLikeUsersLoading] = useState(false);
    const getPostLikeUsers = async (post_id) => {
        setPostLikeUsersLoading(true);
        const response = await axios.get(`/api/posts/likes/users`, {
            params: {
                post_id
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        setPostLikeUsers(response.data.data);
        setPostLikeUsersLoading(false);
        return response.data;
    };
    const getFeed = async (args) => {
        const { user_id, page, group_id, refresh, loadMore, onlyUser } = args;
        const params = {
            user_id,
            page,
            group_id,
            user_only: onlyUser || undefined,
            viewing_user: user.id
        };

        if (!loadMore && !refresh) {
            setPostsIsLoading(true);
        }

        const { data } = await axios.get(`/api/posts/feed`, {
            params
            // headers: {
            //     'Content-Type': 'application/json',
            //     Authorization: `Bearer ${token}`
            // }
        });

        if (refresh === true) {
            if (group_id) {
                setGroupFeed(data.data);
                // setLikes((prevState) => {
                //     const newLikes = data.data.flatMap((post) => post.likes);

                //     const uniqueNewLikes = newLikes.filter((newLike) => !prevState.find((existingLike) => existingLike.id === newLike.id));

                //     return [...prevState, ...uniqueNewLikes];
                // });

                // setComments((prevState) => {
                //     const newComments = data.data.flatMap((post) => post.comments);

                //     const uniqueNewComments = newComments.filter(
                //         (newComment) => !prevState.find((existingComment) => existingComment.id === newComment.id)
                //     );

                //     return [...prevState, ...uniqueNewComments];
                // });

                // const newLikes = data.data.flatMap((post) => post.likes);
                // const newComments = data.data.flatMap((post) => post.comments);

                // setComments(newComments);
                // setLikes(newLikes);
            } else {
                if (onlyUser) {
                    setUserFeed(data.data);
                } else {
                    setFeed(data.data);
                    setLikes(data.data.flatMap((post) => post.likes || []));
                    setComments(data.data.flatMap((post) => post.comments || []));
                }
                // setUserFeed(onlyUser ? data : setFeed(data));
            }
        } else if (data.message !== 'No more pages') {
            if (feed && !group_id && !onlyUser) {
                setFeed((prevFeed) => ({
                    ...prevFeed,
                    data: [...prevFeed.data, ...data.data]
                }));
            } else if (!group_id && !onlyUser) {
                setFeed(data.data);
            }

            if (group_id && groupFeed && loadMore) {
                if (group_id === groupId) {
                    setGroupFeed((prevGroupFeed) => ({
                        ...prevGroupFeed,
                        data: [...prevGroupFeed.data, ...data.data]
                    }));
                    setLikes((prevState) => {
                        const newLikes = data.data.flatMap((post) => post.likes);

                        const uniqueNewLikes = newLikes.filter(
                            (newLike) => !prevState.find((existingLike) => existingLike.id === newLike.id)
                        );

                        return [...prevState, ...uniqueNewLikes];
                    });

                    setComments((prevState) => {
                        const newComments = data.data.flatMap((post) => post.comments);

                        const uniqueNewComments = newComments.filter(
                            (newComment) => !prevState.find((existingComment) => existingComment.id === newComment.id)
                        );

                        return [...prevState, ...uniqueNewComments];
                    });
                } else {
                    setGroupId(group_id);
                    setGroupFeed(data);
                    setLikes((prevState) => {
                        const newLikes = data.data.flatMap((post) => post.likes);

                        const uniqueNewLikes = newLikes.filter(
                            (newLike) => !prevState.find((existingLike) => existingLike.id === newLike.id)
                        );

                        return [...prevState, ...uniqueNewLikes];
                    });

                    setComments((prevState) => {
                        const newComments = data.data.flatMap((post) => post.comments);

                        const uniqueNewComments = newComments.filter(
                            (newComment) => !prevState.find((existingComment) => existingComment.id === newComment.id)
                        );

                        return [...prevState, ...uniqueNewComments];
                    });
                }
            } else if (group_id) {
                setGroupFeed(data);
                // setLikes((prevState) => [...prevState, ...data.data.flatMap((post) => post.likes)]);
                setLikes((prevState) => {
                    const newLikes = data.data.flatMap((post) => post.likes);

                    const uniqueNewLikes = newLikes.filter((newLike) => !prevState.find((existingLike) => existingLike.id === newLike.id));

                    return [...prevState, ...uniqueNewLikes];
                });

                setComments((prevState) => {
                    const newComments = data.data.flatMap((post) => post.comments);

                    const uniqueNewComments = newComments.filter(
                        (newComment) => !prevState.find((existingComment) => existingComment.id === newComment.id)
                    );

                    return [...prevState, ...uniqueNewComments];
                });
            }

            if (onlyUser && userFeed) {
                if (user_id === userId) {
                    setUserFeed((prevUserFeed) => ({
                        ...prevUserFeed,
                        data: [...prevUserFeed.data, ...data.data]
                    }));
                } else {
                    setUserId(user_id);
                    setUserFeed(data);
                }
            } else if (onlyUser) {
                setUserFeed(data);
            }
        }

        setPostsIsLoading(false);
        return data;
    };
    useEffect(() => {
        if (user?.id && groups) {
            getFeed({ user_id: user.id, page: 1, refresh: true }).then((res) => {
                setComments(res.data.flatMap((post) => post.comments || []));
                setLikes(res.data.flatMap((post) => post.likes || []));
            });
        }
    }, [noGroups, groups, user?.id]);
    // }, [noGroups, user && user.id]);

    const addNewGroupPost = async (params) => {
        setIsLoading(true);
        const { user_id, group_id, post_text, images, question, options, hasPoll = false, send_email } = params;

        const formData = new FormData();
        if (images) {
            for (const image of images) {
                let fileUri = image.uri;
                if (Platform.OS === 'android' && !fileUri.startsWith('file://')) {
                    fileUri = `file:/${fileUri}`;
                }

                const file = {
                    uri: fileUri,
                    name: image.fileName || Date.now().toString(),
                    type: image.type
                };

                formData.append('images[]', file);
            }
        }
        formData.append('group_id', group_id);
        formData.append('user_id', user_id);
        formData.append('post_text', post_text);
        formData.append('send_email', send_email === true ? 1 : 0);
        if (hasPoll) {
            const textArray = options.map((item) => item.option_text);

            formData.append('has_poll', hasPoll);
            formData.append('question', question);
            for (let i = 0; i < textArray.length; i++) {
                formData.append('options[]', textArray[i]);
            }
        }

        await axios
            .post(`/api/group/posts`, formData)
            .then((res) => {
                getFeed({ user_id: user.id, page: 1, group_id, refresh: true }),
                    getFeed({ user_id: user.id, page: 1, group_id: null, refresh: true }),
                    setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                throw err;
            });

        // const data = await response.json();
        // console.log('DATA', data);
        // await Promise.all([
        //   getFeed({ user_id: user.id, page: 1, group_id, refresh: true }),
        //   getFeed({ user_id: user.id, page: 1, group_id: null, refresh: true }),
        // ]);
        // } catch (err) {

        // }
    };

    const [pollDetails, setPollDetails] = useState();
    const getPollDetails = async (id) => {
        setIsLoading(true);
        await axios
            .get(`/api/poll/${id}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setPollDetails(res.data.data);
            })
            .catch((er) => {
                setIsLoading(false);
                console.log('ERROR Poll Response', er);
            });
    };
    const addNewPollResponse = async (poll_id, selected_option_id, user_id) => {
        const data = {
            user_id,
            poll_id,
            selected_option_id
        };
        await axios.post(`/api/poll/response`, data).catch((er) => console.log('ERROR Poll Response', er));
    };
    const deletePollResponse = async (poll_id, selected_option_id, user_id) => {
        const data = {
            user_id,
            poll_id,
            selected_option_id
        };
        await axios
            .delete(`/api/poll/response`, {
                params: data
            })
            .catch((er) => console.log('ERROR Poll Response', er));
    };
    const onRefresh = (userId, page, group_id) => {
        setRefreshing(true);
        const refresh = true;
        const response = getFeed({ user_id: userId, page, group_id, refresh }).then((res) => {
            // setComments(res.data.flatMap((post) => post.comments || []));
            // setLikes(res.data.flatMap((post) => post.likes || []));
            setRefreshing(false);
        });
        setTimeout(() => setRefreshing(false), 4000);

        return response;
    };

    const getAllBlogs = async () => {
        setIsLoading(true);
        const response = await axios
            .get(`/api/blog`)
            .then((res) => {
                setIsLoading(false);
                return res.data.blog;
            })
            .catch((er) => {
                setIsLoading(false);
                console.log('ERROR Blog Response', er);
            });
        return response;
    };
    const getBlog = async (slug) => {
        setIsLoading(true);
        const response = await axios
            .get(`/api/blog/${slug}`)
            .then((res) => {
                setIsLoading(false);
                return res.data.blog;
            })
            .catch((er) => {
                setIsLoading(false);
                console.log('ERROR Blog Response', er);
            });
        return response;
    };

    return (
        <PostContext.Provider
            value={{
                postsIsLoading,
                isLoading,
                getGroupPosts,
                addNewGroupPost,
                addNewPostComment,
                feed,
                setFeed,
                groupFeed,
                userFeed,
                setGroupFeed,
                getFeed,
                comments,
                setComments,
                handleAddComment,
                handleAddLike,
                handleRemoveLike,
                likes,
                setLikes,
                posts,
                error,
                onRefresh,
                refreshing,
                flagPost,
                pusherChannels,
                setPusherChannels,
                subscribedChannelsRef,
                deletePost,
                getPostLikeUsers,
                postLikeUsers,
                postLikeUsersLoading,
                addNewPollResponse,
                deletePollResponse,
                getPollDetails,
                pollDetails,
                setPollDetails,
                getPost,
                getAllBlogs,
                getBlog
            }}
        >
            {children}
        </PostContext.Provider>
    );
}
