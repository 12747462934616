/* eslint-disable array-callback-return */
/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    CardContent,
    Chip,
    Divider,
    Drawer,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    useMediaQuery,
    TextField,
    FormControl,
    Typography,
    Box,
    IconButton,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableBody,
    Avatar
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Formik, Form } from 'formik';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import useConfig from 'hooks/useConfig';

import MainCard from 'ui-component/cards/MainCard';
import { appDrawerWidth as drawerWidth, gridSpacing } from 'store/constant';

// assets
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import LabelImportantTwoToneIcon from '@mui/icons-material/LabelImportantTwoTone';
import LabelTwoToneIcon from '@mui/icons-material/LabelTwoTone';
import NewReleasesTwoToneIcon from '@mui/icons-material/NewReleasesTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import { PersonOutlineOutlined } from '@mui/icons-material';
import useLeagues from 'hooks/useLeagues';

// ==============================|| MAIL DRAWER ||============================== //

const MatchInputDrawer = ({ handleDrawerOpen, openSidebar, selectedMatchId, tournamentId }) => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const { getMatch, updateGameScore, addGameScore } = useLeagues();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('xl'));
    const [match, setMatch] = useState();
    const [games, setGames] = useState([]);
    const [error, setError] = useState()

    useEffect(() => {
        if (selectedMatchId && tournamentId) {
            getMatch(selectedMatchId, tournamentId).then((res) => setMatch(res)).catch((err) => setError('No Match Found'));
        }
        if (openSidebar) {
            setError()
        }
    }, [selectedMatchId, openSidebar]);

    useEffect(() => {
        setGames(
            match?.match_games.map((game) => ({
                ...game,
                autoCalculated: false, // add this line
                game_scores:
                    game.game_scores.length > 0
                        ? game.game_scores
                        : [
                            { team_id: match.team_a_id, score: '', game_id: game.id },
                            { team_id: match.team_b_id, score: '', game_id: game.id }
                        ]
            }))
        );
    }, [match]);


    const updateScore = (gameId, teamId, newScore, performCalculation) => {
        setGames(
            games.map((game) => {
                if (game.id === gameId) {
                    let updatedGameScores = game.game_scores.map((score) => {
                        if (score.team_id === teamId) {
                            return { ...score, score: newScore };
                        }
                        return score;
                    });

                    // Perform auto-calculation on onBlur
                    if (performCalculation && !game.autoCalculated) {
                        if (newScore < Number(game.play_until) - 1) {
                            updatedGameScores = updatedGameScores.map((score) => {
                                if (score.team_id !== teamId) {
                                    return { ...score, score: game.play_until };
                                }
                                return score;
                            })
                        } else if (newScore >= Number(game.play_until - 1)) {
                            updatedGameScores = updatedGameScores.map((score) => {
                                if (score.team_id !== teamId) {
                                    return { ...score, score: Number(newScore) + 2 };
                                }
                                return score;
                            })
                        }

                        return {
                            ...game,
                            game_scores: updatedGameScores,
                            autoCalculated: true
                        };
                    }

                    return {
                        ...game,
                        game_scores: updatedGameScores
                    };
                }
                return game;
            })
        );
    };

    const handleScoreChange = (gameId, teamId, newScore) => {
        updateScore(gameId, teamId, newScore, false);
    };

    // New function to be called on onBlur
    const handleScoreBlur = (gameId, teamId, newScore) => {
        updateScore(gameId, teamId, newScore, true);
    };

    const submitScores = () => {
        console.log('submitting scores')
        games.map((game) => {
            game.game_scores.map((score) => {
                const params = { team_id: score.team_id, score: score.score, game_id: score.game_id };
                console.log('params', params);
                if (score.id) {
                    updateGameScore(score.id, { score: score.score });
                } else {
                    addGameScore(params);
                }
            });
        });
        handleDrawerOpen()
    };

    return (
        <Drawer
            sx={{
                ml: openSidebar ? 3 : 0,
                flexShrink: 0,
                zIndex: 1200,
                overflowX: 'hidden',
                width: { xs: 320, md: 450 },
                '& .MuiDrawer-paper': {
                    height: '100vh',
                    width: { xs: 320, md: 450 },
                    position: 'fixed',
                    border: 'none',
                    borderRadius: '0px'
                }
            }}
            variant="temporary"
            anchor="right"
            open={openSidebar}
            ModalProps={{ keepMounted: true }}
            onClose={handleDrawerOpen}
        >
            {openSidebar && (
                <MainCard
                    sx={{
                        bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50'
                    }}
                    border={!matchDownSM}
                    content={false}
                >

                    <CardContent sx={{ height: matchDownSM ? '100vh' : 'auto' }}>
                        <Grid container spacing={gridSpacing}>
                            {!error ?
                                <Grid item xs={12}>
                                    <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h3"> {`Match # ${selectedMatchId}`} </Typography>
                                        <Box>
                                            <IconButton size="small" onClick={() => handleDrawerOpen()}>
                                                <CancelIcon />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                    <Grid>
                                        <Grid mt={2}>
                                            <Typography variant="h5"> Team 1</Typography>
                                            {match?.team_a?.players.map((player) => (
                                                <Grid mt={1} container sx={{ padding: 1, backgroundColor: '#E6E7EC', borderRadius: 3 }}>
                                                    <Avatar src={player.user.user_attributes.profile_picture_url} />
                                                    <Grid ml={2}>
                                                        <Typography> {player.user.name}</Typography>
                                                        <Typography> {player.user.user_attributes.rating}</Typography>
                                                    </Grid>
                                                </Grid>
                                            ))}

                                            {/* <Grid mt={1} container sx={{ padding: 1, backgroundColor: '#E6E7EC', borderRadius: 3 }}>
                                            <Avatar />
                                            <Grid ml={2}>
                                                <Typography> Jordan Thiel</Typography>
                                                <Typography> 3.37</Typography>
                                            </Grid>
                                        </Grid> */}
                                        </Grid>
                                        <Grid mt={2}>
                                            <Typography variant="h5"> Team 2</Typography>
                                            {match?.team_b?.players.map((player) => (
                                                <Grid mt={1} container sx={{ padding: 1, backgroundColor: '#E6E7EC', borderRadius: 3 }}>
                                                    <Avatar src={player.user.user_attributes.profile_picture_url} />
                                                    <Grid ml={2}>
                                                        <Typography> {player.user.name}</Typography>
                                                        <Typography> {player.user.user_attributes.rating}</Typography>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid mt={2}>
                                        <Typography variant="h5"> Scores</Typography>
                                        <Grid>
                                            <Grid container justifyContent="space-evenly" mb={2} mt={2}>
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography variant="h5"> Team 1</Typography>
                                                </Grid>
                                                <Grid item xs={4} />
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography variant="h5"> Team 2</Typography>
                                                </Grid>
                                            </Grid>
                                            {games?.map((game, index) => (
                                                <Grid container justifyContent="space-evenly" alignItems="center" mt={2}>
                                                    <Grid item xs={2}>
                                                        <TextField
                                                            value={game.game_scores[0]?.score?.toString()}
                                                            onChange={(event) =>
                                                                handleScoreChange(game.id, game.game_scores[0]?.team_id, event.target.value)
                                                            }
                                                            onBlur={(event) =>
                                                                handleScoreBlur(game.id, game.game_scores[0]?.team_id, event.target.value)
                                                            }
                                                            inputProps={{ min: 0, style: { textAlign: 'center' } }} // the change is here
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} textAlign="center">
                                                        <Typography variant="h5"> Game {index + 1}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} textAlign="center">
                                                        <TextField
                                                            value={game.game_scores[1]?.score?.toString()}
                                                            onChange={(event) =>
                                                                handleScoreChange(game.id, game.game_scores[1]?.team_id, event.target.value)
                                                            }
                                                            onBlur={(event) =>
                                                                handleScoreBlur(game.id, game.game_scores[1]?.team_id, event.target.value)
                                                            }
                                                            inputProps={{ min: 0, style: { textAlign: 'center' } }} // the change is here
                                                        />
                                                    </Grid>
                                                </Grid>
                                            ))}

                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} mt={4}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            type="submit"
                                            onClick={submitScores}
                                            startIcon={<PersonOutlineIcon stroke={1.5} size="20px" />}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                                :
                                // <Grid>
                                <Grid item xs={12}>

                                    <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h3"> {`Match # ${selectedMatchId}`} </Typography>
                                        <Box>
                                            <IconButton size="small" onClick={() => handleDrawerOpen()}>
                                                <CancelIcon />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                    <Grid item mt={4}>
                                        <Typography color="error"> Match not found for this tournament</Typography>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </CardContent>
                </MainCard>
            )}
        </Drawer>
    );
};

MatchInputDrawer.propTypes = {
    handleDrawerOpen: PropTypes.func,
    openSidebar: PropTypes.bool
};

export default MatchInputDrawer;
