import { useContext } from 'react';

// auth provider
import { FormContext } from 'contexts/FormsContext';

// ==============================|| AUTH HOOKS ||============================== //

const useForms = () => {
    const context = useContext(FormContext);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};

export default useForms;
