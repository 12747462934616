/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
import React, { useState, createContext, useEffect, useRef, useReducer } from 'react';
import axios from 'axios';

import { API_URL, DUPR_KEY } from 'config';

const initialState = {
    userTransactions: [],
    playerTickets: [],
    playerReservations: [],
    playerTicket: {},
    playerReservation: {},
    isLoading: { playerTickets: false, playerReservation: false }
};

function usersReducer(state, action) {
    switch (action.type) {
        case 'SET_STATE':
            return { ...state, [action.payload.key]: action.payload.value };
        default:
            return state;
    }
}

export const UsersContext = createContext();

export function UsersProvider({ children }) {
    const [state, dispatch] = useReducer(usersReducer, initialState);
    const setValue = (key, value) => {
        dispatch({ type: 'SET_STATE', payload: { key, value } });
    };

    const [badgeCount, setBadgeCount] = useState(0);

    const [user, setUser] = useState(null);
    const [error, setError] = useState();
    const [duprToken, setDuprToken] = useState();
    const [recommendedFriends, setRecommendedFriends] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    const validateEmail = async (email) => {
        setIsLoading(true);

        try {
            if (email) {
                const response = await axios.post(`/api/validate_email`, {
                    email
                });

                if (response.status === 200) {
                    setError();
                    setIsLoading(false);

                    return response.status;
                }
            }
        } catch (error) {
            setError('We could not find that email address');
            return error.response ? error.response.status : 500;
        }
    };
    const validateCode = async (email, code) => {
        setIsLoading(true);
        try {
            if (email && code) {
                const response = await axios.post(`/api/validate_code`, {
                    email,
                    code
                });

                if (response.status === 200) {
                    setError();
                    setIsLoading(false);

                    setIsLoading(false);
                    return response.status;
                }
            }
        } catch (error) {
            setIsLoading(false);

            setError('We could not validate that code');
            return error.response ? error.response.status : 500;
        }
    };
    const resetPassword = async (email, password) => {
        setIsLoading(true);
        try {
            if (email && password) {
                const response = await axios.post(`/api/reset_password`, {
                    email,
                    password
                });

                if (response.status === 200) {
                    setError();
                    setIsLoading(false);

                    return response.status;
                }
            }
        } catch (error) {
            setError('We co uld not validate that code');
            setIsLoading(false);

            return error.response ? error.response.status : 500;
        }
    };
    const updateProfile = async (name, rating, email, location) => {
        setIsLoading(true);
        const data = { name, rating, email, location };
        try {
            const response = await axios.put(`/api/user/profile/${user.id}`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setIsLoading(false);
            setUser(response.data.data);

            return response.status;
        } catch (error) {
            setIsLoading(false);

            return error.response ? error.response.status : 500;
        }
    };

    const getUserInfo = async (res) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`/api/user`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${res}`
                }
            });

            // save("token", response.data.token);
            setUser(response.data.user);
            setIsAuthenticated(true);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setIsAuthenticated(false);
        }
    };
    const searchUsers = async (search_input, group_id = null) => {
        const user_id = user.id;
        const data = { user_id, search_input, group_id };
        setIsLoading(true);
        try {
            const response = await axios.get(`/api/user/search`, {
                params: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // save("token", response.data.token);
            return response.data.data.data;
        } catch (error) {
            console.log('eror', error);
            setIsLoading(false);
        }
    };

    const updateUserProfile = async (images) => {
        const formData = new FormData();
        for (const image of images) {
            const file = {
                uri: image.uri,
                name: image.name || Date.now().toString(),
                type: image.type
            };
            formData.append('images[]', file);
            formData.append('user_id', user.id);
        }

        await axios
            .post(`/api/user/attributes/profile-picture`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((res) => setUser(res.data.data));
    };

    const getUserProfile = async (user_id) => {
        setIsLoading(true);
        const data = { user_id };
        const response = await axios
            .get(`/api/user`, {
                params: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsLoading(false);
                return res.data;
            })
            .catch((error) => {
                setIsLoading(false);
            });

        return response;
    };
    const [GK, setGK] = useState();
    const [featureGK, setFeatureGK] = useState();
    const [userInGK, setUserInGK] = useState(false);
    const getFeatureGK = async () => {
        const response = await axios
            .get(`/api/gks/feature`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setFeatureGK(res.data.data);
                return res.data;
            })
            .catch((error) => {
                setIsLoading(false);
            });

        return response;
    };
    const getGK = async () => {
        setIsLoading(true);
        const user_id = user.id;
        const data = { user_id };
        const response = await axios
            .get(`/api/gks`, {
                params: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsLoading(false);
                const gk = res.data.users;
                setGK(res.data.users);

                const userExists = gk.some((item) => item.user && item.user.id === user.id);
                setUserInGK(userExists);

                return res.data;
            })
            .catch((error) => {
                setIsLoading(false);
            });

        return response;
    };
    const [isFriendsLoading, setIsFriendsLoading] = useState(false);
    const getRecommendedFriends = async (user_id, location, page = 1) => {
        setIsFriendsLoading(true);
        const data = { user_id, location, page };
        const response = await axios
            .get(`/api/user/friends/recommended`, {
                params: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setRecommendedFriends(res.data);
                setIsFriendsLoading(false);
                return res.data;
            })
            .catch((error) => {
                setIsFriendsLoading(false);
            });

        return response;
    };
    const [userFriends, setUserFriends] = useState();
    const [userConfirmedFriends, setUserConfirmedFriends] = useState();
    const [friendCount, setFriendCount] = useState();
    const getUserFriends = async (user_id, show_users = null) => {
        setIsLoading(true);
        const data = { user_id, show_users };
        const response = await axios
            .get(`/api/user/friends`, {
                params: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsLoading(false);
                // setUserFriends(res.data);
                // setUserConfirmedFriends(res.data.filter((friend) => friend.status === 'confirmed'));
                return res.data;
            })
            .catch((error) => {
                setIsLoading(false);
            });
        setUserFriends(response.data);
        setUserConfirmedFriends(response.data.filter((friend) => friend.status === 'confirmed'));

        return response;
    };
    const [userFriendsList, setUserFriendsList] = useState([]);
    const getUserFriendsList = async (user_id, group_id = null) => {
        setIsFriendsLoading(true);
        const data = { user_id, group_id };
        const response = await axios
            .get(`/api/user/friends/list`, {
                params: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsFriendsLoading(false);
                // setUserFriends(res.data);
                // setUserConfirmedFriends(res.data.filter((friend) => friend.status === 'confirmed'));
                return res.data;
            })
            .catch((error) => {
                setIsFriendsLoading(false);
            });
        setUserFriendsList(response.data);
        return response;
    };
    const addUserFriend = async (user_id, friend_id) => {
        setIsLoading(true);
        const data = { user_id, friend_id, status: 'pending' };
        const response = await axios
            .post(`/api/user/friends`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsLoading(false);
                return res.data;
            })
            .catch((error) => {
                setIsLoading(false);
            });

        return response;
    };
    const addNewLog = async (user_id, event_type, event_description, additional_metadata) => {
        setIsLoading(true);
        const data = { user_id, event_type, event_description, additional_metadata };
        const response = await axios
            .post(`/api/log`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(
                (res) =>
                    // setIsLoading(false);
                    res.data
            )
            .catch((error) => {
                // setIsLoading(false);
            });

        return response;
    };
    const blockUser = async (blocked_user_id) => {
        setIsLoading(true);
        const user_id = user.id;
        const data = { blocked_user_id, user_id };
        const response = await axios.post(`/api/user/block`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response;
    };
    const requestAccountDeletion = async () => {
        setIsLoading(true);
        const user_id = user.id;
        const data = { user_id };
        const response = await axios.post(`/api/user/delete`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response;
    };
    const updateUserFriend = async (id, status) => {
        setIsLoading(true);
        const data = { id, status };
        const response = await axios
            .put(`/api/user/friends`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsLoading(false);
                return res.data;
            })
            .catch((error) => {
                setIsLoading(false);
            });

        return response;
    };

    useEffect(() => {
        if (user && user.id) {
            getGK();
            getFeatureGK();
        }
    }, [user && user.id]);

    const updateRating = async (rating) => {
        const data = { rating, user_id: user.id };

        await axios.put(`/api/user/attributes`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    };
    const [isUploading, setIsUploading] = useState(false);
    const updateDuprId = async (dupr_id, userAttributeId = null) => {
        setIsUploading(true);
        let data;
        if (userAttributeId == null) {
            data = { dupr_id, id: user.user_attributes.id };
        } else {
            data = { dupr_id, id: userAttributeId };
        }

        const res = await axios
            .post(`/api/user/attributes`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsUploading(false);

                setUser({
                    ...user,
                    user_attributes: res.data.data
                });
                return res;
            })
            .catch(() => {
                setIsUploading(false);
            });
        return res;
    };
    const updateEstimateRating = async (estimateDuprId, userAttributeId = null, isDoubles = false) => {
        setIsUploading(true);
        let data;
        if (userAttributeId == null) {
            if (isDoubles) {
                data = { doubles_self_rating: estimateDuprId, id: user.user_attributes.id };
            } else {
                data = { singles_self_rating: estimateDuprId, id: user.user_attributes.id };
            }
        } else if (isDoubles) {
            data = { doubles_self_rating: estimateDuprId, id: userAttributeId };
        } else {
            data = { singles_self_rating: estimateDuprId, id: userAttributeId };
        }

        const res = await axios
            .post(`/api/user/attributes`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsUploading(false);

                return res;
            })
            .catch(() => {
                setIsUploading(false);
            });
        return res;
    };
    const getDUPRToken = async () => {
        const response = await axios.post(`https://prod.mydupr.com/api/auth/v1.0/token`, null, {
            headers: {
                'Content-Type': 'application/json',
                'x-authorization': DUPR_KEY
            }
        });
        setDuprToken(response.data.result.token);
        return response.data.result.token;
    };

    // eslint-disable-next-line default-param-last
    const searchDUPRUsers = async (name = user.name, limit = 1, tok) => {
        const data = {
            filters: {
                age: {
                    max: 90,
                    min: 14
                },
                // gender: 'ANY',
                rating: {
                    max: 5.3,
                    min: 2.3,
                    reliable: true,
                    type: 'DOUBLES'
                }
            },
            limit,
            offset: 0,
            query: name
        };
        const response = await axios
            .post(`https://prod.mydupr.com/api/user/v1.0/search`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tok}`
                }
            })
            .then(
                (res) =>
                    // setIsLoading(false);
                    res
            );
        // .catch(() => {
        //   setIsLoading(false);
        // });
        return response.data;

        // setDuprToken(response.data.result.token);
    };

    const updateLocation = async (location) => {
        const data = { location, user_id: user.id };

        await axios.put(`/api/user/location`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setUser((prevState) => ({
            ...prevState,
            user_attributes: { ...prevState.user_attributes, location }
        }));
    };
    const getUserTransactions = async () => {
        await axios.get(`/api/transactions/user`).then((res) => {
            setValue('userTransactions', res.data.data);
        });
    };
    const betaRequest = async (email, name = 'App Submit') => {
        const data = { email, name };

        await axios.post(`/api/waitlist`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    };
    const getPlayerTickets = async (time) => {
        setValue('isLoading', { ...state.isLoading, playerTickets: true });
        const data = { time };
        const response = await axios
            .get(`/api/player/tickets`, {
                params: data
            })
            .then((res) => {
                setIsLoading(false);
                setValue('playerTickets', res.data.data);
                setValue('isLoading', { ...state.isLoading, playerTickets: false });

                return res.data;
            })
            .catch((err) => {
                setValue('isLoading', { ...state.isLoading, playerTickets: false });
                setIsLoading(false);
            });
        return response;
    };
    const getPlayerReservations = async (time) => {
        setValue('isLoading', { ...state.isLoading, playerReservations: true });
        const data = { time };
        const response = await axios
            .get(`/api/player/reservations`, {
                params: data
            })
            .then((res) => {
                setIsLoading(false);
                setValue('playerReservations', res.data.data);
                setValue('isLoading', { ...state.isLoading, playerReservations: false });

                return res.data;
            })
            .catch((err) => {
                setValue('isLoading', { ...state.isLoading, playerReservations: false });
                setIsLoading(false);
            });
        return response;
    };
    const getPlayerTicket = async (id) => {
        setValue('isLoading', { ...state.isLoading, playerTicket: true });

        const response = await axios
            .get(`/api/player/ticket/${id}`)
            .then((res) => {
                setValue('playerTicket', res.data.data);
                setValue('isLoading', { ...state.isLoading, playerTicket: false });
                return res.data.data;
            })
            .catch((err) => {
                setValue('isLoading', { ...state.isLoading, playerTicket: false });
                setIsLoading(false);
            });
        return response;
    };
    const getPlayerReservation = async (id) => {
        setValue('isLoading', { ...state.isLoading, playerTicket: true });

        const response = await axios
            .get(`/api/player/reservation/${id}`)
            .then((res) => {
                setValue('playerReservation', res.data.data);
                setValue('isLoading', { ...state.isLoading, playerReservation: false });
                return res.data.data;
            })
            .catch((err) => {
                setValue('isLoading', { ...state.isLoading, playerReservation: false });
                setIsLoading(false);
            });
        return response;
    };

    return (
        <UsersContext.Provider
            value={{
                ...state,
                user,
                isLoading,
                error,
                getUserProfile,
                getGK,
                GK,
                userInGK,
                updateUserFriend,
                getUserFriends,
                addUserFriend,
                setBadgeCount,
                updateRating,
                updateUserProfile,
                setUserFriends,
                userFriends,
                userConfirmedFriends,
                setUserConfirmedFriends,
                friendCount,
                setFriendCount,
                userFriendsList,
                setUserFriendsList,
                getUserFriendsList,
                blockUser,
                requestAccountDeletion,
                betaRequest,
                updateLocation,
                getRecommendedFriends,
                recommendedFriends,
                setRecommendedFriends,
                isFriendsLoading,
                addNewLog,
                validateEmail,
                validateCode,
                resetPassword,
                updateProfile,
                getDUPRToken,
                searchDUPRUsers,
                updateDuprId,
                updateEstimateRating,
                isUploading,
                getFeatureGK,
                featureGK,
                setIsAuthenticated,
                getUserInfo,
                duprToken,
                setIsLoading,
                searchUsers,
                getUserTransactions,
                getPlayerTickets,
                getPlayerTicket,
                getPlayerReservations,
                getPlayerReservation
            }}
        >
            {children}
        </UsersContext.Provider>
    );
}
