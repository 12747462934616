import { createSlice } from '@reduxjs/toolkit';
import { WindowContext } from 'contexts/WindowContext';

// initial state
const initialState = {
    selectedItem: ['Tournaments'],
    selectedID: null,
    drawerOpen: localStorage.getItem('drawer') === 'true' || false
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.selectedItem = action.payload;
        },

        activeID(state, action) {
            state.selectedID = action.payload;
        },

        openDrawer(state, action) {
            localStorage.setItem('drawer', action.payload);
            state.drawerOpen = action.payload;
        }
    }
});

export default menu.reducer;

export const { activeItem, openDrawer, activeID } = menu.actions;
