import { useContext } from 'react';

import { MessagesContext } from 'contexts/MessagesContext';

// ==============================|| AUTH HOOKS ||============================== //

const useMessages = () => {
    const context = useContext(MessagesContext);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};

export default useMessages;
