/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import { Grid, Typography, Avatar, AvatarGroup, Stack } from '@mui/material';

const SinglesTeamCard = ({ party, match }) => (
    <Grid p={1.5} pl={0} sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                padding: 0.25,
                borderRadius: 2,
                paddingRight: 2,
                minHeight: 40,
                overflow: 'hidden'
            }}
        >
            <AvatarGroup sx={{ flexShrink: 0 }}>
                {party?.players?.map((player) => (
                    <Avatar key={player.user.id} sx={{ width: 34, height: 34 }} src={player?.user?.user_attributes?.profile_picture_url} />
                ))}
            </AvatarGroup>
            <Grid
                sx={{ ml: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
                {party?.players?.length > 0 ? (
                    party.players.map((player, index) => (
                        <Typography key={index} variant="h5" noWrap>
                            {player.user.name}
                        </Typography>
                    ))
                ) : match?.state === 'WALK_OVER' ? (
                    <Typography variant="h5">BYE</Typography>
                ) : (
                    <Typography variant="h5">--</Typography>
                )}
            </Grid>
        </Stack>
    </Grid>
);

export default SinglesTeamCard;
