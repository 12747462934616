// project imports
import services from 'utils/mockAdapter';

// third-party
import { add, sub } from 'date-fns';
import { Chance } from 'chance';

const chance = new Chance();

// products list
const products = [
    {
        id: 1,
        image: 'tko.jpeg',
        name: 'The Kitchen Open',
        description: chance.paragraph({ sentences: 2 }),
        rating: chance.floating({ min: 0.1, max: 5.0 }),
        discount: 25,
        salePrice: 350,
        offerPrice: 80,
        gender: 'male',
        categories: ['fashion', 'books'],
        colors: ['errorDark', 'orangeDark', 'errorMain', 'secondaryMain'],
        popularity: 614,
        date: chance.natural(),
        created: sub(new Date(), { days: 8, hours: 6, minutes: 20 }),
        isStock: true,
        playersRegistered: 614,
        location: 'Austin, Texas',
        startDate: 'Nov 16, 2023',
        endDate: 'Nov 18, 2023'
    },
    {
        id: 2,
        image: 'fall-open.png',
        name: "Era Pickleball's Houston Fall Open",
        description: chance.paragraph({ sentences: 2 }),
        rating: chance.floating({ min: 0.1, max: 5.0 }),
        discount: 25,
        salePrice: 350,
        offerPrice: 20,
        gender: 'male',
        categories: ['fashion', 'books'],
        colors: ['errorDark', 'orangeDark', 'errorMain', 'secondaryMain'],
        popularity: 227,
        date: chance.natural(),
        created: sub(new Date(), { days: 8, hours: 6, minutes: 20 }),
        isStock: true,
        playersRegistered: 227,
        location: 'The Woodlands, Texas',
        startDate: 'Nov 17, 2023',
        endDate: 'Nov 19, 2023'
    },
    {
        id: 3,
        image: 'wpt.png',
        name: 'WPT Amateur Invitational Championships',
        description: chance.paragraph({ sentences: 2 }),
        rating: chance.floating({ min: 0.1, max: 5.0 }),
        discount: 25,
        salePrice: 350,
        offerPrice: 100,
        gender: 'male',
        categories: ['fashion', 'books'],
        colors: ['errorDark', 'orangeDark', 'errorMain', 'secondaryMain'],
        popularity: 666,
        date: chance.natural(),
        created: sub(new Date(), { days: 8, hours: 6, minutes: 20 }),
        isStock: true,
        playersRegistered: 666,
        location: 'Rockwall, Texas',
        startDate: 'Jan 11, 2024',
        endDate: 'Jan 15, 2024'
    },
    {
        id: 4,
        image: 'ppa.png',
        name: 'PPA Tour: Austin TX',
        description: chance.paragraph({ sentences: 2 }),
        rating: chance.floating({ min: 0.1, max: 5.0 }),
        discount: 25,
        salePrice: 350,
        offerPrice: 100,
        gender: 'male',
        categories: ['fashion', 'books'],
        colors: ['errorDark', 'orangeDark', 'errorMain', 'secondaryMain'],
        popularity: 8,
        date: chance.natural(),
        created: sub(new Date(), { days: 8, hours: 6, minutes: 20 }),
        isStock: true,
        playersRegistered: 8,
        location: 'Lakeway, Texas',
        startDate: 'Mar 13, 2024',
        endDate: 'Mar 17, 2024'
    }
];

// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/products/list').reply(200, { products });

services.onPost('/api/products/filter').reply((config) => {
    try {
        const { filter } = JSON.parse(config.data);

        if (filter.sort === 'high') {
            products.sort((a, b) => Number(b.offerPrice) - Number(a.offerPrice));
        }

        if (filter.sort === 'low') {
            products.sort((a, b) => Number(a.offerPrice) - Number(b.offerPrice));
        }

        if (filter.sort === 'popularity') {
            products.sort((a, b) => Number(b.popularity) - Number(a.popularity));
        }

        if (filter.sort === 'discount') {
            products.sort((a, b) => Number(b.discount) - Number(a.discount));
        }

        if (filter.sort === 'discount') {
            products.sort((a, b) => Number(b.discount) - Number(a.discount));
        }

        if (filter.sort === 'new') {
            products.sort((a, b) => Number(b.new) - Number(a.new));
        }

        const results = products.filter((product) => {
            let searchMatches = true;

            if (filter.search) {
                const properties = ['name', 'description', 'rating', 'salePrice', 'offerPrice', 'gender'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (product[property].toString().toLowerCase().includes(filter.search.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    searchMatches = false;
                }
            }

            const genderMatches = filter.gender.length > 0 ? filter.gender.some((item) => item === product.gender) : true;
            const categoriesMatches =
                filter.categories.length > 0 && filter.categories.some((category) => category !== 'all')
                    ? filter.categories.some((category) => product.categories.some((item) => item === category))
                    : true;
            const colorsMatches =
                filter.colors.length > 0 ? filter.colors.some((color) => product.colors.some((item) => item === color)) : true;

            const minMax = filter.price ? filter.price.split('-') : '';
            const priceMatches = filter.price ? product.offerPrice >= minMax[0] && product.offerPrice <= minMax[1] : true;
            const ratingMatches = filter.rating > 0 ? product.rating >= filter.rating : true;

            return searchMatches && genderMatches && categoriesMatches && colorsMatches && priceMatches && ratingMatches;
        });

        return [200, results];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server error' }];
    }
});

services.onGet('/api/product/details').reply((config) => {
    try {
        const { id } = JSON.parse(config.data);

        let results;
        if (id === 'default') {
            [results] = products;
        } else {
            [results] = products.filter((product) => product.id === Number(id));
        }

        return [200, results];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server error' }];
    }
});

services.onPost('/api/product/related').reply((config) => {
    try {
        const { id } = JSON.parse(config.data);

        const results = products.filter((product) => product.id !== Number(id));

        return [200, results];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server error' }];
    }
});
