import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    CardContent,
    Checkbox,
    Fab,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';

// third-party
import { format } from 'date-fns';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Avatar from 'ui-component/extended/Avatar';
import Chip from 'ui-component/extended/Chip';
import { useDispatch, useSelector } from 'store';
import { getProducts } from 'store/slices/product';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterListTwoTone';
import PrintIcon from '@mui/icons-material/PrintTwoTone';
import FileCopyIcon from '@mui/icons-material/FileCopyTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/AddTwoTone';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import useLeagues from 'hooks/useLeagues';
import getReadableDateTime from 'utils/get_readable_datetime';
import { openSnackbar } from 'store/slices/snackbar';
import CurrencyFormat from 'react-currency-format';

const prodImage = require.context('assets/images/e-commerce', true);

// table sort
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) =>
    order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

// table header options

// ==============================|| TABLE HEADER ||============================== //

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

// ==============================|| PRODUCT LIST ||============================== //

const OrdersTable = ({
    orders,
    headCells,
    tableTitle,
    page = 1,
    setPage,
    rowsPerPage = 10,
    setRowsPerPage,
    totalRows,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    handleRefundPress
}) => {
    function EnhancedTableHead({ onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, theme, selected }) {
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {/* <TableCell padding="checkbox" sx={{ pl: 3 }}>
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts'
                            }}
                        />
                    </TableCell> */}
                    {numSelected > 0 && (
                        <TableCell padding="none" colSpan={7}>
                            <EnhancedTableToolbar numSelected={selected?.length} />
                        </TableCell>
                    )}
                    {numSelected <= 0 &&
                        headCells?.map((headCell) => (
                            <TableCell
                                key={headCell.id}
                                align={headCell.align}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Typography component="span" sx={{ display: 'none' }}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Typography>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    {numSelected <= 0 && (
                        <TableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                            <Typography
                                variant="subtitle1"
                                sx={{ color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : 'grey.900' }}
                            >
                                Action
                            </Typography>
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
        );
    }
    const EnhancedTableToolbar = ({ numSelected }) => (
        <Toolbar
            sx={{
                p: 0,
                pl: 2,
                pr: 1,
                color: numSelected > 0 ? 'secondary.main' : 'inherit'
            }}
        >
            {numSelected > 0 ? (
                <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="h4" component="div">
                    {numSelected} Selected
                </Typography>
            ) : (
                <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                    Nutrition
                </Typography>
            )}

            {numSelected > 0 && (
                <Tooltip title="Delete">
                    <IconButton size="large">
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [selected, setSelected] = useState([]);
    // const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [rows, setRows] = useState([]);
    const [initialRows, setInitialsRows] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState();
    const [open, setOpen] = useState(false);
    // const { products } = useSelector((state) => state.product);

    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [selectedEventId, setSelectedEventId] = React.useState(null);

    const handleMenuClick = (event, eventId) => {
        setMenuAnchorEl(event.currentTarget);
        setSelectedEventId(eventId);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
        setSelectedEventId(null);
    };

    useEffect(() => {
        setRows(orders);
        setInitialsRows(orders);
    }, [orders]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedId = rows?.map((n) => n.name);
            setSelected(newSelectedId);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected?.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected?.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected?.slice(0, selectedIndex), selected?.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(1);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;
    const emptyRows = page > 0 ? Math.max(0, 1 + page - rows?.length) : 0;

    return (
        <MainCard
            title={tableTitle}
            content={false}
            border={false}
            elevation={16}
            boxShadow
            shadow={theme.shadows[6]}
            data-test-id="views/admin/Orders/components/OrdersTable"
        >
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        numSelected={selected?.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows?.length}
                        theme={theme}
                        selected={selected}
                    />
                    <TableBody>
                        {rows &&
                            rows.map((row, index) => {
                                if (typeof row === 'number') return null;
                                const isItemSelected = isSelected(row?.name);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const mainImage = row?.item_images?.filter((item) => item.is_main === 1) || [];
                                const coverImage = mainImage.length > 0 && mainImage[0]?.image_url;

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        selected={isItemSelected}
                                    >
                                        {/* <TableCell
                                                padding="checkbox"
                                                sx={{ pl: 3 }}
                                                onClick={(event) => handleClick(event, row?.name)}
                                            >
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId
                                                    }}
                                                />
                                            </TableCell> */}
                                        <TableCell> {row.id} </TableCell>
                                        <TableCell>{getReadableDateTime(row.created_at)}</TableCell>
                                        <TableCell component="th" id={labelId} scope="row" sx={{ cursor: 'pointer' }}>
                                            <Typography
                                                component={Link}
                                                to={`/clubs/events/${row.event_type}/${row.id}/Dashboard`}
                                                variant="subtitle1"
                                                sx={{
                                                    color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : 'grey.900',
                                                    textDecoration: 'none'
                                                }}
                                            >
                                                {row?.user?.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" sx={{ cursor: 'pointer' }}>
                                            <Typography
                                                component={Link}
                                                to={`/clubs/events/${row.event_type}/${row.id}/Dashboard`}
                                                variant="subtitle1"
                                                sx={{
                                                    color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : 'grey.900',
                                                    textDecoration: 'none'
                                                }}
                                            >
                                                {row?.product?.name}
                                            </Typography>
                                        </TableCell>

                                        <TableCell align="right">
                                            <CurrencyFormat
                                                decimalScale={2}
                                                fixedDecimalScale
                                                value={row.total_amount / 100 || 0}
                                                displayType="text"
                                                thousandSeparator
                                                prefix="$"
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <CurrencyFormat
                                                decimalScale={2}
                                                fixedDecimalScale
                                                value={row.amount_transferred / 100 || 0}
                                                displayType="text"
                                                thousandSeparator
                                                prefix="$"
                                            />
                                        </TableCell>
                                        <TableCell align="center">{row.discount?.code}</TableCell>

                                        <TableCell align="center">
                                            <Chip
                                                size="small"
                                                label={row.status}
                                                chipcolor={row.status === 'completed' ? 'success' : 'error'}
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </TableCell>
                                        <TableCell align="center" sx={{ pr: 3 }}>
                                            <IconButton onClick={(e) => handleMenuClick(e, row.id)} size="large">
                                                <MoreHorizOutlinedIcon
                                                    fontSize="small"
                                                    aria-controls="menu-popular-card-1"
                                                    aria-haspopup="true"
                                                    sx={{ color: 'grey.500' }}
                                                />
                                            </IconButton>
                                            <Menu
                                                id="menu-popular-card-1"
                                                anchorEl={menuAnchorEl}
                                                open={Boolean(menuAnchorEl) && selectedEventId === row.id}
                                                keepMounted
                                                onClose={handleClose}
                                                variant="selectedMenu"
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right'
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right'
                                                }}
                                                sx={{
                                                    '& .MuiMenu-paper': {
                                                        boxShadow: theme.customShadows.z1
                                                    }
                                                }}
                                            >
                                                <MenuItem
                                                    onClick={() => {
                                                        handleRefundPress(row);
                                                        handleClose();
                                                    }}
                                                    disabled={row.status === 'refunded'}
                                                >
                                                    <Chip
                                                        size="small"
                                                        label={row.status === 'refunded' ? 'Refunded' : 'Refund'}
                                                        // disabled={row.status === 'refunded'}
                                                        chipcolor="error"
                                                        sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                                    />
                                                </MenuItem>
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default OrdersTable;
