import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Divider, Grid, IconButton, Link, TextField, Typography } from '@mui/material';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import Posts from 'ui-component/cards/Post';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { useDispatch, useSelector } from 'store';
import { getPosts, editComment, addComment, addReply, likePost, likeComment, likeReply } from 'store/slices/user';

// assets
import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LayersTwoToneIcon from '@mui/icons-material/LayersTwoTone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import RecentActorsTwoToneIcon from '@mui/icons-material/RecentActorsTwoTone';
import useGroups from 'hooks/useGroups';
import FmdGoodTwoToneIcon from '@mui/icons-material/FmdGoodTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';
import useAuth from 'hooks/useAuth';
import usePosts from 'hooks/usePosts';
import Feed from 'views/dashboard/Default/Feed';
import { useFormik, Form, FormikProvider, Formik } from 'formik';
import * as Yup from 'yup';
import { openSnackbar } from 'store/slices/snackbar';

// ==============================|| SOCIAL PROFILE - POST ||============================== //

const Profile = ({ league }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const sideAvatarSX = {
        borderRadius: '8px',
        width: 48,
        height: 48,
        fontSize: '1.5rem',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        '&>svg': {
            width: 24,
            height: 24
        }
    };
    const { user } = useAuth();
    const { groupInfo, getGroupInfo } = useGroups();
    const { getFeed, groupFeed, addNewGroupPost } = usePosts();

    useEffect(() => {
        getGroupInfo(league?.group_id);
        getFeed({ user_id: user.id, page: 1, refresh: true, group_id: league?.group_id });
    }, [league]);

    const PostSchema = Yup.object().shape({
        postText: Yup.string().min(1).required('Post content required')
    });

    const initialState = {
        post: ''
    };
    const [images, setImages] = useState();
    const formik = useFormik({
        // initialValues: initialState,
        validationSchema: PostSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            console.log('banana', groupInfo);
            try {
                const data = {
                    user_id: user.id,
                    group_id: league?.group_id,
                    post_text: values.postText,
                    images
                };

                addNewGroupPost(data);
                resetForm();
                setSubmitting(false);
            } catch (error) {
                console.error(error);
            }
        }
    });

    const showInMapClicked = (lng, lat) => {
        window.open(`https://maps.google.com?q=${lat},${lng}`);
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sm={12} md={4}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <MainCard>
                            <Grid container alignItems="center" spacing={gridSpacing}>
                                <Grid item>
                                    <Box
                                        sx={{
                                            ...sideAvatarSX,
                                            bgcolor: theme.palette.mode === 'dark' ? theme.palette.primary.main + 20 : 'primary.light',
                                            border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                            borderColor: 'primary.main',
                                            color: 'primary.dark'
                                        }}
                                    >
                                        <PeopleAltTwoToneIcon />
                                    </Box>
                                </Grid>
                                <Grid item xs zeroMinWidth>
                                    <Typography variant="h3" color="primary" component="div" sx={{ mb: 0.625 }}>
                                        {groupInfo?.group?.num_members}
                                    </Typography>
                                    <Typography variant="body2">Players</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton size="large">
                                        <NavigateNextRoundedIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Divider sx={{ margin: '16px 0' }} />
                            <Grid container alignItems="center" spacing={gridSpacing}>
                                <Grid item>
                                    <Box
                                        sx={{
                                            ...sideAvatarSX,
                                            bgcolor: theme.palette.mode === 'dark' ? theme.palette.secondary.main + 20 : 'secondary.light',
                                            borderColor: 'secondary.main',
                                            color: 'secondary.dark'
                                        }}
                                    >
                                        <RecentActorsTwoToneIcon />
                                    </Box>
                                </Grid>
                                <Grid item xs zeroMinWidth>
                                    <Typography
                                        variant="h3"
                                        sx={{
                                            mb: 0.625,
                                            color: theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'secondary.main',
                                            textTransform: 'capitalize'
                                        }}
                                    >
                                        {league?.minimum_rating}
                                    </Typography>
                                    <Typography variant="body2">Minimum Rating</Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{ margin: '16px 0' }} />

                            <Grid container alignItems="center" spacing={gridSpacing}>
                                <Grid item>
                                    <Box
                                        sx={{
                                            ...sideAvatarSX,
                                            bgcolor: theme.palette.mode === 'dark' ? theme.palette.secondary.main + 20 : 'primary.dark',
                                            borderColor: 'primary.light',
                                            color: 'primary.light'
                                        }}
                                    >
                                        {league?.visibility === 'public' ? <VisibilityTwoToneIcon /> : <VisibilityOffTwoToneIcon />}
                                    </Box>
                                </Grid>
                                <Grid item xs zeroMinWidth>
                                    <Typography
                                        variant="h3"
                                        sx={{
                                            mb: 0.625,
                                            color: theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'primary.main',
                                            textTransform: 'capitalize'
                                        }}
                                    >
                                        {league?.visibility}
                                    </Typography>
                                    <Typography variant="body2">League</Typography>
                                </Grid>
                            </Grid>
                        </MainCard>
                    </Grid>
                    {/* {league?.description ||
                        (league?.facility && ( */}
                    <Grid item xs={12}>
                        <MainCard>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h4">About</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">{league?.description}</Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{ margin: '16px 0' }} />
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    '& >div': {
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        display: 'block',
                                        width: '100%'
                                    },
                                    '& a': {
                                        color: theme.palette.grey[700],

                                        '& svg': {
                                            mr: 1,
                                            verticalAlign: 'bottom'
                                        },
                                        '&:hover': {
                                            color: theme.palette.primary.main,
                                            textDecoration: 'none'
                                        }
                                    }
                                }}
                            >
                                {groupInfo?.facility && (
                                    <Grid item xs={12}>
                                        <Link
                                            onClick={() => showInMapClicked(groupInfo?.facility?.longitude, groupInfo?.facility?.latitude)}
                                            target="_blank"
                                            underline="hover"
                                        >
                                            <Grid container>
                                                <Grid item>
                                                    <FmdGoodTwoToneIcon color="secondary" />
                                                </Grid>
                                                <Grid item flexDirection="column">
                                                    <Typography>{groupInfo?.facility?.name}</Typography>
                                                    <Typography variant="caption">{groupInfo?.facility?.address}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Link>
                                    </Grid>
                                )}
                            </Grid>
                        </MainCard>
                    </Grid>
                    {/* ))} */}
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={8}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Formik
                            initialValues={{ postText: '' }}
                            validationSchema={PostSchema}
                            onSubmit={async (values, { resetForm, setSubmitting }) => {
                                try {
                                    const data = {
                                        user_id: user.id,
                                        group_id: league?.group_id,
                                        post_text: values.postText,
                                        images
                                    };

                                    addNewGroupPost(data);
                                    resetForm();
                                    setSubmitting(false);
                                    dispatch(
                                        openSnackbar({
                                            open: true,
                                            message: 'Post Successful',
                                            variant: 'alert',
                                            alert: {
                                                color: 'success'
                                            },
                                            close: true
                                        })
                                    );
                                } catch (error) {
                                    dispatch(
                                        openSnackbar({
                                            open: true,
                                            message: 'Post Pailed',
                                            variant: 'alert',
                                            alert: {
                                                color: 'error'
                                            },
                                            close: true
                                        })
                                    );
                                    console.error(error);
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <Form onSubmit={handleSubmit}>
                                    <MainCard>
                                        <Grid container spacing={gridSpacing}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    // id="outlined-textarea"
                                                    name="postText"
                                                    placeholder="What’s on your mind?"
                                                    rows={4}
                                                    fullWidth
                                                    multiline
                                                    // label="Post"
                                                    value={values.postText}
                                                    onChange={handleChange}
                                                    error={touched.postText && Boolean(errors.postText)}
                                                    helperText={touched.postText && errors.postText}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container justifyContent="space-between" spacing={gridSpacing}>
                                                    <Grid item>
                                                        <Button variant="text" color="secondary" startIcon={<AttachmentTwoToneIcon />}>
                                                            Gallery
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <AnimateButton>
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="secondary"
                                                                startIcon={<LayersTwoToneIcon />}
                                                                disabled={formik.isSubmitting}
                                                            >
                                                                Post
                                                            </Button>
                                                        </AnimateButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </MainCard>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                    <Feed feed={groupFeed} />
                    {/* {posts &&
                        posts.map((post) => (
                            <Grid key={post.id} item xs={12}>
                                <Posts
                                    key={post.id}
                                    post={post}
                                    editPost={editPost}
                                    renderPost={getPost}
                                    setPosts={setPosts}
                                    commentAdd={commentAdd}
                                    replyAdd={replyAdd}
                                    handlePostLikes={handlePostLikes}
                                    handleCommentLikes={handleCommentLikes}
                                    handleReplayLikes={handleReplayLikes}
                                />
                            </Grid>
                        ))} */}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Profile;
