/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

// reducer - state management
import { LOGIN, LOGOUT, UPDATE_USER } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';

// import axios from 'axios';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// axios.create({
//     baseURL: 'http://localhost:8000'
// });

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    const domain = process.env.REACT_APP_NODE_ENV === 'production' ? `.${process.env.REACT_APP_PRIMARY_URL}` : undefined;

    if (serviceToken) {
        Cookies.set('serviceToken', serviceToken, { domain, expires: 36500 });
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        Cookies.remove('serviceToken', { domain });
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [error, setError] = useState('');
    const [location, setLocation] = useState({ city: null, latitude: null, longitude: null });
    const [prevRoute, setPrevRoute] = useState();
    const [subdomain, setSubdomain] = useState();

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = Cookies.get('serviceToken');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    const response = await axios.get('/api/user');
                    const { user } = response.data;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (email, password) => {
        try {
            // const response = await axios.post('/api/login', { email, password });
            const response = await axios.post('/api/login', { email, password });
            const { token, user } = response.data;

            setSession(token);
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user
                }
            });
            return response.data.user;
        } catch (authError) {
            console.log('ERRORRR');
            if (authError.response.status === 400) {
                console.log('passed', authError.response.data.error);
                setError(authError.response.data.error);
            }
            throw authError;
        }
    };
    const impersonateLogin = async (email, password, selected_user_id) => {
        try {
            // const response = await axios.post('/api/login', { email, password });
            const response = await axios.post('/api/impersonate', { email, password, selected_user_id });
            const { token, user } = response.data;

            setSession(token);
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user
                }
            });
            return response.data.user;
        } catch (authError) {
            console.log('ERRORRR');
            if (authError.response.status === 400) {
                console.log('passed', authError.response.data.error);
                setError(authError.response.data.error);
            }
            throw authError;
        }
    };
    const loginViaToken = async (token, userId) => {
        setSession(token);
        const response = await axios.get(`/api/user`, {
            userId
        });
        const user = response.data.user;
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });
    };
    const requestAccountDeletion = async (email) => {
        try {
            if (email) {
                const response = await axios.post(`/api/user/delete`, {
                    email
                });

                if (response.status === 200) {
                    setError();

                    return response.status;
                }
            }
        } catch (error) {
            setError('We could not find that email address');
            throw error;
            // return error.response ? error.response.status : 500;
        }
    };

    const register = async (email, password, name, date_of_birth, gender, phone_number) => {
        // todo: this flow need to be recode as it not verified
        try {
            const response = await axios.post('/api/register', {
                email,
                password,
                name,
                date_of_birth,
                gender,
                phone_number
            });
            const { token, user } = response.data;
            setSession(token);
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user
                }
            });
            setError();
            return response.data.user;
        } catch (authError) {
            if (authError.response.status === 400) {
                console.log('passed', authError.response.data.errors);
                setError(authError.response.data.errors);
            }
        }
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };
    const [isLoading, setIsLoading] = useState(false);
    const [resetEmail, setResetEmail] = useState();
    const [email, setEmail] = useState();
    const validateEmail = async (email, no_send) => {
        setIsLoading(true);
        setResetEmail(email);

        try {
            if (email) {
                const response = await axios.post(`/api/validate_email`, {
                    email,
                    no_send
                });

                if (response.status === 200) {
                    setError();
                    setIsLoading(false);

                    return response;
                }
            }
        } catch (error) {
            setError('We could not find that email address');
            throw error;
            // return error.response ? error.response.status : 500;
        }
    };
    const validateCode = async (email, code) => {
        setIsLoading(true);
        console.log('emal', email, code);
        try {
            if (email && code) {
                const response = await axios.post(`/api/validate_code`, {
                    email,
                    code
                });

                setError();
                setIsLoading(false);

                return response.status;
            }
        } catch (error) {
            setIsLoading(false);

            setError('We could not validate that code');
            throw error;
            // return error.response ? error.response.status : 500;
        }
    };
    const resetPassword = async (email, password) => {
        setIsLoading(true);
        try {
            if (email && password) {
                const response = await axios.post(`/api/reset_password`, {
                    email,
                    password
                });

                if (response.status === 200) {
                    setError();
                    setIsLoading(false);

                    return response.status;
                }
            }
        } catch (error) {
            setError('We could not validate that code');
            // setIsLoading(false);
            throw error;
            // return error.response ? error.response.status : 500;
        }
    };
    const createHostAccount = async () => {
        setIsLoading(true);
        try {
            await axios.post(`/api/host`);
        } catch (error) {
            setError('Request failed');
            // setIsLoading(false);
            throw error;
            // return error.response ? error.response.status : 500;
        }
    };
    const getLocation = async () => {
        setIsLoading(true);
        try {
            const res = await axios.get(`/api/user/location`);
            setLocation({
                city: res.data.secondaryLocation.cityName,
                latitude: res.data.secondaryLocation.latitude,
                longitude: res.data.secondaryLocation.longitude
            });
            return res;
        } catch (error) {
            setError('Request failed');
            // setIsLoading(false);
            throw error;
            // return error.response ? error.response.status : 500;
        }
    };

    // useEffect(() => {
    //     getLocation();
    // }, []);

    const updateProfile = async (params) => {
        setIsLoading(true);
        try {
            const response = await axios.put(`/api/user/profile/${state?.user?.id}`, params, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setIsLoading(false);
            dispatch({
                type: UPDATE_USER,
                payload: {
                    user: response.data.data
                }
            });
            return response.status;
        } catch (error) {
            setIsLoading(false);

            return error.response ? error.response.status : 500;
        }
    };
    const newPageView = async (params) => {
        setIsLoading(true);
        try {
            const response = await axios.post(`/api/page/view`, params);
            return response;
        } catch (error) {
            setError('Request failed');
            // setIsLoading(false);
            throw error;
            // return error.response ? error.response.status : 500;
        }
    };
    const getOnboardingLink = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`/api/host/onboarding-link`);
            console.log('response', response);
            return response.data.data.url;
        } catch (error) {
            setError('Request failed');
            // setIsLoading(false);
            throw error;
            // return error.response ? error.response.status : 500;
        }
    };
    const getStripeAccount = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`/api/host`);
            return response.data.data;
        } catch (error) {
            setError('Request failed');
            // setIsLoading(false);
            throw error;
            // return error.response ? error.response.status : 500;
        }
    };
    const updateUserProfile = async (file) => {
        const formData = new FormData();

        formData.append('images[]', file);
        formData.append('user_id', state.user.id);

        await axios
            .post(`/api/user/attributes/profile-picture`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((res) =>
                dispatch({
                    type: UPDATE_USER,
                    payload: {
                        user: res.data.data
                    }
                })
            );
    };
    const updateUserPreferences = async (params) => {
        setIsLoading(true);
        const response = await axios
            .put(`/api/user/preferences`, params)
            .then((res) => {
                setIsLoading(false);
                dispatch({
                    type: UPDATE_USER,
                    payload: {
                        user: { ...state.user, user_attributes: res.data.data }
                    }
                });

                return res.data;
            })
            .catch((error) => {
                console.log('error', error);
                setIsLoading(false);
            });
        return response;
    };

    // const updateProfile = () => { };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                login,
                logout,
                register,
                resetPassword,
                validateCode,
                validateEmail,
                prevRoute,
                setPrevRoute,
                error,
                resetEmail,
                getOnboardingLink,
                createHostAccount,
                getStripeAccount,
                getLocation,
                newPageView,
                updateProfile,
                requestAccountDeletion,
                location,
                loginViaToken,
                email,
                setEmail,
                updateUserProfile,
                updateUserPreferences,
                setSubdomain,
                subdomain,
                impersonateLogin
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
