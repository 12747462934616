// material-ui
import { Grid, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';

// assets
import EventList from 'views/home/Home/EventList';
import useLeagues from 'hooks/useLeagues';

// project imports

// ==============================|| ANALYTICS DASHBOARD ||============================== //

const AdminHome = ({ showTitle = true }) => {
    const { handleToggleModal } = useLeagues();

    return (
        <Grid container data-test-id="views/admin/Events/index">
            <Grid xs={12} paddingY={2}>
                {showTitle && (
                    <Typography variant="h1" color="#262626" fontSize={48}>
                        Events
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        {/* <MarketShareAreaChartCard /> */}
                        <EventList handleToggleModal={handleToggleModal} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AdminHome;
