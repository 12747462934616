/* eslint-disable no-plusplus */
import React, { useState, createContext, useReducer } from 'react';
import axios from 'axios';

export const FormContext = createContext();

const initialState = {
    formData: {},
    productForm: undefined,
    formResponseCount: undefined,
    formResults: [],
    loading: { formResults: false }
};
const updateNestedStateKey = (keyPath, value) => ({
    type: 'UPDATE_NESTED_STATE_KEY',
    payload: { keyPath, value }
});
function updateNestedStateKeyReducer(state, keyPath, value) {
    const keys = keyPath.split('.');
    const nestedState = { ...state };

    let currentLevel = nestedState;
    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        currentLevel[key] = { ...currentLevel[key] };
        currentLevel = currentLevel[key];
    }

    currentLevel[keys[keys.length - 1]] = value;

    return nestedState;
}

function formReducer(state, action) {
    switch (action.type) {
        case 'SET_STATE':
            return { ...state, [action.payload.key]: action.payload.value };
        case 'UPDATE_NESTED_STATE_KEY':
            return updateNestedStateKeyReducer(state, action.payload.keyPath, action.payload.value);
        default:
            return state;
    }
}

export function FormProvider({ children }) {
    const [state, dispatch] = useReducer(formReducer, initialState);
    const setValue = (key, value) => {
        dispatch({ type: 'SET_STATE', payload: { key, value } });
    };
    const updateValue = (keyPath, value) => {
        dispatch(updateNestedStateKey(keyPath, value));
    };
    const storeForm = async (params) => {
        const response = await axios
            .post(`/api/form`, params)
            .then((res) => res.data.data)
            .catch((err) => {
                throw err;
            });

        return response;
    };
    const getForm = async (id) => {
        await axios.get(`/api/form/${id}`).then((res) => {
            setValue('formData', res.data.data);
        });
    };
    const getProductForm = async (product_id) => {
        await axios.get(`/api/product/form`, { params: { product_id } }).then((res) => {
            setValue('productForm', res.data.data);
        });
    };
    const deleteForm = async (id) => {
        await axios.delete(`/api/form/${id}`).then(() => {
            setValue('productForm', undefined);
        });
    };
    const getFormResults = async (id, view) => {
        updateValue('loading.formResults', true);
        console.log('view', view);
        await axios
            .get(`/api/form/results/${id}`, { params: { view } })
            .then((res) => {
                console.log('res', res);
                setValue('formResults', res.data.data);
                setValue('formResponseCount', res.data.responseCount);
            })
            .finally(() => {
                updateValue('loading.formResults', false);
            });
    };
    return (
        <FormContext.Provider
            value={{
                ...state,
                storeForm,
                getForm,
                getProductForm,
                deleteForm,
                getFormResults
            }}
        >
            {children}
        </FormContext.Provider>
    );
}
