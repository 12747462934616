import React, { useState, createContext, useEffect, useRef, useReducer } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import useAuth from '../hooks/useAuth';
import { API_URL as apiUrl } from 'config';
import useNotifications from '../hooks/useNotifications';
// import * as Notifications from 'expo-notifications';

export const HelpCenterContext = createContext();
const initialState = {
    topics: [],
    topic: [],
    articles: [],
    isLoading: { topics: false },
    category: 'attending',
    article: [],
    recordMap: []
};

function helpReducer(state, action) {
    switch (action.type) {
        case 'SET_STATE':
            return { ...state, [action.payload.key]: action.payload.value };

        default:
            return state;
    }
}

export function HelpCenterProvider({ children }) {
    const [state, dispatch] = useReducer(helpReducer, initialState);

    const setValue = (key, value) => {
        dispatch({ type: 'SET_STATE', payload: { key, value } });
    };
    const getTopics = async (category) => {
        setValue('isLoading', { ...state.isLoading, topics: true });
        const response = await axios
            .get(`/api/help/topics`, {
                params: {
                    category
                }
            })
            .then((res) => {
                setValue('isLoading', { ...state.isLoading, topics: false });
                setValue('topics', res.data.data);
                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
                setValue('isLoading', { ...state.isLoading, topics: false });
            });
        return response;
    };
    const getArticles = async (topic_id) => {
        setValue('isLoading', { ...state.isLoading, articles: true });
        const response = await axios
            .get(`/api/help/articles`, {
                params: {
                    topic_id
                }
            })
            .then((res) => {
                setValue('isLoading', { ...state.isLoading, articles: false });
                setValue('articles', res.data.data);
                setValue('topic', res.data.topic);
                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
                setValue('isLoading', { ...state.isLoading, articles: false });
            });
        return response;
    };
    const getHelpArticle = async (slug) => {
        setValue('isLoading', { ...state.isLoading, article: true });
        const response = await axios
            .get(`/api/help/article/${slug}`)
            .then((res) => {
                setValue('isLoading', { ...state.isLoading, article: false });
                setValue('article', res.data.data);
                setValue('recordMap', res.data.blocks);
                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
                setValue('isLoading', { ...state.isLoading, article: false });
            });
        return response;
    };
    return (
        <HelpCenterContext.Provider
            value={{
                ...state,
                setValue,
                getTopics,
                getArticles,
                getHelpArticle
            }}
        >
            {children}
        </HelpCenterContext.Provider>
    );
}
