import React, { useState, createContext } from 'react';
import axios from 'axios';
// import useAuth from '../hooks/useAuth';
import { API_URL as apiUrl } from 'config';
// import useLocation from '../hooks/useLocation';
import useFacility from '../hooks/useFacility';
import useAuth from 'hooks/useAuth';

export const GroupContext = createContext();

export function GroupProvider({ children }) {
    //   const [user, setUser] = useState({
    //     name: "Jordan Thiel",
    //     email: "jordandt83@gmail.com",
    //   });
    const [groupInfo, setGroupInfo] = useState();
    const [groupSearchResults, setGroupSearchResults] = useState();
    const [groups, setGroups] = useState([]);
    const [groupImages, setGroupImages] = useState([]);
    const [error, setError] = useState();
    const [noGroups, setNoGroups] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isImagesLoading, setIsImagesLoading] = useState(true);
    const [beacons, setBeacons] = useState([]);
    // const { token, user } = useAuth();
    const token = null;
    const { user } = useAuth();
    // const { userLocation } = useLocation();
    const { getUserFacilities } = useFacility();

    const getGroupInfo = async (group_id) => {
        setIsLoading(true);
        const response = await axios.get(`/api/group`, {
            params: {
                id: group_id
            }
        });

        setGroupInfo(response.data.data);
        setIsLoading(false);
        return response.data;
    };
    const getClubGroup = async (subdomain) => {
        setIsLoading(true);
        const response = await axios.get(`/api/club/group`, {
            params: {
                subdomain
            }
        });

        setGroupInfo(response.data.data);
        setIsLoading(false);
        return response.data;
    };

    const getGroupImages = async (group_id) => {
        setIsImagesLoading(true);
        const params = { group_id };
        const response = await axios
            .get(`/api/group/images`, {
                params,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => setGroupImages(res.data.data))
            .then(() => setIsImagesLoading(false));

        // setGroupImages(response.data.data);
        // setIsImagesLoading(false);
    };

    const searchGroups = async (name) => {
        setIsLoading(true);
        const response = await axios.get(`/api/group/search`, {
            params: {
                name
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });

        setGroupSearchResults(response.data);
        setIsLoading(false);
        return response.data;
    };

    const getUserGroups = async (region = null) => {
        setIsLoading(true);
        const user_id = user.id;

        await axios
            .get(`/api/groups/user`, {
                params: {
                    user_id,
                    region
                }
            })
            // .then((res) => // console.log("RD", res.data))
            .then((res) => {
                if (res.data.no_groups === true) {
                    setNoGroups(true);
                } else {
                    setNoGroups(false);
                }

                setGroups(res.data.data);
                setIsLoading(false);
            })
            .then(() => setIsLoading(false))
            .catch((e) => {
                console.log('error');
                setError(e);
                setIsLoading(false);
            });
    };
    const [joiningGroup, setJoiningGroup] = useState(false);
    const joinGroup = async (group_id = null, league_id = null, userId = null) => {
        setJoiningGroup(true);
        const user_id = userId || user.id;
        const data = {
            user_id,
            group_id,
            league_id,
            status: 'confirmed'
        };
        // console.log(data);
        await axios
            .post(`/api/group/join`, data, {})
            .then((res) => {
                getGroupInfo(group_id);
                if (!userId) {
                    setGroups((prevState) => [...prevState, res.data.data]);
                    // getUserGroups();
                    setJoiningGroup(false);
                    setNoGroups(false);
                }
            })
            .catch(() => {
                setJoiningGroup(false);
            });
    };

    const leaveGroup = async (group_id, userId = null) => {
        const user_id = userId || user.id;

        // console.log(data);
        await axios
            .delete(`/api/group/leave`, {
                params: {
                    user_id,
                    group_id
                }
            })
            .then(() => {
                if (!userId) {
                    const removedGroup = groups?.data?.filter((group) => group.id !== group_id);
                    if (removedGroup?.length === 0) {
                        getUserGroups();
                    } else {
                        setGroups({ data: removedGroup });
                    }
                }
                setIsLoading(false);
            });
    };
    const updateGroupNotifications = async (group_id, silenced) => {
        const user_id = user.id;
        const data = {
            user_id,
            group_id,
            silenced
        };
        // console.log(data);

        await axios.put(`/api/group/silence`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
            // params: {
            //   user_id,
            //   group_id,
            //   silenced,
            // },
        });
        // .then(() => getGroupInfo(group_id))
        // .then(() => getUserGroups())
        // .then(() => setIsLoading(false));
    };
    const updateGroup = async (group_id, name, description, onlyAdminsPost, onlyAdminEvent, chatThread) => {
        const data = {
            name,
            description,
            only_admin_post: onlyAdminsPost === true ? 1 : 0,
            only_admin_event: onlyAdminEvent === true ? 1 : 0
        };

        try {
            const response = await axios.put(`/api/group/${group_id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data && response.data.data) {
                const updatedName = response.data.data.name;
                const updatedPostRule = response.data.data.only_admin_post;
                const updatedDescription = response.data.data.description;
                const updatedConversation = response.data.data.conversation_id;

                // Update only the 'name' attribute inside the 'group' portion of groupInfo
                setGroupInfo((prevGroupInfo) => ({
                    ...prevGroupInfo,
                    data: {
                        ...prevGroupInfo.data,
                        group: {
                            ...prevGroupInfo.data.group,
                            name: updatedName,
                            only_admin_post: updatedPostRule,
                            description: updatedDescription,
                            conversation_id: chatThread // This updates only the name inside the group section with the new data
                        }
                    }
                }));
            }
        } catch (error) {
            console.error('Error updating the group:', error);
        }
    };
    const [imageUploading, setImageUploading] = useState(false);
    const addGroupImage = async (group_id, images) => {
        setImageUploading(true);
        const formData = new FormData();
        // eslint-disable-next-line no-restricted-syntax
        for (const image of images) {
            const file = {
                uri: image.uri,
                name: image.name || Date.now().toString(),
                type: image.type
            };
            formData.append('images[]', file);
            formData.append('group_id', group_id);
        }

        await axios
            .post(`/api/group/image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                getGroupInfo(group_id);
                getUserGroups();
                setImageUploading(false);
            })
            .catch((err) => setImageUploading(false));
    };
    const makeAdmin = async (group_id, user_id, role) => {
        const data = {
            group_id,
            user_id,
            role
        };

        try {
            const response = await axios.post(`/api/group/role`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data && response.data.data) {
                setGroupInfo((prevGroupInfo) => {
                    // Update the user's role inside the groupInfo.data.users array
                    const updatedUsers = prevGroupInfo.data.user_info.map((user) => {
                        if (user.user.id === user_id) {
                            return { ...user, role };
                        }
                        return user;
                    });

                    return {
                        ...prevGroupInfo,
                        data: {
                            ...prevGroupInfo.data,
                            user_info: updatedUsers // Set the updated users array here
                        }
                    };
                });
            }
        } catch (error) {
            console.error('Error updating the group:', error);
        }
    };

    const createGroup = async (name, membership_type, group_visibility) => {
        setIsLoading(true);
        const user_id = user.id;

        const data = {
            user_id,
            name,
            membership_type,
            group_visibility: group_visibility || 'public'
        };

        await axios
            .post(`/api/group`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                joinGroup(res.data.data.id, 'admin');
                getGroupInfo(res.data.data.id);
                getUserGroups();
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };
    const inviteUser = async (recipient_user_id, group_id) => {
        const user_id = user.id;

        const data = {
            user_id,
            recipient_user_id,
            group_id
        };

        await axios.post(`/api/group/invite`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
    };
    const sendBeacon = async (group_id, num_players, rating) => {
        const sender_id = user.id;

        const data = {
            sender_id,
            group_id,
            num_players,
            rating,
            status: 'open'
        };

        await axios
            .post(`/api/facility/beacon`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                setBeacons((prevState) => [...prevState, res.data.data]);
                // setActiveBeacon(res.data);
            });
    };
    const stopBeacon = async (group_id) => {
        const data = {
            group_id,
            status: 'closed'
        };

        await axios
            .put(`/api/facility/beacon`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                setBeacons(beacons.filter((beacon) => beacon.group_id !== group_id));
                // setActiveBeacon(res.data);
            });
    };
    const getBeacons = async (group_id) => {
        const data = {
            group_id,
            status: 'open'
        };

        await axios
            .get(`/api/facility/beacon`, {
                params: data,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                console.log();
                // const rating = res.data.data;
                const rating = res.data.data.filter((beacon) => parseFloat(beacon.rating) <= user.user_attributes.rating);
                if (rating.length > 0) {
                    setBeacons(res.data.data);
                } else {
                    setBeacons([]);
                }
                // setActiveBeacon(res.data);
            });
    };
    const addBeaconResponse = async (beacon_id) => {
        const user_id = user.id;
        const data = {
            user_id,
            status: 'accepted',
            beacon_id
        };

        await axios
            .post(`/api/facility/beacon/user`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                setBeacons((prevState) => {
                    const newBeaconUsers = [...prevState[0].beacon_users, res.data.data];
                    const newState = [{ ...prevState[0], beacon_users: newBeaconUsers }];
                    return newState;
                });
                // setActiveBeacon(res.data);
            });
    };
    const [leaderboard, setLeaderboard] = useState();
    const getGroupLeaderboard = async (group_id, time_frame, view_option, month) => {
        const params = {
            group_id,
            time_frame,
            view_option,
            month
        };

        await axios
            .get(`/api/group/leaderboard`, {
                params,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                setLeaderboard(res.data.leaderboard);
                // setActiveBeacon(res.data);
            });
    };
    const [clubs, setClubs] = useState([]);
    const getClubs = async (user_id) => {
        const params = {
            user_id
        };

        await axios
            .get(`/api/clubs`, {
                params
            })
            .then((res) => {
                setClubs(res.data.data);
                // setLeaderboard(res.data.leaderboard);
                // setActiveBeacon(res.data);
            });
    };

    return (
        <GroupContext.Provider
            value={{
                isLoading,
                getGroupInfo,
                getUserGroups,
                groups,
                groupInfo,
                error,
                searchGroups,
                joinGroup,
                leaveGroup,
                createGroup,
                noGroups,
                groupSearchResults,
                getGroupImages,
                groupImages,
                isImagesLoading,
                inviteUser,
                updateGroupNotifications,
                sendBeacon,
                beacons,
                setBeacons,
                stopBeacon,
                getBeacons,
                addBeaconResponse,
                setGroupSearchResults,
                joiningGroup,
                updateGroup,
                makeAdmin,
                addGroupImage,
                imageUploading,
                getGroupLeaderboard,
                leaderboard,
                setLeaderboard,
                getClubs,
                clubs,
                getClubGroup,
                setGroupInfo
            }}
        >
            {children}
        </GroupContext.Provider>
    );
}
