import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';

const DemoRequest = () => {
    // const { tracking_id } = useParams();
    const [queryParams] = useSearchParams();
    const tracking_id = queryParams.get('tracking_id');

    useEffect(() => {
        if (tracking_id) {
            console.log('hello');
            axios.post(`/api/email-cta-press/${tracking_id}`);
        }
    }, [tracking_id]);

    useEffect(() => {
        // Function to load the Calendly script
        const loadScript = () => {
            const script = document.createElement('script');
            script.src = 'https://assets.calendly.com/assets/external/widget.js';
            script.async = true;
            document.body.appendChild(script);
        };

        // Check if the script is already included
        if (!document.querySelector('script[src="https://assets.calendly.com/assets/external/widget.js"]')) {
            loadScript();
        }

        // Optionally, clean up when the component unmounts
        return () => {
            // This removes the script from the body if the component unmounts
            const script = document.querySelector('script[src="https://assets.calendly.com/assets/external/widget.js"]');
            if (script) {
                script.remove();
            }
        };
    }, []);

    return (
        <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/jordan-stacked/stacked-demo"
            style={{ minWidth: '320px', height: '700px' }} // Corrected style here
        />
    );
};

export default DemoRequest;
