// material-ui
import { useTheme } from '@mui/material/styles';
import { Container, Grid, Stack, Typography } from '@mui/material';

// project imports
import FadeInWhenVisible from './Animation';
import SubCard from 'ui-component/cards/SubCard';
import Avatar from 'ui-component/extended/Avatar';
import { gridSpacing } from 'store/constant';

// assets

import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import EventIcon from '@mui/icons-material/Event';
import { AttachMoney, ConfirmationNumber, ConfirmationNumberOutlined, EventOutlined, PeopleAltOutlined } from '@mui/icons-material';
// =============================|| LANDING - FEATURE PAGE ||============================= //

const Testimonials = () => (
    <Container maxWidth="xl">
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Grid container spacing={2} sx={{ mb: 2, justifyContent: 'center' }}>
                    <Grid item xs={12} md={8} mb={4}>
                        <Typography variant="h1" component="div" textAlign="center">
                            Grow your Pickleball business
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Grid xs={12} md={4} p={2}>
                        <Typography variant="h2" style={{ lineHeight: 2 }} color="primary.main">
                            &quot;Ultimately what I needed was a way to run my events with less boots on the ground. Stacked automates
                            everything for you which makes this possible.&quot;
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={4} p={2}>
                        <Typography variant="h2" style={{ lineHeight: 2 }} color="primary.main">
                            &quot;The DUPR integration is killer! I literally just have to click one button and all the matches are
                            uploaded!&quot;
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={4} p={2}>
                        <Typography variant="h2" style={{ lineHeight: 2 }} color="primary.main">
                            &quot;Its a really easy platform and I love that I can see my events analytics to see how much traction its
                            getting&quot;
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Container>
);

export default Testimonials;
