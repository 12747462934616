import React, { useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { FormHelperText, Grid } from '@mui/material';

const mapContainerStyle = {
    width: '100%',
    height: '400px'
};

const center = {
    lat: -34.397,
    lng: 150.644
};
const apiKey = 'AIzaSyDmaBGYH1EjkaybTv8IzfM1TxRB3bhXRUA';
const libraries = ['places'];

const MapComponent = ({ value, onChange, onBlur, helperText, id, name, formik, onSelect, location, changeLocation }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: apiKey, // Replace with your actual API key
        libraries
    });

    // const [location, setLocation] = useState();

    if (loadError) return 'Error loading maps';
    if (!isLoaded) return 'Loading Maps';

    const handlePlaceSelected = (place) => {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        // setLocation({ lat, lng });
        changeLocation(place);
        if (onSelect) {
            onSelect(place);
        } else {
            formik.setFieldValue('location', place);
        }
    };

    return (
        <Grid data-test-id="views/admin/Events/create-event/components/MapComponent">
            <Grid mb={4}>
                <Autocomplete
                    id={id}
                    value={value}
                    defaultValue={value}
                    onChange={onChange}
                    name={name}
                    apiKey={apiKey} // Replace with your actual API key
                    onPlaceSelected={handlePlaceSelected}
                    options={{
                        types: ['establishment'],
                        componentRestrictions: { country: 'us' },
                        fields: ['address_components', 'geometry', 'name', 'formatted_address']
                    }}
                    style={{ width: '100%', borderRadius: 12, padding: 18, border: '1px solid lightgrey' }}
                />
                {helperText && <FormHelperText error>{helperText}</FormHelperText>}
            </Grid>
            {location && (
                <Grid>
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        zoom={15}
                        center={location}
                        clickable={false}
                        draggable={false}
                        editable={false}
                    >
                        <Marker position={location} />
                    </GoogleMap>
                </Grid>
            )}
        </Grid>
    );
};

export default MapComponent;
