/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    CardContent,
    Chip,
    Divider,
    Drawer,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    useMediaQuery,
    TextField,
    FormControl,
    Typography,
    Box,
    IconButton,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableBody,
    Avatar,
    Switch
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Formik, Form } from 'formik';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import useConfig from 'hooks/useConfig';

import MainCard from 'ui-component/cards/MainCard';
import { appDrawerWidth as drawerWidth, gridSpacing } from 'store/constant';

// assets
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import LabelImportantTwoToneIcon from '@mui/icons-material/LabelImportantTwoTone';
import LabelTwoToneIcon from '@mui/icons-material/LabelTwoTone';
import NewReleasesTwoToneIcon from '@mui/icons-material/NewReleasesTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import { PersonOutlineOutlined } from '@mui/icons-material';
import useLeagues from 'hooks/useLeagues';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';

// ==============================|| MAIL DRAWER ||============================== //

const GeneratePoolsDrawer = ({ handleGenerateDrawer, generateDrawerOpen, eventId, leagueId, teamLength }) => {
    console.log('tl', teamLength)
    const theme = useTheme();
    const dispatch = useDispatch();

    const { borderRadius } = useConfig();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('xl'));

    const [error, setError] = useState()
    const [courtsAvailable, setCourtsAvailable] = useState()
    const [poolCount, setPoolCount] = useState();
    const [playUntil, setPlayUntil] = useState(11);
    const [winByTwo, setWinByTwo] = useState(true);
    const { generatePools, getEventPools, setEventPools, generateMatchups } = useLeagues()
    const getRecommendedPoolCount = (totalTeams, numCourts) => {
        const minTeamsPerPool = 4;

        // Calculate the maximum number of pools that can be formed with the minimum team requirement
        const maxPools = Math.floor(totalTeams / minTeamsPerPool);

        // Adjust the number of pools to not exceed the number of courts
        let adjustedPools = Math.min(maxPools, numCourts);

        // If the total number of teams cannot be evenly distributed among the adjusted pools
        // and meet the minimum team requirement, reduce the number of pools
        while (totalTeams % adjustedPools !== 0 && totalTeams / adjustedPools < minTeamsPerPool) {
            adjustedPools--;
        }

        // Ensure there is at least one pool
        adjustedPools = Math.max(adjustedPools, 1);

        // Calculate the recommended pool size
        const recommendedPoolSize = Math.ceil(totalTeams / adjustedPools);

        setPoolCount(adjustedPools);
        return recommendedPoolSize;
    };


    useEffect(() => {
        setCourtsAvailable(teamLength / 4);
    }, [teamLength])

    useEffect(() => {
        getRecommendedPoolCount(teamLength, courtsAvailable)
    }, [teamLength, courtsAvailable])

    const handleGenerationSubmit = () => {
        const params = { event_instance_id: eventId, league_id: leagueId, num_of_groups: courtsAvailable }
        generateMatchups(params)
        // generatePools(eventId, poolCount, playUntil, winByTwo).then(() => {
        //     getEventPools(eventId).then((res) => {
        //         setEventPools(res.data);
        //         handleGenerateDrawer();
        //         dispatch(
        //             openSnackbar({
        //                 open: true,
        //                 message: 'Pools Generated',
        //                 variant: 'alert',
        //                 alert: {
        //                     color: 'success'
        //                 },
        //                 close: true
        //             })
        //         );

        //     })

        // })
    }
    const label = { inputProps: { 'aria-label': 'Size switch demo' } };



    return (
        <Drawer
            sx={{
                ml: generateDrawerOpen ? 3 : 0,
                flexShrink: 0,
                zIndex: 1200,
                overflowX: 'hidden',
                width: { xs: 320, md: 450 },
                '& .MuiDrawer-paper': {
                    height: '100vh',
                    width: { xs: 320, md: 450 },
                    position: 'fixed',
                    border: 'none',
                    borderRadius: '0px'
                }
            }}
            variant="temporary"
            anchor="right"
            open={generateDrawerOpen}
            ModalProps={{ keepMounted: true }}
            onClose={handleGenerateDrawer}
        >
            {generateDrawerOpen && (
                <MainCard
                    sx={{
                        bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50'
                    }}
                    border={!matchDownSM}
                    content={false}
                >

                    <CardContent sx={{ height: matchDownSM ? '100vh' : 'auto' }}>
                        <Grid container spacing={gridSpacing}>
                            {!error ?
                                <Grid item xs={12}>
                                    <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h3"> Generate Matches </Typography>
                                        <Box>
                                            <IconButton size="small" onClick={() => handleGenerateDrawer()}>
                                                <CancelIcon />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                    <Grid mt={4}>

                                        <Typography variant="h5"> Number of Players: {teamLength} </Typography>
                                    </Grid>

                                    <Grid mt={4}>
                                        <Typography variant="h5"> Number of Groups: {poolCount} </Typography>

                                    </Grid>
                                    <Grid mt={4}>
                                        <Typography variant="h5" mb={2}> Play Until: </Typography>
                                        <TextField value={playUntil} onChange={(event) => setPlayUntil(event.target.value)} />
                                    </Grid>
                                    <Grid container mt={4} alignItems="center">
                                        <Typography variant="h5"> Win By Two:</Typography>
                                        <Switch value={winByTwo} onChange={(event) => setWinByTwo(event.target.value)} ml={2} defaultChecked />
                                    </Grid>

                                </Grid>
                                :
                                null
                            }
                        </Grid>
                        <Grid item xs={12} mt={4}>
                            <Button
                                variant="contained"
                                fullWidth
                                type="submit"
                                onClick={handleGenerationSubmit}
                                startIcon={<AutoFixHighIcon stroke={1.5} size="20px" />}
                            >
                                Generate Matches
                            </Button>
                        </Grid>
                    </CardContent>
                </MainCard>
            )}
        </Drawer>
    );
};

GeneratePoolsDrawer.propTypes = {
    eventId: PropTypes.number,
    teamLength: PropTypes.number,
    handleGenerateDrawer: PropTypes.func,
    generateDrawerOpen: PropTypes.bool
};

export default GeneratePoolsDrawer;
