import React, { useState, useEffect } from 'react';
import { Divider, Avatar, Button, Grid, Typography, Checkbox, Collapse } from '@mui/material';
import { format, parseISO, isSameDay } from 'date-fns';
import { useParams } from 'react-router-dom';
import ThumbUpAlt from '@mui/icons-material/ThumbUpAlt';
import useLeagues from 'hooks/useLeagues';
import usePosts from 'hooks/usePosts';
import useAuth from 'hooks/useAuth';
import { AccessTimeFilled } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import HelpIcon from '@mui/icons-material/Help';
import Error from 'views/pages/maintenance/Error';
import getReadableDateTime from 'utils/get_readable_datetime';
import FormControlLabel from '@mui/material/FormControlLabel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';

const SchedulingPoll = () => {
    const { id } = useParams();
    const { getPlayingGroupPoll, playingGroupPoll, loading } = useLeagues();
    const [groupedPollOptions, setGroupedPollOptions] = useState({});
    const [showBestTimes, setShowBestTimes] = useState({});
    const [selectedSlots, setSelectedSlots] = useState({});
    const [usersResponded, setUsersResponded] = useState({});
    const [userIdToName, setUserIdToName] = useState({});
    const [pollIdToReadableTime, setPollIdToReadableTime] = useState({});
    const [bestTimes, setBestTimes] = useState({});
    const { addNewPollResponse, deletePollResponse } = usePosts();
    const { user } = useAuth();
    const [isInGroup, setIsInGroup] = useState();

    useEffect(() => {
        getPlayingGroupPoll(id);
    }, [id]);
    const checkIfUserInGroup = () => playingGroupPoll?.players?.some((player) => player.user_id === user?.id);

    useEffect(() => {
        if (playingGroupPoll?.poll?.poll_options && user?.id) {
            setIsInGroup(checkIfUserInGroup());
            const usersResponded = {};
            const bestTimes = {};
            const pollIdToReadableTime = {};

            // Group the poll options by day and count responses
            const groupedOptions = playingGroupPoll.poll.poll_options.reduce((acc, option) => {
                const date = parseISO(option.option_text);
                const dayKey = format(date, 'yyyy-MM-dd');
                if (!acc[dayKey]) {
                    acc[dayKey] = [];
                }
                const isCurrentUserResponse = option.poll_responses.some((response) => response.user_id === user?.id);
                acc[dayKey].push({
                    date,
                    count: option.poll_responses.length,
                    optionId: option?.id,
                    poll_responses: option.poll_responses
                });
                option?.poll_responses.forEach((pollResponse) => {
                    usersResponded[pollResponse.user_id] = true;
                });
                if (option?.poll_responses.length > 0) {
                    let timeList = bestTimes[option?.poll_responses.length];
                    if (timeList == null) {
                        timeList = [];
                    }
                    timeList.push({
                        responses: option?.poll_responses,
                        optionId: option?.id
                    });
                    bestTimes[option?.poll_responses.length] = timeList;
                }

                // Set the initial selected slots based on the current user's responses
                const slotKey = format(date, 'yyyy-MM-dd HH:mm:ss');
                if (isCurrentUserResponse) {
                    setSelectedSlots((prev) => ({ ...prev, [slotKey]: true }));
                }
                pollIdToReadableTime[option.id] = option.option_text;

                return acc;
            }, {});

            setPollIdToReadableTime(pollIdToReadableTime);

            const userIdToName = {};
            playingGroupPoll?.players.forEach((player) => {
                userIdToName[player.user_id] = player.user.name.split(' ')[0];
            });
            setUserIdToName(userIdToName);

            setUsersResponded(usersResponded);
            setBestTimes(bestTimes);

            setGroupedPollOptions(groupedOptions);
        }
    }, [playingGroupPoll, user?.id]);

    const toggleSlot = (slotKey, optionId) => {
        const pollId = playingGroupPoll?.poll?.id;
        const userId = user?.id;

        // Optimistically update the UI to reflect the change immediately
        setGroupedPollOptions((currentOptions) => {
            const newOptions = { ...currentOptions };
            const dayKey = slotKey.split(' ')[0];

            const optionIndex = newOptions[dayKey].findIndex((option) => option.optionId === optionId);
            if (optionIndex !== -1) {
                if (selectedSlots[slotKey]) {
                    // Remove the response
                    deletePollResponse(pollId, optionId, userId);
                    newOptions[dayKey][optionIndex].count -= 1;
                    newOptions[dayKey][optionIndex].poll_responses = newOptions[dayKey][optionIndex].poll_responses.filter(
                        (response) => response.user_id !== userId
                    );
                } else {
                    // Add the response
                    addNewPollResponse(pollId, optionId, userId);
                    newOptions[dayKey][optionIndex].count += 1;
                    newOptions[dayKey][optionIndex].poll_responses.push({ user_id: userId });
                    usersResponded[userId] = true;
                    setUsersResponded(usersResponded);
                }
            }
            return newOptions;
        });

        setSelectedSlots((prev) => ({ ...prev, [slotKey]: !prev[slotKey] }));
    };

    if (loading.playingGroupPoll || isInGroup === undefined) {
        return <Grid />; // Or null, or a skeleton loader, etc.
    }

    if (!isInGroup) {
        return <Error />;
    }

    function renderTimeGrouping(times) {
        const content = [];
        times.forEach((time) => {
            content.push(
                <Grid container key={time.optionId} mt={1}>
                    <Typography ml={1} sx={{ fontSize: 14, fontWeight: 'bold' }}>
                        {moment(pollIdToReadableTime[time.optionId]).local().format('ddd, MMM D h:mmA')}
                    </Typography>
                    <Typography ml={1} sx={{ fontSize: 14 }}>
                        {' - '}
                        {time.responses.map((response) => userIdToName[response.user_id]).join(', ')}
                    </Typography>
                </Grid>
            );
        });
        return content;
    }

    const toggleShowBestTimes = () => {
        setShowBestTimes((prev) => !prev);
    };

    function toggleDropdown() {
        return (
            <>
                <ChevronRightIcon sx={{ display: showBestTimes ? 'none' : 'block' }} />
                <ExpandMoreIcon sx={{ display: showBestTimes ? 'block' : 'none' }} />
            </>
        );
    }

    function renderBestTimes() {
        if (Object.keys(bestTimes).length === 0) {
            return <></>;
        }
        const content = [];
        for (let i = 5; i >= 1; i -= 1) {
            if (bestTimes[i]) {
                content.push(
                    <Grid key={i} item>
                        <Grid container alignItems="center" mt={2}>
                            <Typography variant="h4">{`${i} Vote${i === 1 ? '' : 's'}`}</Typography>
                            {renderTimeGrouping(bestTimes[i])}
                        </Grid>
                    </Grid>
                );
            }
        }
        return (
            <>
                <Divider sx={{ borderBottom: '1px solid lightgrey', pt: 1 }} />
                <Grid item mt={2}>
                    <FormControlLabel
                        control={toggleDropdown()}
                        label={<Typography variant="h4">Show Best Times</Typography>}
                        onClick={toggleShowBestTimes}
                    />
                </Grid>
                <Collapse in={showBestTimes}>
                    <Grid item>{content}</Grid>
                </Collapse>
            </>
        );
    }

    function renderCheckbox(id) {
        const responded = (
            <Checkbox
                checked
                color="success"
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                sx={{ cursor: 'default' }}
            />
        );
        const noResponse = (
            <Checkbox checked color="warning" icon={<RadioButtonUncheckedIcon />} checkedIcon={<HelpIcon />} sx={{ cursor: 'default' }} />
        );
        return usersResponded[id] ? responded : noResponse;
    }

    function renderPoll() {
        return (
            <Grid container spacing={2} sx={{ marginTop: { xs: 2 }, paddingLeft: { md: 2 } }}>
                <Grid item>
                    <Typography gutterBottom variant="h3">
                        PLAY-TIME POLL
                    </Typography>
                    <Typography>Select all the times you&apos;re available to play</Typography>
                </Grid>
                {Object.entries(groupedPollOptions).map(([day, options]) => (
                    <Grid item xs={12} key={day} mb={2}>
                        <Typography variant="h4">{format(parseISO(day), 'EEEE, MMMM do')}</Typography>
                        {options.map(({ date, count, optionId }) => {
                            const slotKey = format(date, 'yyyy-MM-dd HH:mm:ss');
                            return (
                                <Button
                                    variant={selectedSlots[slotKey] ? 'contained' : 'outlined'}
                                    key={slotKey}
                                    onClick={() => toggleSlot(slotKey, optionId)} // Pass the option ID here
                                    endIcon={
                                        count > 0 ? ( // Show the thumb and count only if there's 1 or more responses
                                            <Grid container alignItems="center">
                                                <ThumbUpAlt fontSize="small" />
                                                <Typography ml={1}>{count}</Typography>
                                            </Grid>
                                        ) : null
                                    }
                                    style={{ borderRadius: 12, marginRight: 12, marginTop: 8 }}
                                >
                                    {getReadableDateTime(date, 'timeCompact')}
                                </Button>
                            );
                        })}
                    </Grid>
                ))}
            </Grid>
        );
    }

    function renderLeagueGroupInfo() {
        return (
            <Grid item>
                <Typography gutterBottom variant="h2" color="secondary">
                    {playingGroupPoll?.league?.name}
                </Typography>
                <Typography variant="h3">
                    {playingGroupPoll?.event_instance?.title}: {playingGroupPoll?.name}
                </Typography>
                <Grid container alignItems="center" mt={2}>
                    <AccessTimeFilled fontSize="medium" />
                    <Typography ml={1} sx={{ fontSize: 18, fontWeight: 'bold' }}>
                        1 hour
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    function renderPlayer(player, index) {
        return (
            <Grid key={index} item>
                <Grid container alignItems="center" mt={0.5}>
                    <Avatar src={player.user?.user_attributes?.profile_picture_url} sx={{ width: 26, height: 26 }} />
                    <Typography ml={1} sx={{ fontSize: 14, fontWeight: 'bold' }}>
                        {player.user?.name} {renderCheckbox(player.user?.id)}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container spacing={2} justifyContent="center" sx={{ minHeight: '100vh' }}>
            <Grid
                item
                xs={12}
                md={4}
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: 2,
                    borderRight: { xs: 'none', md: '3px solid lightgrey' },
                    borderBottom: { md: 'none', xs: '3px solid lightgrey' }
                }}
            >
                {renderLeagueGroupInfo()}
                <Grid item mt={2}>
                    <Typography variant="h3"> Players </Typography>
                    {playingGroupPoll?.players?.map((player, index) => renderPlayer(player, index))}
                </Grid>
                {renderBestTimes()}
            </Grid>
            <Grid md={8} xs={12} item sx={{ flexGrow: 1 }}>
                {renderPoll()}
            </Grid>
        </Grid>
    );
};

export default SchedulingPoll;
