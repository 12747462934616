// project imports
import GroupHome from 'views/groups';
import WhiteLabelLayout from 'layout/WhiteLabelLayout';
import EmailCheck from 'views/pages/authentication/authentication3/EmailCheck';

// login routing

// ==============================|| AUTH ROUTING ||============================== //

const SubdomainRoutes = {
    path: '/',
    element: <WhiteLabelLayout />,
    children: [
        {
            path: '/c',
            element: <GroupHome />,
            children: [
                {
                    path: ':tab',
                    element: <GroupHome />
                }
            ]
        },
        {
            path: '/auth',
            element: <EmailCheck />
        }
    ]
};

export default SubdomainRoutes;
