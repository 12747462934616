// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconTrophy
} from '@tabler/icons';

// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconTrophy
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const events = {
    id: 'events',
    title: <FormattedMessage id="events" />,
    icon: icons.IconApps,
    type: 'group',
    children: [
        {
            id: 'Tournaments',
            title: <FormattedMessage id="tournaments" />,
            type: 'item',
            url: '/comp/tournament',
            icon: icons.IconTrophy,
            breadcrumbs: false
        },
        {
            id: 'Leagues',
            title: <FormattedMessage id="leagues" />,
            type: 'item',
            url: '/comp/league',
            icon: icons.IconLayoutKanban,
            breadcrumbs: false
        }
    ]
};

export default events;
