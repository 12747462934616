import { Skeleton, TableCell, TableRow } from '@mui/material';
import moment from 'moment';

export const getTimeSinceEntered = (entered, assignedAt, unit = 'seconds') => {
    const now = assignedAt ? moment.utc(assignedAt) : moment.utc();
    const enteredMoment = moment.utc(entered);

    const diff = now.diff(enteredMoment, unit);

    return diff;
};

export const renderLoadingSkeleton = (numRows, numCells) =>
    Array.from({ length: numRows }).map((_, rowIndex) => (
        <TableRow key={rowIndex}>
            {Array.from({ length: numCells }).map((_, cellIndex) => (
                <TableCell key={cellIndex}>
                    <Skeleton variant="text" />
                </TableCell>
            ))}
        </TableRow>
    ));

export const getNextHour = (hours) => {
    const now = new Date();
    now.setHours(now.getHours() + hours);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);

    return moment(now).format('YYYY-MM-DDTHH:mm:ss');
};
