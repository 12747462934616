import React, { useEffect, useState } from 'react';
import { AddCircleTwoTone, ArrowForward, Close, MoreVert, Search, SubdirectoryArrowRight } from '@mui/icons-material';
import {
    Button,
    ButtonGroup,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    TextField,
    InputAdornment,
    Select,
    InputLabel,
    Box,
    Chip,
    MenuItem,
    FormControl,
    Drawer,
    CardContent
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import MainCard from 'ui-component/cards/MainCard';
import { dispatch } from 'store';
import { searchUsers } from 'store/slices/user';
import UserListCard from 'ui-component/cards/UserListCard';
import { useSelector } from 'react-redux';
import useLeagues from 'hooks/useLeagues';
import { openSnackbar } from 'store/slices/snackbar';

const ReplaceUserDrawer = ({ handleDrawerOpen, openUserSidebar, leagueId, existingUsers, replacedUser, isSub, eventId, invisibleBg }) => {
    const [search, setSearch] = useState('');
    const { userList } = useSelector((state) => state.user);
    const [replacement, setReplacement] = useState();
    const [groupOptions, setGroupOptions] = useState([]);
    const { replaceLeaguePlayer, getUserPools } = useLeagues();
    const [selectedGroup, setSelectedGroup] = useState('');

    const handleSearch = (event) => {
        const newInputValue = event;
        setSearch(newInputValue);
        dispatch(searchUsers(newInputValue));
    };

    const theme = useTheme();

    const matchDownSM = useMediaQuery(theme.breakpoints.down('xl'));

    useEffect(() => {
        getUserPools(replacedUser?.id, eventId).then((res) => {
            setGroupOptions(res);
            setSelectedGroup(res[0]?.playing_group?.id);
        });
    }, [replacedUser]);

    const handleInputChange = (event) => {
        setSelectedGroup(event.target.value);
    };

    return (
        <Drawer
            sx={{
                ml: openUserSidebar ? 3 : 0,
                flexShrink: 0,
                zIndex: 1200,
                overflowX: 'hidden',
                width: { xs: 320, md: 450 },
                '& .MuiDrawer-paper': {
                    height: '100vh',
                    width: { xs: 320, md: 450 },
                    position: 'fixed',
                    border: 'none',
                    borderRadius: '0px'
                }
            }}
            variant="temporary"
            anchor="right"
            open={openUserSidebar}
            ModalProps={{ keepMounted: true }}
            onClose={() => {
                setReplacement();
                handleDrawerOpen();
            }}
            aria-modal="true"
            BackdropProps={{ invisible: invisibleBg }}
        >
            <MainCard
                sx={{
                    bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50',
                    height: '100vh'
                }}
                border={!matchDownSM}
                content={false}
                title={!isSub ? 'Replace Player' : 'Substitute Player'}
                secondary={
                    <IconButton
                        aria-label="settings"
                        onClick={() => {
                            setReplacement();
                            handleDrawerOpen();
                        }}
                    >
                        <Close />
                    </IconButton>
                }
            >
                <CardContent sx={{ height: matchDownSM ? '100vh' : 'auto' }}>
                    <Grid alignItems="center">
                        <Grid>
                            <UserListCard
                                user={replacedUser}
                                // handleUserSelection={() => selectUserAction(user.id)}
                                clickable={false}
                            />
                            {/* <Typography variant="h4"> Jordan Thiel</Typography> */}
                        </Grid>
                        <Grid>
                            <SubdirectoryArrowRight fontSize="large" />
                        </Grid>
                        <Grid ml={4} mt={-4}>
                            <UserListCard
                                user={replacement}
                                // handleUserSelection={() => selectUserAction(user.id)}
                                clickable={false}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} mb={2} mt={2}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search fontSize="small" />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(e) => handleSearch(e.target.value)}
                                placeholder="Search Player"
                                // value={''} // Bind value
                                value={search}
                                size="small"
                            />
                            {search?.length > 0 && (
                                <MainCard border={false} elevation={4} content={false} boxShadow>
                                    {/* sx={{ backgroundColor: 'white', padding: 1, boxShadow: theme.customShadows.primary }}> */}
                                    {userList?.map((user, index) => {
                                        const userExists = existingUsers.some((element) => element.id === user.id);
                                        return (
                                            <Grid key={index} item xs={12}>
                                                <UserListCard
                                                    user={user}
                                                    handleUserSelection={() => {
                                                        setReplacement(user);
                                                        setSearch('');
                                                    }}
                                                    clickable={!userExists}
                                                    showCheck={userExists}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </MainCard>
                            )}
                            {isSub && groupOptions.length > 0 && (
                                <Grid item mt={2} xs={12}>
                                    <Select
                                        value={selectedGroup || ''}
                                        onChange={handleInputChange}
                                        label="Replacement Group"
                                        id="grouped-select"
                                        fullWidth
                                    >
                                        {groupOptions.map((item) => (
                                            <MenuItem key={item?.playing_group?.id} value={item?.playing_group?.id}>
                                                {item?.playing_group?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            )}
                            <Grid mt={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!replacement}
                                    type="submit" // This will trigger form submission
                                    sx={{ mr: theme.spacing(3), paddingX: 6, paddingY: 1 }}
                                    onClick={() => {
                                        replaceLeaguePlayer(replacedUser.id, replacement.id, leagueId, isSub, eventId, selectedGroup)
                                            .then(() => {
                                                setReplacement();
                                                handleDrawerOpen();
                                                dispatch(
                                                    openSnackbar({
                                                        open: true,
                                                        message: 'Player replaced',
                                                        variant: 'alert',
                                                        alert: {
                                                            color: 'success'
                                                        },
                                                        close: true
                                                    })
                                                );
                                            })
                                            .catch(() => {
                                                dispatch(
                                                    openSnackbar({
                                                        open: true,
                                                        message: 'Error replacing player',
                                                        variant: 'alert',
                                                        alert: {
                                                            color: 'error'
                                                        },
                                                        close: true
                                                    })
                                                );
                                            });
                                    }}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </FormControl>
                    </Grid>
                </CardContent>
            </MainCard>
        </Drawer>
    );
};
export default ReplaceUserDrawer;
