/* eslint-disable prettier/prettier */
import { Button, Grid, InputAdornment, TextField, IconButton, Typography, Stack } from '@mui/material';
import { Add, Remove, BorderColor, Delete } from '@mui/icons-material';
import { useFormik } from 'formik';
import useClubs from 'hooks/useClubs';
import FormSection from 'views/admin/Events/create-event/components/FormSection';
import { gridSpacing } from 'store/constant';
import { act, useEffect, useState } from 'react';
import actionSnackbar from 'ui-component/actionSnackbar';
import MembershipList from '../../../../../components/MembershipList';
import SaveFooter from 'views/admin/AdminHub/components/SaveFooter';
import FormComponents from 'ui-component/FormComponents';
import CardModal from 'ui-component/CardModal';
import { useParams } from 'react-router-dom';

const MembershipPricing = () => {

    const { membershipDetails, updateClubMembership } = useClubs();

    const initialValues = {
        id: membershipDetails?.id || undefined,
        pricing: membershipDetails?.pricing?.map((item) => ({ ...item, price: item.price / 100 })) || [],
        initiation_fee: membershipDetails?.initiation_fee / 100 || 0
    };

    const formik = useFormik({
        initialValues,
        // validationSchema,
        onSubmit: (values) => {
            const updatedValues = { ...values, pricing: values.pricing?.map((item) => ({ ...item, price: item.price * 100 })), initiation_fee: values.initiation_fee * 100 };

            updateClubMembership(updatedValues)
                .then(() => {
                    actionSnackbar(true, 'Pricing updated');
                })
                .catch(() => {
                    actionSnackbar(false, 'Failed to update pricing');
                });
        }
    });

    useEffect(() => {
        formik.resetForm({
            values: initialValues
        });
    }, [membershipDetails]);


    const handleAddPricing = () => {
        const hasMonthlyPricing = formik.values.pricing.some((item) => item.term === 'monthly');


        formik.setFieldValue('pricing', [...formik.values.pricing, { term: hasMonthlyPricing ? 'annual' : 'monthly', price: '' }]);
        // setPricing([...pricing, { term: '', price: '' }]);
    };

    const handleRemovePricing = (index) => {
        const newPricing = formik.values.pricing.filter((_, i) => i !== index);
        formik.setFieldValue('pricing', newPricing);
    };

    const handlePricingChange = (index, field, value) => {
        const newPricing = formik.values.pricing.map((item, i) => (i === index ? { ...item, [field]: value } : item));
        formik.setFieldValue('pricing', newPricing);
    };

    const handleCancel = () => {
        formik.resetForm();
        actionSnackbar(true, 'Changes discarded');
    };



    const pricing = [
        {
            type: 'currency',
            inputType: 'number',
            id: 'initiation_fee',
            label: 'Initiation Fee',
            tooltip: 'The amount that a member must pay to join the club',
            value: formik.values.initiation_fee,
            onChange: (values) => formik.setFieldValue('initiation_fee', values.value),
            smWidth: 6,
            inputWidth: 4,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            showPrefix: false
        },
        {
            type: 'component',
            component: (
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom mb={2}>
                        Pricing
                    </Typography>
                    <Grid container spacing={gridSpacing} alignItems="center">
                        {formik.values.pricing.map((item, index) => (
                            <Grid item key={index} xs={12}>
                                <Grid container spacing={gridSpacing} alignItems="center">
                                    <Grid item xs={4}>
                                        <FormComponents
                                            input={{
                                                type: 'select',
                                                value: item.term,
                                                label: 'Term',
                                                onChange: (event) => handlePricingChange(index, 'term', event.target.value),
                                                onBlur: formik.handleBlur,
                                                options: [
                                                    { value: "monthly", label: "Monthly" },
                                                    { value: "annual", label: "Annual" },
                                                ]
                                            }}
                                            formik={formik}
                                        />

                                    </Grid>
                                    <Grid item xs={7}>
                                        <FormComponents
                                            input={{
                                                type: 'currency',
                                                value: item.price,
                                                onChange: (values) => handlePricingChange(index, 'price', values.value),
                                                label: 'Price',
                                                placeholder: 'Price'
                                            }}
                                            formik={formik}
                                        />

                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => handleRemovePricing(index)}
                                            disabled={formik.values.pricing.length === 1}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        {!(
                            formik.values.pricing.some((item) => item.term === 'monthly') &&
                            formik.values.pricing.some((item) => item.term === 'annual')
                        ) && (
                                <Grid item xs={12}>
                                    <Button variant="outlined" color="primary" startIcon={<Add />} onClick={handleAddPricing}>
                                        Add Pricing
                                    </Button>
                                </Grid>
                            )}
                    </Grid>
                </Grid>
            )
        },

    ]


    return (
        <Grid container spacing={gridSpacing} sx={{ paddingBottom: 10 }}>
            {/* <Grid item xs={12} lg={3} marginRight={{ xs: 0, md: 1 }} marginBottom={{ xs: 2, lg: 0 }}>
                <MembershipList clubId={clubId} toggleMembershipCreationModal={toggleMembershipCreationModal} />
            </Grid> */}
            {membershipDetails && (
                <Grid item xs={12} lg={12} marginTop={{ xs: 2, md: 0 }}>
                    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                        <Grid container spacing={gridSpacing} mt={2} pl={1}>


                            {pricing.map((item, index) => (
                                <FormComponents key={index} input={item} />
                            ))}
                        </Grid>

                        <SaveFooter handleCancel={handleCancel} />

                    </form>
                </Grid>
            )}
        </Grid>
    );
};

export default MembershipPricing;
