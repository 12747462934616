// material-ui
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';

// project imports
import FadeInWhenVisible from './Animation';
import SubCard from 'ui-component/cards/SubCard';
import Avatar from 'ui-component/extended/Avatar';
import { gridSpacing } from 'store/constant';
import { useTheme, styled } from '@mui/material/styles';

// assets
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import EventIcon from '@mui/icons-material/Event';
import { BarChart, ConfirmationNumber, OpenInBrowser } from '@mui/icons-material';
import { motion } from 'framer-motion';

// =============================|| LANDING - FEATURE PAGE ||============================= //

const HeaderAnimationImage = styled('img')(({ theme }) => ({
    maxWidth: '100%', // Ensure the image can scale up to the full width of its container
    maxHeight: '500px', // Set maximum height
    width: 'auto', // Maintain aspect ratio
    height: 'auto', // Allow height to adjust based on the width to maintain aspect ratio
    filter: 'drop-shadow(0px 0px 50px rgb(33 150 243 / 30%))',
    display: 'block', // Use block display to utilize margin auto for centering
    marginLeft: 'auto', // Center align image
    marginRight: 'auto' // Center align image
}));

const GenericSection = ({ sectionContent, flexDirection, isDark, title, subtitle, image, imageWidth = '35%' }) => {
    const theme = useTheme();

    const CopySection = ({ content }) => {
        const { title, descriptor, icon, iconbgcolor } = content;
        return (
            <Grid container flexDirection="column" mb={6}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: iconbgcolor,
                        borderRadius: '50%',
                        width: 42,
                        height: 42,
                        marginBottom: 2
                    }}
                >
                    {icon}
                </Box>
                <Typography gutterBottom variant="h3" color={isDark ? 'white' : 'primary.main'}>
                    {title}
                </Typography>
                <Typography variant="body1" color={isDark ? 'white' : 'primary.main'}>
                    {descriptor}
                </Typography>
            </Grid>
        );
    };

    return (
        <Container maxWidth="xl">
            <Grid container spacing={gridSpacing} position="relative">
                <Grid item xs={12} justifyContent="center" textAlign="center" flexGrow={1} zIndex={2}>
                    <Typography gutterBottom variant="h1" color={isDark ? 'white' : 'primary.main'}>
                        {title}
                    </Typography>
                    <Typography variant="h4" color={isDark ? 'white' : 'primary.main'}>
                        {subtitle}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container mt={6} sx={{ flexDirection }}>
                <Grid item xs={12} md={4}>
                    {sectionContent.map((content) => (
                        <CopySection content={content} />
                    ))}
                </Grid>
                <Grid item xs={0} md={2} />
                <Grid item xs={12} md={6} justifyContent="center" textAlign="center" alignItems="center">
                    <motion.div
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                            type: 'spring',
                            stiffness: 150,
                            damping: 30,
                            delay: 0.2
                        }}
                    >
                        <HeaderAnimationImage src={image} alt="Admin Dashboard" />
                    </motion.div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default GenericSection;
