/* eslint-disable default-param-last */
/* eslint-disable no-plusplus */
import React, { useState, createContext, useReducer } from 'react';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import { API_URL as apiUrl } from 'config';
import moment from 'moment';
import useEvents from 'hooks/useEvents';
import useGroups from 'hooks/useGroups';

export const MarketingContext = createContext();
const updateNestedStateKey = (keyPath, value) => ({
    type: 'UPDATE_NESTED_STATE_KEY',
    payload: { keyPath, value }
});
function updateNestedStateKeyReducer(state, keyPath, value) {
    const keys = keyPath.split('.');
    const nestedState = { ...state };

    let currentLevel = nestedState;
    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        currentLevel[key] = { ...currentLevel[key] };
        currentLevel = currentLevel[key];
    }

    currentLevel[keys[keys.length - 1]] = value;

    return nestedState;
}
const initialState = {
    loading: { emailCampaigns: false, emailCampaign: false },
    isLocationLoaded: false,
    emailCampaigns: [],
    emailCampaign: null,
    templates: []
};

function marketingReducer(state, action) {
    switch (action.type) {
        case 'SET_STATE':
            return { ...state, [action.payload.key]: action.payload.value };
        case 'RESET_STATE':
            return initialState; // Resetting to initial state
        case 'UPDATE_NESTED_STATE_KEY':
            return updateNestedStateKeyReducer(state, action.payload.keyPath, action.payload.value);
        default:
            return state;
    }
}

export function MarketingProvider({ children }) {
    const [state, dispatch] = useReducer(marketingReducer, initialState);

    const setValue = (key, value) => {
        dispatch({ type: 'SET_STATE', payload: { key, value } });
    };

    const resetState = () => {
        dispatch({ type: 'RESET_STATE' });
    };
    const updateValue = (keyPath, value) => {
        dispatch(updateNestedStateKey(keyPath, value));
    };

    const getEmailCampaigns = async (club_id = null, league_id = null, is_internal = false) => {
        updateValue('loading.emailCampaigns', true);
        const response = await axios
            .get(`/api/email/campaigns`, { params: { club_id, league_id, is_internal } })
            .then((res) => {
                setValue('emailCampaigns', res.data.data);

                return res.data.events;
            })
            .finally(() => {
                updateValue('loading.emailCampaigns', false);
            });

        return response;
    };
    const getEmailCampaign = async (id) => {
        updateValue('loading.emailCampaign', true);
        const response = await axios
            .get(`/api/email/campaign/${id}`)
            .then((res) => {
                setValue('emailCampaign', res.data.data);
                return res.data;
            })
            .finally(() => {
                updateValue('loading.emailCampaign', false);
            });
        return response;
    };
    const createEmailCampaign = async (data) => {
        console.log('data', data);
        updateValue('loading.newEmailCampaign', true);
        const response = await axios
            .post(`/api/email/campaign`, data)
            .then((res) => {
                setValue('emailCampaign', res.data.data);
                setValue('emailCampaigns', [...state.emailCampaigns, res.data.data]);
                return res.data.data;
            })
            .finally(() => {
                updateValue('loading.newEmailCampaign', false);
            });
        return response;
    };
    const createCampaignEmail = async (data) => {
        console.log('data', data);
        updateValue('loading.newCampaignEmail', true);
        const response = await axios
            .post(`/api/email/campaign/email`, data)
            .then((res) => {
                setValue('emailCampaign', {
                    ...state.emailCampaign,
                    campaign_emails: [...state.emailCampaign.campaign_emails, res.data.data]
                });
                return res.data.data;
            })
            .finally(() => {
                updateValue('loading.newCampaignEmail', false);
            });
        return response;
    };
    const deleteCampaignEmail = async (id) => {
        const response = await axios.delete(`/api/email/campaign/email/${id}`).then((res) => {
            setValue('emailCampaign', {
                ...state.emailCampaign,
                campaign_emails: state.emailCampaign.campaign_emails.filter((item) => item.id !== id)
            });
            return res.data;
        });
        return response;
    };
    const getEmail = async (id) => {
        const response = await axios.get(`/api/email/campaign/email/${id}`).then((res) => {
            setValue('email', res.data.data);
            return res.data;
        });
        return response;
    };
    const updateEmail = async (id, data) => {
        updateValue('loading.updateEmail', true);
        console.log('data', data);
        const updatedData = {
            ...data,
            send_time: data.send_time ? moment(data.send_time).utc().format('YYYY-MM-DD HH:mm:ss') : data.send_time
        };
        console.log('updatedData', updatedData);
        const response = await axios
            .put(`/api/email/campaign/email/${id}`, updatedData)
            .then((res) => {
                setValue('email', res.data.data);
                return res.data;
            })
            .finally(() => {
                updateValue('loading.updateEmail', false);
            });
        return response;
    };

    const sendEmail = async (id, html, test_emails = null, urls = []) => {
        const response = await axios.post(`/api/email/send/${id}`, { html, test_emails, urls });
        return response;
    };
    const getTemplates = async (user_id, type) => {
        const response = await axios.get(`/api/email/templates`, { params: { user_id, type } }).then((res) => {
            console.log('res', res);
            setValue('templates', res.data.data);
            return res.data;
        });
        return response;
    };
    const saveTemplate = async (id) => {
        const response = await axios.post(`/api/email/template/${id}`).then((res) => res.data);
        return response;
    };
    const deleteCampaign = async (id) => {
        const response = await axios.delete(`/api/email/campaign/${id}`).then((res) => {
            setValue(
                'emailCampaigns',
                state.emailCampaigns.filter((item) => item.id !== id)
            );
            return res.data;
        });
        return response;
    };
    return (
        <MarketingContext.Provider
            value={{
                ...state,
                setValue,
                updateValue,
                resetState,
                getEmailCampaigns,
                getEmailCampaign,
                createEmailCampaign,
                createCampaignEmail,
                deleteCampaignEmail,
                getEmail,
                updateEmail,
                sendEmail,
                getTemplates,
                saveTemplate,
                deleteCampaign
            }}
        >
            {children}
        </MarketingContext.Provider>
    );
}
