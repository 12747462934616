import React, { useEffect, useState } from 'react';

// material-ui
import { Button, Grid, Typography, Box } from '@mui/material';

// project imports
import GalleryCard from 'ui-component/cards/GalleryCard';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { useDispatch, useSelector } from 'store';
import { getGallery } from 'store/slices/user';
import LeagueCard from './LeagueCard';
import useLeagues from 'hooks/useLeagues';
import useClubs from 'hooks/useClubs';
import ProductCard from 'ui-component/cards/ProductCard';

// ==============================|| SOCIAL PROFILE - GALLERY ||============================== //

const Leagues = ({ groupId, clubId }) => {
    const { getClub, club, getClubEvents, clubEvents, upcomingCount, pastCount } = useClubs();
    const [selected, setSelected] = useState(true);
    useEffect(() => {
        getClub(clubId);
    }, [clubId]);

    useEffect(() => {
        getClubEvents(Number(clubId), selected ? 'upcoming' : 'past');
    }, [clubId, selected]);

    const createMarkup = (htmlContent) => ({ __html: htmlContent });

    const onSelected = () => {
        setSelected(!selected);
    };

    return (
        <Grid data-test-id="views/groups/leagues/index">
            <Box
                sx={{
                    p: 2
                }}
            >
                {' '}
                <Grid container mt={2}>
                    <Button variant={selected ? 'contained' : 'outlined'} item sx={{ borderRadius: 25 }} onClick={onSelected}>
                        Upcoming ({upcomingCount})
                    </Button>
                    <Button
                        variant={!selected ? 'contained' : 'outlined'}
                        onClick={onSelected}
                        item
                        sx={{ borderRadius: 25, marginLeft: 2 }}
                    >
                        Past ({pastCount})
                    </Button>
                </Grid>
                <Grid container spacing={gridSpacing} mt={4}>
                    {clubEvents?.map((product, index) => (
                        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                            <ProductCard
                                id={product.id}
                                slug={product.slug}
                                images={product.item_images}
                                name={product.name}
                                description={parseInt(product.description, 10)}
                                offerPrice={parseInt(product.entry_fee, 10)}
                                rating={product.rating}
                                startDate={product.start_date}
                                endDate={product.end_date}
                                location={product.facility?.name}
                                playersRegistered={product.players_registered}
                                eventType={product?.event_type}
                                isRegistered={product.is_registered}
                                groupId={product.group_id}
                                isExternal={product.is_external}
                                externalEventId={product.external_event_id}
                                externalParticipants={product.external_participants}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Grid>
    );
};

export default Leagues;
