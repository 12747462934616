import { FormControl, Grid, InputLabel, ListSubheader, MenuItem, Select } from '@mui/material';
import useLeagues from 'hooks/useLeagues';
import useTournaments from 'hooks/useTournaments';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import capitalizeWords from 'utils/capitalizeWords';
import MedalRound from 'views/admin/AdminHub/TournamentManager/MedalRound';
import Pools from 'views/admin/AdminHub/TournamentManager/Pools';
import LeagueMatches from 'views/groups/leagues/LeagueMatches';

const AppPools = () => {
    const { tournamentId } = useParams();
    const [type, setType] = useState('tournament');
    const navigate = useNavigate();
    const [options, setOptions] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState('');
    const [selectedPool, setSelectedPool] = useState(0);

    const { getLeagueEventList, leagueEventList, getLeagueGroups, setEventPools, eventPools, getEventPools, getEventBrackets, setValue } =
        useLeagues();
    const { getTournamentHub, tournamentDetails } = useTournaments();
    const handleInputChange = (event) => {
        setSelectedEvent(event.target.value);
    };

    let menuItems;
    if (type === 'league') {
        // For leagues, list events without grouping
        menuItems = options.map((item) => (
            // <MenuItem disabled={(item.status !== 'completed' && item.status !== 'in_progress')} key={item.id} value={item}>{item.title}</MenuItem>
            <MenuItem key={item.id} value={item}>
                {item.title}
            </MenuItem>
        ));
    } else {
        // For tournaments, group events
        const uniqueGroups = Array.from(new Set(options.map((option) => option.group)));
        const groupedOptions = uniqueGroups.map((group) => ({
            group,
            items: options.filter((option) => option.group === group)
        }));
        menuItems = groupedOptions.flatMap(({ group, items }) => [
            <ListSubheader sx={{ color: 'primary.dark' }} key={group}>
                {capitalizeWords(group)}
            </ListSubheader>,
            ...items.map((item) => (
                <MenuItem key={item.id} value={item}>
                    {item.title}
                </MenuItem>
            ))
        ]);
    }

    useEffect(() => {
        if (tournamentId) {
            getTournamentHub(tournamentId);
        }
    }, [tournamentId]);

    useEffect(() => {
        if (tournamentDetails?.event_type) {
            setType(tournamentDetails?.event_type);
            getLeagueEventList(tournamentId, tournamentDetails?.event_type);
        }
    }, [tournamentDetails]);

    useEffect(() => {
        setOptions(leagueEventList || []);
        setSelectedEvent(leagueEventList[0]);
    }, [leagueEventList]);

    useEffect(() => {
        if (type === 'league') {
            console.log('league running 235');
            getLeagueGroups(selectedEvent?.id).then((res) => {
                console.log('237', res);
                if (res?.length > 0) {
                    setEventPools(res);
                    setSelectedPool(res[0]);
                } else {
                    setEventPools([]);
                    setSelectedPool('');
                }
            });
        } else if (type === 'tournament') {
            getEventPools(selectedEvent?.id).then((res) => {
                setValue('eventbracket', res.bracket);
                if (res.data?.length > 0) {
                    setEventPools(res.data);

                    setSelectedPool(res.data[0]);
                } else {
                    setEventPools([]);
                    setSelectedPool('');
                }
                if (res.bracket) {
                    setSelectedPool(res.bracket);
                }
            });
        }
    }, [selectedEvent]);

    const poolAction = () => {
        navigate(`/mobile/app/matches/${tournamentId}`);
    };

    return (
        // <Grid p={1}>
        //     <Grid item xs={12} md={12}>
        //         <FormControl fullWidth sx={{ minWidth: 120, my: { xs: 2, md: 0 } }}>
        //             <InputLabel htmlFor="grouped-select">Events</InputLabel>
        //             <Select value={selectedEvent || ''} onChange={handleInputChange} label="Grouping" id="grouped-select">
        //                 {menuItems}
        //             </Select>
        //         </FormControl>
        //     </Grid>
        //     <Pools
        //         type={type}
        //         poolAction={poolAction}
        //         selectedEvent={selectedEvent}
        //         eventPools={eventPools}
        //         setSelectedPool={setSelectedPool}
        //         eventId={selectedEvent?.id}
        //         eventName={selectedEvent?.title}
        //         showAdmin={false}
        //     />
        // </Grid>
        <LeagueMatches leagueId={tournamentId} isAdmin={false} type={type} />
    );
};

export default AppPools;
