// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'axios';
import { dispatch } from '../index';
import useAuth from 'hooks/useAuth';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    products: [],
    product: null,
    relatedProducts: [],
    reviews: [],
    addresses: [],
    isLoading: false,
    lastPage: null,
    location: { city: null, latitude: null, longitude: null },
    userTransactions: []
};

const slice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        setValue(state, action) {
            state = action.payload;
        },

        // GET PRODUCTS
        getProductsSuccess(state, action) {
            state.products = action.payload;
        },
        addProductsSuccess(state, action) {
            state.products = [...state.products, ...action.payload];
        },
        getLocationSuccess(state, action) {
            state.location = action.payload;
        },
        addLastPage(state, action) {
            state.lastPage = action.payload;
        },

        // FILTER PRODUCTS
        filterProductsSuccess(state, action) {
            state.products = action.payload;
        },
        isLoading(state, action) {
            state.isLoading = action.payload;
        },

        // GET PRODUCT
        getProductSuccess(state, action) {
            state.product = action.payload;
        },

        // GET RELATED PRODUCTS
        getRelatedProductsSuccess(state, action) {
            state.relatedProducts = action.payload;
        },

        // GET PRODUCT REVIEWS
        getProductReviewsSuccess(state, action) {
            state.reviews = action.payload;
        },

        // GET ADDRESSES
        getAddressesSuccess(state, action) {
            state.addresses = action.payload;
        },

        // ADD ADDRESS
        addAddressSuccess(state, action) {
            state.addresses = action.payload;
        },

        // EDIT ADDRESS
        editAddressSuccess(state, action) {
            state.addresses = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProducts(type, page, loadMore, filter, club_id = null) {
    return async () => {
        try {
            if (!loadMore) {
                dispatch(slice.actions.isLoading(true));
            }

            const response = await axios.get('/api/leagues', { params: { request_type: 'web', event_type: type, page, filter, club_id } });
            dispatch(slice.actions.isLoading(false));

            if (!loadMore) {
                dispatch(slice.actions.getProductsSuccess(response.data.leagues.data));
            } else {
                dispatch(slice.actions.addProductsSuccess(response.data.leagues.data));
            }
            dispatch(slice.actions.addLastPage(response.data.leagues.last_page));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getLocation() {
    // eslint-disable-next-line consistent-return
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.get(`/api/user/location`);
            const locationData = {
                city: `${response.data.secondaryLocation.cityName}, ${response.data.secondaryLocation.areaCode}, ${response.data.secondaryLocation.countryCode} `,
                latitude: response.data.secondaryLocation.latitude,
                longitude: response.data.secondaryLocation.longitude
            };
            dispatch(slice.actions.getLocationSuccess(locationData));
            return locationData;

            // dispatch(slice.actions.isLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getLeagues() {
    return async () => {
        try {
            const response = await axios.get('/api/leagues');
            console.log('getting events', response.data);
            dispatch(slice.actions.getProductsSuccess(response.data.data.leagues));
        } catch (error) {
            console.log('ERRORR');
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filterProducts(filter) {
    return async () => {
        try {
            const response = await axios.post('/api/products/filter', { filter });
            dispatch(slice.actions.filterProductsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProduct(slug, type) {
    return async () => {
        try {
            // Correctly passing 'type' as a query parameter
            const response = await axios.get(`/api/product/details`, {
                params: { type, slug }
            });
            dispatch(slice.actions.getProductSuccess(response.data.product));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getProductId(id) {
    return async () => {
        try {
            // Correctly passing 'type' as a query parameter
            const response = await axios.get(`/api/product/details/${id}`);
            console.log('response', response);
            dispatch(slice.actions.getProductSuccess(response.data.product));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRelatedProducts(id) {
    return async () => {
        try {
            const response = await axios.post('/api/product/related', { id });
            dispatch(slice.actions.getRelatedProductsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProductReviews() {
    return async () => {
        try {
            const response = await axios.get('/api/review/list');
            dispatch(slice.actions.getProductReviewsSuccess(response.data.productReviews));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAddresses() {
    return async () => {
        try {
            const response = await axios.get('/api/address/list');
            dispatch(slice.actions.getAddressesSuccess(response.data.address));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addAddress(address) {
    return async () => {
        try {
            // const response = await axios.post('/api/address/new', address);
            dispatch(slice.actions.addAddressSuccess([address]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function removeAddress(address) {
    return async () => {
        try {
            // const response = await axios.post('/api/address/new', address);
            dispatch(slice.actions.addAddressSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editAddress(address) {
    return async () => {
        try {
            const response = await axios.post('/api/address/edit', address);
            dispatch(slice.actions.editAddressSuccess(response.data.address));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getTransactions() {
    return async (getState) => {
        const { product } = getState();

        try {
            const response = await axios.post('/api/transactions');
            dispatch(slice.actions.setValue(product.userTransactions, response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
