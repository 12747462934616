// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconHome, IconDeviceAnalytics, IconCalendarEvent, IconBuildingBank, IconSettings } from '@tabler/icons';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

const icons = {
    IconHome,
    IconDeviceAnalytics,
    AdminPanelSettingsOutlinedIcon,
    IconCalendarEvent,
    ChatOutlinedIcon,
    IconBuildingBank,
    IconSettings,
    ReceiptLongIcon
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const adminDashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    icon: icons.IconHome,
    type: 'group',
    breadcrumbs: true,
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/clubs/home',
            icon: icons.IconHome,
            breadcrumbs: false
        },
        // {
        //     id: 'chat',
        //     title: <FormattedMessage id="chat" />,
        //     type: 'item',
        //     url: '/chat',
        //     icon: icons.ChatOutlinedIcon,
        //     breadcrumbs: false
        // },
        {
            id: 'orders',
            title: <FormattedMessage id="orders" />,
            type: 'item',
            url: '/clubs/orders',
            icon: icons.ReceiptLongIcon,
            breadcrumbs: false
        },
        {
            id: 'finance',
            title: <FormattedMessage id="finance" />,
            type: 'item',
            url: '/clubs/finance',
            icon: icons.IconBuildingBank,
            breadcrumbs: false
        },
        {
            id: 'club_settings',
            title: <FormattedMessage id="club_settings" />,
            type: 'item',
            url: '/clubs/info',
            icon: icons.IconSettings,
            breadcrumbs: true
        }
    ]
};

export default adminDashboard;
