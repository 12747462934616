// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'axios';
import { dispatch } from '../index';
import { API_URL } from '../../config';
import useAuth from 'hooks/useAuth';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    userList: [],
    usersS2: [],
    followers: [],
    friendRequests: [],
    friends: [],
    gallery: [],
    posts: [],
    detailCards: [],
    simpleCards: [],
    profileCards: [],
    user: null,
    lastPage: 10,
    tournamentRoster: [],
    userCredits: [],
    totalParticipants: null,
    startCount: 0,
    completedOrders: []
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET USERS STYLE 1
        getUsersListSuccess(state, action) {
            state.userList = action.payload.data;
            state.lastPage = action.payload.last_page;
        },
        getRosterSuccess(state, action) {
            state.tournamentRoster = action.payload.data;
            state.totalParticipants = action.payload.total;
            state.startCount = action.payload.from;

            state.lastPage = action.payload.last_page;
        },
        checkInCountSuccess(state, action) {
            state.totalCheckedIn = action.payload;
        },
        checkInUserSuccess(state, action) {
            const { id, checkedIn } = action.payload;
            if (checkedIn) {
                state.totalCheckedIn += 1;
            } else {
                state.totalCheckedIn -= 1;
            }
            const rosterIndex = state.tournamentRoster.findIndex((item) => item.id === id);
            if (rosterIndex !== -1) {
                state.tournamentRoster[rosterIndex].checked_in = checkedIn;
            }
        },
        // GET USERS STYLE 2
        getUsersListStyle2Success(state, action) {
            state.usersS2 = action.payload;
        },

        // GET FOLLOWERS
        getFollowersSuccess(state, action) {
            state.followers = action.payload;
        },

        // FILTER FOLLOWERS
        filterFollowersSuccess(state, action) {
            state.followers = action.payload;
        },

        // GET FRIEND REQUESTS
        getFriendRequestsSuccess(state, action) {
            state.friendRequests = action.payload;
        },

        // FILTER FRIEND REQUESTS
        filterFriendRequestsSuccess(state, action) {
            state.friendRequests = action.payload;
        },

        // GET FRIENDS
        getFriendsSuccess(state, action) {
            state.friends = action.payload;
        },

        // FILTER FRIENDS
        filterFriendsSuccess(state, action) {
            state.friends = action.payload;
        },

        // GET GALLERY
        getGallerySuccess(state, action) {
            state.gallery = action.payload;
        },

        // GET POSTS
        getPostsSuccess(state, action) {
            state.posts = action.payload;
        },

        // EDIT COMMENT
        editCommentSuccess(state, action) {
            state.posts = action.payload;
        },

        // ADD COMMENT
        addCommentSuccess(state, action) {
            state.posts = action.payload;
        },

        // ADD REPLY
        addReplySuccess(state, action) {
            state.posts = action.payload;
        },

        // LIKE POST
        likePostSuccess(state, action) {
            state.posts = action.payload;
        },

        // LIKE COMMENT
        likeCommentSuccess(state, action) {
            state.posts = action.payload;
        },

        // LIKE REPLY
        likeReplySuccess(state, action) {
            state.posts = action.payload;
        },

        // GET DETAIL CARDS
        getDetailCardsSuccess(state, action) {
            state.detailCards = action.payload;
        },

        // FILTER DETAIL CARDS
        filterDetailCardsSuccess(state, action) {
            state.detailCards = action.payload;
        },

        // GET SIMPLE CARDS
        getSimpleCardsSuccess(state, action) {
            state.simpleCards = action.payload;
        },

        // FILTER SIMPLE CARDS
        filterSimpleCardsSuccess(state, action) {
            state.simpleCards = action.payload;
        },

        // GET PROFILE CARDS
        getProfileCardsSuccess(state, action) {
            state.profileCards = action.payload;
        },

        // FILTER PROFILE CARDS
        filterProfileCardsSuccess(state, action) {
            state.profileCards = action.payload;
        },
        completedOrderSuccess(state, action) {
            state.completedOrders = action.payload.data;
            state.totalOrders = action.payload.total;
        },
        completedRefundSuccess(state, action) {
            const newItem = action.payload;

            const newState = state.completedOrders.map((order) => {
                if (order.id === newItem.id) {
                    return newItem;
                }
                return order;
            });
            state.completedOrders = newState;
        },
        userCreditsSuccess(state, action) {
            state.userCredits = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsersList() {
    return async () => {
        try {
            const response = await axios.get('/api/user-list');
            dispatch(slice.actions.getUsersListSuccess(response.data.users));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function searchUsers(search_input, page_length, page) {
    return async () => {
        try {
            console.log('running', search_input);
            const response = await axios.get('/api/user/search', { params: { search_input, page_length, page } });
            console.log('response.data', response.data);
            console.log('last_page', response.data.data.last_page);

            dispatch(slice.actions.getUsersListSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getTournamentPlayerList(tournament_id, search_input, page_length, page) {
    return async () => {
        try {
            const response = await axios.get('/api/tournament/player-list', { params: { tournament_id, search_input, page_length, page } });
            console.log(response.data.data);
            dispatch(slice.actions.getRosterSuccess(response.data.data));
            dispatch(slice.actions.totalCheckedIn(response.data.checkedIn));
        } catch (error) {
            dispatch(slice.actions.getRosterSuccess([]));
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getLeaguePlayerList(event_instance_id, search_input, page_length, page, tournament_id = null) {
    return async () => {
        try {
            const response = await axios.get('/api/league/player-list', {
                params: { event_instance_id, search_input, page_length, page, tournament_id }
            });
            console.log(response.data.data);
            dispatch(slice.actions.getRosterSuccess(response.data.data));
            dispatch(slice.actions.checkInCountSuccess(response.data.checkedIn));
        } catch (error) {
            console.log('error', error);

            dispatch(slice.actions.getRosterSuccess([]));
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function checkInPlayer(id, checkedIn) {
    return async () => {
        try {
            dispatch(slice.actions.checkInUserSuccess({ id, checkedIn }));

            const response = await axios.put(`/api/tournament/player/check-in/${id}`);

            // dispatch(slice.actions.getRosterSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUsersListStyle2() {
    return async () => {
        try {
            const response = await axios.get('/api/user-list/s2/list');
            dispatch(slice.actions.getUsersListStyle2Success(response.data.users_s2));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFollowers() {
    return async () => {
        try {
            const response = await axios.get('/api/followers/list');
            dispatch(slice.actions.getFollowersSuccess(response.data.followers));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getUserInfo() {
    return async () => {
        try {
            const response = await axios.get('/api/followers/list');
            dispatch(slice.actions.getFollowersSuccess(response.data.followers));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filterFollowers(key) {
    return async () => {
        try {
            const response = await axios.post('/api/followers/filter', { key });
            dispatch(slice.actions.filterFollowersSuccess(response.data.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFriendRequests() {
    return async () => {
        try {
            const response = await axios.get('/api/friend-request/list');
            dispatch(slice.actions.getFriendRequestsSuccess(response.data.friends));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filterFriendRequests(key) {
    return async () => {
        try {
            const response = await axios.post('/api/friend-request/filter', { key });
            dispatch(slice.actions.filterFriendRequestsSuccess(response.data.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFriends() {
    return async () => {
        try {
            const response = await axios.get('/api/friends/list');
            dispatch(slice.actions.getFriendsSuccess(response.data.friends));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filterFriends(key) {
    return async () => {
        try {
            const response = await axios.post('/api/friends/filter', { key });
            dispatch(slice.actions.filterFriendsSuccess(response.data.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getGallery() {
    return async () => {
        try {
            const response = await axios.get('/api/gallery/list');
            dispatch(slice.actions.getGallerySuccess(response.data.gallery));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPosts(user_id) {
    return async () => {
        try {
            const response = await axios.get('/api/posts/feed', { params: { user_id, page: 1 } });
            console.log('posts', response.data.data);
            dispatch(slice.actions.getPostsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editComment(key, id) {
    return async () => {
        try {
            const response = await axios.post('/api/posts/editComment', { key, id });
            dispatch(slice.actions.editCommentSuccess(response.data.posts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addComment(postId, comment) {
    return async () => {
        try {
            const response = await axios.post('/api/comments/add', { postId, comment });
            dispatch(slice.actions.addCommentSuccess(response.data.posts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addReply(postId, commentId, reply) {
    return async () => {
        try {
            const response = await axios.post('/api/replies/add', { postId, commentId, reply });
            dispatch(slice.actions.addReplySuccess(response.data.posts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function likePost(postId) {
    return async () => {
        try {
            const response = await axios.post('/api/posts/list/like', { postId });
            dispatch(slice.actions.likePostSuccess(response.data.posts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function likeComment(postId, commentId) {
    return async () => {
        try {
            const response = await axios.post('/api/comments/list/like', { postId, commentId });
            dispatch(slice.actions.likeCommentSuccess(response.data.posts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function likeReply(postId, commentId, replayId) {
    return async () => {
        try {
            const response = await axios.post('/api/replies/list/like', { postId, commentId, replayId });
            dispatch(slice.actions.likeReplySuccess(response.data.posts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDetailCards() {
    return async () => {
        try {
            const response = await axios.get('/api/details-card/list');
            dispatch(slice.actions.getDetailCardsSuccess(response.data.users));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filterDetailCards(key) {
    return async () => {
        try {
            const response = await axios.post('/api/details-card/filter', { key });
            dispatch(slice.actions.filterDetailCardsSuccess(response.data.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSimpleCards() {
    return async () => {
        try {
            const response = await axios.get('/api/simple-card/list');
            dispatch(slice.actions.getSimpleCardsSuccess(response.data.users));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filterSimpleCards(key) {
    return async () => {
        try {
            const response = await axios.post('/api/simple-card/filter', { key });
            dispatch(slice.actions.filterSimpleCardsSuccess(response.data.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProfileCards() {
    return async () => {
        try {
            const response = await axios.get('/api/profile-card/list');
            dispatch(slice.actions.getProfileCardsSuccess(response.data.users));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filterProfileCards(key) {
    return async () => {
        try {
            const response = await axios.post('/api/profile-card/filter', { key });
            dispatch(slice.actions.filterProfileCardsSuccess(response.data.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getUserCredits(user_id, is_active) {
    return async () => {
        try {
            console.log('is_active user', is_active);
            const response = await axios.get('/api/user/credit', { params: { user_id, is_active } });
            console.log('credits', response.data.data);
            dispatch(slice.actions.userCreditsSuccess(response.data.data));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return null;
        }
    };
}
export function getAdminCompletedOrder(page, pageLength, order, orderBy, eventId) {
    return async () => {
        try {
            const response = await axios.get('/api/completed-orders', { params: { page, pageLength, order, orderBy, eventId } });
            dispatch(slice.actions.completedOrderSuccess(response.data.completed_orders));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return null;
        }
    };
}
export function refundPayment(id, amount) {
    return async () => {
        try {
            const response = await axios.post(`/api/order/refund/${id}`, { amount: amount * 100 });
            dispatch(slice.actions.completedRefundSuccess(response.data.data));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError({ refund: 'Not enough funds in Stripe Balance' }));
            throw error;
        }
    };
}
export function clearError() {
    return async () => {
        dispatch(slice.actions.hasError(null));
    };
}
