import { useContext } from 'react';

import { HelpCenterContext } from 'contexts/HelpCenterContext';

const useHelpCenter = () => {
    const context = useContext(HelpCenterContext);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};

export default useHelpCenter;
