/* eslint-disable prettier/prettier */
import { useEffect, useRef, useState } from 'react';

// material-ui
import { Button, Dialog, useMediaQuery, Grid, Typography, Chip } from '@mui/material';

// third-party
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';

// redux
import { useDispatch, useSelector } from 'store';
import { getEvents, addEvent, updateEvent, removeEvent } from 'store/slices/calendar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import CalendarStyled from './CalendarStyled';
import Toolbar from './Toolbar';

// assets
import AddAlarmTwoToneIcon from '@mui/icons-material/AddAlarmTwoTone';
import { useParams } from 'react-router-dom';
import useEvents from 'hooks/useEvents';
import useAuth from 'hooks/useAuth';

// ==============================|| APPLICATION CALENDAR ||============================== //

const GroupCalendar = ({ groupId, editable = false }) => {
    const dispatch = useDispatch();
    const calendarRef = useRef(null);

    const matchSm = useMediaQuery((theme) => theme.breakpoints.down('md'));

    // fetch events data
    const { getEvents, events } = useEvents();
    const { user } = useAuth();

    useEffect(() => {
        getEvents(user?.id, groupId, 1);
    }, [groupId]);

    const [date, setDate] = useState(new Date());
    const [view, setView] = useState(matchSm ? 'listWeek' : 'dayGridMonth');

    // calendar toolbar events
    const handleDateToday = () => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.today();
            setDate(calendarApi.getDate());
        }
    };

    const handleViewChange = (newView) => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.changeView(newView);
            setView(newView);
        }
    };

    // set calendar view
    useEffect(() => {
        handleViewChange(matchSm ? 'listWeek' : 'dayGridMonth');
    }, [matchSm]);

    const handleDatePrev = () => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.prev();
            setDate(calendarApi.getDate());
        }
    };

    const handleDateNext = () => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.next();
            setDate(calendarApi.getDate());
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRange, setSelectedRange] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);

    // calendar event select/add/edit/delete
    const handleRangeSelect = (arg) => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.unselect();
        }

        setSelectedRange({
            start: arg.start,
            end: arg.end
        });
        setIsModalOpen(true);
    };

    const handleEventSelect = (arg) => {
        if (editable) {
            console.log('arg', arg.event.id)
            if (arg.event.id) {
                console.log('events', events)
                const selectEvent = events.find((event) => parseInt(event.id, 10) === parseInt(arg.event.id, 10));
                console.log('selected Event', selectEvent)
                setSelectedEvent(selectEvent);
            } else {
                setSelectedEvent(null);
            }
            setIsModalOpen(true);
        }
    };

    const handleEventUpdate = async ({ event }) => {
        try {
            dispatch(
                updateEvent({
                    eventId: event.id,
                    update: {
                        allDay: event.allDay,
                        start: event.start,
                        end: event.end
                    }
                })
            );
        } catch (err) {
            console.error(err);
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedEvent(null);
        setSelectedRange(null);
    };

    const handleEventCreate = async (data) => {
        dispatch(addEvent(data));
        handleModalClose();
    };

    const handleUpdateEvent = async (eventId, update) => {
        dispatch(updateEvent({ eventId, update }));
        handleModalClose();
    };

    const handleEventDelete = async (id) => {
        try {
            dispatch(removeEvent(id));
            handleModalClose();
        } catch (err) {
            console.error(err);
        }
    };

    const handleAddClick = () => {
        setIsModalOpen(true);
    };

    const renderEventContent = (eventInfo) => {
        const isFull = eventInfo.event.extendedProps.event_invitations_count >= eventInfo.event.extendedProps.participant_limit;
        return (
            <Grid>
                <Grid container alignItems="center" justifyContent="space-between" mb={0.5}>
                    <Typography variant="h5" color="white">
                        {eventInfo.timeText}
                    </Typography>
                    {eventInfo.event.extendedProps.participant_limit && (
                        <Grid ml={0.5}>
                            <Chip
                                size="small"
                                label={
                                    isFull
                                        ? 'FULL'
                                        : `${eventInfo.event.extendedProps.event_invitations_count} / ${eventInfo.event.extendedProps.participant_limit}`
                                }
                                color="secondary"
                            />
                        </Grid>
                    )}
                </Grid>
                <Typography
                    style={{
                        whiteSpace: 'normal',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '95%'
                    }}
                >
                    {eventInfo.event.title}
                </Typography>
            </Grid>
        );
    };

    return (
        <MainCard
            title="Event Calendar"

        >
            <CalendarStyled>
                <Toolbar
                    date={date}
                    view={view}
                    onClickNext={handleDateNext}
                    onClickPrev={handleDatePrev}
                    onClickToday={handleDateToday}
                    onChangeView={handleViewChange}
                />
                <SubCard>
                    <FullCalendar
                        weekends
                        editable={editable}
                        eventBackgroundColor="#0e264c"
                        droppable={editable}
                        selectable={editable}
                        events={events}
                        ref={calendarRef}
                        rerenderDelay={10}
                        initialDate={date}
                        initialView={view}
                        dayMaxEventRows={3}
                        eventDisplay="block"
                        headerToolbar={false}
                        allDayMaintainDuration
                        eventResizableFromStart
                        select={handleAddClick}
                        eventDrop={handleEventUpdate}
                        eventContent={renderEventContent}
                        eventClick={handleEventSelect}
                        eventResize={handleEventUpdate}
                        height={matchSm ? 'auto' : 720}
                        plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
                    />
                </SubCard>
            </CalendarStyled>

            {/* Dialog renders its body even if not open */}

        </MainCard>
    );
};

export default GroupCalendar;