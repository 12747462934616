/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { Button, Avatar, AvatarGroup, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';
import CondensedTeamCard from 'ui-component/cards/CondensedTeamCard';
import SinglesTeamCard from 'ui-component/cards/SinglesTeamCard';
import ReplaceUserDrawer from 'views/admin/AdminHub/EventInfo/components/ReplaceUserDrawer';
import useAuth from 'hooks/useAuth';

const MatchCard = ({ match, index, toggleSidebar: toggleScoresSidebar, setSelectedMatch, setGames }) => {
    const { playing_group, event_instance, team_a, team_b } = match;
    const theme = useTheme();
    const { user } = useAuth();

    const [openReplaceUserSidebar, setOpenReplaceUserSidebar] = useState(false);
    const [replacedUser, setReplacedUser] = useState();

    const handleReplaceDrawerOpen = () => {
        setOpenReplaceUserSidebar((prev) => !prev);
    };

    useEffect(() => {
        setGames(
            match?.match_games.map((game) => ({
                ...game,
                autoCalculated: false, // add this line
                game_scores:
                    game.game_scores.length > 0
                        ? game.game_scores
                        : [
                              { team_id: match.team_a_id, score: '', game_id: game.id },
                              { team_id: match.team_b_id, score: '', game_id: game.id }
                          ]
            }))
        );
    }, [match]);

    const onClickScoreButton = () => {
        setGames(
            match?.match_games.map((game) => ({
                ...game,
                autoCalculated: false, // add this line
                game_scores:
                    game.game_scores.length > 0
                        ? game.game_scores
                        : [
                              { team_id: match.team_a_id, score: '', game_id: game.id },
                              { team_id: match.team_b_id, score: '', game_id: game.id }
                          ]
            }))
        );
        setSelectedMatch(match);
        toggleScoresSidebar();
    };

    const onClickSubButton = () => {
        handleReplaceDrawerOpen();
        setReplacedUser(user);
    };

    function isDoubles() {
        return team_a?.players?.length > 1;
    }

    function isDoublesLeagueMatch() {
        return match?.event_type === 'league' && isDoubles();
    }

    return (
        <Grid container sx={{ boxShadow: theme.shadows[4], borderRadius: '8px', p: 2, mt: index === 0 ? 0 : 3 }}>
            <Grid item xs={12}>
                <Typography variant="h5">{playing_group?.league?.name}</Typography>
                <Typography variant="subtitle2">
                    {event_instance?.title} - {playing_group?.name}
                </Typography>
            </Grid>
            <Grid item xs={12} container>
                {/* {team_a?.players?.length > 1 && <DoublesTeamCard team_a={team_a} team_b={team_b} />} */}
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} lg={4.5}>
                        {isDoubles() && <CondensedTeamCard party={team_a} match={match} />}
                        {!isDoubles() && <SinglesTeamCard party={team_a} match={match} />}
                    </Grid>
                    <Grid item xs={12} lg={1} ml={2}>
                        <Typography variant="h5"> VS </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4.5}>
                        {isDoubles() && <CondensedTeamCard party={team_b} match={match} />}
                        {!isDoubles() && <SinglesTeamCard party={team_b} match={match} />}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={2} container>
                <AnimateButton>
                    <Button type="submit" variant="contained" color="secondary" onClick={() => onClickScoreButton()}>
                        Scores
                    </Button>
                </AnimateButton>
            </Grid>
            {isDoublesLeagueMatch() && (
                <Grid item xs={12} sm={2} container>
                    <AnimateButton>
                        <Button type="submit" variant="contained" color="error" onClick={() => onClickSubButton()}>
                            Sub me
                        </Button>
                    </AnimateButton>
                </Grid>
            )}
            <ReplaceUserDrawer
                openUserSidebar={openReplaceUserSidebar}
                handleDrawerOpen={handleReplaceDrawerOpen}
                leagueId={match?.league_id}
                existingUsers={[]}
                replacedUser={user}
                isSub
                eventId={match?.event_instance_id} // match.event_id?
            />
        </Grid>
    );
};

export default MatchCard;
