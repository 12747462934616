import axios from 'axios';

// Replace 'YOUR_BASE_URL' with the URL you want to set
const baseURL = 'http://localhost:8000';

export const axiosInstance = axios.create({
    baseURL: 'http://localhost:8000'
    // baseURL: 'https://api.stackedpickleball.com'
});

// interceptor for http
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('Axios error:', error);
        return Promise.reject((error.response && error.response.data) || 'Wrong Services');
    }
);

axiosInstance.interceptors.request.use((request) => {
    console.log('Starting Request', request.url);
    return request;
});

export default axiosInstance;
