import dashboard from './dashboard';
import widget from './widget';
import events from './events';
import forms from './forms';
import elements from './elements';
import pages from './pages';
import utilities from './utilities';
import support from './support';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // items: [dashboard, widget, application, forms, elements, pages, utilities, support, other]
    items: [dashboard, events]
};

export default menuItems;
