import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    CardContent,
    Checkbox,
    Grid,
    InputAdornment,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography
} from '@mui/material';

// third-party

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Avatar from 'ui-component/extended/Avatar';
import Chip from 'ui-component/extended/Chip';
import { useDispatch, useSelector } from 'store';
import { getProducts } from 'store/slices/product';

// assets
import SearchIcon from '@mui/icons-material/Search';
import useLeagues from 'hooks/useLeagues';
import { openSnackbar } from 'store/slices/snackbar';
import { getUserCredits } from 'store/slices/user';
import useAuth from 'hooks/useAuth';
import getReadableDateTime from 'utils/get_readable_datetime';
import CurrencyFormat from 'react-currency-format';

// table sort

// table header options
const headCells = [
    {
        id: 'id',
        numeric: true,
        label: '#',
        align: 'left'
    },
    {
        id: 'name',
        numeric: false,
        label: 'Event Name',
        align: 'left'
    },
    {
        id: 'credit_date',
        numeric: false,
        label: 'Credit Awared',
        align: 'left'
    },
    {
        id: 'club',
        numeric: false,
        label: 'Club',
        align: 'left'
    },
    {
        id: 'credit_amount',
        numeric: true,
        label: 'Credit Amount',
        align: 'left'
    }
];

// ==============================|| TABLE HEADER ||============================== //

function EnhancedTableHead({ onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, selected }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox" sx={{ pl: 3 }}>
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts'
                        }}
                    />
                </TableCell> */}
                {numSelected > 0 && (
                    <TableCell padding="none" colSpan={7}>
                        <EnhancedTableToolbar numSelected={selected?.length} />
                    </TableCell>
                )}
                {numSelected <= 0 &&
                    headCells?.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Typography component="span" sx={{ display: 'none' }}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Typography>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    selected: PropTypes.array,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 2,
            pr: 1,
            color: numSelected > 0 ? 'secondary.main' : 'inherit'
        }}
    >
        {numSelected > 0 ? (
            <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="h4" component="div">
                {numSelected} Selected
            </Typography>
        ) : (
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                Nutrition
            </Typography>
        )}

        {/* {numSelected > 0 && (
            <Tooltip title="Delete">
                <IconButton size="large">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        )} */}
    </Toolbar>
);

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
};

// ==============================|| PRODUCT LIST ||============================== //

const Credit = ({ eventId, type, leagueId }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [search, setSearch] = useState('');
    const [rows, setRows] = useState([]);
    const [initialRows, setInitialRows] = useState([]);
    const { userCredits } = useSelector((state) => state.user);
    const [selected, setSelected] = useState([]);
    const [isActive, setIsActive] = useState(1);
    const [activeAmount, setActiveAmount] = useState();
    const [inactiveAmount, setInactiveAmount] = useState();

    const { user } = useAuth();

    useEffect(() => {
        console.log('running');
        dispatch(getUserCredits(user?.id, isActive)).then((res) => {
            setActiveAmount(res?.activeAmount);
            setInactiveAmount(res?.inactiveAmount);
        });
    }, [user?.id, isActive]);

    useEffect(() => {
        setInitialRows(userCredits);
    }, [userCredits]);
    useEffect(() => {
        setRows(initialRows);
    }, [initialRows]);

    const handleSearch = (event) => {
        const newString = event?.target.value;
        setSearch(newString || '');
        if (newString === '') {
            setRows(initialRows);
        } else if (newString) {
            const newRows = initialRows?.filter((row) => {
                let matches = true;
                console.log('row', row);
                const properties = ['id', 'name', 'club'];
                let containsQuery = false;

                properties.forEach((property) => {
                    console.log('propery', property);
                    // if (row[property]) {
                    if (row[property]?.toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                    // }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setRows(newRows);
        } else {
            getProducts();
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelectedId = rows?.map((n) => n.invitation_id);
            setSelected(newSelectedId);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, invitation_id) => {
        const selectedIndex = selected.indexOf(invitation_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, invitation_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected?.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected?.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected?.slice(0, selectedIndex), selected?.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };
    const onSelected = (newState) => {
        setIsActive(newState);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

    return (
        <MainCard title={`Credits (${userCredits.length})`} content={false} data-test-id="views/user/pages/Credits">
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search Credits"
                            value={search}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                        {/* <Grid container mt={2}> */}
                        <Button variant={isActive ? 'contained' : 'outlined'} item sx={{ borderRadius: 25 }} onClick={() => onSelected(1)}>
                            Active ({activeAmount})
                        </Button>
                        <Button
                            variant={!isActive ? 'contained' : 'outlined'}
                            onClick={() => onSelected(0)}
                            item
                            sx={{ borderRadius: 25, marginLeft: 2 }}
                        >
                            Previous ({inactiveAmount})
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>

            {/* table */}
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows?.length}
                        theme={theme}
                        selected={false}
                        numSelected={0}
                    />
                    <TableBody>
                        {rows &&
                            rows.map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell
                                            align="left"
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            onClick={(event) => handleClick(event, row.name)}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            {index + 1}
                                        </TableCell>
                                        <TableCell>{row.product?.name}</TableCell>

                                        <TableCell>{getReadableDateTime(row.created_at)}</TableCell>
                                        <TableCell>{row.club?.name}</TableCell>

                                        <TableCell>
                                            <CurrencyFormat
                                                decimalScale={2}
                                                fixedDecimalScale
                                                value={row.credit_amount / 100}
                                                displayType="text"
                                                thousandSeparator
                                                prefix="$"
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default Credit;
