// material-ui
import { Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

function truncateRating(rating) {
    if (rating && typeof rating === 'string') {
        return rating.substring(0, 4);
    }
    return rating;
}

export default function PlayerRatings({ userAttributes, ml }) {
    if (!userAttributes) {
        return <></>;
    }
    const { singles_self_rating, doubles_self_rating } = userAttributes;
    let { rating, singles_dupr_rating, dupr_rating } = userAttributes;

    singles_dupr_rating = truncateRating(singles_dupr_rating);
    dupr_rating = truncateRating(dupr_rating);
    rating = truncateRating(rating);

    let singlesRating = 'NR';
    let doublesRating = 'NR';

    if (singles_dupr_rating && singles_dupr_rating !== 'NR') {
        singlesRating = singles_dupr_rating;
    } else if (singles_self_rating) {
        singlesRating = `~${singles_self_rating}`;
    }

    if (dupr_rating && dupr_rating !== 'NR') {
        doublesRating = dupr_rating;
    } else if (rating && rating !== 'NR' && rating !== doubles_self_rating) {
        doublesRating = rating;
    } else if (doubles_self_rating) {
        doublesRating = `~${doubles_self_rating}`;
    }

    function getFullRatings(singlesRating, doublesRating) {
        return (
            <Stack direction="row" alignItems="center" gap={0.5} ml={ml} data-test-id="views/components/playerRatings.component">
                <PersonIcon sx={{ fontSize: 18, color: 'grey' }} />
                <Typography sx={{ mt: 0.2 }} variant="caption">
                    {singlesRating}
                </Typography>
                <PeopleIcon sx={{ fontSize: 21, ml: 1.5, mt: 0.15, color: 'grey' }} />
                <Typography sx={{ mt: 0.2 }} variant="caption">
                    {doublesRating}
                </Typography>
            </Stack>
        );
    }

    if (singlesRating === 'NR' && doublesRating === 'NR' && !userAttributes?.dupr_id) {
        return <Tooltip title="User does not have a rating">{getFullRatings(singlesRating, doublesRating)}</Tooltip>;
    }
    return getFullRatings(singlesRating, doublesRating);
}
