import React, { useMemo, useEffect, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Grid, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Customization from '../Customization';
import TopRightMenuButton from './TopRightMenuButton';
import HorizontalBar from './HorizontalBar';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { WindowContext } from 'contexts/WindowContext';

// assets
import { IconChevronRight } from '@tabler/icons';
import useAuth from 'hooks/useAuth';
import EventMenu from 'views/admin/AdminHub/EventMenu';
import SupportBar from './SupportBar';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, layout, isLoggedIn }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: isLoggedIn && layout === LAYOUT_CONST.VERTICAL_LAYOUT ? -(drawerWidth - 72) : '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            marginTop: layout === 'horizontal' ? 135 : 118
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: isLoggedIn && '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginTop: 88
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: isLoggedIn && '0px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '0px',
            marginTop: 88
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: layout === 'horizontal' ? '10px' : 0,
        marginTop: layout === 'horizontal' ? 135 : 88,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.up('md')]: {
            marginTop: layout === 'horizontal' ? 135 : 118
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '10px',
            marginTop: 88
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
            marginTop: 88
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const [localDrawerState, setLocalDrawerState] = useState();

    const { drawerOpen } = useSelector((state) => state.menu);
    const { container, drawerType, layout } = useConfig();
    const { isLoggedIn } = useAuth();

    const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;
    useEffect(() => {
        const savedDrawer = localStorage.getItem('drawer');
        const savedDrawerState = savedDrawer ? savedDrawer === 'true' : undefined; // Keep undefined or set a default state
        if (savedDrawerState !== undefined) {
            setLocalDrawerState(savedDrawerState);
        }
    }, []);

    const header = useMemo(
        () => (
            <Toolbar sx={{ p: condition ? '10px' : '10px' }}>
                <TopRightMenuButton />
            </Toolbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [layout, matchDownMd]
    );

    return (
        <Box sx={{ display: 'flex' }} data-test-id="layout/MainLayout/index">
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    transition: drawerOpen ? theme.transitions.create('width') : 'none'
                }}
            >
                <SupportBar />
                {header}
            </AppBar>

            {/* horizontal menu-list bar */}
            {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd && <HorizontalBar />}

            {/* drawer */}
            {isLoggedIn && (layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && <Sidebar />}

            {/* main content */}
            <Main theme={theme} open={drawerOpen} layout={layout} isLoggedIn={isLoggedIn}>
                <Container maxWidth={container ? 'lg' : false} {...(!container && { sx: { px: { xs: 0 } } })}>
                    {/* breadcrumb */}
                    <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                    <Outlet />
                </Container>
            </Main>
            {/* <Customization /> */}
        </Box>
    );
};

export default MainLayout;
