/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useMemo, useState, useEffect, useRef } from 'react';

// material-ui
import { Grid, Button } from '@mui/material';

// project imports

// assets
import { SingleEliminationBracket, DoubleEliminationBracket, Match, SVGViewer, createTheme } from '@g-loot/react-tournament-brackets';
import useLeagues from 'hooks/useLeagues';
import ProfileIcon from 'assets/images/icons/profile-blue.png';
import { useParams } from 'react-router-dom';
import ProductEmpty from 'views/application/e-commerce/Products/ProductEmpty';
import emptyImage from 'assets/images/empty.png';



const MedalRound = ({ eventId: propEventId, handleGenerateBracketDrawer, isAdmin }) => {
    const { eventId: paramEventId } = useParams(); // Always retrieve eventId from useParams

    // Use eventId from props if it's available; otherwise, use eventId from useParams
    const eventId = propEventId || paramEventId;
    // const [width, height] = useWindowSize();
    // const finalWidth = Math.max(width - 50, 500);
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };

        // Set the dimensions at load
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const svgWrapper = useMemo(
        () =>
            ({ children, ...props }) =>
            (
                <SVGViewer SVGBackground="#f7f8fe" width={width} height={height} {...props}>
                    {children}
                </SVGViewer>
            ),
        [width, height] // Recompute when dimensions change
    );

    const containerRef = useRef(null);
    const [svgWidth, setSvgWidth] = useState('100%');

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setSvgWidth(`${containerRef.current.offsetWidth}px`);
            }
        };

        window.addEventListener('resize', updateWidth);
        updateWidth(); // Initial setting

        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    const [partySelected, setPartySelected] = useState();
    const [showParty, setShowParty] = useState(false);

    const { getEventBracket, matches } = useLeagues();
    const [bracketType, setBracketType] = useState('double');

    useEffect(() => {
        if (eventId) {
            getEventBracket(eventId).then((res) => {
                setBracketType(res?.type);
            });
        }
    }, [eventId]);

    function determineColors(game) {
        if (
            !(
                game &&
                game.game_scores &&
                game.game_scores[0] &&
                game.game_scores[1] &&
                game.game_scores[0].score &&
                game.game_scores[1].score
            )
        ) {
            return;
        }
        if (game.game_scores[0].score > game.game_scores[1].score) {
            game.game_scores[0].color = 'green';
            game.game_scores[1].color = 'black';
        } else {
            game.game_scores[0].color = 'black';
            game.game_scores[1].color = 'green';
        }
    }

    const MatchCardHtml = ({ onPartyClick, party, onMouseEnter, won, match }) => (
        <button
            type="button"
            onClick={() => onPartyClick(party.id, true)}
            onMouseEnter={() => onMouseEnter(party.id)}
            style={{
                outline: 'none',
                cursor: 'pointer',
                boxShadow: 'none',
                backgroundColor: 'white',
                padding: 8,
                borderRadius: 6,
                paddingRight: 2,
                minHeight: 40,
                opacity: won || match.state !== 'SCORE_DONE' ? 1 : 0.3,
                border: showParty && partySelected === party.id ? '2px solid green' : 'none',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            {/* <div style={{ flexDirection: 'column' }}>
                <div style={{ fontSize: 10 }}> C: {match.id}</div>
                <div style={{ fontSize: 10 }}> W: {match.nextMatchId}</div>
                <div style={{ fontSize: 10 }}> L: {match.nextLooserMatchId}</div>
            </div> */}
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ fontWeight: 600, marginRight: 8 }}> {party?.bracket_seed}</div>

                {party?.players?.length > 0 ? (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                marginBottom: 8,
                                marginRight: 6,
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            {party.players?.map((player, index) => (
                                <div key={index} style={{ marginLeft: index > 0 ? -10 : 0 }}>
                                    {player?.user?.user_attributes?.profile_picture_url ? (
                                        <img
                                            alt="Player"
                                            src={player?.user?.user_attributes?.profile_picture_url}
                                            style={{ width: 26, height: 26, borderRadius: '50%' }}
                                        />
                                    ) : (
                                        <img alt="Player" src={ProfileIcon} style={{ width: 26, height: 26, borderRadius: '50%' }} />
                                    )}
                                </div>
                            ))}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                            {party.players?.map((player, index) => (
                                <div key={index} style={{ marginRight: 12, fontSize: 12, fontWeight: 600, textAlign: 'left', color: 'black' }}>
                                    {player?.user?.name}
                                </div>
                            ))}
                        </div>
                    </div>
                ) : match.walk_over ? (
                    <div>BYE</div>
                ) : (
                    <div> -- </div>
                )}
            </div>
            {match.winner_id === party.id && (
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: 6 }}>
                    <div style={{ color: 'green', border: '1px solid green', borderRadius: '15px', fontSize: 8, padding: 3 }}>Won</div>
                </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: 6 }}>
                {match?.match_games?.length > 0 &&
                    match?.match_games?.map((game, index) => (
                        <div style={{ padding: 1 }}>
                            {determineColors(game)}
                            {game.game_scores
                                .filter((score) => score.team_id === party.id)
                                .map((filteredScore, scoreIndex) => (
                                    <div
                                        key={scoreIndex}
                                        style={{
                                            color: filteredScore.color || 'black',
                                            fontSize: 16,
                                            fontWeight: 'bolder',
                                            marginLeft: 5,
                                            padding: 2,
                                            paddingLeft: filteredScore.score < 10 ? 7 : 3,
                                            paddingRight: filteredScore.score < 10 ? 7 : 3,
                                            borderRadius: '5px',
                                            border: '1px solid lightgrey'
                                        }}
                                    >
                                        {filteredScore.score}
                                    </div>
                                ))}
                        </div>
                    ))}
            </div>
        </button>
    );
    const showGenerateButton = () => {
        if (!bracketType) {
            return true;
        } if (bracketType === 'double') {
            if (matches?.upper?.length > 0) {
                return false
            }
            return true

        } if (bracketType === 'single') {
            if (matches.length > 0) {
                return false
            }
            return true

        }
        return false
    }

    return (
        <Grid item xs={12} data-test-id="views/admin/AdminHub/TournamentManager/MedalRound">
            {matches?.length > 0 ? (
                <SingleEliminationBracket
                    matches={matches}
                    onPartyClick={(party, partyWon) => {
                        if (showParty && party === partySelected) {
                            setShowParty(false);
                        } else {
                            setShowParty(true);
                        }
                        setPartySelected(party);
                    }}
                    matchComponent={({
                        match,
                        onMatchClick,
                        onPartyClick,
                        onMouseEnter,
                        onMouseLeave,
                        topParty,
                        bottomParty,
                        topWon,
                        bottomWon,
                        topHovered,
                        bottomHovered,
                        topText,
                        bottomText,
                        connectorColor,
                        computedStyles,
                        teamNameFallback,
                        resultFallback
                    }) => (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                color: '#000',
                                width: '100%',
                                // border: '1px solid #e6e6e6',
                                borderRadius: '7px',
                                height: '100%'
                            }}
                        >
                            <MatchCardHtml
                                onPartyClick={onPartyClick}
                                party={topParty}
                                onMouseEnter={onMouseEnter}
                                won={topWon}
                                match={match}
                            />

                            <div style={{ height: '1px', width: '100%' }} />
                            <MatchCardHtml
                                onPartyClick={onPartyClick}
                                party={bottomParty}
                                onMouseEnter={onMouseEnter}
                                won={bottomWon}
                                match={match}
                            />
                        </div>
                    )}
                // svgWrapper={svgWrapper}
                />
            ) : (
                isAdmin && (showGenerateButton()) && (
                    <Grid mt={8}>
                        <ProductEmpty content="No bracket for this division" helperText="Please create a bracket from the Overview tab first" image={emptyImage} imageWidth={{ xs: 200, sm: 300 }} />
                        {/* <Button onClick={() => handleGenerateBracketDrawer()} variant="contained">
                            Generate Bracket
                        </Button> */}
                    </Grid>
                )
            )}
            {bracketType === 'double' && matches?.upper?.length > 0 && matches?.lower?.length > 0 && (
                <DoubleEliminationBracket
                    matches={matches}
                    onPartyClick={(party, partyWon) => {
                        if (showParty && party === partySelected) {
                            setShowParty(false);
                        } else {
                            setShowParty(true);
                        }
                        setPartySelected(party);
                    }}
                    matchComponent={({
                        match,
                        onMatchClick,
                        onPartyClick,
                        onMouseEnter,
                        onMouseLeave,
                        topParty,
                        bottomParty,
                        topWon,
                        bottomWon,
                        topHovered,
                        bottomHovered,
                        topText,
                        bottomText,
                        connectorColor,
                        computedStyles,
                        teamNameFallback,
                        resultFallback
                    }) => (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                color: '#000',
                                width: '100%',
                                border: '1px solid #e6e6e6',
                                borderRadius: '7px',
                                height: '100%'
                            }}
                        >
                            <MatchCardHtml
                                onPartyClick={onPartyClick}
                                party={topParty}
                                onMouseEnter={onMouseEnter}
                                won={topWon}
                                match={match}
                            />

                            <div style={{ height: '1px', width: '100%' }} />
                            <MatchCardHtml
                                onPartyClick={onPartyClick}
                                party={bottomParty}
                                onMouseEnter={onMouseEnter}
                                won={bottomWon}
                                match={match}
                            />
                        </div>
                    )}
                />
            )}
        </Grid>
    );
};

export default MedalRound;
