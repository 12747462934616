import React, { useState } from 'react';
import {
    Box,
    TextField,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Chip,
    Grid,
    FormControlLabel,
    Switch,
    FormHelperText,
    InputAdornment,
    Checkbox,
    Radio,
    RadioGroup,
    ListItemText,
    Tooltip,
    Stack
} from '@mui/material';
import MapComponent from 'views/admin/Events/create-event/components/MapComponent';
import CustomDateTime from 'views/forms/components/DateTime/CustomDateTime';
import moment from 'moment-timezone';
import { gridSpacing } from 'store/constant';
import { styled } from '@mui/styles';
import CurrencyFormat from 'react-currency-format';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import { Help, QuestionAnswer } from '@mui/icons-material';

const CustomSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
    width: 40,
    height: 24,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: 0
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5
            }
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: theme.palette.primary.main,
            border: '6px solid #fff'
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
        }
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 20,
        height: 20
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500
        })
    }
}));

const FormComponents = ({ index, input, formik }) => {
    const width = input?.width || 12;
    const smWidth = input?.smWidth || 12;
    const inputWidth = input?.inputWidth || 12;
    const timezones = moment.tz.names();
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(input.value);
    const handlePhoneNumberChange = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setFormattedPhoneNumber(formattedPhoneNumber);
        formik.setFieldValue(input.id, e.target.value.replace(/[^\d]/g, ''));
    };
    const renderLabel = (label, tooltip) => {
        if (tooltip) {
            return (
                <Grid container>
                    <Tooltip title={tooltip} placement="right-end">
                        <Stack direction="row">
                            <Typography variant="h5" gutterBottom>
                                {label}
                            </Typography>
                            <Help fontSize="6" sx={{ marginLeft: 1 }} />
                        </Stack>
                    </Tooltip>
                </Grid>
            );
        }
        return (
            <Typography variant="h5" gutterBottom>
                {label}
            </Typography>
        );
    };

    switch (input.type) {
        case 'text':
            return (
                <Grid item xs={width} sm={smWidth}>
                    {input.label && renderLabel(input.label, input.tooltip)}
                    <Grid item xs={12} sm={inputWidth}>
                        <TextField
                            key={index}
                            id={input.id}
                            name={input.id}
                            type={input.inputType}
                            value={input.value}
                            onBlur={input.onBlur}
                            error={input.touched && Boolean(input.error)}
                            helperText={input.touched && input.error}
                            onChange={input.onChange || formik.handleChange}
                            fullWidth
                            placeholder={input.placeholder}
                            disabled={input.disabled}
                            multiline={input.multiline}
                            InputProps={{
                                startAdornment: input.startAdornment,
                                endAdornment: input.endAdornment
                            }}
                        />
                    </Grid>
                </Grid>
            );
        case 'phone-number':
            return (
                <Grid item xs={width} sm={smWidth}>
                    {input.label && renderLabel(input.label)}
                    <TextField
                        key={index}
                        id={input.id}
                        name={input.id}
                        value={formattedPhoneNumber}
                        onBlur={input.onBlur}
                        error={input.touched && Boolean(input.error)}
                        helperText={input.touched && input.error}
                        onChange={handlePhoneNumberChange}
                        fullWidth
                        placeholder={input.placeholder}
                        disabled={input.disabled}
                        multiline={input.multiline}
                        InputProps={{
                            startAdornment: input.startAdornment,
                            endAdornment: input.endAdornment,
                            inputMode: 'numeric'
                        }}
                    />
                </Grid>
            );
        case 'currency':
            return (
                <Grid item xs={width} sm={smWidth}>
                    {input.label && renderLabel(input.label)}
                    <Grid item xs={12} sm={inputWidth}>
                        <CurrencyFormat
                            customInput={TextField}
                            fullWidth
                            placeholder={input.placeholder}
                            value={input.value}
                            onValueChange={input.onChange}
                            thousandSeparator
                            prefix={input.showPrefix !== false ? '$' : ''}
                            decimalScale={2}
                            fixedDecimalScale
                            InputProps={{
                                startAdornment: input.startAdornment,
                                endAdornment: input.endAdornment
                            }}
                        />
                    </Grid>
                    {input.touched && <FormHelperText error>{input.error}</FormHelperText>}
                </Grid>
            );
        case 'select':
            return (
                <Grid item xs={width} sm={smWidth}>
                    {input.label && renderLabel(input.label)}
                    <FormControl fullWidth key={index} margin="dense">
                        <Select
                            id={input.id}
                            name={input.id}
                            value={input.value}
                            onBlur={input.onBlur}
                            onChange={input.onChange || formik.handleChange}
                        >
                            {input.options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {input.touched && <FormHelperText error>{input.error}</FormHelperText>}
                    </FormControl>
                </Grid>
            );
        case 'multi-select':
            return (
                <Grid item xs={width} sm={smWidth}>
                    {input.label && renderLabel(input.label)}
                    <FormControl fullWidth margin="normal" key={index}>
                        <Select
                            id={input.id}
                            name={input.id}
                            value={input.value}
                            onBlur={input.onBlur}
                            onChange={input.onChange}
                            multiple
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected?.map((value) => {
                                        const court = input.options.find((option) => option.value === value);
                                        return <Chip key={value} label={court?.label} />;
                                    })}
                                </Box>
                            )}
                        >
                            {input.options.map((option) => (
                                <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                                    <Checkbox checked={input.value.includes(option.value)} />
                                    <ListItemText primary={option.label} />
                                </MenuItem>
                            ))}
                        </Select>
                        {input.touched && <FormHelperText error>{input.error}</FormHelperText>}
                    </FormControl>
                </Grid>
            );
        case 'multiple-choice':
            return (
                <Grid item xs={width} sm={smWidth}>
                    {input.label && renderLabel(input.label)}
                    <FormControl fullWidth margin="normal" key={index}>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={input.value}
                            onChange={input.onChange}
                        >
                            {input.options.map((option, oIndex) => (
                                <FormControlLabel
                                    key={oIndex}
                                    value={option.option_text}
                                    control={<Radio disabled={input.disabled} />}
                                    label={option.option_text || `Option ${oIndex + 1}`}
                                />
                            ))}
                        </RadioGroup>
                        {input.touched && <FormHelperText error>{input.error}</FormHelperText>}
                    </FormControl>
                </Grid>
            );
        case 'tags':
            return (
                <Grid item xs={width} sm={smWidth}>
                    {input.label && renderLabel(input.label)}
                    <TextField
                        key={index}
                        fullWidth
                        placeholder={input.placeholder}
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        helperText={input.helperText}
                        InputProps={{
                            startAdornment: input.tags.map((tag) => <Chip key={tag} label={tag} size="small" margin="dense" />)
                        }}
                    />
                </Grid>
            );
        case 'location':
            return (
                <Grid item xs={width} sm={smWidth} mb={1}>
                    {input.label && renderLabel(input.label)}
                    <MapComponent
                        key={index}
                        id={input.id}
                        name={input.id}
                        value={input.value}
                        onBlur={input.onBlur}
                        error={input.touched && Boolean(input.error)}
                        helperText={input.touched && input.error}
                        onChange={input.onChange}
                        fullWidth
                        placeholder={input.placeholder}
                        onSelect={input.onSelect}
                        formik={formik}
                        location={input.location}
                        changeLocation={input.changeLocation}
                    />
                </Grid>
            );
        case 'datetime':
            return (
                <Grid item xs={width} sm={smWidth}>
                    {input.label && renderLabel(input.label)}
                    <CustomDateTime
                        key={index}
                        value={input.value}
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        helperText={input.helperText}
                        placeholder={input.placeholder}
                        touched={input.touched}
                        id={input.id}
                        formik={formik}
                        type="datetime"
                        disabled={input.disabled}
                        minDate={input.minDate}
                        error={input.touched && Boolean(input.error)}
                    />
                </Grid>
            );
        case 'date':
            return (
                <Grid item xs={width} sm={smWidth}>
                    {input.label && renderLabel(input.label)}
                    <CustomDateTime
                        key={index}
                        value={input.value}
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        helperText={input.touched && input.error}
                        placeholder={input.placeholder}
                        touched={input.touched}
                        id={input.id}
                        formik={formik}
                        type="date"
                        disabled={input.disabled}
                        minDate={input.minDate}
                        maxDate={input.maxDate}
                        allowedDates={input.allowedDates}
                        error={input.touched && Boolean(input.error)}
                    />
                </Grid>
            );
        case 'time':
            return (
                <Grid item xs={width} sm={smWidth}>
                    {input.label && renderLabel(input.label)}
                    <CustomDateTime
                        key={index}
                        value={input.value}
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        helperText={input.helperText}
                        placeholder={input.placeholder}
                        touched={input.touched}
                        id={input.id}
                        formik={formik}
                        type="time"
                        disabled={input.disabled}
                        minutesStep={input.minutesStep}
                    />
                </Grid>
            );
        case 'timezone':
            return (
                <Grid item xs={width} sm={smWidth}>
                    {input.label && renderLabel(input.label)}
                    <FormControl fullWidth margin="dense" key={index}>
                        <Select
                            id={input.id}
                            name={input.id}
                            value={input.value}
                            onBlur={input.onBlur}
                            onChange={input.onChange}
                            disabled={input.disabled}
                        >
                            {timezones.map((tz) => {
                                const offset = moment.tz(tz).format('Z');
                                return (
                                    <MenuItem key={tz} value={tz}>
                                        {`${tz} (UTC${offset})`}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {input.touched && <FormHelperText error>{input.error}</FormHelperText>}
                    </FormControl>
                </Grid>
            );
        case 'switch':
            return (
                <Grid item xs={width} sm={smWidth}>
                    <Grid container spacing={gridSpacing} alignItems="center" justifyContent="flex-start">
                        <Grid item sx={{ order: input.switchFirst ? 2 : 1, minWidth: 160 }}>
                            {input.label && renderLabel(input.label, input.tooltip)}
                        </Grid>
                        <Grid item sx={{ order: input.switchFirst ? 1 : 2 }}>
                            <CustomSwitch id={input.id} checked={input.value} onChange={input.onChange} disabled={input.disabled} />
                        </Grid>
                    </Grid>

                    {input.value &&
                        input.additionalInputs?.length > 0 &&
                        input.additionalInputs?.map((additionalInput) => (
                            <Grid mt={1}>
                                <FormComponents input={additionalInput} />
                            </Grid>
                        ))}
                </Grid>
            );
        case 'checkbox':
            return (
                <Grid item xs={width} sm={smWidth}>
                    <FormControlLabel
                        control={<Checkbox id={input.id} checked={input.value} onChange={input.onChange} />}
                        label={input.label && renderLabel(input.label)}
                    />
                </Grid>
            );
        case 'component':
            return (
                <Grid item xs={width} sm={smWidth}>
                    {input.label && renderLabel(input.label)}
                    {input.component}
                </Grid>
            );

        default:
            return null;
    }
};

export default FormComponents;
