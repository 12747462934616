import React, { useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Divider, Grid, IconButton, Link, TextField, Typography } from '@mui/material';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import Posts from 'ui-component/cards/Post';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { useDispatch, useSelector } from 'store';
import { getPosts, editComment, addComment, addReply, likePost, likeComment, likeReply } from 'store/slices/user';

// assets
import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LayersTwoToneIcon from '@mui/icons-material/LayersTwoTone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import RecentActorsTwoToneIcon from '@mui/icons-material/RecentActorsTwoTone';
import useAuth from 'hooks/useAuth';
import usePosts from 'hooks/usePosts';

const Feed = ({ isLoading, feed }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [posts, setPosts] = React.useState([]);
    const userState = useSelector((state) => state.user);
    const { user } = useAuth();
    const { handleAddLike, handleAddComment, getFeed } = usePosts();
    // const getPost = async () => {
    //     dispatch(getPosts(user.id));
    // };

    // React.useEffect(() => {
    //     setPosts(userState.posts);
    // }, [userState]);

    // React.useEffect(() => {
    //     getPost();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const editPost = async (id, commentId) => {
        dispatch(editComment(id, commentId));
    };

    const commentAdd = async (id, comment) => {
        dispatch(addComment(id, comment));
    };

    const replyAdd = async (postId, commentId, reply) => {
        dispatch(addReply(postId, commentId, reply));
    };

    const handlePostLikes = async (postId) => {
        dispatch(likePost(postId));
    };

    const handleCommentLikes = async (postId, commentId) => {
        dispatch(likeComment(postId, commentId));
    };

    const handleReplayLikes = async (postId, commentId, replayId) => {
        dispatch(likeReply(postId, commentId, replayId));
    };

    const sideAvatarSX = {
        borderRadius: '8px',
        width: 48,
        height: 48,
        fontSize: '1.5rem',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        '&>svg': {
            width: 24,
            height: 24
        }
    };
    return (
        <Grid item xs={12} sm={12}>
            <Grid container spacing={gridSpacing}>
                {/* <Grid item>
                    <Typography variant="h4">Feed</Typography>
                </Grid> */}
                {/* <Grid item xs={12}>
                    <MainCard>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <TextField id="outlined-textarea" placeholder="What’s on your mind, Larry?" rows={4} fullWidth multiline />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="space-between" spacing={gridSpacing}>
                                    <Grid item>
                                        <Button variant="text" color="secondary" startIcon={<AttachmentTwoToneIcon />}>
                                            Gallery
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <AnimateButton>
                                            <Button variant="contained" color="secondary" startIcon={<LayersTwoToneIcon />}>
                                                Post
                                            </Button>
                                        </AnimateButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid> */}
                {feed &&
                    feed.map((post) => (
                        <Grid key={post.id} item xs={12}>
                            <Posts
                                key={post.id}
                                post={post}
                                editPost={editPost}
                                renderPost={getFeed}
                                setPosts={setPosts}
                                commentAdd={handleAddComment}
                                replyAdd={replyAdd}
                                handleAddLike={handleAddLike}
                                handleAddComment={handleAddComment}
                                handleReplayLikes={handleReplayLikes}
                            />
                        </Grid>
                    ))}
            </Grid>
        </Grid>
    );
};

export default Feed;
