// material-ui
import { Grid, LinearProgress, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { camelCase, capitalize, startCase } from 'lodash';

// ===========================|| DATA WIDGET - TRAFFIC SOURCES ||=========================== //
function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
const DivisionSignups = ({ eventList }) => (
    <MainCard title="Division Signups">
        <Grid container spacing={gridSpacing}>
            {eventList?.map((event, index) => {
                const categoryName = event.group;
                const isEven = index % 2 === 0;
                const random = getRandomNumber(1, 100);
                return (
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item sm zeroMinWidth>
                                <Typography variant="body2">{startCase(camelCase(categoryName))}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" align="right">
                                    {random}%
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <LinearProgress variant="determinate" value={random} color={isEven ? 'primary' : 'secondary'} />
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    </MainCard>
);

export default DivisionSignups;
