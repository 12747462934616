import { Home } from '@mui/icons-material';
import { Breadcrumbs, Grid, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useTheme } from '@mui/styles';
import { useFormik } from 'formik';
import useMarketing from 'hooks/useMarketing';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import CardModal from 'ui-component/CardModal';
import StyledBreadcrumb from 'ui-component/extended/StyledBreadcrumb';
import FormComponents from 'ui-component/FormComponents';
import CustomTable from 'ui-component/table/CustomTable';
import actionSnackbar from 'ui-component/actionSnackbar';

const CampaignEmails = () => {
    const { id, clubId } = useParams();
    const location = useLocation();
    const currentPath = location.pathname;
    const isInternal = currentPath.includes('internal-tools') || false;
    const theme = useTheme();
    const navigate = useNavigate();
    const { getEmailCampaign, emailCampaign, loading, createCampaignEmail, deleteCampaignEmail } = useMarketing();

    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const toggleModal = () => setOpen(!open);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required')
    });
    const formik = useFormik({
        initialValues: {
            name: '',
            campaign_id: id
        },
        validationSchema,
        onSubmit: (values) => {
            createCampaignEmail(values).then((res) => {
                toggleModal();
                formik.resetForm();
                actionSnackbar(true, 'Email created successfully');
                navigate(isInternal ? `/internal-tools/campaigns/${id}/email/${res.id}` : `/${clubId}/campaigns/${id}/email/${res.id}`);
            });
        }
    });

    useEffect(() => {
        getEmailCampaign(id);
    }, [id]);

    const headers = [
        { id: 'id', label: 'ID' },
        { id: 'name', label: 'Name' },
        { id: 'subject', label: 'Subject' }
    ];

    useEffect(() => {
        setData(
            emailCampaign?.campaign_emails?.map((item) => [
                {
                    id: 'id',
                    data: item.id,
                    isVisible: true
                },
                {
                    id: 'name',
                    data: item.name,
                    isVisible: true
                },
                {
                    id: 'subject',
                    data: item.subject,
                    isVisible: true
                }
            ])
        );
    }, [emailCampaign]);
    const handleDelete = (id) => {
        deleteCampaignEmail(id).then((res) => {
            actionSnackbar(true, 'Email deleted successfully');
        });
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 4 }}>
                    <StyledBreadcrumb
                        component="a"
                        href="#"
                        label="Campaigns"
                        icon={<Home fontSize="small" />}
                        theme={theme}
                        onClick={() => navigate(isInternal ? `/internal-tools/campaigns` : `/club/${clubId}/email-campaigns`)}
                    />
                    <StyledBreadcrumb component="a" href="#" label={emailCampaign?.name} theme={theme} />
                </Breadcrumbs>
                <CustomTable
                    title={emailCampaign?.name}
                    buttonText="Add Email"
                    handleButtonClick={() => toggleModal()}
                    headers={headers}
                    data={data}
                    selectable
                    handleClick={(row) =>
                        navigate(isInternal ? `/internal-tools/campaigns/${id}/email/${row}` : `/${clubId}/campaigns/${id}/email/${row}`)
                    }
                    showSorting
                    loading={loading.emailCampaign}
                    showActions
                    actions={[{ label: 'delete', onClick: (row) => handleDelete(row), chipColor: 'error' }]}
                />
            </Grid>
            <CardModal
                open={open}
                toggleModal={toggleModal}
                title="Create Email Campaign"
                content={
                    <Grid container spacing={gridSpacing}>
                        <FormComponents
                            input={{
                                type: 'text',
                                id: 'name',
                                label: 'Email Name',
                                value: formik.values.name,
                                onBlur: formik.handleBlur,
                                error: formik.errors.name,
                                touched: formik.touched.name
                            }}
                            formik={formik}
                        />
                    </Grid>
                }
                submitAction={formik.handleSubmit}
                actionText="Create"
                showCancel
            />
        </Grid>
    );
};

export default CampaignEmails;
