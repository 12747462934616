import { Celebration, EmojiEvents, EventAvailable, EventAvailableOutlined, Money, Person, ViewList } from '@mui/icons-material';
import { Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import useHelpCenter from 'hooks/useHelpCenter';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';

const getIcon = (id) => {
    switch (id) {
        case 1:
            return <EventAvailableOutlined fontSize="large" color="secondary" />;
        case 2:
            return <EmojiEvents fontSize="large" color="secondary" />;
        case 3:
            return <Celebration fontSize="large" color="secondary" />;
        case 4:
            return <ViewList fontSize="large" color="secondary" />;
        case 5:
            return <Money fontSize="large" color="secondary" />;
        case 6:
            return <Person fontSize="large" color="secondary" />;
        default:
            return <EventAvailableOutlined fontSize="large" color="secondary" />;
    }
};
const HelpHome = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { topics, getTopics, category, setValue } = useHelpCenter();

    useEffect(() => {
        getTopics(category);
    }, [category]);
    return (
        <Grid data-test-id="views/help-center/index">
            <Stack sx={{ textAlign: 'center' }}>
                <Typography variant="h1">How can we help?</Typography>
            </Stack>
            <Stack
                direction="row"
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: 4,
                    borderBottom: '1px solid lightgrey'
                }}
            >
                <Grid sx={{ borderBottom: category === 'attending' ? `3px solid ${theme.palette.primary.main}` : '0px' }}>
                    <Button
                        onClick={() => setValue('category', 'attending')}
                        sx={{
                            paddingBottom: 2,
                            '&:hover': {
                                backgroundColor: 'transparent',
                                color: theme.palette.primary.main
                            },
                            color: category === 'attending' ? theme.palette.primary.main : theme.palette.grey[500]
                        }}
                        disableFocusRipple
                        disableRipple
                        variant="text"
                    >
                        Attending an event
                    </Button>
                </Grid>
                <Grid sx={{ borderBottom: category === 'hosting' ? `3px solid ${theme.palette.primary.main}` : '0px', marginLeft: 6 }}>
                    <Button
                        onClick={() => setValue('category', 'hosting')}
                        sx={{
                            paddingBottom: 2,
                            '&:hover': {
                                backgroundColor: 'transparent',
                                color: theme.palette.primary.main
                            },
                            color: category === 'hosting' ? theme.palette.primary.main : theme.palette.grey[500]
                        }}
                        disableFocusRipple
                        disableRipple
                        variant="text"
                    >
                        Hosting an event
                    </Button>
                </Grid>
            </Stack>
            <Grid container py={2} spacing={2} direction="column">
                <Grid item mb={4}>
                    <Typography variant="h4"> Browse by topic</Typography>
                </Grid>
                <Grid container spacing={2} paddingLeft="16px">
                    {topics.map((item, index) => (
                        <Grid item xs={12} md={6} lg={3}>
                            <MainCard
                                elevation={2}
                                pointer
                                sx={{
                                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50]
                                }}
                                onClick={() => navigate(`topics/${item.slug}/${item.id}`)}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            // spacing={2}
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            textAlign="center"
                                        >
                                            <Stack
                                                item
                                                sx={{
                                                    // padding: 1,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    borderRadius: 25,
                                                    width: 55,
                                                    height: 55,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginBottom: 2
                                                }}
                                            >
                                                {getIcon(item?.id)}
                                            </Stack>
                                            <Grid item xs zeroMinWidth>
                                                <Typography variant="h4">{item?.name}</Typography>
                                                {/* <GroupTextSection /> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};
export default HelpHome;
