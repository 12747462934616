import React, { useState, useEffect } from 'react';

// material-ui
import { Grid, Typography, Table, TableBody, TableCell, TableRow, TableContainer, TableHead, Avatar, Chip } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

// assets
import useLeagues from 'hooks/useLeagues';
import { useTheme } from '@mui/material/styles';

// project imports
import * as sorters from 'utils/statsSorters';
import PlayerCell from 'views/components/playerCell.component';
import getPointDifferentialPercentage from 'utils/pointDifferentialPercentage';

const LeagueStatsTable = ({ eventId, eventName, leagueId, showAdmin = false, handleGenerateDrawer }) => {
    const theme = useTheme();

    const { leagueStats } = useLeagues();

    const [sortedLeagueStats, setSortedLeagueStats] = useState();

    const [currentSort, setCurrentSort] = useState('');

    function toggleXSort(descStr, ascStr, sortFcnAsc, sortFcnDesc) {
        if (!sortedLeagueStats || sortedLeagueStats.length === 0) {
            return;
        }
        const resortedStats = sortedLeagueStats;

        if (currentSort === descStr) {
            resortedStats.sort(sortFcnAsc);
            setCurrentSort(ascStr);
        } else {
            resortedStats.sort(sortFcnDesc);
            setCurrentSort(descStr);
        }
        setSortedLeagueStats(resortedStats);
    }

    function togglePointDifferentialSort() {
        toggleXSort('PD_DESC', 'PD_ASC', sorters.sortByPointDifferentialAsc, sorters.sortByPointDifferentialDesc);
    }

    function togglePointsAllowedSort() {
        toggleXSort('PA_DESC', 'PA_ASC', sorters.sortByPointsAllowedAsc, sorters.sortByPointsAllowedDesc);
    }

    function togglePointsEarnedSort() {
        toggleXSort('PE_DESC', 'PE_ASC', sorters.sortByPointsEarnedAsc, sorters.sortByPointsEarnedDesc);
    }

    function toggleLossesSort() {
        toggleXSort('LOSSES_DESC', 'LOSSES_ASC', sorters.sortByLossesAsc, sorters.sortByLossesDesc);
    }

    function toggleWinsSort() {
        toggleXSort('WINS_DESC', 'WINS_ASC', sorters.sortByWinsAsc, sorters.sortByWinsDesc);
    }

    function toggleDifferentialPercentageSort() {
        toggleXSort('PDP_DESC', 'PDP_ASC', sorters.sortByPointDifferentialPercentageAsc, sorters.sortByPointDifferentialPercentageDesc);
    }

    function toggleNameSort() {
        toggleXSort('NAME_DESC', 'NAME_ASC', sorters.sortByNameAsc, sorters.sortByNameDesc);
    }

    useEffect(() => {
        if (leagueStats.length !== 0) {
            setSortedLeagueStats(leagueStats);
        }
    }, [leagueStats]);

    useEffect(() => {
        toggleWinsSort();
    }, [sortedLeagueStats]);

    function renderRow(userAttributes) {
        return (
            <TableRow key={userAttributes?.name}>
                <TableCell
                    sx={{
                        flexDirection: 'column',
                        minWidth: '200px',
                        flex: 1,
                        whiteSpace: 'nowrap'
                    }}
                >
                    <PlayerCell userAttributes={userAttributes} />
                </TableCell>
                <TableCell align="center">{userAttributes.wins}</TableCell>
                <TableCell align="center">{userAttributes.losses}</TableCell>
                <TableCell align="center">{userAttributes.points_earned}</TableCell>
                <TableCell align="center">{userAttributes.points_against}</TableCell>
                <TableCell align="center">{userAttributes.point_differential}</TableCell>
                <TableCell align="center">
                    {getPointDifferentialPercentage(userAttributes.points_earned, userAttributes.points_against)}
                </TableCell>
            </TableRow>
        );
    }

    function renderSortableTableHeader(sortFunction, ascStr, descStr, text) {
        const shouldRenderArrow = currentSort.includes(ascStr) || currentSort.includes(descStr);
        return (
            <TableCell align="center">
                <Grid
                    onClick={() => sortFunction()}
                    sx={{
                        borderRadius: '5px',
                        backgroundColor: shouldRenderArrow ? theme.palette.success[200] : theme.palette.primary.light,
                        paddingLeft: 1,
                        paddingRight: 1
                    }}
                    container
                >
                    <Grid item xs={shouldRenderArrow ? 4 : 5} />
                    <Grid item xs={shouldRenderArrow ? 2 : 1} sx={{ pt: '3px' }}>
                        <Typography sx={{ float: shouldRenderArrow ? 'right' : 'none', textAlign: 'center' }}>{text}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ height: '24px' }}>
                        <ArrowUpwardIcon
                            sx={{
                                display: currentSort === ascStr ? 'inline-block' : 'none',
                                fontSize: '18px',
                                mt: '3px',
                                float: 'left'
                            }}
                        />
                        <ArrowDownwardIcon
                            sx={{
                                display: currentSort === descStr ? 'inline-block' : 'none',
                                fontSize: '18px',
                                mt: '3px',
                                float: 'left'
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} />
                </Grid>
            </TableCell>
        );
    }

    return (
        <Grid>
            <TableContainer>
                <Table size="small" aria-label={eventName} sx={{ backgroundColor: 'white', borderRadius: 2 }}>
                    <TableHead>
                        <TableRow>
                            {renderSortableTableHeader(toggleNameSort, 'NAME_ASC', 'NAME_DESC', 'Players')}
                            {renderSortableTableHeader(toggleWinsSort, 'WINS_ASC', 'WINS_DESC', 'W')}
                            {renderSortableTableHeader(toggleLossesSort, 'LOSSES_ASC', 'LOSSES_DESC', 'L')}
                            {renderSortableTableHeader(togglePointsEarnedSort, 'PE_ASC', 'PE_DESC', 'PE')}
                            {renderSortableTableHeader(togglePointsAllowedSort, 'PA_ASC', 'PA_DESC', 'PA')}
                            {renderSortableTableHeader(togglePointDifferentialSort, 'PD_ASC', 'PD_DESC', 'PD')}
                            {renderSortableTableHeader(toggleDifferentialPercentageSort, 'PDP_ASC', 'PDP_DESC', 'PD%')}
                        </TableRow>
                    </TableHead>

                    <TableBody>{sortedLeagueStats?.map((team, teamIndex) => renderRow(team))}</TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default LeagueStatsTable;
