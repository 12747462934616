import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    AppBar as MuiAppBar,
    Box,
    Button,
    Container,
    Drawer,
    IconButton,
    Link,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Toolbar,
    useScrollTrigger,
    Typography,
    Grid
} from '@mui/material';

// project imports
import StackedLogo from 'assets/images/landing/stacked_logo_full_nobackplate.png';

// assets
import { IconBook, IconHome2 } from '@tabler/icons';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import PeopleIcon from '@mui/icons-material/People';

import MenuIcon from '@mui/icons-material/Menu';
import { gridSpacing } from 'store/constant';

// elevation scroll
function ElevationScroll({ children, window }) {
    const theme = useTheme();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window
    });
    const darkBorder = theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.grey[200];

    return React.cloneElement(children, {
        elevation: trigger ? 2 : 0,
        style: {
            backgroundColor: theme.palette.background.default,
            borderBottom: trigger ? 'none' : '1px solid',
            borderColor: trigger ? '' : darkBorder,
            color: theme.palette.text.dark
        }
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.node,
    window: PropTypes.object
};

// ==============================|| MINIMAL LAYOUT APP BAR ||============================== //

const AppBar = ({ ...others }) => {
    const [showFeatureSection, setShowFeatureSection] = useState(null);

    const [drawerToggle, setDrawerToggle] = React.useState(false);
    /** Method called on multiple components with different event types */
    const drawerToggler = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerToggle(open);
    };

    const navigationItems = [
        // {
        //     title: 'Features',
        //     link: '/',
        //     icon: <IconHome2 />,
        //     action: () => setShowFeatureSection(!showFeatureSection)
        // },
        { title: 'Our Team', link: '/team', icon: <PeopleIcon /> },
        { title: 'Contact Us', link: '/contact-us', icon: <RecentActorsIcon /> },
        { title: 'FAQ', link: '/faq', icon: <ContactSupportIcon /> },
        { title: 'Blog', link: '/blog', icon: <IconBook /> }
    ];
    return (
        <ElevationScroll {...others} data-test-id="ui-component/extended/AppBar">
            <MuiAppBar>
                <Container maxWidth="xl">
                    <Toolbar>
                        <Box sx={{ flexGrow: 1, textAlign: 'left' }} component={RouterLink} to="/">
                            <img src={StackedLogo} alt="hello" loading="lazy" width="25%" />
                            {/* <Logo /> */}
                        </Box>
                        <Stack direction="row" sx={{ display: { xs: 'none', sm: 'block' } }} spacing={2}>
                            {navigationItems.map((item, index) =>
                                item.action ? (
                                    <Button key={index.toString()} color="inherit" onClick={item.action}>
                                        {item.title}
                                    </Button>
                                ) : (
                                    <Button key={index.toString()} color="inherit" component={RouterLink} to={item.link}>
                                        {item.title}
                                    </Button>
                                )
                            )}
                            <Button component={RouterLink} to="/download" disableElevation variant="contained" color="secondary">
                                Download Now
                            </Button>
                        </Stack>
                        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <IconButton color="inherit" onClick={drawerToggler(true)} size="large">
                                <MenuIcon />
                            </IconButton>
                            <Drawer anchor="top" open={drawerToggle} onClose={drawerToggler(false)}>
                                {drawerToggle && (
                                    <Box
                                        sx={{ width: 'auto' }}
                                        role="presentation"
                                        onClick={drawerToggler(false)}
                                        onKeyDown={drawerToggler(false)}
                                    >
                                        <List>
                                            {navigationItems.map((item, index) => (
                                                <Link key={index} style={{ textDecoration: 'none' }} component={RouterLink} to={item.link}>
                                                    <ListItemButton key={index} component="a">
                                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                                        <ListItemText primary={item.title} />
                                                    </ListItemButton>
                                                </Link>
                                            ))}
                                        </List>
                                    </Box>
                                )}
                            </Drawer>
                        </Box>
                    </Toolbar>
                    {showFeatureSection && (
                        <Grid container spacing={gridSpacing}>
                            <Grid item padding={10} xs={12} lg={4} md={4}>
                                <Typography variant="h3"> Stay Connected</Typography>
                                <Typography> Connect & coordinate with your Pickleball community.</Typography>
                            </Grid>
                            <Grid item padding={10} xs={12} lg={4} md={4}>
                                <Typography variant="h3"> Organize Games</Typography>
                                <Typography>
                                    Organize games with your friends and use the power of Stacked to find more players, if needed.
                                </Typography>
                            </Grid>
                            <Grid item padding={10} xs={12} lg={4} md={4}>
                                <Typography variant="h3"> Coordinate Events</Typography>
                                <Typography>
                                    Use our event managemenet platform to create leagues, manage open play and so much more
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Container>
            </MuiAppBar>
        </ElevationScroll>
    );
};

export default AppBar;
