/* eslint-disable radix */
/* eslint-disable no-bitwise */
/* eslint-disable prefer-template */

// Example Lighten: shadeColor("#63C6FF", 40);
// Example Darken: shadeColor("#63C6FF", -40);
function shadeColor(color, percent) {
    let red = parseInt(color.substring(1, 3), 16);
    let green = parseInt(color.substring(3, 5), 16);
    let blue = parseInt(color.substring(5, 7), 16);

    red = parseInt((red * (100 + percent)) / 100);
    green = parseInt((green * (100 + percent)) / 100);
    blue = parseInt((blue * (100 + percent)) / 100);

    red = red < 255 ? red : 255;
    green = green < 255 ? green : 255;
    blue = blue < 255 ? blue : 255;

    red = Math.round(red);
    green = Math.round(green);
    blue = Math.round(blue);

    const RR = red.toString(16).length === 1 ? '0' + red.toString(16) : red.toString(16);
    const GG = green.toString(16).length === 1 ? '0' + green.toString(16) : green.toString(16);
    const BB = blue.toString(16).length === 1 ? '0' + blue.toString(16) : blue.toString(16);

    return '#' + RR + GG + BB;
}

export const hashStringForHex = (str) => {
    let hash = 0;
    str.split('').forEach((char) => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
    let color = '#';
    for (let i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += value.toString(16).padStart(2, '0');
    }
    return shadeColor(color, -15);
};

export default hashStringForHex;
