/* eslint-disable prettier/prettier */
import { TabContext, TabPanel } from '@mui/lab';
import { Grid, CardContent, Tab, Tabs, Typography } from '@mui/material';
import useForms from 'hooks/useForms';
import { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import ResponseSummary from './components/ResponseSummary';

const FormResponses = ({ id }) => {
    const { formResults, formResponseCount, getFormResults, loading } = useForms();
    const [view, setView] = useState('summary');

    useEffect(() => {
        getFormResults(id, view);
    }, [id, view]);

    return (
        <Grid container mt={2}>
            <TabContext value={view}>
                <Grid item xs={12}>
                    <MainCard
                        title={<Typography variant="h3">{`${formResponseCount} responses`}</Typography>}
                        showDivider={false}
                        content={false}
                    >
                        <CardContent sx={{ padding: 0, alignItems: 'flex-end', height: 49 }}>
                            <Tabs
                                value={view}
                                onChange={(e, newValue) => setView(newValue)}
                                sx={{ display: 'flex', alignSelf: 'flex-end' }}
                            // variant="fullWidth"
                            >
                                <Tab label="Summary" value="summary" />
                                {/* <Tab label="Question" value="question" />
                                <Tab label="individual" value="individual" /> */}
                            </Tabs>
                        </CardContent>
                    </MainCard>
                </Grid>
                <Grid item xs={12}>
                    <TabPanel value="summary">{!loading?.formResults && <ResponseSummary formResults={formResults} />}</TabPanel>
                </Grid>
            </TabContext>
        </Grid>
    );
};

export default FormResponses;
