import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Chip, Grid, InputAdornment, Menu, MenuItem, OutlinedInput, Pagination, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets
import { IconSearch } from '@tabler/icons';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { useDispatch, useSelector } from 'react-redux';
import { getTournamentPlayerList } from 'store/slices/user';
import LeaderboardTable from 'views/leagues/LeagueHome/TournamentManager/LeagueLeaderboardTable';
import useLeagues from 'hooks/useLeagues';
import LeagueStatsTable from './LeagueStatsTable';
import capitalizeWords from 'utils/capitalizeWords';

// ==============================|| USER LIST STYLE 1 ||============================== //

const LeagueStats = ({ leagueId, type = 'league' }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { getLeagueLeaderboard, getLeagueRoster, getLeagueStats } = useLeagues();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const [newInputValue, setNewInputValue] = useState('');
    const [pageLength, setPageLength] = useState(10);
    const [page, setPage] = useState(1);

    const handlePageLengthChange = (row) => {
        setPageLength(row);
        handleClose();
    };
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };
    useEffect(() => {
        getLeagueStats(leagueId);
    }, [leagueId]);

    // useEffect(() => {
    //     dispatch(getTournamentPlayerList(tournamentId, newInputValue, pageLength, page));
    //     // const filteredUsers = allUsers.filter((user) => user.toLowerCase().includes(newInputValue.toLowerCase()));
    //     // setUsers(filteredUsers);
    // }, [newInputValue, pageLength, page]);
    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Typography variant="h3">{capitalizeWords(type)} Stats</Typography>
                            <Grid ml={1}>
                                <Chip label="All Time" />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item>
                        <OutlinedInput
                            id="input-search-list-style1"
                            placeholder="Search"
                            value={newInputValue}
                            onChange={(e) => setNewInputValue(e.target.value)}
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconSearch stroke={1.5} size="16px" />
                                </InputAdornment>
                            }
                            size="small"
                        />
                    </Grid> */}
                </Grid>
            }
            content={false}
        >
            <LeagueStatsTable leagueId={leagueId} showAdmin={false} />
        </MainCard>
    );
};

export default LeagueStats;
