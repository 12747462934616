/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';

// material-ui
import { Grid, Typography, Table, TableBody, TableCell, TableRow, TableContainer, TableHead, Chip, Button, Tooltip } from '@mui/material';

// project imports
import PlayerCell from 'views/components/playerCell.component';

// assets
import useLeagues from 'hooks/useLeagues';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import CurrencyFormat from 'react-currency-format';
import getPointDifferentialPercentage from 'utils/pointDifferentialPercentage';

const LeaderboardTable = ({ eventId, eventName, leagueId, showAdmin = false, handleGenerateDrawer, hasLeaderboard }) => {
    const { leagueLeaderboard, getLeaguePlayers } = useLeagues();

    const initialTableRows = [
        { tooltip: 'Games Won', text: 'W' },
        { tooltip: 'Games Lost', text: 'L' },
        { tooltip: 'League Points', text: 'Points' },
        { tooltip: 'Points Earned', text: 'PE' },
        { tooltip: 'Points Against', text: 'PA' },
        { tooltip: 'Point Differential', text: 'PD' },
        { tooltip: 'Point Differential %', text: 'PD %' },
        { tooltip: 'Position Change from Start', text: 'Position Change' }
    ];
    const [tableRows, setTableRows] = useState(initialTableRows);
    const [hasMicroPaymentAmount, setHasMicroPaymentAmount] = useState(false);

    const getPositionChangeIcon = (positionChange) => {
        if (positionChange < 0) {
            return <ArrowDropDownCircleIcon color="error" />;
        }
        if (positionChange === 0) {
            return <PlayCircleFilledIcon color="warning" />;
        }
        if (positionChange > 0) {
            return <ArrowDropDownCircleIcon color="success" sx={{ transform: 'rotate(180deg)' }} />;
        }
        return <ArrowDropDownCircleIcon />;
    };

    useEffect(() => {
        if (!hasLeaderboard) {
            getLeaguePlayers(leagueId, null);
        }
    }, [leagueId, hasLeaderboard]);

    useEffect(() => {
        // Check and compute the total_micro_earned_amount from all teams
        const totalMicroEarnedAmount = leagueLeaderboard.reduce((acc, team) => acc + (team.total_micro_earned_amount || 0), 0);

        // Set the flag true if total_micro_earned_amount is greater than 0
        setHasMicroPaymentAmount(totalMicroEarnedAmount > 0);
    }, [leagueLeaderboard]);

    function renderAdmin() {
        return (
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                mt={2}
                sx={{
                    backgroundColor: 'white',
                    borderRadius: 1,
                    paddingX: 2,
                    paddingY: 2,
                    borderBottom: '1px solid lightgrey'
                }}
            >
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography
                                variant="h4"
                                sx={{
                                    color: 'primary.text'
                                }}
                            >
                                {eventName}
                            </Typography>
                        </Grid>
                        <Grid item ml={2}>
                            <Chip label="No Matchups" color="error" variant="filled" />
                        </Grid>
                    </Grid>
                </Grid>

                {/* <Grid
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        alignSelf: 'flex-end',
                        textAlign: { xs: 'start', md: 'end' },
                        marginTop: { xs: 2, md: 0 }
                    }}
                    xs={12}
                    md={6}
                >
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleGenerateDrawer(eventId, leagueLeaderboard.filter((plr) => plr.status === 'accepted').length, leagueId);
                        }}
                    >
                        Generate Matchups
                    </Button>
                </Grid> */}
            </Grid>
        );
    }
    return (
        <Grid>
            {showAdmin && renderAdmin()}
            <TableContainer>
                <Table size="small" aria-label={eventName} sx={{ backgroundColor: 'white', borderRadius: 2 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" width="5%">
                                Seed
                            </TableCell>
                            <TableCell>Players</TableCell>
                            {hasLeaderboard && (
                                <>
                                    {tableRows.map((row) => (
                                        <TableCell align={row.text === 'Position Change' ? 'start' : 'center'}>
                                            <Tooltip title={row.tooltip}>
                                                <Typography variant="h5"> {row.text} </Typography>
                                            </Tooltip>
                                        </TableCell>
                                    ))}
                                    {hasMicroPaymentAmount && (
                                        <TableCell>
                                            <Tooltip title="Amount earned in Micro-Payments">
                                                <Typography variant="h5"> Weekly Earn. </Typography>
                                            </Tooltip>
                                        </TableCell>
                                    )}
                                    {showAdmin && (
                                        <TableCell>
                                            <Tooltip title="Amount earned in Micro-Payments">
                                                <Typography variant="h5"> Venmo ID </Typography>
                                            </Tooltip>
                                        </TableCell>
                                    )}
                                </>
                            )}
                        </TableRow>
                    </TableHead>
                    {leagueLeaderboard?.map((team, teamIndex) => (
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">
                                    <Typography variant="subtitle1">{teamIndex + 1}</Typography>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        flexDirection: 'column',
                                        minWidth: '150px',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    <Grid
                                        container
                                        sx={{
                                            flexDirection: 'column',
                                            whiteSpace: 'nowrap',
                                            minWidth: '150px'
                                        }}
                                    >
                                        <PlayerCell user={team?.user} />
                                    </Grid>
                                </TableCell>
                                {hasLeaderboard && (
                                    <>
                                        <TableCell align="center">{team.wins}</TableCell>
                                        <TableCell align="center">{team.losses}</TableCell>
                                        <TableCell align="center">{team.league_points}</TableCell>
                                        <TableCell align="center">{team.points_earned}</TableCell>
                                        <TableCell align="center">{team.points_against}</TableCell>
                                        <TableCell align="center">{team.point_differential}</TableCell>
                                        <TableCell align="center">
                                            {getPointDifferentialPercentage(team.points_earned, team.points_against)}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{ borderRight: hasMicroPaymentAmount ? '1px solid lightgrey' : null }}
                                        >
                                            <Grid container ml={1} alignItems="center">
                                                {getPositionChangeIcon(team?.position_change || 0)}
                                                <Typography ml={1}>{team?.position_change || 0} </Typography>
                                            </Grid>
                                        </TableCell>
                                        {hasMicroPaymentAmount && (
                                            <TableCell align="left">
                                                <CurrencyFormat
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    value={team.total_micro_earned_amount / 100}
                                                    displayType="text"
                                                    thousandSeparator
                                                    prefix="$"
                                                />
                                            </TableCell>
                                        )}
                                        {showAdmin && <TableCell align="left">{team.user?.user_attributes?.venmo_id}</TableCell>}
                                    </>
                                )}
                            </TableRow>
                        </TableBody>
                    ))}
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default LeaderboardTable;
