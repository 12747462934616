import { AttachMoney, CalendarMonthRounded, ConfirmationNumber, EmojiEvents, LocationOnRounded } from '@mui/icons-material';
import { Container, Typography, Grid, Tooltip, Button, Stack } from '@mui/material';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import useUsers from 'hooks/useUsers';
import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useParams } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import FieldSection from 'ui-component/FieldSection';
import FormComponents from 'ui-component/FormComponents';
import CondensedTeamCard from 'ui-component/cards/CondensedTeamCard';
import getReadableDateTime from 'utils/get_readable_datetime';
import TeamDrawer from 'views/admin/AdminHub/EventInfo/components/TeamDrawer';
import PlayerCell from 'views/components/playerCell.component';
import TeamsTable from 'views/components/teamsTable.component';

const apiKey = 'AIzaSyDmaBGYH1EjkaybTv8IzfM1TxRB3bhXRUA';
const libraries = ['places'];

const PlayerTicket = () => {
    const { id } = useParams();
    const { getPlayerTicket, playerTicket } = useUsers();
    const [teamLength, setTeamLength] = useState();
    const [teamDrawerOpen, setTeamDrawerOpen] = useState(false);
    const [teamId, setTeamId] = useState();
    const [team, setTeam] = useState();
    const [selectedEventId, setSelectedEventId] = useState();

    useEffect(() => {
        getPlayerTicket(id);
    }, [id]);
    const mapContainerStyle = {
        width: '100%',
        height: '400px'
    };

    const handleGenerateTeamDrawer = (selectedEventId, selectedTeamLength, teamId) => {
        setSelectedEventId(selectedEventId);
        const selectedEvent = playerTicket.teams.find((item) => item.event?.id === selectedEventId);
        setTeamLength(selectedEvent?.event?.team_count);
        setTeamId(teamId);
        const findTeam = playerTicket.teams.find((item) => item.id === teamId);
        setTeam(findTeam?.players);
        setTeamDrawerOpen(!teamDrawerOpen);
    };

    useEffect(() => {
        if (teamId) {
            const findTeam = playerTicket.teams.find((item) => item.id === teamId);
            findTeam.players = team;
        }
    }, [team]);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries
    });
    if (loadError) return 'Error loading maps';
    if (!isLoaded) return 'Loading Maps';

    return (
        <Container>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h2"> {playerTicket?.name}</Typography>
                        {/* <Button variant="contained" color="error">
                            Withdraw
                        </Button> */}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        {playerTicket?.order && (
                            <Grid item xs={12} sm={6}>
                                <FieldSection
                                    icon={<ConfirmationNumber />}
                                    label="Order #"
                                    value={<Typography> {playerTicket?.order?.id}</Typography>}
                                />
                                <FieldSection
                                    icon={<CalendarMonthRounded />}
                                    label="Date Ordered"
                                    value={<Typography> {getReadableDateTime(playerTicket?.order?.created_at, 'dateShort')}</Typography>}
                                />
                                <FieldSection
                                    icon={<AttachMoney />}
                                    label="Amount Paid"
                                    value={
                                        <CurrencyFormat
                                            decimalScale={2}
                                            fixedDecimalScale
                                            value={playerTicket?.order?.total_amount / 100}
                                            displayType="text"
                                            thousandSeparator
                                            prefix="$"
                                        />
                                    }
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6}>
                            <FieldSection
                                icon={<LocationOnRounded />}
                                label="Venue"
                                value={<Typography> {playerTicket?.venue}</Typography>}
                            />
                            {playerTicket?.event_type === 'tournament' && (
                                <FieldSection
                                    icon={<EmojiEvents />}
                                    label="# of divisions"
                                    value={<Typography> {playerTicket?.teams?.length}</Typography>}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h3">
                                Event Details
                            </Typography>
                            <Typography gutterBottom variant="h5">
                                {' '}
                                {playerTicket?.venue}
                            </Typography>
                            <Typography gutterBottom> {playerTicket?.street_address}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <GoogleMap
                                mapContainerStyle={mapContainerStyle}
                                zoom={15}
                                center={{ lat: playerTicket?.latitude, lng: playerTicket?.longitude }}
                                clickable={false}
                                draggable={false}
                                editable={false}
                            >
                                <Marker position={{ lat: playerTicket?.latitude, lng: playerTicket?.longitude }} />
                            </GoogleMap>
                        </Grid>
                    </Grid>
                </Grid>
                {playerTicket?.event_type === 'tournament' && (
                    <Grid item xs={12}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant="h3">
                                    Divisions
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {playerTicket?.teams?.map((team) => (
                                    <Grid item xs={12} key={team.id}>
                                        <TeamsTable
                                            leagueId={playerTicket?.id}
                                            showPoolInfo={false}
                                            eventId={team?.event?.id}
                                            eventName={team?.event?.title}
                                            showAdmin
                                            teamList={[team]}
                                            type={playerTicket?.event_type}
                                            handleGenerateTeamDrawer={handleGenerateTeamDrawer}
                                            showDelete={false}
                                        />
                                        {/* {team.players?.map((player) => (
                                        <PlayerCell user={player?.user} key={player.id} />
                                    ))} */}
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {teamDrawerOpen && (
                <TeamDrawer
                    openTeamSidebar={teamDrawerOpen}
                    handleDrawerOpen={handleGenerateTeamDrawer}
                    leagueId={playerTicket?.id}
                    existingUsers={[]}
                    maxTeamCount={teamLength}
                    eventId={selectedEventId}
                    // handleUserDrawerOpen={handleUserDrawerOpen}
                    teamId={teamId}
                    team={team}
                    setTeam={setTeam}
                />
            )}
        </Container>
    );
};

export default PlayerTicket;
