/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AvatarGroup, Box, Button, CardMedia, Grid, Tab, Tabs, Typography, Autocomplete, TextField } from '@mui/material';
import { styled, lighten, darken } from '@mui/system';

// project imports
import Profile from './Profile';
import Followers from './Followers';
import Gallery from './Gallery';
import FriendRequest from './FriendRequest';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import Avatar from 'ui-component/extended/Avatar';
import Chip from 'ui-component/extended/Chip';
import MainCard from 'ui-component/cards/MainCard';
import ImagePlaceholder from 'ui-component/cards/Skeleton/ImagePlaceholder';
import { gridSpacing } from 'store/constant';
import { getProduct } from 'store/slices/product';
import { useDispatch, useSelector } from 'store';
import chartData from 'views/widget/Chart/chart-data';

// assets
import { IconFriends, IconInbox, IconPhoto, IconUserPlus, IconUsers } from '@tabler/icons';
import PersonAddTwoToneIcon from '@mui/icons-material/PersonAddTwoTone';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import User1 from 'assets/images/profile/img-user.png';
import Cover from 'assets/images/group-default.svg';
import useGroups from 'hooks/useGroups';
import useLeagues from 'hooks/useLeagues';
import DrawResults from './DrawResults';

import Avatar1 from 'assets/images/users/avatar-1.png';
import Avatar2 from 'assets/images/users/avatar-2.png';
import Avatar3 from 'assets/images/users/avatar-3.png';
import Avatar4 from 'assets/images/users/avatar-4.png';
import Avatar5 from 'assets/images/users/avatar-5.png';
import SeoChartCard from 'ui-component/cards/SeoChartCard';
import Overview from './Overview';
import Divisions from './Divisions';
import CheckIn from './CheckIn/CheckIn';
import TournamentManager from './TournamentManager';
import { getTournamentPlayerList } from 'store/slices/user';
import Leaderboard from './Leaderboard/Leaderboard';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box
                    sx={{
                        p: 0
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

// ==============================|| SOCIAL PROFILE ||============================== //

const LeagueHome = () => {
    const theme = useTheme();
    const { leagueId } = useParams();
    const dispatch = useDispatch();

    const { user } = useAuth();
    const { borderRadius } = useConfig();
    const { tab } = useParams();
    const { getGroupInfo, groupInfo, joinGroup, leaveGroup } = useGroups();
    const { getLeague, league } = useLeagues();

    const navigate = useNavigate();

    // useEffect(() => {
    //     getGroupInfo(groupId);
    // }, [groupId]);

    useEffect(() => {
        getLeague(leagueId);
    }, [leagueId]);
    console.log('LD', league);

    let selectedTab = 0;
    switch (tab) {
        case 'follower':
            selectedTab = 1;
            break;
        case 'friends':
            selectedTab = 2;
            break;
        case 'gallery':
            selectedTab = 3;
            break;
        case 'friend-request':
            selectedTab = 4;
            break;
        case 'posts':
        default:
            selectedTab = 0;
    }
    const [value, setValue] = React.useState(selectedTab);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    }, []);

    const [showAdminOptions, setShowAdminOptions] = useState(true);

    const tabOptions = [
        {
            to: '/user/social-profile/posts',
            icon: <IconInbox stroke={1.5} size="17px" />,
            label: 'Overview'
        },
        {
            to: '/user/social-profile/posts',
            icon: <IconInbox stroke={1.5} size="17px" />,
            label: 'Leaderboard'
        }
        // {
        //     to: '/user/social-profile/posts',
        //     icon: <IconInbox stroke={1.5} size="17px" />,
        //     label: 'Roster'
        // },
        // {
        //     to: '/user/social-profile/posts',
        //     icon: <IconInbox stroke={1.5} size="17px" />,
        //     label: 'Admin'
        // }
    ];

    // Assume these are your subtabs for the Tournament Manager
    const mainTabs = [
        'Overview',
        'Divisions',
        'Check In',
        'Pools',
        'Medal Round',
        'Results',
        'Admin'
        // ... any other subtabs for the Tournament Manager
    ];

    const [activeMainTab, setActiveMainTab] = useState(0);

    // State for the active subtab of the Tournament Manager

    const handleMainTabChange = (event, newValue) => {
        setActiveMainTab(newValue);
        // If we're leaving the Tournament Manager tab, reset the subtab
    };

    // TODO : Check if user is admin of the tournament

    const eventList = league?.grouped_events;

    const [options, setOptions] = useState([]);
    useEffect(() => {
        if (eventList) {
            setOptions(
                eventList
                    .map((group) =>
                        group.events.map((event) => ({
                            group: group.group,
                            ...event
                        }))
                    )
                    .flat()
            );
        }
    }, [eventList]);

    const getContentForMainTab = (subTabIndex) => {
        switch (subTabIndex) {
            case 0:
                return <Profile league={league} />;
            case 1:
                return <Leaderboard leagueId={league?.id} />;
            // case 2:
            //     return <Leaderboard leagueId={league?.id} />;
            // ... handle other subtabs
            case 2:
                return <TournamentManager options={options} leagueId={league?.id} groupId={league?.group_id} />;
            default:
                return <Overview eventList={league?.grouped_events} />;
        }
    };

    const [adminMode, setAdminMode] = useState(false);
    const handleChangeTime = (event, newValue) => {
        setAdminMode(newValue);
    };
    useEffect(() => {
        dispatch(getTournamentPlayerList(leagueId, '', 10, 1));
        // const filteredUsers = allUsers.filter((user) => user.toLowerCase().includes(newInputValue.toLowerCase()));
        // setUsers(filteredUsers);
    }, []);
    const { tournamentRoster, totalParticipants } = useSelector((state) => state.user);

    return (
        <Grid container spacing={gridSpacing} data-test-id="views/leagues/LeagueHome/index">
            <Grid container justifyContent="center" xs={12} mt={2} />
            <Grid item xs={12}>
                <MainCard
                    contentSX={{
                        p: 1.5,
                        paddingBottom: '0px !important',
                        [theme.breakpoints.down('lg')]: {
                            textAlign: 'center'
                        }
                    }}
                >
                    {/* {isLoading ? (
                        <ImagePlaceholder
                            sx={{
                                borderRadius: `${borderRadius}px`,
                                overflow: 'hidden',
                                mb: 3,
                                height: { xs: 85, sm: 150, md: 260 }
                            }}
                        />
                    ) : (
                        <CardMedia
                            component="img"
                            src={league?.images?.length > 0 && league?.images[0]?.image_url}
                            image={!league?.images?.length === 0 && Cover}
                            sx={{ borderRadius: `${borderRadius}px`, overflow: 'hidden', mb: 3, height: { xs: 150, md: 360 }, width: { xs: '100%' } }}
                        />
                    )} */}
                    <Grid container spacing={gridSpacing}>
                        {/* <Grid item xs={12} md={3} /> */}
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="h2">{league?.name}</Typography>
                                    {league?.club && <Typography variant="subtitle2">{league?.club?.name}</Typography>}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            justifyContent: 'flex-end',
                                            [theme.breakpoints.down('lg')]: {
                                                justifyContent: 'center'
                                            }
                                        }}
                                    >
                                        {/* <Grid item>
                                            <Button variant="outlined">Message</Button>
                                        </Grid> */}
                                        {/* <Grid item>
                                            <Button
                                                variant="contained"
                                                startIcon={groupInfo?.user_group ? <SupervisorAccountIcon /> : <PersonAddTwoToneIcon />}
                                                onClick={() => {
                                                    if (groupInfo?.user_group) {
                                                        leaveGroup(tournamentId);
                                                        navigate('/dashboard');
                                                    } else {
                                                        joinGroup(tournamentId);
                                                    }
                                                }}
                                            >
                                                {groupInfo?.user_group ? 'Member' : 'Join Group'}
                                            </Button>
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="space-between" alignItems="center" ml={2}>
                                <Grid container flexDirection="column" justifyContent="flex-start" xs={12} md={4}>
                                    <Grid item alignSelf="flex-start">
                                        <AvatarGroup
                                            max={4}
                                            total={totalParticipants}
                                            renderSurplus={(surplus) => <Typography style={{ color: 'white' }}>+{surplus}</Typography>}
                                        >
                                            {tournamentRoster.map((usr) => (
                                                <Avatar alt="User 1" src={usr.user.user_attributes.profile_picture_url} />
                                            ))}
                                        </AvatarGroup>
                                    </Grid>
                                </Grid>

                                <Grid container xs={12} md={8} justifyContent="flex-end" alignSelf="flex-end">
                                    <Tabs
                                        value={activeMainTab}
                                        onChange={handleMainTabChange}
                                        variant="scrollable"
                                        sx={{
                                            marginTop: 2.5,
                                            '& .MuiTabs-flexContainer': {
                                                border: 'none'
                                            },
                                            '& a': {
                                                minHeight: 'auto',
                                                minWidth: 10,
                                                py: 1.5,
                                                px: 1,
                                                mr: 2.25,
                                                color: 'grey.700',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            },
                                            '& a.Mui-selected': {
                                                color: 'primary.main'
                                            },
                                            '& a > svg': {
                                                marginBottom: '4px !important',
                                                mr: 1.25
                                            }
                                        }}
                                    >
                                        {tabOptions.map((option, index) => (
                                            <Tab
                                                key={index}
                                                component={Link}
                                                // to={option.to}
                                                icon={option.icon}
                                                label={option.label}
                                                {...a11yProps(index)}
                                            />
                                        ))}
                                    </Tabs>

                                    {/* Conditionally render the admin options if 'Tournament Manager' is active */}
                                </Grid>
                            </Grid>

                            {/* {showAdminOptions && (
                                <Grid container justifyContent="flex-end">
                                    <Tabs
                                        value={value}
                                        variant="scrollable"
                                        onChange={handleChange}
                                        sx={{
                                            marginTop: 2.5,
                                            '& .MuiTabs-flexContainer': {
                                                border: 'none'
                                            },
                                            '& a': {
                                                minHeight: 'auto',
                                                minWidth: 10,
                                                py: 1.5,
                                                px: 1,
                                                mr: 2.25,
                                                color: 'grey.700',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            },
                                            '& a.Mui-selected': {
                                                color: 'primary.main'
                                            },
                                            '& a > svg': {
                                                marginBottom: '4px !important',
                                                mr: 1.25
                                            }
                                        }}
                                    >
                                        {adminTabOptions.map((option, index) => (
                                            <Tab
                                                key={index}
                                                component={Link}
                                                // to={option.to}
                                                icon={option.icon}
                                                label={option.label}
                                                {...a11yProps(index)}
                                            />
                                        ))}
                                    </Tabs>
                                </Grid>
                            )} */}
                        </Grid>
                    </Grid>
                </MainCard>
            </Grid>
            <Grid item xs={12}>
                {/* <TabPanel value={value} index={0}>
                    <Profile league={league} />
                </TabPanel> */}
                {/* <TabPanel value={value} index={1}>
                    <Followers />
                </TabPanel> */}
                {/* <TabPanel value={value} index={1}>
                    <DrawResults />
                </TabPanel> */}
                {/* <TabPanel value={value} index={2}>
                    <Gallery />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <FriendRequest />
                </TabPanel> */}
                {/* <TabPanel value={value} index={0}>
                    <Profile />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Followers />
                </TabPanel> */}
                {/* <TabPanel value={value} index={2}>
                    <Friends />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Gallery />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <TournamentManager />
                </TabPanel> */}
                {mainTabs.map((tab, index) => (
                    <TabPanel value={activeMainTab} index={index} key={index}>
                        {/* This is where you determine what to show for each main tab */}
                        {getContentForMainTab(index)}
                    </TabPanel>
                ))}
            </Grid>
        </Grid>
    );
};

export default LeagueHome;
