// material-ui
import { styled } from '@mui/material/styles';

// project imports
import Header from './Header';
import Feature from './Feature';
import Demos from './Demos';
import Layouts from './Layouts';
import KeyFeature from './KeyFeature';
import Subscribe from './Subscribe';
import Footer from './Footer';
import Customization from 'layout/Customization';
import { Box, Button, Typography, Card, CardContent, CardActions, Grid, Container, Stack, AppBar } from '@mui/material';
import GenericSection from './GenericSection';
import {
    BarChart,
    CardMembershipOutlined,
    ConfirmationNumber,
    CreditCardOutlined,
    Edit,
    NotificationsActive,
    OpenInBrowser,
    PostAdd,
    TransferWithinAStation,
    TransferWithinAStationOutlined
} from '@mui/icons-material';
import { useTheme } from '@mui/styles';
// import AdminDashboard from 'assets/images/landing/admin_dashboard.png';
import AdminDashboard from 'assets/images/landing/admin_dashboard copy.png';
import PlayerExperience from 'assets/images/landing/player_experience_home.png';
import GetPaid from 'assets/images/landing/payments.png';
import Tools from './Tools';
import Testimonials from './Testimonials';

const HeaderWrapper = styled('div')(({ theme }) => ({
    // paddingTop: 30,
    // overflowX: 'hidden',
    // overflowY: 'clip'
    // [theme.breakpoints.down('md')]: {
    //     paddingTop: 42
    // }
}));

const SecondWrapper = styled('div')(({ theme }) => ({
    paddingTop: 60,
    paddingBottom: 60,
    [theme.breakpoints.down('md')]: {
        paddingTop: 60
    }
}));
const DarkWrapper = styled('div')(({ theme }) => ({
    paddingTop: 60,
    backgroundColor: theme.palette.primary.main, // Use the theme for colors
    marginLeft: -40,
    paddingLeft: 40,
    paddingRight: 40,
    marginRight: -40,
    [theme.breakpoints.down('md')]: {
        paddingTop: 60
    }
}));

// =============================|| LANDING MAIN ||============================= //

const HostLanding = () => {
    const theme = useTheme();
    const sectionContent = [
        {
            title: 'Event Ticketing',
            descriptor:
                'Sell more tickets with customizable event pages and a seamless checkout experience for attendees on a trusted platform',
            icon: <ConfirmationNumber style={{ color: 'white' }} />,
            iconbgcolor: 'primary.main'
        },
        {
            title: 'Reporting & Analytics',
            descriptor: 'Learn more about your buyers and discover where sales are coming from with real-time analytics',
            icon: <BarChart style={{ color: 'white' }} />,
            iconbgcolor: 'primary.main'
        },
        {
            title: 'Powerful Organizer Dashboard',
            descriptor: 'Check players in, generate pools & brackets, and track data with our easy-to-use Oranizer Dashboard',
            icon: <OpenInBrowser style={{ color: 'white' }} />,
            iconbgcolor: 'primary.main'
        }
    ];
    const playerExperienceContent = [
        {
            title: 'View Matches & Input Scores',
            descriptor: 'Take pressure off of your event coordinators by having players input their scores directly in the app',
            icon: <Edit style={{ color: theme.palette.primary.main }} />,
            iconbgcolor: 'white'
        },
        {
            title: 'Court & Match Notifications',
            descriptor: 'Reach your players directly with time-sensitive court updates to help the tournament flow better',
            icon: <NotificationsActive style={{ color: theme.palette.primary.main }} />,
            iconbgcolor: 'white'
        },
        {
            title: 'Event Announcements & Comms',
            descriptor: 'Players can see event announcements and reach out to admins through the in-app messaging',
            icon: <PostAdd style={{ color: theme.palette.primary.main }} />,
            iconbgcolor: 'white'
        }
    ];
    const payContent = [
        {
            title: 'Low-cost fees',
            descriptor: 'Clear value, transparent feed',
            icon: <CreditCardOutlined style={{ color: 'white' }} />,
            iconbgcolor: 'primary.main'
        },
        {
            title: 'Payment Processing',
            descriptor: 'Easily collect and simplify event payments with built-in, full-service payment processing',
            icon: <CardMembershipOutlined style={{ color: 'white' }} />,
            iconbgcolor: 'primary.main'
        },
        {
            title: 'Immediate Payouts',
            descriptor: 'Your money is immediately transferred into your Stripe account',
            icon: <TransferWithinAStationOutlined style={{ color: 'white' }} />,
            iconbgcolor: 'primary.main'
        }
    ];
    return (
        <>
            <HeaderWrapper id="home" data-test-id="views/landing/Host/index">
                {/* <AppBar /> */}
                <Header />
            </HeaderWrapper>
            <SecondWrapper>
                <Feature />
            </SecondWrapper>
            <SecondWrapper>
                <GenericSection
                    sectionContent={sectionContent}
                    flexDirection="row"
                    isDark={false}
                    title="Event hosting made easy"
                    subtitle="Easily create and manage event on a platform that players love and trust"
                    image={AdminDashboard}
                />
            </SecondWrapper>
            <DarkWrapper>
                <GenericSection
                    sectionContent={playerExperienceContent}
                    flexDirection="row-reverse"
                    isDark
                    title="Re-imagined Player Experience "
                    subtitle="Differentiate your events with a superior player experience through our mobile app"
                    image={PlayerExperience}
                    imageWidth={[theme.breakpoints.down('xs')] ? '75%' : '30%'}
                />
            </DarkWrapper>
            <SecondWrapper>
                <Tools />
            </SecondWrapper>
            <SecondWrapper>
                <GenericSection
                    sectionContent={payContent}
                    flexDirection="row"
                    isDark={false}
                    title="Get paid, earn more"
                    subtitle="Do what you love and earn more money with low-cost fees and quicker payouts"
                    image={GetPaid}
                    imageWidth="125%"
                />
            </SecondWrapper>
            <SecondWrapper>
                <Testimonials />
            </SecondWrapper>
            <SecondWrapper>
                <Subscribe />
            </SecondWrapper>

            <Footer />
        </>
    );
};

export default HostLanding;
