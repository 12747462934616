import { useContext } from 'react';

// auth provider
import { LeaguesContext } from 'contexts/LeaguesContext';
// import AuthContext from 'contexts/Auth0Context';
// import AuthContext from 'contexts/JWTContext';
// import AuthContext from 'contexts/AWSCognitoContext';

// ==============================|| AUTH HOOKS ||============================== //

const useLeagues = () => {
    const context = useContext(LeaguesContext);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};

export default useLeagues;
