/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';

// material-ui
import { Grid, Button, Tabs, Tab, Autocomplete, TextField, InputAdornment, Typography, Link as Linkk } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import { useDispatch, useSelector } from 'store';

// assets
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import useLeagues from 'hooks/useLeagues';
import { makeStyles } from '@mui/styles';
import PoolsTable from 'views/components/poolsTable/poolsTable.component';
import Pools from './Pools';
import MedalRound from './MedalRound';
import { Link } from 'react-router-dom';
import capitalizeWords from 'utils/capitalizeWords';
import { styled, lighten, darken } from '@mui/system';
import Matches from './Matches';
import MatchInputDrawer from './MatchInputDrawer';
import { useFormik } from 'formik';
import SearchIcon from '@mui/icons-material/Search';
import GeneratePoolsDrawer from './GeneratePoolsDrawer';
import GenerateBracketDrawer from './GenerateBracketDrawer';
import useEvents from 'hooks/useEvents';
import useAuth from 'hooks/useAuth';
import getReadableDateTime from 'utils/get_readable_datetime';

// ==============================|| SOCIAL PROFILE - FOLLOWERS ||============================== //
const TournamentManager = ({ options, leagueId, groupId }) => {
    const { getLeagueGroups, getLeagueLeaderboard, leagueLeaderboard, eventBracket } = useLeagues();
    const { getEvents, events } = useEvents();
    const { user } = useAuth();

    const [selectedEvent, setSelectedEvent] = useState();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedMatchId, setSelectedMatchId] = useState();
    const [matchSearch, setMatchSearch] = useState('');
    const handleDrawerOpen = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleMatchSearch = (matchId) => {
        setSelectedMatchId(matchId);
        handleDrawerOpen();
    };

    useEffect(() => {
        setSelectedEvent(options.sort((a, b) => -b.group.localeCompare(a.group))[0]);
    }, [options]);

    const [tab, setTab] = useState(0);
    const handleTabChange = (value) => {
        setTab(value);
    };

    const [selectedPool, setSelectedPool] = useState(0);
    const [selectedRound, setSelectedRound] = useState();
    const [leagueGroups, setLeagueGroups] = useState([]);

    useEffect(() => {
        getEvents(user?.id, groupId, 1);
    }, [groupId]);

    useEffect(() => {
        getLeagueGroups(selectedEvent?.id).then((res) => {
            setLeagueGroups(res.data);
        });
        getLeagueLeaderboard(leagueId, selectedEvent?.id);
    }, [selectedEvent]);

    const [generateDrawerOpen, setGenerateDrawerOpen] = useState(false);
    const [generateBracketOpen, setGenerateBracketOpen] = useState(false);
    const [eventId, setEventId] = useState();
    const [teamLength, setTeamLength] = useState();
    const handleGenerateDrawer = (selectedEventId, selectedTeamLength) => {
        setEventId(selectedEventId);
        setTeamLength(selectedTeamLength);
        setGenerateDrawerOpen(!generateDrawerOpen);
    };
    const handleGenerateBracketDrawer = () => {
        // setEventId(selectedEvent.id);
        setGenerateBracketOpen(!generateBracketOpen);
    };

    const getAdminTab = () => {
        switch (tab) {
            case 'overview':
                return (
                    <div>
                        <p> overview tab</p>
                    </div>
                );
            case 0:
                return (
                    <Pools
                        leagueId={leagueId}
                        leagueGroups={leagueGroups}
                        setTab={setTab}
                        setSelectedPool={setSelectedPool}
                        eventId={selectedEvent?.id}
                        eventName={selectedEvent?.title}
                        handleGenerateDrawer={handleGenerateDrawer}
                        leagueLeaderboard={leagueLeaderboard}
                    />
                );
            case 1:
                return (
                    <Matches
                        leagueGroups={leagueGroups}
                        selectedPool={selectedPool}
                        setSelectedPool={setSelectedPool}
                        setTab={setTab}
                        handleDrawerOpen={handleDrawerOpen}
                        setSelectedMatchId={setSelectedMatchId}
                        selectedEvent={selectedEvent}
                        selectedRound={selectedRound}
                        setSelectedRound={setSelectedRound}
                        eventBracket={eventBracket}
                        eventId={selectedEvent?.id}
                    />
                );
            case 2:
                return (
                    <MedalRound
                        eventId={selectedEvent?.id}
                        handleGenerateBracketDrawer={handleGenerateBracketDrawer}
                        selectedEvent={selectedEvent}
                    />
                );
            default:
                return (
                    <div>
                        <p> overview tab</p>
                    </div>
                );
        }
    };

    const handleMainTabChange = (event, newValue) => {
        setTab(newValue);
        // If we're leaving the Tournament Manager tab, reset the subtab
    };
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }
    const tabOptions = ['Pools', 'Matches', 'Medal Rounds', 'Results'];

    return (
        <Grid container spacing={gridSpacing} data-test-id="views/leagues/LeagueHome/TournamentManager/index">
            <Grid item xs={12}>
                <Grid container justifyContent="flex-start" xs={12} mt={2}>
                    <Grid container xs={12} justifyContent="space-between" alignSelf="flex-end">
                        <Tabs
                            value={tab}
                            onChange={handleMainTabChange}
                            variant="scrollable"
                            sx={{
                                // marginTop: 2.5,
                                '& .MuiTabs-flexContainer': {
                                    border: 'none'
                                },
                                '& a': {
                                    minHeight: 'auto',
                                    minWidth: 10,
                                    py: 1.5,
                                    px: 1,
                                    mr: 2.25,
                                    color: 'grey.700',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                },
                                '& a.Mui-selected': {
                                    color: 'primary.main'
                                },
                                '& a > svg': {
                                    marginBottom: '4px !important',
                                    mr: 1.25
                                }
                            }}
                        >
                            {tabOptions.map((option, index) => (
                                <Tab
                                    key={index}
                                    component={Link}
                                    // to={option.to}
                                    // icon={option.icon}
                                    label={option}
                                    {...a11yProps(index)}
                                />
                            ))}
                        </Tabs>
                        <Grid>
                            <TextField
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <Linkk
                                                component="button"
                                                value={matchSearch}
                                                onClick={() => {
                                                    handleMatchSearch(matchSearch);
                                                }}
                                            >
                                                <SearchIcon />
                                            </Linkk>
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(event) => setMatchSearch(event.target.value)}
                                id="match_search"
                                placeholder="Match ID Search"
                                onKeyDown={(ev) => {
                                    if (ev.key === 'Enter') {
                                        handleMatchSearch(matchSearch);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item mb={1} xs={12} mt={2} sx={{ paddingRight: 4 }}>
                            <Autocomplete
                                id="grouped-demo"
                                options={events}
                                disableClearable
                                // groupBy={(option) => capitalizeWords(option.group)}
                                onChange={(event, value) => setSelectedEvent(value)}
                                defaultValue={options[0]}
                                getOptionLabel={(option) => getReadableDateTime(option.start_date_time, 'date')} // Replace 'eventName' with the actual property name of your event
                                renderInput={(params) => <TextField {...params} label="Events" />}
                                // renderGroup={(params) => (
                                //     <li key={params.key}>
                                //         <GroupHeader>{params.group}</GroupHeader>
                                //         <GroupItems>{params.children}</GroupItems>
                                //     </li>
                                // )}
                            />
                        </Grid>

                        {/* Conditionally render the admin options if 'Tournament Manager' is active */}
                    </Grid>
                </Grid>
            </Grid>
            {getAdminTab()}
            {/* <Pools leagueGroups={leagueGroups} /> */}
            <MatchInputDrawer
                handleDrawerOpen={handleDrawerOpen}
                openSidebar={drawerOpen}
                selectedMatchId={selectedMatchId}
                tournamentId={1}
            />
            <GeneratePoolsDrawer
                handleGenerateDrawer={handleGenerateDrawer}
                generateDrawerOpen={generateDrawerOpen}
                eventId={eventId}
                teamLength={teamLength}
                leagueId={leagueId}
            />
            <GenerateBracketDrawer
                handleGenerateDrawer={handleGenerateBracketDrawer}
                generateDrawerOpen={generateBracketOpen}
                selectedEvent={selectedEvent}
            />
        </Grid>
    );
};

export default TournamentManager;
