/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    CardContent,
    Drawer,
    Grid,
    Typography,
    Box,
    IconButton,
    Tooltip,
    useMediaQuery,
    Button,
    Menu,
    MenuItem,
    ListItemIcon
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import MainCard from 'ui-component/cards/MainCard';
import PlayerCell from 'views/components/playerCell.component';
import { CalendarMonth, CheckCircle, ConfirmationNumber, Delete, Edit, Merge, Money, MoreHoriz, QueryBuilder, ViewArray } from '@mui/icons-material';
import FieldSection from 'ui-component/FieldSection';
import getReadableDateTime from 'utils/get_readable_datetime';
import CurrencyFormat from 'react-currency-format';
import capitalizeWords from 'utils/capitalizeWords';
import { gridSpacing } from 'store/constant';
import CustomTable from 'ui-component/table/CustomTable';
import useEvents from 'hooks/useEvents';
import actionSnackbar from 'ui-component/actionSnackbar';

const EventDrawer = ({ toggleDrawer, drawerOpen, eventId, handleEdit }) => {
    const theme = useTheme();
    const { getEvent, event, getEventParticipants, eventInvitations, checkInUser, deleteInstance } = useEvents();
    const [anchorEl, setAnchorEl] = useState(null);
    const [participants, setParticipants] = useState([])
    const handleClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        getEvent(eventId)
    }, [eventId])

    useEffect(() => {
        getEventParticipants(event?.event_id, event?.id)
    }, [event?.id])
    useEffect(() => {
        const data = eventInvitations?.map((item) => ([
            {
                id: 'name',
                data: <PlayerCell user={item.user} mt={0} padding={0} />,
                isVisible: true
            },
            {
                id: 'check_in',
                data: <Button startIcon={item.checked_in ? <CheckCircle /> : null} onClick={() => checkInUser(item.invitation_id, !item.checked_in)} variant={item.checked_in ? 'contained' : 'outlined'} > {item.checked_in ? 'Checked In' : 'Check In'}</Button >,
                isVisible: true
            },

        ]))
        setParticipants(data)
    }, [eventInvitations])

    const matchDownSM = useMediaQuery(theme.breakpoints.down('xl'));
    const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
    const drawerBG = theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50';
    const [tooltipText, setTooltipText] = useState('Copy ID');

    const handleCopy = () => {
        navigator.clipboard.writeText(event?.id).then(() => {
            setTooltipText('Copied');
            setTimeout(() => {
                setTooltipText('Copy ID');
            }, 3000); // Reset tooltip text after 3 seconds
        });
    };
    const headers = [{ id: 'name', label: 'Name' }, { id: 'check_in', label: 'Check In' }]



    return (
        <Drawer
            sx={{
                ml: drawerOpen ? 3 : 0,
                flexShrink: 0,
                zIndex: 1200,
                overflowX: 'hidden',
                width: { xs: '95%', sm: '75%', lg: '50%' },
                '& .MuiDrawer-paper': {
                    height: '100vh',
                    width: { xs: '95%', sm: '75%', lg: '50%' },
                    position: 'fixed',
                    border: 'none',
                    borderRadius: '0px'
                }
            }}
            variant="temporary"
            anchor="right"
            open={drawerOpen}
            ModalProps={{ keepMounted: true }}
            onClose={toggleDrawer}
        >
            {drawerOpen && (
                <MainCard
                    sx={{
                        bgcolor: matchDownLG ? 'transparent' : drawerBG,
                        paddingBottom: 150
                    }}
                    content={false}
                    elevation={16}
                    boxShadow
                    shadow={theme.shadows[6]}
                    title={
                        <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', paddingY: 0 }}>
                            <Grid>
                                <Tooltip title={tooltipText}>
                                    <Grid
                                        mb={1}
                                        sx={{
                                            border: '1px solid lightgrey',
                                            borderRadius: 1,
                                            paddingX: 1.2,
                                            display: 'inline-block',
                                            '&:hover': { cursor: 'pointer' }
                                        }}
                                        onClick={handleCopy}
                                    >
                                        <Typography variant="caption" gutterBottom color={theme.palette.grey[800]}>
                                            #{event?.id}
                                        </Typography>

                                    </Grid>
                                </Tooltip>

                                <Typography variant="h3">{event?.title}</Typography>
                            </Grid>

                            <Box>
                                <IconButton size="small" onClick={handleClick}>
                                    <MoreHoriz fontSize='small' />

                                </IconButton>
                                <Menu
                                    id="menu-followers-card"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    variant="selectedMenu"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                >
                                    <MenuItem onClick={() => {
                                        deleteInstance(eventId).then(() => {
                                            toggleDrawer();
                                            actionSnackbar(true, 'Event Canceled')
                                        })
                                    }}

                                    >
                                        <ListItemIcon>
                                            <Delete fontSize="small" />
                                        </ListItemIcon>
                                        Cancel
                                    </MenuItem>

                                </Menu>
                                <IconButton size="small" onClick={handleEdit} sx={{ ml: 1 }}>
                                    <Edit fontSize='small' />

                                </IconButton>
                                <IconButton size="small" onClick={toggleDrawer} sx={{ ml: 1 }}>
                                    <CancelIcon fontSize='small' />
                                </IconButton>
                            </Box>
                        </Grid>
                    }
                >
                    <CardContent
                        sx={{
                            height: matchDownSM ? '100vh' : 'auto'
                            // overflowY: 'auto'
                        }}
                    >
                        <Grid container direction="column" spacing={gridSpacing}>
                            <Grid item>
                                <Typography variant="h4"> Details</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={6}>
                                        <FieldSection
                                            icon={<ViewArray />}
                                            label="Court"
                                            value={event?.resources?.map((item, index) => {
                                                if (event.resources.length > index + 1) {
                                                    return `${item.court?.name}, `
                                                }
                                                return item.court.name


                                            })}
                                        />
                                        <FieldSection
                                            icon={<CalendarMonth />}
                                            label="Date"
                                            value={getReadableDateTime(event?.start_date_time, 'date')}
                                        />
                                        <FieldSection
                                            icon={<QueryBuilder />}
                                            label="Time "
                                            value={event?.all_day ? 'ALL DAY' : `${getReadableDateTime(event?.start_date_time, 'time')} - ${getReadableDateTime(event?.end_date_time, 'time')}`}
                                        />



                                    </Grid>
                                    {event?.event?.booking &&
                                        <Grid item xs={12} md={6}>
                                            <FieldSection
                                                icon={<Money />}
                                                label="Amount Paid"
                                                value={<CurrencyFormat
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    value={event?.event?.booking?.total_cost / 100 || 0}
                                                    displayType="text"
                                                    thousandSeparator
                                                    prefix="$"
                                                />}
                                            />
                                            <FieldSection
                                                icon={<ConfirmationNumber />}
                                                label="Status"
                                                value={capitalizeWords(event?.event?.booking?.status)}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            {event.description &&
                                <>
                                    <Grid item>
                                        <Typography variant='h4'> Event Description</Typography>

                                    </Grid>
                                    <Grid item>

                                        <Typography> {event.description}</Typography>
                                    </Grid>
                                </>
                            }
                            {eventInvitations.length > 0 && event.event?.booking &&
                                <>
                                    <Grid item>
                                        <Typography variant='h4'> Participants</Typography>

                                    </Grid>
                                    <Grid item>
                                        <CustomTable sx={{ border: '1px solid lightgrey' }} headers={headers} data={participants} />

                                    </Grid>
                                </>
                            }


                        </Grid>
                    </CardContent>
                </MainCard>
            )
            }
        </Drawer >
    );
};

EventDrawer.propTypes = {
    toggleDrawer: PropTypes.func,
    drawerOpen: PropTypes.bool,
    eventId: PropTypes.number
};

export default EventDrawer;
