import { TableRow, Grid, TableCell, Stack, Typography } from '@mui/material';

const FieldSection = ({ icon, label, value }) => (
    <Stack
        direction={{ xs: 'column', sm: 'row' }}
        pt={1}
        pb={1}
        alignItems={{ xs: 'flex-center', sm: 'center' }}
        justifyContent="space-between"
    >
        {/* <Grid container justifyContent="space-between" pt={1} pb={1} alignItems="center"> */}
        <Grid item width={{ xs: '100%', sm: '45%' }} sx={{ wordWrap: 'break-word', overflow: 'hidden', minWidth: 150 }}>
            <Stack direction="row" alignItems="center">
                {icon}
                <Typography variant={{ xs: 'caption', sm: 'h5' }} ml={1}>
                    {label}
                </Typography>
            </Stack>
        </Grid>
        <Grid item width={{ xs: '100%', sm: '45%' }} mt={{ xs: 1, sm: 0 }}>
            <Typography ml={{ xs: 4, sm: 0 }} fontWeight={{ xs: 600, sm: 200 }} sx={{ wordWrap: 'break-word', overflow: 'hidden' }}>
                {value}
            </Typography>
        </Grid>
    </Stack>
);

export default FieldSection;
