import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, ButtonGroup, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import TwoWheelerTwoToneIcon from '@mui/icons-material/TwoWheelerTwoTone';
import AirportShuttleTwoToneIcon from '@mui/icons-material/AirportShuttleTwoTone';
import DirectionsBoatTwoToneIcon from '@mui/icons-material/DirectionsBoatTwoTone';
import CurrencyFormat from 'react-currency-format';
import useClubs from 'hooks/useClubs';
import MembershipCheckoutModal from './MembershipCheckoutModal';

const MembershipPricing = ({ clubId }) => {
    const theme = useTheme();
    const [priceFlag, setPriceFlag] = useState('monthly');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedMembership, setSelectedMembership] = useState(undefined);
    const [selectedPrice, setSelectedPrice] = useState(undefined);
    const [clientSecret, setClientSecret] = useState(undefined);
    const [subId, setSubId] = useState(undefined);
    const [stripeInvoiceId, setStripeInvoiceId] = useState(undefined);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const { getClubMemberships, clubMemberships, selectMembership, getClub, club } = useClubs();

    useEffect(() => {
        if (clubId) {
            getClub(clubId);
            getClubMemberships(clubId);
        }
    }, [clubId]);

    return (
        <>
            <Stack direction="row" spacing={gridSpacing} justifyContent="center" mb={2}>
                <ButtonGroup disableElevation sx={{ mb: 3 }}>
                    <Button
                        size="large"
                        variant={priceFlag === 'monthly' ? 'contained' : 'outlined'}
                        // sx={{ bgcolor: priceFlag === 'monthly' ? 'primary.main' : 'primary.200' }}
                        onClick={() => setPriceFlag('monthly')}
                    >
                        Monthly
                    </Button>
                    <Button
                        variant={priceFlag === 'annual' ? 'contained' : 'outlined'}
                        size="large"
                        // sx={{ bgcolor: priceFlag === 'yearly' ? 'primary.main' : 'primary.200' }}
                        onClick={() => setPriceFlag('annual')}
                    >
                        Annual
                    </Button>
                </ButtonGroup>
            </Stack>
            <Grid container spacing={gridSpacing}>
                {clubMemberships?.map((plan, index) => {
                    const darkBorder = theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75;
                    return (
                        <Grid item xs={12} sm={6} md={4} key={index} display="flex" flex={1}>
                            <MainCard
                                boxShadow
                                sx={{
                                    pt: 1.75,
                                    border: '1px solid',
                                    borderColor: darkBorder
                                }}
                            >
                                <Grid container textAlign="center" spacing={gridSpacing}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontSize: '1.5625rem',
                                                fontWeight: 500,
                                                position: 'relative',
                                                mb: 1.875,
                                                '&:after': {
                                                    content: '""',
                                                    position: 'absolute',
                                                    bottom: -15,
                                                    left: 'calc(50% - 25px)',
                                                    width: 50,
                                                    height: 4,
                                                    background: theme.palette.primary.main,
                                                    borderRadius: '3px'
                                                }
                                            }}
                                        >
                                            {plan.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">{plan.description}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            component="div"
                                            variant="body2"
                                            sx={{
                                                fontSize: '2.1875rem',
                                                fontWeight: 700,
                                                '& > span': {
                                                    fontSize: '1.25rem',
                                                    fontWeight: 500
                                                }
                                            }}
                                        >
                                            <sup>$</sup>
                                            <CurrencyFormat
                                                style={{
                                                    fontSize: '2.1875rem',
                                                    fontWeight: 700,
                                                    '& > span': {
                                                        fontSize: '1.25rem',
                                                        fontWeight: 500
                                                    }
                                                }}
                                                decimalScale={0}
                                                fixedDecimalScale
                                                value={plan.pricing.find((item) => item.term === priceFlag)?.price / 100}
                                                displayType="text"
                                                thousandSeparator
                                            />
                                            {priceFlag === 'monthly' ? <span>/Month</span> : <span>/Year</span>}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                setSelectedMembership(plan);
                                                setSelectedPrice(plan.pricing.find((item) => item.term === priceFlag));
                                                selectMembership(plan.id, plan.pricing.find((item) => item.term === priceFlag)?.id).then(
                                                    (res) => {
                                                        console.log('res', res);
                                                        setClientSecret(res.data.intent.client_secret);
                                                        setSubId(res.data.stripe_sub.id);
                                                        setStripeInvoiceId(res.data.invoice.id);
                                                        toggleModal();
                                                    }
                                                );
                                            }}
                                        >
                                            Buy Now
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List
                                            sx={{
                                                m: 0,
                                                p: 0,
                                                '&> li': {
                                                    px: 0,
                                                    py: 0.625,
                                                    '& svg': {
                                                        fill: theme.palette.success.dark
                                                    }
                                                }
                                            }}
                                            component="ul"
                                        >
                                            {plan?.features?.map((list, i) => (
                                                <React.Fragment key={i}>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <CheckTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={list.feature_description} />
                                                    </ListItem>
                                                    <Divider />
                                                </React.Fragment>
                                            ))}
                                        </List>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                    );
                })}
            </Grid>
            <MembershipCheckoutModal
                membership={selectedMembership}
                price={selectedPrice}
                open={modalOpen}
                toggleModal={toggleModal}
                clientSecret={clientSecret}
                stripeAccount={club?.owner?.stripe_account_id}
                stripeInvoiceId={stripeInvoiceId}
                subId={subId}
            />
        </>
    );
};

export default MembershipPricing;
