/* eslint-disable arrow-body-style */
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';

// third-party
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import useAuth from 'hooks/useAuth';

// assets
import { IconLogout, IconSettings, IconUser } from '@tabler/icons';
import useConfig from 'hooks/useConfig';
import { ConfirmationNumber, Event, Money, Settings } from '@mui/icons-material';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selected, setSelected] = useState('');
    const { logout, user } = useAuth();
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        setSelected(route);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const HostButton = () => (
        <ListItemButton
            sx={{ borderRadius: `${borderRadius}px` }}
            selected={location?.pathname === '/host'}
            onClick={(event) => handleListItemClick(event, 1, `/host`)}
        >
            <ListItemIcon>
                <Event stroke={1.5} size="20px" />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item>
                            <Typography variant="body2">
                                <FormattedMessage id="host_events" />
                            </Typography>
                        </Grid>
                    </Grid>
                }
            />
        </ListItemButton>
    );

    const ManageMyEventsButton = () => (
        <ListItemButton
            sx={{ borderRadius: `${borderRadius}px` }}
            selected={location?.pathname === '/clubs/home'}
            onClick={(event) => handleListItemClick(event, 0, `/clubs/home`)}
        >
            <ListItemIcon>
                <Event stroke={1.5} size="20px" />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item>
                            <Typography variant="body2">
                                <FormattedMessage id="manage_events" />
                            </Typography>
                        </Grid>
                    </Grid>
                }
            />
        </ListItemButton>
    );

    const UserSettingsButton = () => (
        <ListItemButton
            sx={{ borderRadius: `${borderRadius}px` }}
            selected={location?.pathname === '/user/settings'}
            onClick={(event) => handleListItemClick(event, 2, `/user/settings`)}
        >
            <ListItemIcon>
                <Settings stroke={1.5} size="20px" />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item>
                            <Typography variant="body2">
                                <FormattedMessage id="account-settings" />
                            </Typography>
                        </Grid>
                    </Grid>
                }
            />
        </ListItemButton>
    );

    const UserCreditButton = () => (
        <ListItemButton
            sx={{ borderRadius: `${borderRadius}px` }}
            selected={location?.pathname === '/user/credit'}
            onClick={(event) => handleListItemClick(event, 4, `/user/credit`)}
        >
            <ListItemIcon>
                <Money stroke={1.5} size="20px" />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item>
                            <Typography variant="body2">
                                <FormattedMessage id="credits" />
                            </Typography>
                        </Grid>
                    </Grid>
                }
            />
        </ListItemButton>
    );
    const UserTicketsButton = () => (
        <ListItemButton
            sx={{ borderRadius: `${borderRadius}px` }}
            selected={location?.pathname === '/user/tickets'}
            onClick={(event) => handleListItemClick(event, 5, `/user/tickets`)}
        >
            <ListItemIcon>
                <ConfirmationNumber stroke={1.5} size="20px" />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item>
                            <Typography variant="body2">
                                <FormattedMessage id="tickets" />
                            </Typography>
                        </Grid>
                    </Grid>
                }
            />
        </ListItemButton>
    );

    const UserProfileButton = () => (
        <ListItemButton
            sx={{ borderRadius: `${borderRadius}px` }}
            selected={location?.pathname === `/user/social-profile/0/${user.id}`}
            onClick={(event) => handleListItemClick(event, 6, `/user/social-profile/0/${user.id}`)}
        >
            <ListItemIcon>
                <IconUser stroke={1.5} size="20px" />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item>
                            <Typography variant="body2">
                                <FormattedMessage id="profile" />
                            </Typography>
                        </Grid>
                    </Grid>
                }
            />
        </ListItemButton>
    );

    const LogoutButton = () => (
        <ListItemButton sx={{ borderRadius: `${borderRadius}px` }} selected={selectedIndex === 6} onClick={handleLogout}>
            <ListItemIcon>
                <IconLogout stroke={1.5} size="20px" />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="body2">
                        <FormattedMessage id="logout" />
                    </Typography>
                }
            />
        </ListItemButton>
    );

    const renderMenuOptions = () => {
        return (
            <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                <Box sx={{ p: 2, pb: 1 }}>
                    <Stack>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <Typography variant="h4">Hello,</Typography>
                            <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                {user?.name.split(' ')[0]}!
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
                <PerfectScrollbar
                    style={{
                        height: '100%',
                        maxHeight: 'calc(100vh - 250px)',
                        overflowX: 'hidden'
                    }}
                >
                    <Box sx={{ p: 2, pt: 0 }}>
                        <Divider />
                        <List
                            component="nav"
                            sx={{
                                width: '100%',
                                maxWidth: 350,
                                minWidth: 300,
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: '10px',
                                [theme.breakpoints.down('md')]: {
                                    minWidth: '100%'
                                },
                                '& .MuiListItemButton-root': {
                                    mt: 0.5
                                }
                            }}
                        >
                            {!user?.is_host && !user?.stripe_account_id && <HostButton />}
                            {user?.is_host && <ManageMyEventsButton />}
                            <UserSettingsButton />
                            <UserTicketsButton />
                            <UserCreditButton />
                            <UserProfileButton />
                            <LogoutButton />
                        </List>
                    </Box>
                </PerfectScrollbar>
            </MainCard>
        );
    };

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                data-test-id="layout/MainLayout/Header/ProfileSection/index"
                icon={
                    <Avatar
                        src={user?.user_attributes?.profile_picture_url}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={<IconSettings stroke={1.5} size="24px" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 14]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={open} {...TransitionProps}>
                            <Paper>{open && renderMenuOptions()}</Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
