import React, { useState, useEffect } from 'react';
import { AddCircleTwoTone, Close, Search, SubdirectoryArrowRight } from '@mui/icons-material';
import {
    Button,
    Grid,
    IconButton,
    useMediaQuery,
    TextField,
    InputAdornment,
    FormControl,
    Drawer,
    CardContent,
    FormHelperText,
    Typography,
    Divider
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import MainCard from 'ui-component/cards/MainCard';
import { dispatch } from 'store';
import { searchUsers } from 'store/slices/user';
import UserListCard from 'ui-component/cards/UserListCard';
import { useSelector } from 'react-redux';
import useLeagues from 'hooks/useLeagues';
import { openSnackbar } from 'store/slices/snackbar';
import { gridSpacing } from 'store/constant';
import DocumentUpload from './DocumentUpload';
import useTournaments from 'hooks/useTournaments';

const TeamDrawer = ({
    handleDrawerOpen,
    openTeamSidebar,
    existingUsers,
    maxTeamCount,
    eventId,
    handleUserDrawerOpen,
    leagueId,
    teamId,
    team,
    setTeam
}) => {
    const [search, setSearch] = useState('');
    const { userList } = useSelector((state) => state.user);
    const { createNewTeam, errors, setErrors } = useLeagues();
    const { getTeam, editTeam } = useTournaments();
    const { uploadRoster } = useTournaments();
    const [selectedFile, setSelectedFile] = useState();

    useEffect(() => {
        if (teamId) {
            getTeam(teamId).then((res) => {
                console.log('res', res);
                setTeam(res.players);
            });
        }
    }, [teamId]);

    const handleSearch = (event) => {
        const newInputValue = event;
        setSearch(newInputValue);
        dispatch(searchUsers(newInputValue));
    };
    useEffect(() => {
        setErrors({ newTeam: null });
    }, [team]);

    const handleAddMember = (user) => {
        setTeam((prevState) => [...prevState, { user }]);
    };

    const theme = useTheme();

    const matchDownSM = useMediaQuery(theme.breakpoints.down('xl'));

    const handleUserDelete = (id) => {
        setTeam((prevState) => prevState.filter((usr) => usr.user.id !== id));
    };
    const submitAction = () => {
        uploadRoster(selectedFile, eventId, leagueId)
            .then(() => {
                setSelectedFile();
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Roster Uploaded',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
            })
            .catch(() => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Failed Uploading Roster',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            });
    };

    return (
        <Drawer
            data-test-id="views/admin/AdminHub/EventInfo/components/TeamDrawer"
            sx={{
                ml: openTeamSidebar ? 3 : 0,
                flexShrink: 0,
                zIndex: 1200,
                overflowX: 'hidden',
                width: { xs: 320, md: 450 },
                '& .MuiDrawer-paper': {
                    height: '100vh',
                    width: { xs: 320, md: 450 },
                    position: 'fixed',
                    border: 'none',
                    borderRadius: '0px'
                }
            }}
            variant="temporary"
            anchor="right"
            open={openTeamSidebar}
            ModalProps={{ keepMounted: true }}
            onClose={() => {
                handleDrawerOpen();
            }}
        >
            <MainCard
                sx={{
                    bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50',
                    height: '100vh'
                }}
                pointer={false}
                border={!matchDownSM}
                content={false}
                title="Team"
                secondary={
                    <IconButton
                        aria-label="settings"
                        onClick={() => {
                            handleDrawerOpen();
                            setTeam([]);
                            setSearch('');
                        }}
                    >
                        <Close />
                    </IconButton>
                }
            >
                <CardContent sx={{ height: matchDownSM ? '100vh' : 'auto' }}>
                    <Grid alignItems="center">
                        <Grid>
                            {team.map((usr) => (
                                <UserListCard
                                    user={usr.user}
                                    // handleUserSelection={() => selectUserAction(user.id)}
                                    clickable={false}
                                    showDelete
                                    deleteAction={handleUserDelete}
                                />
                            ))}
                        </Grid>
                    </Grid>
                    {!selectedFile && (
                        <Grid item xs={12} mb={2} mt={2}>
                            <FormControl sx={{ width: '100%' }}>
                                {team.length < maxTeamCount && (
                                    <TextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search fontSize="small" />
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={(e) => handleSearch(e.target.value)}
                                        placeholder="Search Player"
                                        // value={''} // Bind value
                                        value={search}
                                        size="small"
                                        disabled={team.length >= maxTeamCount}
                                    />
                                )}
                                {search?.length > 0 && (
                                    <MainCard border={false} elevation={4} content={false} boxShadow>
                                        {/* sx={{ backgroundColor: 'white', padding: 1, boxShadow: theme.customShadows.primary }}> */}
                                        {userList?.map((user, index) => {
                                            const userExists = team.some((element) => element.id === user.id);
                                            return (
                                                <Grid key={index} item xs={12}>
                                                    <UserListCard
                                                        user={user}
                                                        handleUserSelection={() => {
                                                            handleAddMember(user);
                                                            setSearch('');
                                                        }}
                                                        clickable={!userExists}
                                                        showCheck={userExists}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                        <Button
                                            onClick={() => {
                                                setSearch('');

                                                handleUserDrawerOpen();
                                            }}
                                            sx={{
                                                padding: 2,
                                                width: '100%',
                                                justifyContent: 'flex-start'
                                            }}
                                        >
                                            <AddCircleTwoTone color="red" />

                                            <Typography ml={1}> Manually Add User</Typography>
                                        </Button>
                                    </MainCard>
                                )}
                            </FormControl>
                        </Grid>
                    )}
                    {team.length < 1 && (
                        <Grid>
                            {!selectedFile && (
                                <>
                                    <Divider />
                                    <Grid textAlign="center" mt={4}>
                                        <Typography variant="h5"> OR </Typography>
                                    </Grid>
                                </>
                            )}
                            <Grid container direction="row" spacing={gridSpacing}>
                                <DocumentUpload
                                    text="Select file"
                                    descriptor="Upload csv"
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFile}
                                    fileType=".csv"
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid mt={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit" // This will trigger form submission
                            disabled={team.length !== maxTeamCount && !selectedFile}
                            sx={{ mr: theme.spacing(3), paddingX: 6, paddingY: 1, marginBottom: 2 }}
                            onClick={() => {
                                if (teamId) {
                                    const params = {
                                        team_id: teamId,
                                        team_members: team
                                    };
                                    editTeam(params).then(() => {
                                        dispatch(
                                            openSnackbar({
                                                open: true,
                                                message: 'Team Saved',
                                                variant: 'alert',
                                                alert: {
                                                    color: 'success'
                                                },
                                                close: true
                                            })
                                        );
                                        handleDrawerOpen();
                                        setTeam([]);
                                        setSearch('');
                                    });
                                } else if (team.length > 0) {
                                    const params = {
                                        team_members: team,
                                        name: 'team',
                                        event_instance_id: eventId,
                                        status: 'Confirmed'
                                    };
                                    createNewTeam(params).then(() => {
                                        dispatch(
                                            openSnackbar({
                                                open: true,
                                                message: 'Team Added',
                                                variant: 'alert',
                                                alert: {
                                                    color: 'success'
                                                },
                                                close: true
                                            })
                                        );
                                        handleDrawerOpen();
                                        setTeam([]);
                                        setSearch('');
                                    });
                                } else if (selectedFile) {
                                    submitAction();
                                }
                            }}
                        >
                            Save
                        </Button>
                        <FormHelperText error>{errors.newTeam}</FormHelperText>
                        {team?.length !== maxTeamCount && !selectedFile && (
                            <FormHelperText error>Team must have {maxTeamCount} players </FormHelperText>
                        )}
                    </Grid>
                </CardContent>
            </MainCard>
        </Drawer>
    );
};
export default TeamDrawer;
