import PropTypes from 'prop-types';

// material-ui
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';

// third party
import Slider from 'react-slick';

// assets

import courtImage from 'assets/images/landing/find_courts_image.png';
import homeImage from 'assets/images/landing/join_group_image.png';
import groupImage from 'assets/images/landing/activity_page.png';
import imgLayoutGrid from 'assets/images/landing/img-lay-grid.png';

// styles
const LayoutImage = styled('img')({
    position: 'relative',
    // top: 0,
    // left: 0,
    width: '100%',
    height: '100%',
    animation: '5s wings ease-in-out infinite'
});

// =============================|| SLIDER ITEMS ||============================= //

const Item = ({ item }) => (
    <Grid container alignItems="center" justifyContent="center" spacing={3} textAlign="center">
        <Grid item xs={12} container justifyContent="center">
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                <img src={item.bg} alt="Berry" style={{ width: '25%', height: '25%' }} />
                <LayoutImage src={item.image} alt="Berry" />
            </Box>
        </Grid>
        <Grid item xs={10}>
            <Grid container direction="column" alignItems="center" spacing={3} textAlign="center">
                <Grid item sm={12}>
                    <Typography variant="h4" component="div">
                        {item.title}
                    </Typography>
                </Grid>
                <Grid item sm={12}>
                    <Typography variant="body2">{item.content}</Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);

Item.propTypes = {
    item: PropTypes.object.isRequired
};

// ==============================|| LANDING - SLIDER PAGE ||============================== //

const SliderPage = () => {
    const settings = {
        autoplay: true,
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const items = [
        {
            bg: imgLayoutGrid,
            image: courtImage,
            title: 'Find Courts',
            content: 'We have one of the largest repository of courts in the world. Find the court you are looking for...'
        },
        {
            bg: imgLayoutGrid,
            image: homeImage,
            title: 'Join Groups',
            content: 'Join existings groups or create your own'
        },
        {
            bg: imgLayoutGrid,
            image: groupImage,
            title: 'See whos at the court',
            content: 'With our first ever geofence court check-in feature, you can see whos;s at the courts and track your playing time.'
        }
    ];

    return (
        <Slider {...settings}>
            {items.map((item, index) => (
                <Item key={index} item={item} />
            ))}
        </Slider>
    );
};

export default SliderPage;
