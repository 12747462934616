import React, { useEffect, useState } from 'react';

// material-ui
import { Chip, Grid, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets
import LeaderboardTable from '../TournamentManager/LeagueLeaderboardTable';
import LadderGraph from './LadderGraph';
import useLeagues from 'hooks/useLeagues';

// ==============================|| USER LIST STYLE 1 ||============================== //

const Leaderboard = ({ leagueId, event_instance_id = null, isAdmin = false }) => {
    const { getLeagueLeaderboard } = useLeagues();
    const [hasLeaderboard, setHasLeaderboard] = useState(false);
    const [week, setWeek] = useState();

    useEffect(() => {
        if (leagueId) {
            getLeagueLeaderboard(leagueId, event_instance_id).then((res) => {
                setHasLeaderboard(res?.has_leaderboard);
                setWeek(res?.week);
            });
        }
    }, [leagueId, event_instance_id]);

    return (
        <MainCard
            data-test-id="views/leagues/LeagueHome/Leaderboard/Leaderboard"
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Typography variant="h3">{hasLeaderboard ? `Leaderboard - Week ${week}` : 'Roster'}</Typography>
                            {!hasLeaderboard && (
                                <Grid ml={1}>
                                    <Chip label="No Sessions Yet" />
                                </Grid>
                            )}
                            <Typography variant="caption" ml={1} />
                        </Grid>
                    </Grid>
                </Grid>
            }
            content={false}
        >
            <Grid container>
                <Grid item xs={12}>
                    <LeaderboardTable leagueId={leagueId} showAdmin={isAdmin} hasLeaderboard={hasLeaderboard} />
                </Grid>
                <Grid item mt={5} xs={12} sx={{ overflowX: 'auto', overflowY: 'hidden' }}>
                    <LadderGraph leagueId={leagueId} />
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default Leaderboard;
