import React, { useState } from 'react';
import {
    Box,
    TextField,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Chip,
    Grid,
    FormControlLabel,
    Switch,
    FormHelperText,
    InputAdornment,
    Stack,
    IconButton
} from '@mui/material';
import MapComponent from './MapComponent';
import CustomDateTime from 'views/forms/components/DateTime/CustomDateTime';
import moment from 'moment-timezone';
import { gridSpacing } from 'store/constant';
import FormComponents from 'ui-component/FormComponents';
import { Delete } from '@mui/icons-material';

const FormSection = ({ icon, title, descriptor, inputs, formik, showDelete = false, deleteAction = null }) => (
    <Box sx={{ marginBottom: '20px' }} data-test-id="views/admin/Events/create-event/components/FormSection">
        <Grid container spacing={2}>
            <Grid item xs={1}>
                {icon && <Box component="span">{icon}</Box>}
            </Grid>
            <Grid item xs={12} md={11}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h2" gutterBottom>
                        {title}
                    </Typography>
                    {showDelete && (
                        <IconButton onClick={deleteAction}>
                            <Delete color="error" />
                        </IconButton>
                    )}
                </Stack>
                <Typography variant="body2" mb={2}>
                    {descriptor}
                </Typography>
                <Grid container spacing={gridSpacing}>
                    {inputs.map((input, index) => (
                        <FormComponents index={index} input={input} formik={formik} />
                    ))}
                </Grid>
            </Grid>
        </Grid>
    </Box>
);

export default FormSection;
