import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Chip,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Button
} from '@mui/material';

// project imports
import Avatar from 'ui-component/extended/Avatar';

// assets
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import BlockTwoToneIcon from '@mui/icons-material/BlockTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import { checkInPlayer } from 'store/slices/user';
import { useDispatch } from 'react-redux';

const avatarImage = require.context('assets/images/profile', true);

// ==============================|| USER LIST 1 ||============================== //

const CheckinList = ({ data }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    // const dispatch = useDispatch();

    // const [data, setData] = React.useState([]);
    // const { usersS1 } = useSelector((state) => state.user);

    // React.useEffect(() => {
    //     setData(usersS1);
    // }, [usersS1]);

    // React.useEffect(() => {
    //     dispatch(getUsersList());
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const handleCheckIn = (id, checkIn) => {
        dispatch(checkInPlayer(id, checkIn));
    };
    return (
        <TableContainer data-test-id="views/leagues/LeagueHome/Checkin/CheckinList">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ pl: 3 }}>#</TableCell>
                        <TableCell>Player</TableCell>
                        <TableCell>Team</TableCell>
                        <TableCell>Event</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Check In</TableCell>
                        <TableCell align="center" sx={{ pr: 3 }}>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data &&
                        data.map((row, index) => (
                            <TableRow hover key={index}>
                                <TableCell sx={{ pl: 3 }}>{index + 1}</TableCell>
                                <TableCell
                                    sx={{
                                        minWidth: '150px',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    <Grid container spacing={2} alignItems="center" sx={{ minWidth: '150px', whiteSpace: 'nowrap' }}>
                                        <Grid item>{/* <Avatar alt="User 1" src={avatarImage(`./${row.avatar}`)} /> */}</Grid>
                                        <Grid container alignItems="center" sx={{ minWidth: '200px', whiteSpace: 'nowrap' }}>
                                            <Grid item>
                                                <Avatar
                                                    alt="User 1"
                                                    src={row?.user.user_attributes.profile_picture_url}
                                                    sx={{ width: 30, height: 30 }}
                                                />
                                            </Grid>
                                            <Grid item ml={1}>
                                                <Typography align="left" variant="subtitle1" component="div">
                                                    {row.user.name}{' '}
                                                    {row.status === 'Active' && (
                                                        <CheckCircleIcon sx={{ color: 'success.dark', width: 14, height: 14 }} />
                                                    )}
                                                </Typography>
                                                <Typography align="left" variant="subtitle2" noWrap>
                                                    {row.user.email}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell>{row.team?.name}</TableCell>
                                <TableCell>{row.team?.event?.title}</TableCell>
                                <TableCell>
                                    {row.name && (
                                        <Chip
                                            label="Active"
                                            size="small"
                                            sx={{
                                                background:
                                                    theme.palette.mode === 'dark'
                                                        ? theme.palette.dark.main
                                                        : theme.palette.success.light + 60,
                                                color: theme.palette.success.dark
                                            }}
                                        />
                                    )}
                                    {row.status === 'Rejected' && (
                                        <Chip
                                            label="Rejected"
                                            size="small"
                                            sx={{
                                                background:
                                                    theme.palette.mode === 'dark'
                                                        ? theme.palette.dark.main
                                                        : theme.palette.orange.light + 80,
                                                color: theme.palette.orange.dark
                                            }}
                                        />
                                    )}
                                    {row.status === 'Pending' && (
                                        <Chip
                                            label="Pending"
                                            size="small"
                                            sx={{
                                                background:
                                                    theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
                                                color: theme.palette.warning.dark
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={{ minWidth: 175 }}>
                                    <Button
                                        variant={row.checked_in ? 'contained' : 'outlined'}
                                        disableElevation
                                        endIcon={row.checked_in ? <CheckIcon stroke={1.5} size="20px" /> : null}
                                        onClick={() => handleCheckIn(row.id, !row.checked_in)}
                                    >
                                        {row.checked_in ? 'Checked In' : 'Check In'}
                                    </Button>
                                </TableCell>
                                <TableCell align="center" sx={{ pr: 3 }}>
                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                        <Tooltip placement="top" title="Message">
                                            <IconButton color="primary" aria-label="delete" size="large">
                                                <ChatBubbleTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                        {/* <Tooltip placement="top" title="Block"> */}
                                        {/* <IconButton
                                                color="primary"
                                                sx={{
                                                    color: theme.palette.orange.dark,
                                                    borderColor: theme.palette.orange.main,
                                                    '&:hover ': { background: theme.palette.orange.light }
                                                }}
                                                size="large"
                                            >
                                                <BlockTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                                            </IconButton> */}

                                        {/* </Tooltip> */}
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CheckinList;
