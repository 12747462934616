import { FormControl, Grid, InputLabel, ListSubheader, MenuItem, Select } from '@mui/material';
import useLeagues from 'hooks/useLeagues';
import useTournaments from 'hooks/useTournaments';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import capitalizeWords from 'utils/capitalizeWords';
import MedalRound from 'views/admin/AdminHub/TournamentManager/MedalRound';

const AppBracket = () => {
    const { tournamentId } = useParams();
    const [type, setType] = useState('tournament');
    const [options, setOptions] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState('');

    const { getLeagueEventList, leagueEventList } = useLeagues();
    const { getTournamentHub, tournamentDetails } = useTournaments();
    const handleInputChange = (event) => {
        setSelectedEvent(event.target.value);
    };

    let menuItems;
    if (type === 'league') {
        // For leagues, list events without grouping
        menuItems = options.map((item) => (
            // <MenuItem disabled={(item.status !== 'completed' && item.status !== 'in_progress')} key={item.id} value={item}>{item.title}</MenuItem>
            <MenuItem key={item.id} value={item}>
                {item.title}
            </MenuItem>
        ));
    } else {
        // For tournaments, group events
        const uniqueGroups = Array.from(new Set(options.map((option) => option.group)));
        const groupedOptions = uniqueGroups.map((group) => ({
            group,
            items: options.filter((option) => option.group === group)
        }));
        menuItems = groupedOptions.flatMap(({ group, items }) => [
            <ListSubheader sx={{ color: 'primary.dark' }} key={group}>
                {capitalizeWords(group)}
            </ListSubheader>,
            ...items.map((item) => (
                <MenuItem key={item.id} value={item}>
                    {item.title}
                </MenuItem>
            ))
        ]);
    }

    useEffect(() => {
        if (tournamentId) {
            getTournamentHub(tournamentId);
        }
    }, [tournamentId]);

    useEffect(() => {
        if (tournamentDetails?.event_type) {
            setType(tournamentDetails?.event_type);
            getLeagueEventList(tournamentId, tournamentDetails?.event_type);
        }
    }, [tournamentDetails]);

    useEffect(() => {
        setOptions(leagueEventList || []);
        setSelectedEvent(leagueEventList[0]);
    }, [leagueEventList]);

    return (
        <Grid>
            <Grid item xs={12} md={12} p={2}>
                <FormControl fullWidth sx={{ minWidth: 120, my: { xs: 2, md: 0 } }}>
                    <InputLabel htmlFor="grouped-select">Events</InputLabel>
                    <Select value={selectedEvent || ''} onChange={handleInputChange} label="Grouping" id="grouped-select">
                        {menuItems}
                    </Select>
                </FormControl>
            </Grid>
            {selectedEvent?.id && <MedalRound eventId={selectedEvent?.id} />}
        </Grid>
    );
};

export default AppBracket;
