import { useContext } from 'react';

// auth provider
import { PostContext } from 'contexts/PostsContext';

// ==============================|| AUTH HOOKS ||============================== //

const usePosts = () => {
    const context = useContext(PostContext);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};

export default usePosts;
