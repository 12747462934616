import moment from 'moment';

const getTimeSince = (entered, assignedAt, unit = 'seconds') => {
    const now = assignedAt ? moment.utc(assignedAt) : moment.utc();
    const enteredMoment = moment.utc(entered);

    const diff = now.diff(enteredMoment, unit);

    return diff;
};

export default getTimeSince;
