import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import RoundSelector from './RoundSelector';
import { gridSpacing } from 'store/constant';

import useLeagues from 'hooks/useLeagues';
import MatchesTable from './MatchesTable';

const Matches = ({
    eventPools,
    selectedPool,
    setSelectedPool,
    handleDrawerOpen,
    setSelectedMatchId,
    eventId,
    selectedRound,
    setSelectedRound,
    eventBracket,
    selectedEvent
}) => {
    const page = true;
    const { getPoolRounds, getPoolRoundMatches, eventMatchups, setEventMatchups, getBracketRounds, getBracketRoundMatches } = useLeagues();
    const [poolRounds, setPoolRounds] = useState([]);

    const [tab, setTab] = useState(0);

    useEffect(() => {
        if (selectedPool) {
            if (selectedPool.name === 'Bracket') {
                getBracketRounds(selectedPool).then((res) => {
                    setPoolRounds(res);
                    setSelectedRound(res[0]?.id);
                    setTab(0);
                });
            } else {
                getPoolRounds(selectedPool).then((res) => {
                    setPoolRounds(res);
                    setSelectedRound(res[0]?.id);
                    setTab(0);
                });
            }
        } else {
            setPoolRounds([]);
        }
    }, [selectedPool, eventId]);

    useEffect(() => {
        if (selectedPool && selectedRound) {
            if (selectedPool.name === 'Bracket') {
                getBracketRoundMatches(selectedPool, selectedRound?.round_number).then((res) => {
                    if (res) {
                        setEventMatchups(res?.matches);
                    } else {
                        setEventMatchups([]);
                    }
                });
            } else {
                getPoolRoundMatches(selectedRound?.id).then((res) => {
                    if (res) {
                        setEventMatchups(res?.matches);
                    } else {
                        setEventMatchups([]);
                    }
                });
            }
        } else {
            setEventMatchups([]);
        }
    }, [selectedPool, selectedRound]);

    return (
        <Grid item xs={12}>
            <RoundSelector
                eventPools={eventPools}
                selectedPool={selectedPool}
                setTab={setTab}
                poolRounds={poolRounds}
                setSelectedPool={setSelectedPool}
                setSelectedRound={setSelectedRound}
                selectedRound={selectedRound}
                tab={tab}
                eventBracket={eventBracket}
                selectedEvent={selectedEvent}
                eventId={eventId}
            />
            <MatchesTable matches={eventMatchups} handleDrawerOpen={handleDrawerOpen} setSelectedMatchId={setSelectedMatchId} />
        </Grid>
    );
};
export default Matches;
