import React, { useState, useEffect } from 'react';
import { Grid, Typography, Tabs, Tab, Link, Autocomplete, TextField, MenuItem, InputLabel, FormControl, Select } from '@mui/material';
import SubCard from 'ui-component/cards/SubCard';
import { useTheme } from '@mui/styles';
import useConfig from 'hooks/useConfig';
import useLeagues from 'hooks/useLeagues';

const RoundSelector = ({
    eventPools,
    selectedPool,
    setSelectedPool,
    poolRounds,
    setRound,
    selectedRound,
    setSelectedRound,
    tab,
    setTab,
    eventBracket,
    selectedEvent,
    eventId
}) => {
    // const [tab, setTab] = useState(0);
    const handleMainTabChange = (event, newValue) => {
        // console.log('newValue', newValue);
        setSelectedRound(poolRounds[newValue]?.id);
        setTab(newValue);
        // If we're leaving the Tournament Manager tab, reset the subtab
    };
    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`
        };
    }
    const tabOptions = ['Round 1', 'Round 2', 'Round 3', 'Round 4'];

    const theme = useTheme();
    const { setValue } = useLeagues();
    const { borderRadius } = useConfig();
    const [poolOptions, setPoolOptions] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    useEffect(() => {
        if (eventBracket) {
            setPoolOptions([...eventPools, eventBracket]);
        } else {
            setPoolOptions(eventPools);
        }
    }, [eventPools, eventId]);
    const handleChange = (item) => {
        setSelectedPool(item.id);
        setValue('poolType', item.name);
    };
    useEffect(() => {
        if (poolOptions.length > 0) {
            setMenuItems(
                poolOptions?.map((item) => [
                    <MenuItem key={item.id} value={item.id} onClick={() => handleChange(item)}>
                        {item.name}
                    </MenuItem>
                ])
            );
        } else {
            setMenuItems([]);
        }
    }, [poolOptions]);

    const handleInputChange = (event) => {
        setSelectedPool(event.target.value);
    };

    return (
        <Grid item xs={12} data-test-id="views/admin/AdminHub/TournamentManager/RoundSelector">
            <SubCard
                title={
                    <FormControl sx={{ m: 1, pr: 1 }} fullWidth>
                        <InputLabel htmlFor="pool-select">Pools</InputLabel>
                        <Select value={selectedPool} label="Pools" id="pool-select">
                            {menuItems}
                        </Select>
                    </FormControl>
                }
                sx={{ paddingLeft: 0 }}
                headerPadding={1}
            >
                <Tabs
                    value={tab}
                    onChange={handleMainTabChange}
                    variant="scrollable"
                    orientation="vertical"
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            borderBottom: 'none'
                        },
                        '& button': {
                            borderRadius: `${borderRadius}px`,
                            color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[600],
                            minHeight: 'auto',
                            minWidth: '100%',
                            py: 1.5,
                            px: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            textAlign: 'left',
                            justifyContent: 'flex-start'
                        },
                        '& button.Mui-selected': {
                            color: theme.palette.primary.main,
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[300]
                        },
                        '& button > svg': {
                            marginBottom: '0px !important',
                            marginRight: 1.25,
                            marginTop: 1.25,
                            height: 20,
                            width: 20
                        },
                        '& button > div > span': {
                            display: 'block'
                        },
                        '& > div > span': {
                            display: 'none'
                        }
                    }}
                >
                    {poolRounds?.map((option, index) => (
                        <Tab
                            // icon={<PersonOutlineTwoToneIcon />}
                            label={
                                <Grid container direction="column">
                                    <Typography component="div" variant="h6" sx={{ textTransform: 'capitalize' }}>
                                        {option?.name || `Round ${option?.round_number}`}
                                    </Typography>
                                </Grid>
                            }
                            {...a11yProps(0)}
                        />
                    ))}
                </Tabs>
            </SubCard>
        </Grid>
    );
};

export default RoundSelector;
