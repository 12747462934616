import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, Grid, IconButton } from '@mui/material';

// assets

import { CheckCircle, Delete } from '@mui/icons-material';
import PlayerCell from 'views/components/playerCell.component';

// ==============================|| SOCIAL PROFILE - FOLLOWER CARD ||============================== //

const UserListCard = ({ user, handleUserSelection, clickable = true, showDelete = false, deleteAction, showCheck }) => {
    const theme = useTheme();

    return (
        <Card
            sx={{
                padding: '16px',
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                border: '1px solid',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100],
                '&:hover': {
                    border: `1px solid${theme.palette.primary.main}`
                }
            }}
            onClick={clickable ? handleUserSelection : null}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <PlayerCell user={user} mt={0} isAdmin />
                        </Grid>
                        {showDelete && (
                            <Grid item>
                                <IconButton onClick={() => deleteAction(user.id)}>
                                    <Delete color="error" />
                                </IconButton>
                            </Grid>
                        )}

                        {showCheck && (
                            <Grid item>
                                <CheckCircle color="success" />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

UserListCard.propTypes = {
    user: PropTypes.object,
    clickable: PropTypes.bool
};

export default UserListCard;
