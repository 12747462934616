/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    CardContent,
    Drawer,
    Grid,
    Typography,
    Box,
    IconButton,
    Tooltip,
    useMediaQuery
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import MainCard from 'ui-component/cards/MainCard';
import { AllInclusive, CalendarMonth, CardMembership, CheckCircle, CreditCard, Error, Money, Timelapse } from '@mui/icons-material';
import FieldSection from 'ui-component/FieldSection';
import getReadableDateTime from 'utils/get_readable_datetime';
import CurrencyFormat from 'react-currency-format';
import capitalizeWords from 'utils/capitalizeWords';
import useClubs from 'hooks/useClubs';
import { gridSpacing } from 'store/constant';
import CustomTable from 'ui-component/table/CustomTable';

const MemberDrawer = ({ toggleDrawer, drawerOpen, memberId }) => {
    const theme = useTheme();
    const { getClubMember, member } = useClubs();
    const [anchorEl, setAnchorEl] = useState(null);
    const [invoices, setInvoices] = useState([])
    const handleClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        getClubMember(memberId)
    }, [memberId])
    const headers = [{ id: 'id', label: 'ID' }, { id: 'start', label: 'Start' }, { id: 'end', label: 'End' }, { id: 'paid', label: 'Paid' }, { id: 'amount_due', label: 'Amount Due' }]

    useEffect(() => {
        const data =
            member?.invoices?.length ?
                member?.invoices?.map((invoice) => ([
                    {
                        id: 'id',
                        data: invoice.id,
                        isVisible: true
                    },
                    {
                        id: 'start',
                        data: getReadableDateTime(invoice.start, 'fullDate'),
                        isVisible: true
                    },
                    {
                        id: 'end',
                        data: getReadableDateTime(invoice.end, 'fullDate'),
                        isVisible: true
                    },
                    {
                        id: 'paid',
                        data: invoice.paid ? <CheckCircle fontSize="small" color="success" /> : <Error color="red" fontSize="small" />,
                        isVisible: true
                    },
                    {
                        id: 'amount_due',
                        data: <CurrencyFormat
                            decimalScale={2}
                            fixedDecimalScale
                            value={invoice.amount_due / 100}
                            displayType="text"
                            thousandSeparator
                            prefix="$"
                        />,
                        isVisible: true
                    }

                ])) : []
        setInvoices(data)
    }, [member?.id])

    const matchDownSM = useMediaQuery(theme.breakpoints.down('xl'));
    const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
    const drawerBG = theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50';
    const [tooltipText, setTooltipText] = useState('Copy ID');

    const handleCopy = () => {
        navigator.clipboard.writeText(member?.id).then(() => {
            setTooltipText('Copied');
            setTimeout(() => {
                setTooltipText('Copy ID');
            }, 3000); // Reset tooltip text after 3 seconds
        });
    };


    return (
        <Drawer
            sx={{
                ml: drawerOpen ? 3 : 0,
                flexShrink: 0,
                zIndex: 1200,
                overflowX: 'hidden',
                width: { xs: '95%', sm: '75%', lg: '50%' },
                '& .MuiDrawer-paper': {
                    height: '100vh',
                    width: { xs: '95%', sm: '75%', lg: '50%' },
                    position: 'fixed',
                    border: 'none',
                    borderRadius: '0px'
                }
            }}
            variant="temporary"
            anchor="right"
            open={drawerOpen}
            ModalProps={{ keepMounted: true }}
            onClose={toggleDrawer}
        >
            {drawerOpen && (
                <MainCard
                    sx={{
                        bgcolor: matchDownLG ? 'transparent' : drawerBG,
                        paddingBottom: 150
                    }}
                    content={false}
                    elevation={16}
                    boxShadow
                    shadow={theme.shadows[6]}
                    title={
                        <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', paddingY: 0 }}>
                            <Grid>
                                <Tooltip title={tooltipText}>
                                    <Grid
                                        mb={1}
                                        sx={{
                                            border: '1px solid lightgrey',
                                            borderRadius: 1,
                                            paddingX: 1.2,
                                            display: 'inline-block',
                                            '&:hover': { cursor: 'pointer' }
                                        }}
                                        onClick={handleCopy}
                                    >
                                        <Typography variant="caption" gutterBottom color={theme.palette.grey[800]}>
                                            #{member?.id}
                                        </Typography>

                                    </Grid>
                                </Tooltip>

                                <Typography variant="h3">{member?.user?.name}</Typography>
                            </Grid>

                            <Box>
                                {/* <IconButton size="small" onClick={handleClick}>
                                    <MoreHoriz fontSize='small' />

                                </IconButton> */}
                                {/* <Menu
                                    id="menu-followers-card"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    variant="selectedMenu"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                >
                                    <MenuItem onClick={handleMerge}>
                                        <ListItemIcon>
                                            <Merge fontSize="small" />
                                        </ListItemIcon>
                                        Merge Division
                                    </MenuItem>

                                </Menu> */}
                                {/* <IconButton size="small" onClick={handleEdit} sx={{ ml: 1 }}>
                                    <Edit fontSize='small' />

                                </IconButton> */}
                                <IconButton size="small" onClick={toggleDrawer} sx={{ ml: 1 }}>
                                    <CancelIcon fontSize='small' />
                                </IconButton>
                            </Box>
                        </Grid>
                    }
                >
                    <CardContent
                        sx={{
                            height: matchDownSM ? '100vh' : 'auto'
                            // overflowY: 'auto'
                        }}
                    >
                        <Grid container direction="column" spacing={gridSpacing}>
                            <Grid item>
                                <Typography variant="h4"> Membership Details</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={6}>
                                        <FieldSection
                                            icon={<CardMembership />}
                                            label="Membership Type"
                                            value={member?.membership?.name}
                                        />
                                        <FieldSection
                                            icon={<CalendarMonth />}
                                            label="Member Since"
                                            value={getReadableDateTime(member?.membership?.start, 'fullDate')}
                                        />

                                        <FieldSection
                                            icon={<Money />}
                                            label="Price"
                                            value={<CurrencyFormat
                                                decimalScale={2}
                                                fixedDecimalScale
                                                value={member?.pricing?.price / 100}
                                                displayType="text"
                                                thousandSeparator
                                                prefix="$"
                                            />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>

                                        <FieldSection
                                            icon={<Timelapse />}
                                            label="Term"
                                            value={capitalizeWords(member?.pricing?.term)}
                                        />
                                        <FieldSection
                                            icon={<CreditCard />}
                                            label="Stripe ID"
                                            value={member?.stripe_subscription_id}
                                        />
                                    </Grid>



                                </Grid>




                            </Grid>
                            <Grid item>
                                <Typography variant='h4'> Invoices</Typography>

                            </Grid>
                            <Grid item>
                                <CustomTable sx={{ border: '1px solid lightgrey' }} headers={headers} data={invoices} />

                            </Grid>


                        </Grid>
                    </CardContent>
                </MainCard>
            )
            }
        </Drawer >
    );
};

MemberDrawer.propTypes = {
    toggleDrawer: PropTypes.func,
    drawerOpen: PropTypes.bool,
    memberId: PropTypes.number
};

export default MemberDrawer;
