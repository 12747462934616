/* eslint-disable prettier/prettier */
import React from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports

// assets
import PoolsTable from 'views/components/poolsTable/poolsTable.component';
import LeaderboardTable from './LeagueLeaderboardTable';

const Pools = ({ eventPools, setSelectedPool, setTab, eventId, eventName, handleGenerateDrawer, leagueId }) => (
    <Grid item xs={12}>
        {eventPools?.length > 0 ? (
            <PoolsTable eventPools={eventPools} setTab={setTab} setSelectedPool={setSelectedPool} />
        ) : (
            <LeaderboardTable
                leagueId={leagueId}
                eventId={eventId}
                eventName={eventName}
                showAdmin
                handleGenerateDrawer={handleGenerateDrawer}
            />
        )}
    </Grid>
);

export default Pools;
