import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import AdminLayout from 'layout/AdminLayout';
import Orders from 'views/admin/Orders';
import Memberships from 'views/club-management/pages/Settings/Memberships';
import CampaignEmails from 'views/internal-tools/Email/CampaignEmails';
import EmailPage from 'views/internal-tools/Email/EmailPage';

// dashboard routing
const CreateEvent = Loadable(lazy(() => import('views/admin/Events/create-event')));
const AdminHome = Loadable(lazy(() => import('views/admin/Events')));
const Home = Loadable(lazy(() => import('views/admin/ManageEventsHome')));

// widget routing
const WidgetStatistics = Loadable(lazy(() => import('views/widget/Statistics')));

const AdminHub = Loadable(lazy(() => import('views/admin/AdminHub')));
const Finance = Loadable(lazy(() => import('views/admin/Finance')));
const ClubInfo = Loadable(lazy(() => import('views/admin/ClubInfo')));
const EditClubProfile = Loadable(lazy(() => import('views/admin/ClubInfo/EditClubProfile')));
const AppChat = Loadable(lazy(() => import('views/application/chat')));
const ClubManagementHub = Loadable(lazy(() => import('views/club-management')));

// sample page routing

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <AdminLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/widget/statistics',
            element: <WidgetStatistics />
        },
        {
            path: 'admin/chat/:conversationId',
            element: <AppChat />
        },
        {
            path: '/tournament/hub/admin/:tournamentId',
            element: <AdminHub />
        },

        {
            path: '/:clubId/campaigns/:id',
            element: <CampaignEmails />,
            breadcrumb: true
        },
        {
            path: '/:clubId/campaigns/:id/email/:emailId',
            element: <EmailPage />,
            breadcrumb: true
        },
        {
            path: 'club/:clubId',
            element: <ClubManagementHub />,
            children: [
                {
                    path: ':tab',
                    element: <ClubManagementHub />,
                    children: [
                        {
                            path: ':id',
                            element: <ClubManagementHub />
                        }
                    ]
                }
            ]
        },

        {
            path: '/clubs',
            // element: <Clubs />,
            children: [
                {
                    path: 'home',
                    element: <Home />
                },
                {
                    path: 'events/create/:type',
                    element: <CreateEvent />
                },
                {
                    path: 'events/:type/:eventId',
                    element: <AdminHub />,
                    children: [
                        {
                            path: ':tab',
                            element: <AdminHub />
                        }
                    ]
                },

                {
                    path: 'events',
                    element: <AdminHome />
                },
                {
                    path: 'finance',
                    element: <Finance />
                },
                {
                    path: 'orders',
                    element: <Orders />
                },
                {
                    path: 'info/profile/:clubId',
                    element: <EditClubProfile />,
                    breadcrumbs: true
                },
                {
                    path: 'info/profile/create',
                    element: <EditClubProfile />,
                    breadcrumbs: true
                },
                {
                    path: 'info',
                    element: <ClubInfo />,
                    breadcrumbs: true,
                    children: [
                        {
                            path: ':tab',
                            element: <ClubInfo />
                        }
                    ]
                }
            ]
        }
    ]
};

export default AdminRoutes;
