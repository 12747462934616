/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';

// material-ui
import { Grid, TableCell, TableRow, TableHead } from '@mui/material';

// assets

// project imports
import PlayerRatings from 'views/components/playerRatings.component';
import { WindowContext } from 'contexts/WindowContext';

const PoolsTableHeader = ({ teams, isAdmin }) => {
    const { isMobile } = useContext(WindowContext);
    function renderTeamHeader(teams) {
        if (isMobile) {
            return 'Team';
        }
        let singlesTotal = 0;
        let doublesTotal = 0;
        let numSingles = 0;
        let numDoubles = 0;
        for (let i = 0; i < teams.length; i += 1) {
            const team = teams[i]?.user?.user_attributes;
            if (team?.dupr_rating && team?.dupr_rating !== 'NR') {
                doublesTotal += Number(team?.dupr_rating);
                numDoubles += 1;
            }
            if (team?.singles_dupr_rating && team?.singles_dupr_rating !== 'NR') {
                singlesTotal += Number(team?.singles_dupr_rating);
                numSingles += 1;
            }
        }
        const doublesDuprRating = doublesTotal !== 0 ? (doublesTotal / numDoubles).toPrecision(3) : 'NR';
        const singlesDuprRating = singlesTotal !== 0 ? (singlesTotal / numSingles).toPrecision(3) : 'NR';
        return (
            <Grid container>
                <Grid>Team</Grid>
                <Grid pt={0.25}>
                    <PlayerRatings
                        ml={3}
                        userAttributes={{
                            dupr_rating: doublesDuprRating,
                            singles_dupr_rating: singlesDuprRating
                        }}
                    />
                </Grid>
            </Grid>
        );
    }

    return (
        <TableHead>
            <TableRow>
                <TableCell> Rank</TableCell>
                <TableCell>{renderTeamHeader(teams)}</TableCell>
                <TableCell align="center" width={70}>
                    {' '}
                    W
                </TableCell>
                <TableCell align="center" width={40}>
                    {' '}
                    L
                </TableCell>
                <TableCell align="center" width={40}>
                    {' '}
                    PE
                </TableCell>
                <TableCell align="center" width={40}>
                    {' '}
                    PA
                </TableCell>
                <TableCell align="center" width={40}>
                    {' '}
                    PD
                </TableCell>
                <TableCell align="center" width={40}>
                    {' '}
                    PD%
                </TableCell>
                {isAdmin && (
                    <TableCell align="center" width={40}>
                        {' '}
                        Actions
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
};

export default PoolsTableHeader;
