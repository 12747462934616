import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import useAuth from '../hooks/useAuth';

export const NotificationsContext = createContext();

export function NotificationsProvider({ children }) {
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [unreadNotifications, setUnreadNotifications] = useState(0);
    const { user, token, pusher } = useAuth();
    const [page, setPage] = useState(1);

    const getNotifications = async (page = 1) => {
        setIsLoading(true);
        // const data = { user_id: user.id };
        const user_id = user.id;
        const response = await axios.get(`/api/user/notifications`, {
            params: {
                user_id,
                page
            }
        });
        setNotifications(response.data.data);
        setIsLoading(false);
        return response.data.data;
    };

    const updateNotificationStatus = async (id) => {
        setIsLoading(true);
        const data = { id };
        await axios.put(`/api/user/notifications`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
    };

    useEffect(() => {
        if (user && notifications.length === 0) {
            getNotifications();
        }
    }, [user?.id]);

    useEffect(() => {
        const UR = notifications.filter((notif) => notif.status === 0 || notif.status === false);
        setUnreadNotifications(UR.length);
    }, [notifications]);

    return (
        <NotificationsContext.Provider
            value={{
                notifications,
                setNotifications,
                isLoading,
                getNotifications,
                page,
                setPage,
                unreadNotifications,
                updateNotificationStatus
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
}
