/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
import React, { useState, createContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import useAuth from '../hooks/useAuth';
import { API_URL as apiUrl } from 'config';
import useNotifications from '../hooks/useNotifications';
// import * as Notifications from 'expo-notifications';

export const MessagesContext = createContext();

export function MessagesProvider({ children }) {
    const [conversations, setConversations] = useState([]);
    const [messages, setMessages] = useState([]);
    const [unreadMessages, setUnreadMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [messagesIsLoading, setMessagesIsLoading] = useState(false);
    const { user, token, initPusher, pusher, isAuthenticated } = useAuth();
    // const [page, setPage] = useState(1);
    // const [subscribed, setSubscribed] = useState(false);
    const [subscribedChannels, setSubscribedChannels] = useState([]);
    const { getNotifications } = useNotifications();
    const [sendingMessage, setSendingMessage] = useState(false);
    const [activeChatScreen, setActiveChatScreen] = useState(null);

    const [archiveLength, setArchiveLength] = useState();
    const [archivedConversations, setArchivedConversations] = useState([]);
    const [nextPageAvailable, setNextPageAvailable] = useState(false);
    const getConversations = async (status, page = 1, reload = false, loadMore = false) => {
        if (!reload) {
            setIsLoading(true);
        }
        const page_enabled = true;

        // const data = { user_id: user.id };
        const user_id = user.id;
        // if (loadMore && totalConvPages && page > totalConvPages) {
        //   setIsLoading(false);
        //   return;
        // }
        const response = await axios.get(`/api/conversations`, {
            params: {
                user_id,
                status,
                page,
                page_enabled
            }
        });
        setNextPageAvailable(response.data.data.next_page_url !== null);
        // setTotalConvPages(response.data.data.total);
        if (!loadMore) {
            if (status !== 'archived') {
                setConversations(response.data.data.data);
            } else {
                setArchivedConversations(response.data.data.data);
            }
        } else if (status !== 'archived') {
            setConversations((prevState) => prevState.concat(response.data.data.data));
        } else {
            setArchivedConversations((prevState) => prevState.concat(response.data.data.data));
        }

        setIsLoading(false);
        return response.data.data;
    };

    // useEffect(() => {
    //     if (user) {
    //         getConversations('active', 1);
    //     }
    // }, [user?.id]);
    const getMessages = async (conversation_id, page, refresh = false) => {
        console.log(conversation_id, page);
        if (!refresh) {
            setMessagesIsLoading(true);
        }
        const user_id = user.id;
        const response = await axios.get(`/api/messages`, {
            params: {
                conversation_id,
                page,
                user_id
            }
        });
        // setMessages(response.data.data);

        if (!refresh) {
            setMessages(response.data.data);
        } else {
            setMessages((prevState) => {
                // Assuming each message has a unique 'id' property
                const newMessages = response.data.data.filter((newMsg) => !prevState.some((prevMsg) => prevMsg.id === newMsg.id));

                // Concatenate and sort based on 'created_at'
                return [...prevState, ...newMessages].sort((a, b) => {
                    const dateA = new Date(a.created_at);
                    const dateB = new Date(b.created_at);
                    return dateB - dateA; // For ascending order. Use dateB - dateA for descending.
                });
            });
        }

        setMessagesIsLoading(false);
        return response.data.data;
    };

    const checkIfConversation = async (member_ids) => {
        setIsLoading(true);
        const response = await axios.get(`/api/conversation/check`, {
            params: {
                member_ids,
                user_id: user.id
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        setIsLoading(false);
        return response.data;
    };
    const getUnreadMessages = async (user_id) => {
        // setIsLoading(true);
        const response = await axios.get(`/api/messages/unread`, {
            params: {
                user_id
            }
        });
        setUnreadMessages(response.data.data);
        setIsLoading(false);
        return response.data;
    };
    const createGroupConversation = async (group_id) => {
        setIsLoading(true);
        const data = { user_id: user.id, group_id };
        const response = await axios.post(`/api/conversation/group`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        setIsLoading(false);
        return response.data;
    };
    const newMessage = async (conversation_id, message_text, message_type, images, hasPoll, question, options, reply_to) => {
        const sender_id = user.id;
        const data = { conversation_id, message_text, message_type, sender_id, reply_to };

        const formData = new FormData();
        for (const image of images) {
            let fileUri = image.uri;
            if (Platform.OS === 'android' && !fileUri.startsWith('file://')) {
                fileUri = `file:/${fileUri}`;
            }

            const file = {
                uri: fileUri,
                name: image.fileName || Date.now().toString(),
                type: image.type
            };

            formData.append('images[]', file);
        }
        formData.append('conversation_id', conversation_id);
        formData.append('message_text', message_text);
        formData.append('message_type', message_type);
        formData.append('sender_id', sender_id);
        if (reply_to) {
            formData.append('reply_to', reply_to);
        }
        if (hasPoll) {
            const textArray = options.map((item) => item.option_text);

            formData.append('has_poll', hasPoll);
            formData.append('question', question);
            for (let i = 0; i < textArray.length; i++) {
                formData.append('options[]', textArray[i]);
            }
        }
        try {
            const response = await axios.post(`/api/messages`, formData);

            // Check if the status is not 200
            if (response.status !== 200) {
                throw new Error('API responded with a non-200 status');
            }

            setSendingMessage(false);
            setError();

            return response.data;
        } catch (error) {
            setSendingMessage(false);
            setError(error.message); // Set the error message, you can adjust this based on what you want to display
            throw error; // optional, if you want to propagate the error to the caller
        }
    };

    const markRead = async (user_id, message_id, conversation_id) => {
        // setIsLoading(true);
        const data = {
            user_id,
            // message_id,
            conversation_id
        };
        await axios.put(`/api/messages/markread`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });

        setUnreadMessages((prevState) => prevState.filter((mess) => mess.conversation_id !== conversation_id));
        // setIsLoading(false);
    };
    const editUserConversation = async (id, status, silenced) => {
        // setIsLoading(true);
        const data = {};

        if (status) {
            data.status = status;
        }
        if (silenced !== null) {
            data.silenced = silenced;
        }
        const response = await axios.put(`/api/conversation/user/${id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        if (status === 'active') {
            getConversations('active');
        }
        return response.data;
        // setIsLoading(false);
    };
    const newConversation = async (name, description) => {
        setIsLoading(true);
        const sender_id = user.id;
        const data = { name, description, sender_id };
        const response = await axios.post(`/api/conversations`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        setIsLoading(false);
        return response.data;
    };
    const newUserConversation = async (user_id, conversation_id, status) => {
        setIsLoading(true);
        const data = { user_id, conversation_id, status };
        const response = await axios.post(`/api/conversations/user`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });

        setIsLoading(false);
        return response;
    };
    const newMessageReaction = async (user_id, message_id, reaction_name) => {
        const data = { user_id, message_id, reaction_name };
        const response = await axios.post(`/api/message/reaction`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        const updatedMessages = messages.map((message) => {
            if (message.id === message_id) {
                // assuming response.data.data contains updated reactions for the message
                return {
                    ...message,
                    reactions: [...message.reactions, response.data.data]
                };
            }
            return message;
        });

        // Update the state with the modified messages
        setMessages(updatedMessages);
        return response.data.data;
    };
    const updateMessageReaction = async (reaction_id, reaction_name) => {
        const data = { reaction_name };

        const response = await axios.put(`/api/message/reaction/${reaction_id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });

        const updatedReaction = response.data.data;
        const updatedMessages = messages.map((message) => {
            if (message.id === updatedReaction.message_id) {
                const reactionIndex = message.reactions.findIndex((reaction) => reaction.id === updatedReaction.id);
                message.reactions[reactionIndex] = updatedReaction;

                return {
                    ...message,
                    reactions: [...message.reactions]
                };
            }
            return message;
        });

        setMessages(updatedMessages); // Assuming you have a setMessages function to update the state

        return updatedReaction;
    };

    const getMessageReactions = async (message_id) => {
        const data = { message_id };

        const response = await axios.get(`/api/message/reaction`, {
            params: data,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });

        return response.data.data;
    };

    useEffect(() => {
        if (user && unreadMessages.length === 0) {
            getUnreadMessages(user.id);
        }
    }, [user && user.id]);

    // useEffect(() => {
    //     if (user && pusher) {
    //         conversations.map((conversation) => {
    //             const subscribeToChannels = async () => {
    //                 // pusher.connect();
    //                 const channel = pusher.subscribe({
    //                     channelName: `newMessageConversation.${conversation.conversation.id}`,
    //                     onEvent: debounce(async (event) => {
    //                         const newResponse = JSON.parse(event.data).data;
    //                         // console.log("NR", newResponse)
    //                         const userCheck = newResponse.sender_id === user.id;
    //                         if (unreadMessages.length === 0 && !userCheck && event.eventName === 'NewMessage') {
    //                             if (event.eventName === 'NewMessage') {
    //                                 // conversation.map((conv) => {
    //                                 //   console.log('conv', conv);
    //                                 // });
    //                                 setConversations((prevState) =>
    //                                     prevState.map((conv) => {
    //                                         if (conv.conversation.id === newResponse.conversation_id) {
    //                                             return {
    //                                                 ...conv,
    //                                                 conversation: {
    //                                                     ...conv.conversation,
    //                                                     latest_message: {
    //                                                         conversation_id: newResponse.conversation_id,
    //                                                         created_at: newResponse.created_at,
    //                                                         id: newResponse.id,
    //                                                         message_text: newResponse.message_text,
    //                                                         message_type: newResponse.message_type,
    //                                                         reply_to: newResponse.reply_to,
    //                                                         sender_id: newResponse.sender_id,
    //                                                         updated_at: newResponse.updated_at
    //                                                     }
    //                                                 }
    //                                             };
    //                                         }
    //                                         return conv;
    //                                     })
    //                                 );

    //                                 setUnreadMessages((prevState) => [
    //                                     ...prevState,
    //                                     {
    //                                         message_id: newResponse.id,
    //                                         user_id: user.id,
    //                                         read_at: null,
    //                                         conversation_id: newResponse.conversation_id
    //                                     }
    //                                 ]);
    //                             } else {
    //                                 await getConversations('active', 1, true);
    //                             }
    //                         }
    //                     }, 1000)
    //                 });
    //                 // setSubscribed(true);

    //                 return await Promise.all(channel);
    //             };
    //             subscribeToChannels();
    //         });

    //         // });
    //     }
    // }, [pusher, user?.id, conversations]);

    return (
        <MessagesContext.Provider
            value={{
                conversations,
                setConversations,
                isLoading,
                getConversations,
                // page,
                // setPage,
                getMessages,
                messages,
                setMessages,
                newMessage,
                newConversation,
                newUserConversation,
                checkIfConversation,
                getUnreadMessages,
                unreadMessages,
                setUnreadMessages,
                markRead,
                subscribedChannels,
                setSubscribedChannels,
                messagesIsLoading,
                setMessagesIsLoading,
                sendingMessage,
                activeChatScreen,
                setActiveChatScreen,
                editUserConversation,
                archiveLength,
                setArchiveLength,
                createGroupConversation,
                newMessageReaction,
                updateMessageReaction,
                getMessageReactions,
                error,
                setError,
                archivedConversations,
                nextPageAvailable
            }}
        >
            {children}
        </MessagesContext.Provider>
    );
}
