/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    CardContent,
    Drawer,
    Grid,
    useMediaQuery,
    TextField,
    Typography,
    Box,
    IconButton,
    Button,
    Switch,
    Select,
    MenuItem
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

// third-party

// project imports
import useConfig from 'hooks/useConfig';

import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets
import useLeagues from 'hooks/useLeagues';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';

// ==============================|| MAIL DRAWER ||============================== //

const GenerateBracketDrawer = ({ handleGenerateDrawer, generateDrawerOpen, selectedEvent }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const { borderRadius } = useConfig();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('xl'));

    const [error, setError] = useState()
    const [courtsAvailable, setCourtsAvailable] = useState()
    const [poolCount, setPoolCount] = useState();
    const [playUntil, setPlayUntil] = useState(11);
    const [roundCount, setRoundCount] = useState();
    const [bestOf, setBestOf] = useState(1);
    const [winByTwo, setWinByTwo] = useState(true);
    const { generatePools, getEventPools, setEventPools, pools, teams, generateBracket } = useLeagues()

    function calculateRounds(numberOfTeams) {
        return Math.ceil(Math.log2(numberOfTeams));
    }
    useEffect(() => {
        if (pools > 0) {
            setRoundCount(3)
        } else {
            setRoundCount(calculateRounds(teams))

        }
    }, [teams, pools])





    const handleGenerationSubmit = () => {

        generateBracket(selectedEvent?.id, roundCount, bestOf, winByTwo, playUntil).then(() => {
            handleGenerateDrawer();
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Bracket Generated',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
        }).catch(() => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error Generating Bracket',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        })
    }



    return (
        <Drawer
            sx={{
                ml: generateDrawerOpen ? 3 : 0,
                flexShrink: 0,
                zIndex: 1200,
                overflowX: 'hidden',
                width: { xs: 320, md: 450 },
                '& .MuiDrawer-paper': {
                    height: '100vh',
                    width: { xs: 320, md: 450 },
                    position: 'fixed',
                    border: 'none',
                    borderRadius: '0px'
                }
            }}
            variant="temporary"
            anchor="right"
            open={generateDrawerOpen}
            ModalProps={{ keepMounted: true }}
            onClose={handleGenerateDrawer}
        >
            {generateDrawerOpen && (
                <MainCard
                    sx={{
                        bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50'
                    }}
                    border={!matchDownSM}
                    content={false}
                >

                    <CardContent sx={{
                        height: matchDownSM ? 'calc(100vh - 112px)' : 'auto', // Adjust the height as needed
                        overflowY: 'auto' // This makes the content scrollable vertically
                    }}>                        <Grid container spacing={gridSpacing}>
                            {!error ?
                                <Grid item xs={12}>
                                    <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h3"> {`Event: ${selectedEvent?.title}`} </Typography>
                                        <Box>
                                            <IconButton size="small" onClick={() => handleGenerateDrawer()}>
                                                <CancelIcon />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                    <Grid mt={4}>

                                        <Typography variant="h5"> Number of Teams: {teams} </Typography>
                                    </Grid>

                                    <Grid mt={4}>
                                        <Typography variant="h5" mb={2}> Number of Pools: {pools}</Typography>

                                    </Grid>

                                    <Grid mt={4}>
                                        <Typography variant="h5" mb={2}> Number of Rounds: </Typography>
                                        <TextField value={roundCount} onChange={(event) => setRoundCount(event.target.value)} />
                                    </Grid>
                                    <Grid mt={4}>
                                        <Typography variant="h5" mb={2}> Play Until: </Typography>
                                        <TextField value={playUntil} onChange={(event) => setPlayUntil(event.target.value)} />
                                    </Grid>
                                    <Grid mt={4}>
                                        <Typography variant="h5" mb={2}> Best Of: </Typography>
                                        <Select
                                            // labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={bestOf}
                                            // label="Age"
                                            onChange={(event) => setBestOf(event.target.value)}  >
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                        </Select>
                                        {/* <TextField value={bestOf} onChange={(event) => setBestOf(event.target.value)} /> */}
                                    </Grid>
                                    <Grid container mt={4} alignItems="center">
                                        <Typography variant="h5"> Win By Two:</Typography>
                                        <Switch value={winByTwo} onChange={(event) => setWinByTwo(event.target.value)} ml={2} defaultChecked />
                                    </Grid>

                                </Grid>
                                :
                                null
                            }
                        </Grid>
                        <Grid item xs={12} mt={4}>
                            <Button
                                variant="contained"
                                fullWidth
                                type="submit"
                                onClick={handleGenerationSubmit}
                                startIcon={<AutoFixHighIcon stroke={1.5} size="20px" />}
                            >
                                Generate Bracket
                            </Button>
                        </Grid>
                    </CardContent>
                </MainCard>
            )}
        </Drawer>
    );
};

GenerateBracketDrawer.propTypes = {
    selectedEvent: PropTypes.object,
    handleGenerateDrawer: PropTypes.func,
    generateDrawerOpen: PropTypes.bool
};

export default GenerateBracketDrawer;
