import React from 'react';

// material-ui
import { Button, Grid, Menu, MenuItem, Pagination } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';

// assets
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { useTheme } from '@mui/styles';

const PaginationFooter = ({ lastPage, page, setPage, pageLength, setPageLength }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };
    const handlePageLengthChange = (row) => {
        setPageLength(row);
        handleClose();
    };

    return (
        <Grid item xs={12} sx={{ p: 3 }}>
            <Grid container justifyContent="space-between" spacing={gridSpacing}>
                <Grid item>
                    <Pagination count={lastPage} page={page} onChange={handlePageChange} color="primary" />
                </Grid>
                <Grid item>
                    <Button
                        size="large"
                        sx={{ color: theme.palette.grey[900] }}
                        color="secondary"
                        endIcon={<ExpandMoreRoundedIcon />}
                        onClick={handleClick}
                    >
                        {pageLength} Rows
                    </Button>
                    {anchorEl && (
                        <Menu
                            id="menu-user-list-style1"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            variant="selectedMenu"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                        >
                            <MenuItem onClick={() => handlePageLengthChange(10)}> 10 Rows</MenuItem>
                            <MenuItem onClick={() => handlePageLengthChange(20)}> 20 Rows</MenuItem>
                            <MenuItem onClick={() => handlePageLengthChange(30)}> 30 Rows </MenuItem>
                        </Menu>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
export default PaginationFooter;
