/* eslint-disable prettier/prettier */
import React, { useState } from 'react';

// material-ui
import { Chip, MenuItem, Menu, Checkbox, FormControlLabel } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

// assets

// project imports
import useLeagues from 'hooks/useLeagues';

const EditChip = ({ team }) => {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [isSub, setIsSub] = useState(team?.is_sub === 1);
    const [isWithdrawn, setIsWithdrawn] = useState(team?.absent === 1);
    const { putUserPlayingGroup } = useLeagues();

    const handleIsSubCheckboxChange = (event) => {
        putUserPlayingGroup(team?.id, { is_sub: event.target.checked ? 1 : 0 }).then((res) => console.log('res', res));
        setIsSub(event.target.checked);
    };

    const handleIsWithdrawnCheckboxChange = (event) => {
        putUserPlayingGroup(team?.id, { absent: event.target.checked ? 1 : 0 }).then((res) => console.log('res', res));
        setIsWithdrawn(event.target.checked);
    };

    const handleMenuClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => setMenuAnchorEl(null);

    return (
        <>
            <Chip
                size="medium"
                icon={<EditOutlinedIcon />}
                color="success"
                // onClick={() => onClickSubButton(team?.user)}
                onClick={(e) => handleMenuClick(e)}
                sx={{
                    mt: 1,
                    pl: 1.5,
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white'
                }}
            />
            <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                keepMounted
                onClose={handleClose}
                variant="selectedMenu"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <MenuItem>
                    <FormControlLabel control={<Checkbox onChange={handleIsSubCheckboxChange} />} label="Is A Sub" checked={isSub} />
                </MenuItem>
                <MenuItem>
                    <FormControlLabel
                        control={<Checkbox onChange={handleIsWithdrawnCheckboxChange} />}
                        label="Is Withdrawn"
                        checked={isWithdrawn}
                    />
                </MenuItem>
            </Menu>
        </>
    );
};

export default EditChip;
