import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { Button, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material';

// project import
import MainCard from 'ui-component/cards/MainCard';
import SkeletonProductPlaceholder from 'ui-component/cards/Skeleton/ProductPlaceholder';
import { useDispatch, useSelector } from 'store';
import { addProduct } from 'store/slices/cart';
import { openSnackbar } from 'store/slices/snackbar';
import getReadableDateTime from 'utils/get_readable_datetime';
import LeagueDefault from 'assets/images/league_default.png';
// assets
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';

const prodImage = require.context('assets/images/e-commerce', true);

// ==============================|| PRODUCT CARD ||============================== //

const LeagueCard = ({ league }) => {
    const prodProfile = LeagueDefault;

    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    }, []);
    console.log('league', league);

    return (
        <>
            {isLoading ? (
                <SkeletonProductPlaceholder />
            ) : (
                <MainCard
                    content={false}
                    boxShadow
                    sx={{
                        '&:hover': {
                            transform: 'scale3d(1.02, 1.02, 1)',
                            transition: 'all .4s ease-in-out'
                        }
                    }}
                >
                    <CardMedia
                        sx={{ height: 220 }}
                        image={prodProfile}
                        title="Contemplative Reptile"
                        component={Link}
                        to={`/web/group/${league?.group_id}`}
                    />
                    <CardContent sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    component={Link}
                                    to={`/web/group/${league?.group_id}`}
                                    variant="subtitle1"
                                    sx={{ textDecoration: 'none' }}
                                >
                                    {league?.name}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sx={{ pt: '8px !important' }}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    {/* <Rating precision={0.5} name="size-small" value={productRating} size="small" readOnly /> */}
                                    {/* <Typography variant="caption">({offerPrice}+)</Typography> */}
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            overflow: 'hidden'
                                            // height: 45
                                        }}
                                    >
                                        {league?.location}
                                    </Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Button variant="contained" sx={{ minWidth: 0 }} component={Link} to={`/league/${league?.id}`}>
                                        <Typography> league Home</Typography>
                                        {/* <ShoppingCartTwoToneIcon fontSize="small" /> */}
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                </MainCard>
            )}
        </>
    );
};

LeagueCard.propTypes = {
    league: PropTypes.object
};

export default LeagueCard;
